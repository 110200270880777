import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useMeasure } from "react-use";
import SwitchSelector from "react-switch-selector";
import _ from "lodash";

// import useSound from "use-sound";

import { imgList } from "@constants/imgList";
// import { soundList } from "@constants/soundList";
import { colors } from "@constants/colors";
import { device } from "@constants/styles";
import { InputIcon } from "@components/Inputs";
import { SelectSimple } from "@components/Selects";
import Table from "@components/Table";
import { dateConvert, getTimeArray } from "@utils/date";
import DatePicker from "@components/DatePicker/DatePickerCustom";
import Excel from "@components/Excel";
import Coming from "@components/Coming";
import {
  ModalDeviceDetail,
  ModalFilterTemp,
  ModalFilterOperation,
  ModalFilterIot,
  ModalFilterPower,
  ModalZoomImage,
} from "@components/Modals";
import GoogleMapSM from "@components/GoogleMapSM";
import {
  ChartTemperature,
  ChartNewTemperature,
  ChartOperation,
  ChartIot,
  ChartNewIot,
  ChartRssi,
  ChartNewRssi,
  ChartPower,
  ChartNewPower,
  ChartNewOperation,
} from "@components/Charts";
import { displayImage } from "@utils/image";
import { toFixed } from "@utils/string";
import {
  computePowerUnit,
  computePowerCost,
  computeSumOperationRecordUsed,
  formatDuration,
  computeSumOperationEventUsed,
} from "@utils/deviceReportService";
import {
  ModMonitoringTemp,
  ModMonitoringOparetion,
  ModMonitoringIot,
  ModMonitoringRssi,
  ModMonitoringPower,
} from "@utils/monitoring";
import { apiGet, apiPut } from "@apis/gateway";
import { loadingAction } from "@reduxs/Actions/storeAction";
import {
  deviceDetailAction,
  filterDeviceDetailAction,
  mapLocationDevicesAction,
  updateDateAction,
  isSoundPlayingAction,
  isPlayAction,
} from "@reduxs/Actions/monitoringAction";
import { stateInterface } from "@reduxs/interface";
const { RangePicker } = DatePicker;

export default function DeviceDetail() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector(
    (state: stateInterface) => state.storage.language
  );
  const deviceDetail = useSelector(
    (state: stateInterface) => state.monitoring.deviceDetail
  );
  const filterDeviceDetail = useSelector(
    (state: stateInterface) => state.monitoring.filterDeviceDetail
  );
  const filterTemp = useSelector(
    (state: stateInterface) => state.monitoring.filterTemp
  );
  const filterOpe = useSelector(
    (state: stateInterface) => state.monitoring.filterOpe
  );
  const sound = useSelector(
    (state: stateInterface) => state?.storage?.sound ?? "off"
  );
  const isSoundPlaying = useSelector(
    (state: stateInterface) => state.monitoring.isSoundPlaying
  );
  const loading = useSelector((state: stateInterface) => state.store.loading);
  const [ref, { width }]: any = useMeasure();
  const { locationID, id } = useParams();
  const [tab, setTab] = useState(1);
  const [plan, setPlan] = useState(1);
  const [init, setInit] = useState(false);
  const [modal, setModal] = useState({
    open: "",
  });
  const [zoomImage, setZoomImage] = useState("");
  const [listUnit, setListUnit] = useState<{ value: string; label: string }[]>(
    []
  );
  const [listPeriod, setListPeriod] = useState<
    { value: string; label: string }[]
  >([]);
  const [dataTempRaw, setDataTempRaw] = useState<any>({});
  const [dataTempNew, setDataTempNew] = useState<any>({});
  const [dataOperationRaw, setDataOperationRaw] = useState<any>({});
  const [dataOperationNew, setDataOperationNew] = useState<any>({});
  const [dataOperationReport, setDataOperationReport] = useState<any>({});
  const [dataPowerRaw, setDataPowerRaw] = useState<any>({});
  const [dataPowerNew, setDataPowerNew] = useState<any>({});
  const [dataPowerReport, setDataPowerReport] = useState<any>({});
  const [dataIot, setDataIot] = useState<any>({});
  const [dataIotNew, setDataIotNew] = useState<any>({});
  const [dataReportDetail, setDataReportDetail] = useState<any>({});
  const [dataLogsRaw, setDataLogsRaw] = useState<any[]>([]);
  const [dataLogsFilter, setDataLogsFilter] = useState<any[]>([]);
  const [loadSound, setLoadSound] = useState(false);
  const [search, setSearch] = useState("");
  const [rangeDate, setRangeDate] = useState<any[]>([]);
  // const [play, { stop }] = useSound(soundList.beeps, {
  //   loop: true,
  //   interrupt: true,
  //   onload: () => setLoadSound(true),
  // });

  const updateDate = useSelector(
    (state: stateInterface) => state.monitoring.updateDate
  );
  const options = [
    {
      label: (
        <div className="sw__choose">
          <div className={`font-inter weight-600 size-16 color-gray67e`}>
            Report
          </div>
        </div>
      ),
      value: 1,
    },
    {
      label: (
        <div className="sw__choose">
          <div className={`font-inter weight-600 size-16 color-gray67e`}>
            History
          </div>
        </div>
      ),
      value: 2,
    },
  ];
  const onChangeSwitch = (newValue: any) => {
    setTab(newValue);
  };

  useEffect(() => {
    return () => {
      dispatch(deviceDetailAction({}));
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(deviceDetail)) {
      checkSound();
    }
  }, [deviceDetail, sound]);

  const checkSound = async () => {
    const soundAlarm = deviceDetail?.controlInfo?.soundAlarm ?? false;
    const alarm = deviceDetail?.controlInfo?.control?.alarm ?? false;
    if (soundAlarm && alarm && sound === "on" && !isSoundPlaying) {
      // play();
      dispatch(isPlayAction(true));
      dispatch(isSoundPlayingAction(true));
    } else if (soundAlarm && alarm && sound === "on" && isSoundPlaying) {
      dispatch(isSoundPlayingAction(true));
    } else {
      dispatch(isPlayAction(false));
      dispatch(isSoundPlayingAction(false));
      // stop();
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      renderListPeriod();
    }, 60000);
    return () => clearInterval(intervalId);
  }, [filterDeviceDetail.date]);

  useEffect(() => {
    return () => {
      dispatch(deviceDetailAction({}));
    };
  }, []);

  useEffect(() => {
    return () => {
      setInit(false);
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      initData();
    }
  }, [id]);
  useEffect(() => {
    // renderListUnit();
    const d = new Date();
    dispatch(
      filterDeviceDetailAction({
        ...filterDeviceDetail,
        ...{
          unit: "hours",
          days: d.getDate().toString(),
          months: (d.getMonth() + 1).toString(),
          years: d.getFullYear().toString(),
          period: d.getHours(),
          date: dayjs(),
        },
      })
    );
    setRangeDate([dayjs().subtract(1, "day"), dayjs()]);
  }, [plan]);
  useEffect(() => {
    if (init) handleFilterTemp();
  }, [filterTemp]);
  useEffect(() => {
    renderListUnit();
    renderListPeriod();
    if (init) {
      getReport();
    }
  }, [filterDeviceDetail, init]);
  useEffect(() => {
    dispatch(updateDateAction(dayjs()));
  }, [deviceDetail]);
  useEffect(() => {
    let arr = dataLogsRaw;
    if (search !== "") {
      arr = arr.filter(
        (e) =>
          (e?.actionBy?.email ?? "")
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          (e?.actionBy?.user_fullname ?? "")
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          (e?.activityName ?? "").toLowerCase().includes(search.toLowerCase())
      );
    }
    setDataLogsFilter(arr);
  }, [dataLogsRaw, search]);
  useEffect(() => {
    if (init) getDeviceLogs();
  }, [rangeDate, init]);
  const initData = async () => {
    dispatch(loadingAction(true));
    await getDeviceDetail();
    await getPlan();
    await getDeviceDetailReport();
    setInit(true);
    dispatch(loadingAction(false));
  };
  const getReport = async () => {
    dispatch(loadingAction(true));
    // await getReportTemp();
    await getReportTempNew();
    // await getReportOperation();
    await getReportOperationNew();
    // await getReportPower();
    await getReportPowerNew();
    // await getReportIot();
    await getReportIotNew();
    dispatch(loadingAction(false));
  };
  const getDeviceDetail = async () => {
    // const res = await apiGet(
    //   `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device-report/detail/${locationID}/${id}`,
    //   {},
    //   language
    // );
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device-report/detail/${id}`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      dispatch(deviceDetailAction(res?.data?.data ?? {}));
      dispatch(
        mapLocationDevicesAction([
          {
            macAddress: res?.data?.data?.deviceInfo?.macAddress ?? "",
            index1: 0,
            index2: 0,
          },
        ])
      );
    } else {
      navigate("/404");
    }
  };
  const getDeviceDetailReport = async () => {
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device-report/device-detail/${id}`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      setDataReportDetail(res?.data?.data ?? {});
    }
  };
  const getDeviceLogs = async () => {
    dispatch(loadingAction(true));
    const res = await apiGet(
      `${
        process.env.REACT_APP_API_DOMAIN
      }/monitoring-api/device-report/${id}/pagination/activity-logs?startDate=${dayjs(
        rangeDate[0]
      ).format("YYYY-MM-DD 00:00:00")}&endDate=${dayjs(rangeDate[1]).format(
        "YYYY-MM-DD 23:59:59"
      )}&limit=9999&page=1`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      setDataLogsRaw(res?.data?.data?.data ?? []);
    }
    dispatch(loadingAction(false));
  };

  const getPlan = async () => {
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device-report/plan/${id}`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      setPlan((res?.data?.data?.plan ?? "standard") === "standard" ? 1 : 2);
    }
  };
  // const getReportTemp = async () => {
  //   const res = await apiGet(
  //     `${
  //       process.env.REACT_APP_API_DOMAIN
  //     }/monitoring-api/device-report/${id}/temperature?dateTime=${dayjs(
  //       filterDeviceDetail.date
  //     ).format("YYYY-MM-DD")} ${
  //       filterDeviceDetail.period.toString().length < 2
  //         ? `0${filterDeviceDetail.period}:00:00`
  //         : `${filterDeviceDetail.period}:00:00`
  //     }&dateTimeUnit=${filterDeviceDetail.unit}&tempUnit=${filterTemp.unit}`,
  //     {},
  //     language
  //   );
  //   if (res.status && res.code === 200) {
  //     setDataTemp({
  //       ...(res?.data?.data ?? {}),
  //       results: (res?.data?.data?.results ?? []).filter(
  //         (e: any) => !_.isEmpty(e?.data ?? {})
  //       ),
  //     });
  //   }
  // };
  const getReportTempNew = async () => {
    const res = await apiGet(
      `${
        process.env.REACT_APP_API_DOMAIN
      }/monitoring-api/device-report/${id}/temperature?summarize=false&dateTime=${dayjs(
        filterDeviceDetail.date
      ).format("YYYY-MM-DD")} ${
        filterDeviceDetail.period.toString().length < 2
          ? `0${filterDeviceDetail.period}:00:00`
          : `${filterDeviceDetail.period}:00:00`
      }&dateTimeUnit=${filterDeviceDetail.unit}&tempUnit=${filterTemp.unit}`,
      {},
      language
    );
    const temp = {
      evapCoil: null,
      highestTemp: null,
      insideTemp: null,
      lowestTemp: null,
      setPointTemp: null,
      realTemp: null,
      probe2R: null,
      id: "",
      time: null,
    };
    if (res.status && res.code === 200) {
      let arr: any[] = [];
      const array = getTimeArray(
        `${dayjs(filterDeviceDetail.date).format("YYYY-MM-DD")} ${
          filterDeviceDetail.period.toString().length < 2
            ? `0${filterDeviceDetail.period}:00:00`
            : `${filterDeviceDetail.period}:00:00`
        }`,
        filterDeviceDetail.unit,
        1
      );
      array.forEach((e) => {
        arr.push({
          ...temp,
          time: e,
          ..._.uniqBy(res?.data?.data?.result ?? [], "time").filter(
            (e1: any) => e1.time === e
          )?.[0],
        });
      });
      // console.log('arr',arr)
      if (
        filterDeviceDetail.unit !== "weeks" &&
        filterDeviceDetail.unit !== "months"
      ) {
        setDataTempNew({
          results: arr,
        });
      } else if (filterDeviceDetail.unit === "months") {
        setDataTempNew({
          results: ModMonitoringTemp(arr, 10),
        });
      } else {
        setDataTempNew({
          results: ModMonitoringTemp(arr, 5),
        });
      }
      setDataTempRaw({
        results: _.uniqBy(res?.data?.data?.result ?? [], "time"),
      });
    }
  };

  // const getReportOperation = async () => {
  //   const res = await apiGet(
  //     `${
  //       process.env.REACT_APP_API_DOMAIN
  //     }/monitoring-api/device-report/${id}/operation?dateTime=${dayjs(
  //       filterDeviceDetail.date
  //     ).format("YYYY-MM-DD")} ${
  //       filterDeviceDetail.period.toString().length < 2
  //         ? `0${filterDeviceDetail.period}:00:00`
  //         : `${filterDeviceDetail.period}:00:00`
  //     }&dateTimeUnit=${filterDeviceDetail.unit}`,
  //     {},
  //     language
  //   );
  //   if (res.status && res.code === 200) {
  //     setDataOperation({
  //       ...(res?.data?.data ?? {}),
  //       results: (res?.data?.data?.results ?? []).filter(
  //         (e: any) => !_.isEmpty(e?.data ?? {})
  //       ),
  //     });
  //   }
  // };
  const getReportOperationNew = async () => {
    const res = await apiGet(
      `${
        process.env.REACT_APP_API_DOMAIN
      }/monitoring-api/device-report/${id}/operation?summarize=false&dateTime=${dayjs(
        filterDeviceDetail.date
      ).format("YYYY-MM-DD")} ${
        filterDeviceDetail.period.toString().length < 2
          ? `0${filterDeviceDetail.period}:00:00`
          : `${filterDeviceDetail.period}:00:00`
      }&dateTimeUnit=${filterDeviceDetail.unit}`,
      {},
      language
    );
    const temp = {
      active: undefined,
      comp: undefined,
      defrost: undefined,
      door: undefined,
      fan: undefined,
      light: undefined,
    };
    if (res.status && res.code === 200) {
      let arrResults: any[] = [];
      let arrEventResults: any[] = [];
      const filter = (res?.data?.data?.result ?? []).filter(
        (e: any) => e.recordType === "record"
      );
      const filterEvent = (res?.data?.data?.result ?? []).filter(
        (e: any) => e.recordType === "event"
      );
      const filterUniq = _.uniqBy(filter, "time");
      const filterEventUniq = _.uniqBy(filterEvent, "time");
      const array = getTimeArray(
        `${dayjs(filterDeviceDetail.date).format("YYYY-MM-DD")} ${
          filterDeviceDetail.period.toString().length < 2
            ? `0${filterDeviceDetail.period}:00:00`
            : `${filterDeviceDetail.period}:00:00`
        }`,
        filterDeviceDetail.unit,
        1
      );
      array.forEach((e) => {
        arrResults.push({
          ...temp,
          time: e,
          ...filterUniq.filter((e1: any) => e1.time === e)?.[0],
        });
        arrEventResults.push({
          ...temp,
          time: e,
          ...filterEventUniq.filter((e1: any) => e1.time === e)?.[0],
        });
      });
      let compressorPeriodUseInHour = 0;
      let defrostPeriodUseInHour = 0;
      let fanPeriodUseInHour = 0;
      let activePeriodUseInHour = 0;
      let doorOpenPeriodInHour = 0;
      let doorOpenTime = 0;
      filterUniq.forEach((e: any, index: number) => {
        const {
          compressorPeriodUseInHourResult,
          defrostPeriodUseInHourResult,
          fanPeriodUseInHourResult,
          activePeriodUseInHourResult,
        } = computeSumOperationRecordUsed(
          filterUniq[index],
          filterUniq[index - 1],
          filterDeviceDetail.unit
        );
        compressorPeriodUseInHour += compressorPeriodUseInHourResult;
        defrostPeriodUseInHour += defrostPeriodUseInHourResult;
        fanPeriodUseInHour += fanPeriodUseInHourResult;
        activePeriodUseInHour += activePeriodUseInHourResult;
      });
      filterEventUniq.forEach((e: any, index: number) => {
        const { doorOpenPeriodInHourResult, doorOpenTimeResult } =
          computeSumOperationEventUsed(
            filterEventUniq[index],
            filterEventUniq[index - 1]
          );
        doorOpenPeriodInHour += doorOpenPeriodInHourResult;
        doorOpenTime += doorOpenTimeResult;
      });
      setDataOperationReport({
        compressorPeriodUseInHour: formatDuration(compressorPeriodUseInHour),
        defrostPeriodUseInHour: formatDuration(defrostPeriodUseInHour),
        fanPeriodUseInHour: formatDuration(fanPeriodUseInHour),
        activePeriodUseInHour: formatDuration(activePeriodUseInHour),
        doorOpenPeriodInHour: formatDuration(doorOpenPeriodInHour),
        doorOpenTime: `${doorOpenTime} times`,
      });
      if (
        filterDeviceDetail.unit !== "weeks" &&
        filterDeviceDetail.unit !== "months"
      ) {
        setDataOperationNew({
          results: arrResults,
          eventResults: filterEvent,
        });
      } else if (filterDeviceDetail.unit === "months") {
        setDataOperationNew({
          results: ModMonitoringOparetion(arrResults, 10),
          eventResults: filterEvent,
        });
      } else {
        setDataOperationNew({
          results: ModMonitoringOparetion(arrResults, 5),
          eventResults: filterEvent,
        });
      }
      setDataOperationRaw({
        results: _.uniqBy(filter, "time"),
        eventResults: _.uniqBy(filterEvent, "time"),
      });
    }
  };
  // const getReportPower = async () => {
  //   const res = await apiGet(
  //     `${
  //       process.env.REACT_APP_API_DOMAIN
  //     }/monitoring-api/device-report/${id}/power?dateTime=${dayjs(
  //       filterDeviceDetail.date
  //     ).format("YYYY-MM-DD")} ${
  //       filterDeviceDetail.period.toString().length < 2
  //         ? `0${filterDeviceDetail.period}:00:00`
  //         : `${filterDeviceDetail.period}:00:00`
  //     }&dateTimeUnit=${filterDeviceDetail.unit}`,
  //     {},
  //     language
  //   );
  //   if (res.status && res.code === 200) {
  //     setDataPower({
  //       ...(res?.data?.data ?? {}),
  //       results: (res?.data?.data?.results ?? []).filter(
  //         (e: any) => !_.isEmpty(e?.data ?? {})
  //       ),
  //     });
  //   }
  // };
  const getReportPowerNew = async () => {
    const res = await apiGet(
      `${
        process.env.REACT_APP_API_DOMAIN
      }/monitoring-api/device-report/${id}/power?summarize=false&dateTime=${dayjs(
        filterDeviceDetail.date
      ).format("YYYY-MM-DD")} ${
        filterDeviceDetail.period.toString().length < 2
          ? `0${filterDeviceDetail.period}:00:00`
          : `${filterDeviceDetail.period}:00:00`
      }&dateTimeUnit=${filterDeviceDetail.unit}`,
      {},
      language
    );
    const temp = {
      current: null,
      kw: null,
      power: null,
      voltage: null,
    };
    if (res.status && res.code === 200) {
      let arr: any[] = [];
      let totalKWH = 0;
      let maxKWH = 0;
      let minKWH = 0;
      const dataUniq = _.uniqBy(res?.data?.data?.result ?? [], "time");
      const array = getTimeArray(
        `${dayjs(filterDeviceDetail.date).format("YYYY-MM-DD")} ${
          filterDeviceDetail.period.toString().length < 2
            ? `0${filterDeviceDetail.period}:00:00`
            : `${filterDeviceDetail.period}:00:00`
        }`,
        filterDeviceDetail.unit,
        1
      );
      array.forEach((e) => {
        arr.push({
          ...temp,
          time: e,
          ..._.uniqBy(res?.data?.data?.result ?? [], "time").filter(
            (e1: any) => e1.time === e
          )?.[0],
        });
      });
      dataUniq.forEach((e, index) => {
        const currentKWH = computePowerUnit(
          dataUniq[index - 1],
          dataUniq[index]
        );
        if (maxKWH < currentKWH) {
          maxKWH = currentKWH;
        }
        if (minKWH > currentKWH) {
          minKWH = currentKWH;
        }
        totalKWH += currentKWH;
      });
      setDataPowerReport({
        electricityUnit: totalKWH,
        electricityAVGUnit:
          dataUniq.length > 0 ? totalKWH / dataUniq.length : 0,
        electricityMaxUnit: maxKWH,
        electricityMinUnit: minKWH,
        electricityBill: computePowerCost(totalKWH),
        mode: "Normal",
      });
      if (
        filterDeviceDetail.unit !== "weeks" &&
        filterDeviceDetail.unit !== "months"
      ) {
        setDataPowerNew({
          results: arr,
        });
      } else if (filterDeviceDetail.unit === "months") {
        setDataPowerNew({
          results: ModMonitoringPower(arr, 10),
        });
      } else {
        setDataPowerNew({
          results: ModMonitoringPower(arr, 5),
        });
      }
      setDataPowerRaw({
        results: _.uniqBy(res?.data?.data?.result ?? [], "time"),
      });
    }
  };
  // const getReportIot = async () => {
  //   const res = await apiGet(
  //     `${
  //       process.env.REACT_APP_API_DOMAIN
  //     }/monitoring-api/device-report/${id}/iot-status?dateTime=${dayjs(
  //       filterDeviceDetail.date
  //     ).format("YYYY-MM-DD")} ${
  //       filterDeviceDetail.period.toString().length < 2
  //         ? `0${filterDeviceDetail.period}:00:00`
  //         : `${filterDeviceDetail.period}:00:00`
  //     }&dateTimeUnit=${filterDeviceDetail.unit}`,
  //     {},
  //     language
  //   );
  //   if (res.status && res.code === 200) {
  //     setDataIot({
  //       ...(res?.data?.data ?? {}),
  //       results: (res?.data?.data?.results ?? []).filter(
  //         (e: any) => !_.isEmpty(e?.data ?? {})
  //       ),
  //     });
  //   }
  // };
  const getReportIotNew = async () => {
    const res = await apiGet(
      `${
        process.env.REACT_APP_API_DOMAIN
      }/monitoring-api/device-report/${id}/iot-status?summarize=false&dateTime=${dayjs(
        filterDeviceDetail.date
      ).format("YYYY-MM-DD")} ${
        filterDeviceDetail.period.toString().length < 2
          ? `0${filterDeviceDetail.period}:00:00`
          : `${filterDeviceDetail.period}:00:00`
      }&dateTimeUnit=${filterDeviceDetail.unit}`,
      {},
      language
    );
    const tempIot = {
      wifi: undefined,
      cloudStatus: undefined,
    };
    const tempRssi = {
      rssi: null,
    };
    if (res.status && res.code === 200) {
      let arrWifiResults: any[] = [];
      let arrRssiResults: any[] = [];
      const array = getTimeArray(
        `${dayjs(filterDeviceDetail.date).format("YYYY-MM-DD")} ${
          filterDeviceDetail.period.toString().length < 2
            ? `0${filterDeviceDetail.period}:00:00`
            : `${filterDeviceDetail.period}:00:00`
        }`,
        filterDeviceDetail.unit,
        1
      );
      array.forEach((e) => {
        arrWifiResults.push({
          ...tempIot,
          time: e,
          ..._.uniqBy(res?.data?.data?.result ?? [], "time").filter(
            (e1: any) => e1.time === e
          )?.[0],
        });
        arrRssiResults.push({
          ...tempRssi,
          time: e,
          ..._.uniqBy(res?.data?.data?.result ?? [], "time").filter(
            (e1: any) => e1.time === e
          )?.[0],
        });
      });
      if (
        filterDeviceDetail.unit !== "weeks" &&
        filterDeviceDetail.unit !== "months"
      ) {
        setDataIotNew({
          wifiResults: arrWifiResults,
          rssiResults: arrRssiResults,
        });
      } else if (filterDeviceDetail.unit === "months") {
        setDataIotNew({
          wifiResults: ModMonitoringIot(arrWifiResults, 10),
          rssiResults: ModMonitoringRssi(arrRssiResults, 10),
        });
      } else {
        setDataIotNew({
          wifiResults: ModMonitoringIot(arrWifiResults, 5),
          rssiResults: ModMonitoringRssi(arrRssiResults, 5),
        });
      }
    }
  };
  const handleFilterTemp = async () => {
    dispatch(loadingAction(true));
    // await getReportTemp();
    await getReportTempNew();
    dispatch(loadingAction(false));
  };
  const handleFilterIot = async () => {
    dispatch(loadingAction(true));
    // await getReportIot();
    await getReportIotNew();
    dispatch(loadingAction(false));
  };
  const handleFilterPower = async () => {
    dispatch(loadingAction(true));
    // await getReportPower();
    await getReportPowerNew();
    dispatch(loadingAction(false));
  };
  const renderUpdate = (status = "") => {
    let color = "";
    let bgColor = "";
    switch (status) {
      case "normal":
        color = "color-status-normal";
        bgColor = "bg-status-normal";
        break;
      case "powerOff":
        color = "color-status-poweroff";
        bgColor = "bg-status-poweroff";
        break;
      case "offline":
        color = "color-status-offline";
        bgColor = "bg-status-offline";
        break;
      case "alarm":
        color = "color-status-alarm";
        bgColor = "bg-status-alarm";
        break;
      case "updating":
        color = "color-status-updating";
        bgColor = "bg-status-updating";
        break;
      default:
        color = "color-status-normal";
        bgColor = "bg-status-normal";
        break;
    }
    return (
      <div className={`detail__text font-sukhumvitSet-text size-16 ${color}`}>
        <div className={`detail__dot ${bgColor}`}></div>
        อัปเดตล่าสุด: {dateConvert(updateDate)}
      </div>
    );
  };
  const renderStatus = (status = "", statusText = "") => {
    let color = "";
    switch (status) {
      case "normal":
        color = "color-status-normal";
        break;
      case "powerOff":
        color = "color-status-poweroff";
        break;
      case "offline":
        color = "color-status-offline";
        break;
      case "alarm":
        color = "color-status-alarm";
        break;
      case "updating":
        color = "color-status-updating";
        break;
      default:
        color = "color-status-normal";
        break;
    }
    return (
      <div
        className={`headline__middle-text headline__middle-text--sp size-20 weight-700 font-sukhumvitSet-text ${color}`}
      >
        {statusText}
      </div>
    );
  };
  const renderWifi = (wifi = false, rssi = 0, wifiStrength = "") => {
    if (wifiStrength === "offline") {
      return (
        <Mode active={wifi}>
          <img src={imgList[`ico_wifi_offline`]} alt="" />
        </Mode>
      );
    } else {
      if (rssi > -50) {
        return (
          <Mode active={true}>
            <img src={imgList[`ico_wifi_active`]} alt="" />
          </Mode>
        );
      } else if (rssi <= -50 && rssi >= -59) {
        return (
          <Mode active={true}>
            <img src={imgList[`ico_wifi_1`]} alt="" />
          </Mode>
        );
      } else if (rssi <= -60 && rssi >= -69) {
        return (
          <Mode active={true} isError={true}>
            <img src={imgList[`ico_wifi_2`]} alt="" />
          </Mode>
        );
      } else {
        return (
          <Mode active={true} isError={true}>
            <img src={imgList[`ico_wifi_3`]} alt="" />
          </Mode>
        );
      }
    }
  };
  const renderLight = (light = false) => {
    return (
      <Mode active={light}>
        <img
          src={imgList[`ico_light_bulb_${light ? "active" : "disable"}`]}
          alt=""
        />
      </Mode>
    );
  };
  const renderComp = (status = "", comp = false, flash = false) => {
    const active = status !== "updating" && (comp || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_snow_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderFan = (status = "", fan = false, flash = false) => {
    const active = status !== "updating" && (fan || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_fan_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderDefrost = (status = "", defrost = false, flash = false) => {
    const active = status !== "updating" && (defrost || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_bulb_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderAlarm = (alarm = false, flash = false) => {
    return (
      <Mode active={alarm || flash} isError={false}>
        <img
          src={imgList[`ico_shield_${alarm || flash ? "active" : "disable"}`]}
          alt=""
          className={`${alarm || flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderDoor = (status = "", door = false, flash = false) => {
    const active = status !== "updating" && (door || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_door_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderSound = (macAddress = "", soundAlarm = false, alarm = false) => {
    if (alarm) {
      if (soundAlarm) {
        return (
          <img
            src={imgList.ico_sound}
            alt=""
            className="headline__sound headline__sound--active"
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              onMute(macAddress);
            }}
          />
        );
      } else {
        return (
          <img
            src={imgList.ico_sound_disable}
            alt=""
            className="headline__sound"
          />
        );
      }
    } else {
      return <></>;
    }
  };
  const onMute = async (macAddress = "") => {
    const params = {};
    dispatch(loadingAction(true));
    const res = await apiPut(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device/${macAddress}/mute-alarm`,
      { ...params },
      {},
      language
    );
    dispatch(loadingAction(false));
  };
  const renderListUnit = () => {
    if (plan === 1) {
      setListUnit([
        {
          value: "hours",
          label: "Hours",
        },
        {
          value: "days",
          label: "Days",
        },
        {
          value: "weeks",
          label: "Weeks",
        },
        {
          value: "months",
          label: "Months",
        },
      ]);
    } else if (plan === 2) {
      setListUnit([
        {
          value: "hours",
          label: "Hours",
        },
        {
          value: "days",
          label: "Days",
        },
        {
          value: "weeks",
          label: "Weeks",
        },
        {
          value: "months",
          label: "Months",
        },
        {
          value: "years",
          label: "Years",
        },
      ]);
    }
  };
  const renderListPeriod = () => {
    const d = new Date();
    let a: any = [];
    if (dayjs().isSame(filterDeviceDetail.date, "date")) {
      new Array(24).fill("").forEach((e, index) => {
        if (index <= d.getHours()) {
          a.push({
            value: index,
            label: `${
              index.toString().length < 2 ? `0${index}` : index.toString()
            }:00 - ${
              (index + 1).toString().length < 2
                ? `0${index + 1}`
                : (index + 1).toString()
            }:00`,
          });
        }
      });
    } else {
      new Array(24).fill("").forEach((e, index) => {
        a.push({
          value: index,
          label: `${
            index.toString().length < 2 ? `0${index}` : index.toString()
          }:00 - ${
            (index + 1).toString().length < 2
              ? `0${index + 1}`
              : (index + 1).toString()
          }:00`,
        });
      });
    }
    setListPeriod(a);
  };
  const renderPercent = () => {
    // const a =
    //   (deviceDetail?.deviceStatus?.electricityUnit ?? 0) /
    //   ((deviceDetail?.deviceInfo?.alarmSetting?.electricityUnitPlan ?? 0) /
    //     100);
    const a =
      parseFloat(toFixed(deviceDetail?.deviceStatus?.electricityUnit ?? 0, 1)) /
      (parseFloat(
        toFixed(
          deviceDetail?.deviceInfo?.alarmSetting?.electricityUnitPlan ?? 0,
          1
        )
      ) /
        100);
    return {
      number: isPlanElectic ? parseFloat(toFixed(a, 1)) : 0,
      string: isPlanElectic ? toFixed(a, 1) : "0",
    };
  };
  const isPlanElectic =
    (deviceDetail?.deviceInfo?.alarmSetting?.electricityUnitPlan ?? null) !==
      null &&
    (deviceDetail?.deviceInfo?.alarmSetting?.electricityUnitPlan ?? null) !== 0;
  const columns = [
    {
      title: "Time",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) => {
        return (
          dayjs(a.createdAt, "DD/MM/YYYY HH:mm").valueOf() -
          dayjs(b.createdAt, "DD/MM/YYYY HH:mm").valueOf()
        );
      },
      render: (text: any) => {
        return <div className="color-graye6e">{text}</div>;
      },
    },
    {
      title: "Activity",
      dataIndex: "activityName",
      key: "activityName",
      sorter: (a: any, b: any) => a.activityName.length - b.activityName.length,
      render: (text: any) => {
        return (
          <div className="color-blueA4B font-sukhumvitSet-bold">{text}</div>
        );
      },
    },
    {
      title: "",
      dataIndex: "activityValue",
      key: "activityValue",
      render: (text: any) => {
        return (
          <Bagde className="color-blueaa2 font-sukhumvitSet-bold size-16">
            {text}
          </Bagde>
        );
      },
    },
    {
      title: "",
      dataIndex: "verify",
      key: "verify",
      render: (text: any) => {
        if (text) {
          return <IconCheck src={imgList.ico_check_blue} alt="" />;
        } else {
          return <></>;
        }
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      sorter: (a: any, b: any) => a.type.length - b.type.length,
      render: (text: any) => {
        return <div className="color-graye6e">{text}</div>;
      },
    },
    {
      title: "By",
      dataIndex: "actionBy",
      key: "actionBy",
      sorter: (a: any, b: any) => a.actionBy.length - b.actionBy.length,
      render: (text: any) => {
        return <div className="color-graye6e">{text}</div>;
      },
    },
  ];
  return (
    <Container className="detail" isTab1={tab === 1}>
      <div className="detail__head">
        <div className="detail__top">
          <div className="detail__top-head">
            <img
              src={imgList.ico_back}
              alt=""
              onClick={
                location.key !== "default"
                  ? () => navigate(-1)
                  : () => navigate("/devices")
              }
            />
            <div className="font-inter size-30 color-blue828 weight-600">
              Device Details
            </div>
          </div>
          <div className="detail__text font-sukhumvitSet-text size-16 color-gray474">
            <img src={imgList.ico_clock} alt="" />
            อัปเดตล่าสุด:
            <span className="color-black">{dateConvert(updateDate)}</span>
          </div>
          {/* {renderUpdate(deviceDetail?.deviceStatus?.status ?? "")} */}
        </div>
        <div className="detail__breadclumb">
          <div
            className="font-sukhumvitSet-text size-14 color-black pointer"
            onClick={() => navigate(`/devices`)}
          >
            Device List
          </div>
          <div className="dot" />
          <div className="font-sukhumvitSet-text size-14 active">
            Device Detail
          </div>
        </div>
        {(deviceDetail?.controlInfo?.errors ?? []).map(
          (e: any, key: number) => {
            return (
              <div className="detail__alert" key={key}>
                <img
                  src={imgList.ico_danger}
                  alt=""
                  className="detail__alert-left"
                />
                <div className="detail__alert-middle size-12 weight-600 font-sukhumvitSet-text color-white">
                  {e?.code ?? ""} :
                </div>
                <div className="detail__alert-right size-12 font-sukhumvitSet-text color-white">
                  {e?.description ?? ""}
                </div>
              </div>
            );
          }
        )}
        <Headline className="headline">
          <div className="headline__left">
            <div className="headline__cover">
              <img
                src={
                  deviceDetail?.deviceInfo?.image ?? ""
                    ? displayImage(deviceDetail?.deviceInfo?.image ?? "")
                    : imgList.ex
                }
                alt=""
              />
            </div>
            <div className="headline__left-detail">
              <div className="headline__row-head">
                <div className="headline__name size-16 weight-600 font-sukhumvitSet-text color-black">
                  {deviceDetail?.deviceInfo?.name ?? ""}
                </div>
                {renderSound(
                  deviceDetail?.controlInfo?.macAddress ?? "",
                  deviceDetail?.controlInfo?.soundAlarm ?? false,
                  deviceDetail?.controlInfo?.control?.alarm ?? false
                )}
              </div>
              <div className="headline__text-group">
                <div className="headline__text headline__text--sp">
                  <img src={imgList.ico_location_gray} alt="" />
                  <div className="color-grayf4f size-12 font-sukhumvitSet-text">
                    {deviceDetail?.name ?? ""}
                  </div>
                </div>
              </div>
              {/* <div className="headline__text-group">
                <div className="headline__text">
                  <img src={imgList.ico_build_gray} alt="" />
                  <div className="color-grayf4f size-12 font-sukhumvitSet-text">
                    โซนออฟฟิศ
                  </div>
                </div>
                <div className="headline__text">
                  <img src={imgList.ico_folder_fray} alt="" />
                  <div className="color-grayf4f size-12 font-sukhumvitSet-text">
                    ห้องเย็น
                  </div>
                </div>
              </div> */}
              <div className="headline__text-group">
                <div className="headline__text headline__text--sp">
                  <img src={imgList.ico_user_gray} alt="" />
                  <div className="color-grayf4f size-12 font-sukhumvitSet-text">
                    {deviceDetail?.isOwner ?? false
                      ? "My Device"
                      : "Shared with me"}
                  </div>
                </div>
              </div>
              <div
                className="headline__btn"
                onClick={() => setModal({ open: "device_detail" })}
              >
                <div className="size-12 weight-600 font-sukhumvitSet-text color-black">
                  ดูเพิ่มเติม
                </div>
                <img src={imgList.ico_arrow_yellow} alt="" />
              </div>
            </div>
            {/* <div className="headline__hr-top" /> */}
          </div>
          <div className="headline__map-warp">
            <div className="headline__map">
              {/* <div className="headline__hr-bottom" /> */}
              <GoogleMapSM
                lat={dataReportDetail?.coordinates?.[1] ?? 0}
                lng={dataReportDetail?.coordinates?.[0] ?? 0}
                googlePlaceId={dataReportDetail?.googlePlaceId ?? ""}
              />
            </div>
          </div>
          <div className="headline__middle">
            <div className="headline__temp">
              <div className="size-14 weight-500 font-sukhumvitSet-text color-grayf4f">
                Current Temp
              </div>
              <div className="size-36 weight-600 font-sukhumvitSet-text color-gray333">
                {toFixed(deviceDetail?.deviceInfo?.currentTemp ?? 0, 1)}
                <span className="size-16">°C</span>
              </div>
            </div>
            <div className="headline__group">
              {renderWifi(
                deviceDetail?.controlInfo?.control?.wifi ?? false,
                deviceDetail?.controlInfo?.rssi ?? 0,
                deviceDetail?.controlInfo?.wifiStrength ?? "good"
              )}
              {renderLight(deviceDetail?.controlInfo?.control?.light ?? false)}
              {renderDefrost(
                deviceDetail?.deviceStatus?.status ?? "",
                deviceDetail?.controlInfo?.control?.defrost ?? false,
                deviceDetail?.controlInfo?.flashing?.defrost ?? false
              )}
              {renderFan(
                deviceDetail?.deviceStatus?.status ?? "",
                deviceDetail?.controlInfo?.control?.fan ?? false,
                deviceDetail?.controlInfo?.flashing?.fan ?? false
              )}
              {renderComp(
                deviceDetail?.deviceStatus?.status ?? "",
                deviceDetail?.controlInfo?.control?.comp ?? false,
                deviceDetail?.controlInfo?.flashing?.comp ?? false
              )}
              {renderAlarm(
                deviceDetail?.controlInfo?.control?.alarm ?? false,
                deviceDetail?.controlInfo?.flashing?.alarm ?? false
              )}
              {renderDoor(
                deviceDetail?.deviceStatus?.status ?? "",
                deviceDetail?.controlInfo?.control?.door ?? false,
                deviceDetail?.controlInfo?.flashing?.door ?? false
              )}
            </div>
            <div className="headline__middle-group">
              <div className="headline__middle-left">
                <div className="headline__middle-text size-12 font-sukhumvitSet-text color-grayd7d">
                  Status
                </div>
                {renderStatus(
                  deviceDetail?.deviceStatus?.status ?? "",
                  deviceDetail?.deviceStatus?.statusDisplayText ?? ""
                )}
              </div>
              <div className="headline__middle-right">
                <div className="headline__middle-text size-12 font-sukhumvitSet-text color-grayd7d">
                  Mode :{" "}
                  <span className="color-black">
                    {deviceDetail?.deviceStatus?.modeDisplayText ?? ""}
                  </span>
                </div>
                <div className="headline__middle-text size-12 font-sukhumvitSet-text color-grayd7d">
                  Voltage :{" "}
                  <span className="color-black">
                    {deviceDetail?.deviceStatus?.voltage ?? ""}V
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="headline__hr" />
          <div className="headline__right" ref={ref}>
            <Progress
              className="progress"
              width={width}
              percent={isPlanElectic ? renderPercent().number : 0}
              spColor={
                !isPlanElectic
                  ? false
                  : renderPercent().number > 100
                  ? true
                  : false
              }
            >
              <div className="progress__progress">
                <div className="progress__bar-overflow">
                  <div className="progress__bar" />
                  <div className="progress__detail">
                    <div className="progress__avg size-12 font-sukhumvitSet-text color-grayf4f">
                      24H Avg
                    </div>
                    <div className="progress__percent size-30 weight-600 font-sukhumvitSet-text color-blue828">
                      {isPlanElectic ? renderPercent().string : "-"}%
                    </div>
                    <div className="size-12 weight-500 font-sukhumvitSet-text color-gray282">
                      คงเหลือ{" "}
                      {isPlanElectic
                        ? toFixed(
                            parseFloat(
                              toFixed(
                                deviceDetail?.deviceInfo?.alarmSetting
                                  ?.electricityUnitPlan ?? 0,
                                1
                              )
                            ) -
                              parseFloat(
                                toFixed(
                                  deviceDetail?.deviceStatus?.electricityUnit ??
                                    0,
                                  1
                                )
                              ),
                            1
                          )
                        : "-"}
                      {` kWh`}
                    </div>
                  </div>
                </div>
                <div className="progress__row">
                  <div className="progress__row-detail">
                    <div className="progress__dot progress__dot--1" />
                    <div className="progress__row-text text-left">
                      <div className="size-12 weight-500 font-sukhumvitSet-text color-grayf4f">
                        Used
                      </div>
                      <div className="size-26 weight-700 font-sukhumvitSet-text color-gray333">
                        {isPlanElectic
                          ? toFixed(
                              deviceDetail?.deviceStatus?.electricityUnit ?? 0,
                              1
                            )
                          : "-"}{" "}
                        <span className="size-16">kWh</span>
                      </div>
                    </div>
                  </div>
                  <div className="progress__row-detail progress__row-detail--2">
                    <div className="progress__dot progress__dot--2" />
                    <div className="progress__row-text text-right">
                      <div className="size-12 weight-500 font-sukhumvitSet-text color-grayf4f">
                        Electricity unit plan
                      </div>
                      <div className="size-26 weight-700 font-sukhumvitSet-text color-gray333">
                        {isPlanElectic
                          ? toFixed(
                              deviceDetail?.deviceInfo?.alarmSetting
                                ?.electricityUnitPlan ?? 0,
                              1
                            )
                          : "-"}{" "}
                        <span className="size-16">kWh</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Progress>
          </div>
        </Headline>
        <div className="detail__filter">
          <div className="detail__filter-left">
            <div className="detail__switch">
              <SwitchWarp className="sw">
                <SwitchSelector
                  forcedSelectedIndex={options.findIndex(
                    ({ value }) => value === tab
                  )}
                  onChange={onChangeSwitch}
                  options={options}
                  backgroundColor={"#353b48"}
                  fontColor={"#f5f6fa"}
                />
              </SwitchWarp>
            </div>
            {tab === 1 && (
              <>
                <div className="detail__period">
                  <SelectSimple
                    id="unit"
                    labelText="Unit"
                    value={filterDeviceDetail.unit}
                    options={listUnit}
                    placeholder="แสดงทั้งหมด"
                    classNameWarp=""
                    onChange={(e) => {
                      dispatch(
                        filterDeviceDetailAction({
                          ...filterDeviceDetail,
                          unit: e,
                        })
                      );
                    }}
                  />
                </div>
                <div className="detail__period">
                  <DatePick id="date-search">
                    <TextDatePick>Period Date</TextDatePick>
                    <DatePicker
                      allowClear={false}
                      value={filterDeviceDetail.date}
                      picker={
                        filterDeviceDetail.unit === "hours" ||
                        filterDeviceDetail.unit === "days" ||
                        filterDeviceDetail.unit === "weeks"
                          ? "date"
                          : "month"
                      }
                      format={
                        filterDeviceDetail.unit === "hours" ||
                        filterDeviceDetail.unit === "days" ||
                        filterDeviceDetail.unit === "weeks"
                          ? "DD/MM/YYYY"
                          : "MM/YYYY"
                      }
                      disabledDate={(current) => {
                        return (
                          current > dayjs() ||
                          current <
                            dayjs().subtract(plan === 1 ? 3 : 12, "month")
                        );
                      }}
                      getPopupContainer={() =>
                        document.getElementById("date-search") as HTMLElement
                      }
                      onChange={(e) => {
                        const d = new Date();
                        let period = filterDeviceDetail.period;
                        if (
                          dayjs().isSame(e, "date") &&
                          filterDeviceDetail.period > d.getHours()
                        ) {
                          period = d.getHours();
                        }
                        dispatch(
                          filterDeviceDetailAction({
                            ...filterDeviceDetail,
                            date: e,
                            period: period,
                          })
                        );
                      }}
                    />
                  </DatePick>
                </div>
                <div className="detail__period">
                  <SelectSimple
                    id="period"
                    labelText="Period Time"
                    value={filterDeviceDetail.period}
                    options={listPeriod}
                    placeholder="แสดงทั้งหมด"
                    classNameWarp=""
                    disabled={
                      filterDeviceDetail.unit === "hours" ? false : true
                    }
                    onChange={(e) => {
                      dispatch(
                        filterDeviceDetailAction({
                          ...filterDeviceDetail,
                          period: e,
                        })
                      );
                    }}
                  />
                </div>
              </>
            )}
            {tab === 2 && (
              <div className="detail__search detail__search--desktop">
                <InputIcon
                  type="text"
                  value={search}
                  placeholder="Search Activity"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            )}
          </div>
          {tab === 2 && (
            <div className="detail__filter-right">
              <Excel
                fileName={`${id}-logs-${dayjs().format("YYYYMMDDHHmmss")}`}
                data={(dataLogsFilter ?? []).map((e: any) => {
                  return {
                    Time: dayjs(e?.createdAt ?? "").format(
                      "DD/MM/YYYY HH:mm:ss"
                    ),
                    Activity: `${e?.activityName ?? "-"} ${
                      e?.activityValue ?? "-"
                    } ${e?.verify ?? false ? "checked" : "notchecked"}`,
                    Type: e?.type ?? "-",
                    By:
                      e?.actionByType === "user"
                        ? (e?.actionBy?.user_fullname ?? "") !== ""
                          ? e?.actionBy?.user_fullname ?? ""
                          : e?.actionBy?.email ?? "-"
                        : e?.actionByType === "automation"
                        ? "Automation"
                        : e?.actionByType === "device"
                        ? "Device"
                        : e?.actionByType === "protectedMode"
                        ? "Protected Mode"
                        : e?.actionByType,
                  };
                })}
                button={
                  <div className="detail__download">
                    <img src={imgList.ico_download} alt="" />
                  </div>
                }
              />
              <div className="detail__date detail__date--desktop">
                <DatePick id="date-search">
                  <RangePicker
                    value={[rangeDate[0], rangeDate[1]]}
                    format="DD/MM/YYYY"
                    disabledDate={(current) => {
                      return (
                        current > dayjs() ||
                        current < dayjs().subtract(7, "day")
                      );
                    }}
                    getPopupContainer={() =>
                      document.getElementById("date-search") as HTMLElement
                    }
                    allowClear={false}
                    onChange={(e) => setRangeDate([e?.[0], e?.[1]])}
                  />
                </DatePick>
              </div>
            </div>
          )}
        </div>
        <div
          className={`detail__filter detail__filter--ismobile ${
            tab === 2 ? "show" : ""
          }`}
        >
          <div className="detail__filter-left">
            <div className="detail__search">
              <InputIcon
                type="text"
                value={search}
                placeholder="Search Activity"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
            <div className="detail__date">
              <DatePick id="date-search">
                <RangePicker
                  value={[rangeDate[0], rangeDate[1]]}
                  format="DD/MM/YYYY"
                  disabledDate={(current) => {
                    return (
                      current > dayjs() || current < dayjs().subtract(7, "day")
                    );
                  }}
                  getPopupContainer={() =>
                    document.getElementById("date-search") as HTMLElement
                  }
                  allowClear={false}
                  onChange={(e) => setRangeDate([e?.[0], e?.[1]])}
                />
              </DatePick>
            </div>
          </div>
        </div>
      </div>
      <div className="detail__content">
        {tab === 1 ? (
          <>
            <div className="detail__card">
              <div className="detail__card-head">
                <div className="detail__card-name">
                  <img src={imgList.ico_temperature} alt="" />
                  <div className="size-20 weight-700 font-sukhumvitSet-text color-black">
                    Temperature
                  </div>
                </div>
                <div className="detail__card-filter">
                  <img
                    src={imgList.ico_filter}
                    alt=""
                    className="w-50"
                    onClick={() => setModal({ open: "filter_temp" })}
                  />
                  {/* <img src={imgList.ico_download} alt="" className="w-50" /> */}
                  <img
                    src={imgList.ico_arrow_yellow}
                    alt=""
                    className="w-33 last"
                    // onClick={() =>
                    //   navigate(`/device-detail/${locationID}/${id}/temperature`)
                    // }
                    onClick={() => navigate(`/device-detail/${id}/temperature`)}
                  />
                </div>
              </div>
              <Highlight className="high temp">
                <div className="high__box">
                  <img src={imgList.ico_low} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      Lowest
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataTempRaw?.results ?? []).length > 0
                        ? toFixed(
                            _.orderBy(
                              dataTempRaw?.results ?? [],
                              filterTemp.view === "inside"
                                ? "insideTemp"
                                : "evapCoil",
                              "asc"
                            )?.[0]?.[
                              filterTemp.view === "inside"
                                ? "insideTemp"
                                : "evapCoil"
                            ] ?? 0,
                            1
                          )
                        : "-"}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        °C
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_high} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      Highest
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataTempRaw?.results ?? []).length > 0
                        ? toFixed(
                            _.orderBy(
                              dataTempRaw?.results ?? [],
                              filterTemp.view === "inside"
                                ? "insideTemp"
                                : "evapCoil",
                              "desc"
                            )?.[0]?.[
                              filterTemp.view === "inside"
                                ? "insideTemp"
                                : "evapCoil"
                            ] ?? 0,
                            1
                          )
                        : "-"}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        °C
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_avg} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      Average
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataTempRaw?.results ?? []).length > 0
                        ? toFixed(
                            dataTempRaw?.results ?? [].length > 0
                              ? _.meanBy(dataTempRaw?.results ?? [], (p: any) =>
                                  filterTemp.view === "inside"
                                    ? p?.insideTemp ?? 0
                                    : p?.evapCoil ?? 0
                                )
                              : 0,
                            1
                          )
                        : "-"}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        °C
                      </span>
                    </div>
                  </div>
                </div>
              </Highlight>
              {!loading && (
                <>
                  <ChartNewTemperature data={dataTempNew?.results ?? []} />
                  {/* <TemperatureAmchart datas={dataTempNew?.results ?? []} /> */}
                </>
              )}
            </div>
            <div className="detail__card detail__card--margin">
              <div className="detail__card-head">
                <div className="detail__card-name">
                  <img src={imgList.ico_operation} alt="" />
                  <div className="size-20 weight-700 font-sukhumvitSet-text color-black">
                    Operation
                  </div>
                </div>
                <div className="detail__card-filter">
                  <img
                    src={imgList.ico_filter}
                    alt=""
                    className="w-50"
                    onClick={() => setModal({ open: "filter_oparetion" })}
                  />
                  {/* <img src={imgList.ico_download} alt="" className="w-50" /> */}
                  <img
                    src={imgList.ico_arrow_yellow}
                    alt=""
                    className="w-33 last"
                    // onClick={() =>
                    //   navigate(`/device-detail/${locationID}/${id}/operation`)
                    // }
                    onClick={() => navigate(`/device-detail/${id}/operation`)}
                  />
                </div>
              </div>
              <Highlight className="high operation">
                <div className="high__box">
                  <img src={imgList.ico_ice} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      Compressor
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataOperationRaw?.results ?? []).length > 0
                        ? (
                            dataOperationReport?.compressorPeriodUseInHour ?? ""
                          )?.split(" ")?.[0] ?? ""
                        : "-"}{" "}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        {(
                          dataOperationReport?.compressorPeriodUseInHour ?? ""
                        )?.split(" ")?.[1] ?? ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_water_2} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      Defrost
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataOperationRaw?.results ?? []).length > 0
                        ? (
                            dataOperationReport?.defrostPeriodUseInHour ?? ""
                          )?.split(" ")?.[0] ?? ""
                        : "-"}{" "}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        {(
                          dataOperationReport?.defrostPeriodUseInHour ?? ""
                        )?.split(" ")?.[1] ?? ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_fan_2} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      Fan
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataOperationRaw?.results ?? []).length > 0
                        ? (
                            dataOperationReport?.fanPeriodUseInHour ?? ""
                          )?.split(" ")?.[0] ?? ""
                        : "-"}{" "}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        {(dataOperationReport?.fanPeriodUseInHour ?? "")?.split(
                          " "
                        )?.[1] ?? ""}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_hour} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      Door
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataOperationRaw?.results ?? []).length > 0
                        ? (dataOperationReport?.doorOpenTime ?? "")?.split(
                            " "
                          )?.[0] ?? ""
                        : "-"}{" "}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        {(dataOperationReport?.doorOpenTime ?? "")?.split(
                          " "
                        )?.[1] ?? ""}{" "}
                        <span className="size-12 font-sukhumvitSet-text color-gray797">
                          {dataOperationReport?.doorOpenPeriodInHour ?? ""
                            ? `(${
                                (dataOperationRaw?.results ?? []).length > 0
                                  ? dataOperationReport?.doorOpenPeriodInHour ??
                                    ""
                                  : "-"
                              })`
                            : ""}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </Highlight>
              {/* <ChartOperation data={dataOperation?.results ?? []} /> */}
              {!loading && (
                <ChartNewOperation
                  isEvent={filterOpe.recordType !== "record"}
                  data={
                    dataOperationNew?.[
                      filterOpe.recordType === "record"
                        ? "results"
                        : "eventResults"
                    ] ?? []
                  }
                />
              )}
            </div>
            <div className="detail__card detail__card--margin">
              <div className="detail__card-head">
                <div className="detail__card-name">
                  <img src={imgList.ico_power} alt="" />
                  <div className="size-20 weight-700 font-sukhumvitSet-text color-black">
                    Power Meter
                  </div>
                </div>
                <div className="detail__card-filter">
                  {/* <img
                    src={imgList.ico_filter}
                    alt=""
                    className="w-50"
                    onClick={() => setModal({ open: "filter_power" })}
                  /> */}
                  {/* <img src={imgList.ico_download} alt="" className="w-50" /> */}
                  <img
                    src={imgList.ico_arrow_yellow}
                    alt=""
                    className="w-33 last"
                    // onClick={() =>
                    //   navigate(`/device-detail/${locationID}/${id}/power`)
                    // }
                    onClick={() => navigate(`/device-detail/${id}/power`)}
                  />
                </div>
              </div>
              <Highlight className="high temp">
                <div className="high__box">
                  <img src={imgList.ico_elec} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-14 font-sukhumvitSet-text color-graye6e">
                      Electricity unit
                    </div>
                    <div className="size-18 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataPowerRaw?.results ?? []).length > 0
                        ? toFixed(dataPowerReport?.electricityUnit ?? 0, 2)
                        : "-"}{" "}
                      <span className="size-10 font-sukhumvitSet-text color-black">
                        unit
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_bill} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-14 font-sukhumvitSet-text color-graye6e">
                      Electricity bill
                    </div>
                    <div className="size-18 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataPowerRaw?.results ?? []).length > 0
                        ? toFixed(dataPowerReport?.electricityBill ?? 0, 2)
                        : "-"}{" "}
                      <span className="size-10 font-sukhumvitSet-text color-black">
                        Baht
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div className="high__box">
                  <img src={imgList.ico_high} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-14 font-sukhumvitSet-text color-graye6e">
                      Maximum unit
                    </div>
                    <div className="size-18 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataPowerRaw?.results ?? []).length > 0
                        ? toFixed(dataPowerReport?.electricityMaxUnit ?? 0, 2)
                        : "-"}{" "}
                      <span className="size-10 font-sukhumvitSet-text color-black">
                        unit
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_low} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-14 font-sukhumvitSet-text color-graye6e">
                      Minimum unit
                    </div>
                    <div className="size-18 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataPowerRaw?.results ?? []).length > 0
                        ? toFixed(dataPowerReport?.electricityMinUnit ?? 0, 2)
                        : "-"}{" "}
                      <span className="size-10 font-sukhumvitSet-text color-black">
                        unit
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_mode} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-14 font-sukhumvitSet-text color-graye6e">
                      Average unit
                    </div>
                    <div className="size-18 weight-700 font-sukhumvitSet-bold color-black">
                      {(dataPowerRaw?.results ?? []).length > 0
                        ? toFixed(dataPowerReport?.electricityAVGUnit ?? 0, 2)
                        : "-"}{" "}
                      <span className="size-10 font-sukhumvitSet-text color-black">
                        unit
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className="high__box">
                  <img src={imgList.ico_avg} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-14 font-sukhumvitSet-text color-graye6e">
                      Mode
                    </div>
                    <div className="size-18 weight-700 font-sukhumvitSet-bold color-black">
                      {dataPowerReport?.mode ?? ""}
                    </div>
                  </div>
                </div>
              </Highlight>
              <div className="detail__row-chart">
                {/* <ChartPower data={dataPower?.results ?? []} type="kw" />
                <ChartPower data={dataPower?.results ?? []} type="voltage" />
                <ChartPower data={dataPower?.results ?? []} type="power" />
                <ChartPower data={dataPower?.results ?? []} type="current" /> */}
                {!loading && (
                  <>
                    <ChartNewPower
                      data={dataPowerNew?.results ?? []}
                      type="kw"
                    />
                    <ChartNewPower
                      data={dataPowerNew?.results ?? []}
                      type="voltage"
                    />
                    <ChartNewPower
                      data={dataPowerNew?.results ?? []}
                      type="power"
                    />
                    <ChartNewPower
                      data={dataPowerNew?.results ?? []}
                      type="current"
                    />
                  </>
                )}
              </div>
            </div>
            <div className="detail__card detail__card--margin">
              <div className="detail__card-head">
                <div className="detail__card-name">
                  <img src={imgList.ico_iot} alt="" />
                  <div className="size-20 weight-700 font-sukhumvitSet-text color-black">
                    IOT
                  </div>
                </div>
                <div className="detail__card-filter">
                  {/* <img
                    src={imgList.ico_filter}
                    alt=""
                    className="w-50"
                    onClick={() => setModal({ open: "filter_iot" })}
                  /> */}
                  {/* <img src={imgList.ico_download} alt="" className="w-50" /> */}
                  <img
                    src={imgList.ico_arrow_yellow}
                    alt=""
                    className="w-33 last"
                    // onClick={() =>
                    //   navigate(`/device-detail/${locationID}/${id}/iot`)
                    // }
                    onClick={() => navigate(`/device-detail/${id}/iot`)}
                  />
                </div>
              </div>
              {/* <Highlight className="high iot">
                <div className="high__box">
                  <img src={imgList.ico_elec} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      Offline Device
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {toFixed(dataPower?.electricityUnit ?? 0, 2)}{" "}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        unit
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_bill} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      Max. offline
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {toFixed(dataPower?.electricityBill ?? 0, 2)}{" "}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        Baht
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_high} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      MTTR
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {toFixed(dataPower?.electricityMaxUnit ?? 0, 2)}{" "}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        unit
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_low} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      Lowest (Rssi)
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {toFixed(dataPower?.electricityMinUnit ?? 0, 2)}{" "}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        unit
                      </span>
                    </div>
                  </div>
                </div>
                <div className="high__box">
                  <img src={imgList.ico_mode} alt="" className="high__img" />
                  <div className="high__detail">
                    <div className="size-16 font-sukhumvitSet-text color-graye6e">
                      Highest (Rssi)
                    </div>
                    <div className="size-22 weight-700 font-sukhumvitSet-bold color-black">
                      {toFixed(dataPower?.electricityAVGUnit ?? 0, 2)}{" "}
                      <span className="size-12 font-sukhumvitSet-text color-black">
                        unit
                      </span>
                    </div>
                  </div>
                </div>
              </Highlight> */}
              {!loading && (
                <>
                  <ChartNewIot data={dataIotNew?.wifiResults ?? []} />
                  <ChartNewRssi data={dataIotNew?.rssiResults ?? []} />
                </>
              )}
              {/* <ChartIot data={dataIot?.results ?? []} />
              <ChartRssi data={dataIot?.results ?? []} /> */}
            </div>
          </>
        ) : (
          // <div className="detail__comming">
          //   <Coming />
          // </div>
          <TableWarp>
            <Table
              showSorterTooltip={false}
              columns={columns}
              dataSource={(dataLogsFilter ?? []).map(
                (e: any, index: number) => {
                  return {
                    key: index,
                    createdAt: dayjs(e?.createdAt ?? "").format(
                      "DD/MM/YYYY HH:mm:ss"
                    ),
                    activityName: e?.activityName ?? "-",
                    verify: e?.verify ?? false,
                    activityValue: e?.activityValue ?? "-",
                    type: e?.type ?? "-",
                    // actionBy: _.isNull(e?.actionBy)
                    //   ? "Device"
                    //   : (e?.actionBy?.user_fullname ?? "") !== ""
                    //   ? e?.actionBy?.user_fullname ?? ""
                    //   : e?.actionBy?.email ?? "-",
                    actionBy:
                      e?.actionByType === "user"
                        ? (e?.actionBy?.user_fullname ?? "") !== ""
                          ? e?.actionBy?.user_fullname ?? ""
                          : e?.actionBy?.email ?? "-"
                        : e?.actionByType === "automation"
                        ? "Automation"
                        : e?.actionByType === "device"
                        ? "Device"
                        : e?.actionByType === "protectedMode"
                        ? "Protected Mode"
                        : e?.actionByType,
                  };
                }
              )}
            />
          </TableWarp>
        )}
      </div>
      <ModalDeviceDetail
        open={modal.open === "device_detail"}
        data={dataReportDetail}
        name={deviceDetail?.deviceInfo?.name ?? "-"}
        onOk={() => {}}
        onClose={() =>
          setModal({
            ...modal,
            open: "",
          })
        }
        onZoomImage={(e) => setZoomImage(e)}
      />
      <ModalZoomImage
        open={zoomImage !== ""}
        image={zoomImage}
        onClose={() => setZoomImage("")}
      />
      <ModalFilterTemp
        open={modal.open === "filter_temp"}
        onOk={() => {
          setModal({
            ...modal,
            open: "",
          });
          // handleFilterTemp();
        }}
        onClose={() =>
          setModal({
            ...modal,
            open: "",
          })
        }
      />
      <ModalFilterOperation
        open={modal.open === "filter_oparetion"}
        onOk={() => {
          setModal({
            ...modal,
            open: "",
          });
          // handleFilterTemp();
        }}
        onClose={() =>
          setModal({
            ...modal,
            open: "",
          })
        }
      />
      <ModalFilterIot
        open={modal.open === "filter_iot"}
        onOk={() => {
          setModal({
            ...modal,
            open: "",
          });
          handleFilterIot();
        }}
        onClose={() =>
          setModal({
            ...modal,
            open: "",
          })
        }
      />
      <ModalFilterPower
        open={modal.open === "filter_power"}
        onOk={() => {
          setModal({
            ...modal,
            open: "",
          });
          handleFilterPower();
        }}
        onClose={() =>
          setModal({
            ...modal,
            open: "",
          })
        }
      />
    </Container>
  );
}

interface IStyle {
  active: boolean;
  isError?: boolean;
}

interface IStyleProgress {
  width: number;
  percent: number;
  spColor: boolean;
}

const TableWarp = styled.div`
  max-width: 100%;
  overflow: auto;
`;
const IconCheck = styled.img`
  width: 16px;
`;
const Bagde = styled.div`
  display: inline-block;
  padding: 2px 8px 0px;
  border-radius: 16px;
  background: #f0f9ff;
`;
const Highlight = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 1.389vw 0;
  &.temp {
    > div {
      width: calc(95% / 3);
    }
  }
  &.operation {
    > div {
      width: calc(92% / 4);
    }
  }
  &.power {
    > div {
      width: calc(94% / 6);
    }
  }
  &.iot {
    > div {
      width: calc(95% / 5);
    }
  }
  @media ${device.desktopL} {
    margin: 20px 0;
  }
  @media ${device.laptop} {
    flex-wrap: wrap;
    &.temp {
      > div {
        width: calc(100 - 8px / 3);
      }
    }
    &.operation {
      > div {
        width: calc(50% - 8px);
        margin: 0 0 16px;
      }
    }
    &.power {
      > div {
        width: calc(50% - 8px);
        margin: 0 0 16px;
      }
    }
  }
  @media ${device.mobileLL} {
    &.temp {
      > div {
        width: 100%;
        margin: 0 0 16px;
      }
    }
    &.operation {
      > div {
        width: 100%;
        margin: 0 0 16px;
      }
    }
    &.power {
      > div {
        width: 100%;
        margin: 0 0 16px;
      }
    }
  }
  .high {
    &__box {
      display: flex;
      align-items: flex-start;
      background: rgba(254, 188, 17, 0.08);
      border-radius: 0.694vw;
      padding: 1.111vw 1.111vw 0.694vw;
      @media ${device.desktopL} {
        border-radius: 10px;
        padding: 16px 16px 10px;
      }
    }
    &__img {
      width: 2.361vw;
      display: block;
      margin: 0 0.694vw 0 0;
      @media ${device.desktopL} {
        width: 34px;
        margin: 0 10px 0 0;
      }
    }
    &__detail {
      width: calc(100% - 2.361vw - 0.694vw);
      @media ${device.desktopL} {
        width: calc(100% - 34px - 10px);
      }
    }
  }
`;

const TextDatePick = styled.div`
  position: absolute;
  z-index: 1;
  color: #919eab;
  font-family: "SukhumvitSet-Text";
  font-weight: 400;
  font-size: 0.833vw;
  left: 0.972vw;
  top: -0.556vw;
  background: #fff;
  display: inline-block;
  padding: 0 0.278vw;
  @media ${device.desktopL} {
    padding: 0 4px;
    font-size: 12px;
    left: 14px;
    top: -8px;
  }
`;

const DatePick = styled.div`
  position: relative;
  & .ant-picker {
    width: 100%;
    border-radius: 0.556vw;
    padding: 1.007vw 1.111vw;
    font-size: 1.389vw;
    border: 0.069vw solid rgba(145, 158, 171, 0.32);
    &:hover {
      border-color: rgb(255, 187, 84);
    }
    @media ${device.desktopL} {
      border-radius: 8px;
      padding: 14.5px 16px;
      font-size: 20px;
      border: 1px solid rgba(145, 158, 171, 0.32);
    }
  }
  & .ant-picker-input > input {
    font-size: 0.972vw;
    font-family: SukhumvitSet-Text, sans-serif;
    color: ${colors.gray333};
    @media ${device.desktopL} {
      font-size: 14px;
    }
  }
  & .ant-picker-focused {
    box-shadow: none;
    border-color: rgb(255, 187, 84);
  }
  & .ant-picker-range .ant-picker-active-bar {
    background: rgb(255, 187, 84);
  }
  &
    .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner:before {
    border: 1px solid rgb(255, 187, 84);
  }
  & .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: rgb(255, 187, 84);
  }
  & .ant-picker-cell-in-view.ant-picker-cell-in-range:before {
    background: rgba(255, 187, 84, 0.2);
  }
  &
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    ):before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    ):before {
    background: rgba(255, 187, 84, 0.2);
  }
`;

const SwitchWarp = styled.div`
  width: 100%;
  & .react-switch-selector-wrapper {
    background: #f9fafb;
    border-radius: 0.556vw;
  }
  & .react-switch-selector-wrapper::before {
    background: #ffffff;
    border: none;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 0.556vw;
    height: calc(100% - 0.417vw);
    width: calc(50% - 0.417vw);
    margin-left: 0.208vw;
  }
  @media ${device.desktopL} {
    & .react-switch-selector-wrapper {
      border-radius: 8px;
    }
    & .react-switch-selector-wrapper::before {
      height: calc(100% - 12px);
      width: calc(50% - 12px);
      border-radius: 8px;
      margin-left: 6px;
    }
  }
  .sw {
    &__choose {
      width: 100%;
      height: 3.889vw;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 1.528vw;
        margin: 0 0.556vw 0 0;
      }
      @media ${device.desktopL} {
        height: 56px;
        > img {
          width: 22px;
          margin: 0 8px 0 0;
        }
      }
    }
  }
`;

const Progress = styled.div<IStyleProgress>`
  position: relative;
  width: 100%;
  .progress {
    &__progress {
      position: relative;
      float: left;
      text-align: center;
    }
    &__bar-overflow {
      position: relative;
      overflow: hidden;
      width: ${(props) => props.width}px;
      height: ${(props) => props.width / 2}px;
    }
    &__bar {
      position: absolute;
      top: 0;
      left: 0;
      width: ${(props) => props.width}px;
      height: ${(props) => props.width}px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 1.528vw solid
        ${(props) => (props.spColor ? "#FFA500" : "#eaecf0")};
      border-bottom-color: #febc11;
      border-right-color: #febc11;
      transform: rotate(${(props) => 45 + props.percent * 1.8}deg);
      @media ${device.desktopL} {
        border: 22px solid ${(props) => (props.spColor ? "#FFA500" : "#eaecf0")};
        border-bottom-color: #febc11;
        border-right-color: #febc11;
      }
    }
    &__detail {
      position: absolute;
      top: 2.431vw;
      left: 50%;
      transform: translate(-50%, 0);
      @media ${device.desktopL} {
        top: 35px;
      }
    }
    &__avg {
      line-height: 1.2;
      padding: 0.278vw 0.694vw;
      background: #f4f4f4;
      border-radius: 100px;
      @media ${device.desktopL} {
        padding: 4px 10px;
      }
    }
    &__percent {
      margin: -0.139vw 0 -0.139vw;
      @media ${device.desktopL} {
        margin: -2px 0 -2px;
      }
    }
    &__row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin: 1.389vw 0 0;
      @media ${device.desktopL} {
        margin: 20px 0 0;
      }
    }
    &__row-detail {
      width: 49%;
      display: flex;
      align-items: flex-start;
      &--2 {
        justify-content: flex-end;
      }
    }
    &__dot {
      width: 0.486vw;
      height: 0.486vw;
      margin: 0 0.417vw 0 0;
      border-radius: 50%;
      &--1 {
        background: #febc11;
      }
      &--2 {
        background: #eaecf0;
      }
      @media ${device.desktopL} {
        width: 7px;
        height: 7px;
        margin: 0 6px 0 0;
      }
    }
    &__row-text {
      max-width: calc(100% - 0.486vw - 0.417vw);
      margin: -0.139vw 0 0;
      > div {
        line-height: 1;
      }
      @media ${device.desktopL} {
        max-width: calc(100% - 7px - 6px);
        margin: -2px 0 0;
      }
    }
  }
`;

const Mode = styled.div<IStyle>`
  & .animate-flicker {
    -webkit-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
  }
  position: relative;
  background: ${(props) =>
    props.active
      ? props.isError
        ? "rgba(255, 221, 221, 0.3)"
        : "#eef6ff"
      : "rgba(208, 209, 211, 0.4)"};
  border-radius: 0.417vw;
  > img {
    position: absolute;
    width: 58%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media ${device.desktopL} {
    border-radius: 6px;
  }
`;

const Headline = styled.div`
  width: 100%;
  background: #ffffff;
  border: 0.069vw solid #dedede;
  box-shadow: 0px 0.278vw 0.833vw rgba(205, 205, 205, 0.25);
  border-radius: 1.111vw;
  padding: 1.389vw 0;
  margin: 1.389vw 0 0;
  display: flex;
  // align-items: flex-start;
  @media ${device.desktopL} {
    border: 1px solid #dedede;
    box-shadow: 0px 4px 12px rgba(205, 205, 205, 0.25);
    border-radius: 16px;
    padding: 20px 0;
    margin: 20px 0 0;
  }
  @media ${device.laptop} {
    flex-wrap: wrap;
  }
  .headline {
    &__hr-top {
      position: absolute;
      width: 1px;
      height: 90%;
      top: 0;
      right: 0;
      background: #e4e4e4;
    }
    &__hr-bottom {
      position: absolute;
      width: 1px;
      height: 90% !important;
      bottom: 0;
      right: 0;
      background: #e4e4e4;
    }
    &__left {
      position: relative;
      width: 40%;
      padding: 0 1.389vw;
      display: flex;
      align-items: flex-start;
      border-right: 0.069vw solid #e4e4e4;
      @media ${device.desktopL} {
        padding: 0 20px;
        border-right: 1px solid #e4e4e4;
      }
      @media ${device.laptopSL} {
        width: 39%;
      }
      @media ${device.laptop} {
        width: 50%;
        margin: 0 0 14px;
        border-right: none;
      }
      @media ${device.mobileLL} {
        width: 100%;
      }
    }
    &__middle {
      width: 35%;
      padding: 0 1.389vw;
      border-right: 0.069vw solid #e4e4e4;
      @media ${device.desktopL} {
        padding: 0 20px;
        border-right: 1px solid #e4e4e4;
      }
      @media ${device.laptopSL} {
        width: 33%;
      }
      @media ${device.laptop} {
        width: 50%;
        border-right: none;
        margin: 40px 0 0;
      }
      @media ${device.mobileLL} {
        width: 100%;
        margin: 20px 0 0;
        border-right: none;
      }
    }
    &__hr {
      width: calc(100% - 40px);
      margin: 20px auto 0;
      border-bottom: 1px solid #e4e4e4;
      display: none;
      @media ${device.mobileLL} {
        display: block;
      }
    }
    &__right {
      width: 25%;
      padding: 0 1.389vw;
      @media ${device.desktopL} {
        padding: 0 20px;
      }
      @media ${device.laptopSL} {
        width: 28%;
      }
      @media ${device.laptop} {
        width: 40%;
        margin: 40px auto 0;
      }
      @media ${device.mobileLL} {
        width: 80%;
        margin: 20px auto 0;
      }
    }
    &__map-warp {
      position: relative;
      width: 50%;
      display: none;
      > div {
        height: 100%;
      }
      @media ${device.laptop} {
        display: block;
      }
      @media ${device.mobileLL} {
        width: 100%;
      }
    }
    &__map {
      width: 100%;
      margin: 0 auto;
      padding: 0px 20px;
      display: none;
      > div {
        height: 100%;
      }
      @media ${device.laptop} {
        display: block;
      }
      @media ${device.mobileLL} {
        width: 100%;
        margin: 12px 0 20px;
        > div {
          height: 200px;
        }
      }
    }
    &__cover {
      position: relative;
      width: 7.361vw;
      padding-top: 7.361vw;
      border-radius: 0.556vw;
      margin: 0 1.389vw 0 0;
      overflow: hidden;
      border-radius: 0.556vw;
      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        object-fit: cover;
      }
      @media ${device.desktopL} {
        width: 106px;
        padding-top: 106px;
        border-radius: 8px;
        margin: 0 20px 0 0;
        border-radius: 8px;
      }
    }
    &__name {
      max-width: calc(100% - 1.736vw - 0.972vw);
      @media ${device.desktopL} {
        max-width: calc(100% - 25px - 14px);
      }
    }
    &__sound {
      width: 1.736vw;
      display: block;
      margin: 0 0 0 0.972vw;
      &--active {
        cursor: pointer;
      }
      @media ${device.desktopL} {
        width: 25px;
        margin: 0 0 0 14px;
      }
    }
    &__left-detail {
      width: calc(100% - 7.361vw - 1.389vw);
      @media ${device.desktopL} {
        width: calc(100% - 106px - 20px);
      }
    }
    &__row-head {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }
    &__text-group {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0.833vw 0 0;
      @media ${device.desktopL} {
        margin: 12px 0 0;
      }
    }
    &__text {
      width: 48%;
      display: flex;
      align-items: flex-start;
      > img {
        width: 1.111vw;
        margin: 0 0.417vw 0 0;
      }
      > div {
        width: calc(100% - 1.528vw);
      }
      &--sp {
        width: 100%;
      }
      @media ${device.desktopL} {
        > img {
          width: 16px;
          margin: 0 6px 0 0;
        }
        > div {
          width: calc(100% - 22px);
        }
      }
    }
    &__btn {
      display: inline-flex;
      align-items: center;
      background: #ffffff;
      border: 0.139vw solid #ffe6a4;
      box-shadow: 0px 0.278vw 0.972vw rgba(165, 165, 165, 0.08);
      border-radius: 3.472vw;
      padding: 0.278vw 0.694vw;
      margin: 0.833vw 0px 0px;
      cursor: pointer;
      > div {
        line-height: 1.3;
        margin: 1px 0 0;
      }
      > img {
        width: 1.389vw;
        display: block;
        margin: 0 0 0 0.417vw;
      }
      @media ${device.desktopL} {
        border: 2px solid #ffe6a4;
        box-shadow: 0px 4px 14px rgba(165, 165, 165, 0.08);
        border-radius: 50px;
        padding: 4px 10px;
        margin: 12px 0px 0px;
        > div {
          margin: 1px 0 0;
        }
        > img {
          width: 20px;
          margin: 0 0 0 6px;
        }
      }
    }
    &__temp {
      width: 100%;
      background: #ffffff;
      border: 0.069vw solid #efefef;
      box-shadow: 0.208vw 0.208vw 2.083vw rgba(206, 206, 206, 0.5);
      border-radius: 0.833vw;
      padding: 0.417vw 1.667vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media ${device.desktopL} {
        border: 1px solid #efefef;
        box-shadow: 3px 3px 30px rgba(206, 206, 206, 0.5);
        border-radius: 12px;
        padding: 6px 24px;
      }
    }
    &__group {
      width: 100%;
      margin: 1.042vw 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        width: calc(70% / 7);
        padding-top: calc(70% / 7);
      }
      @media ${device.desktopL} {
        margin: 15px 0 0;
      }
    }
    &__middle-group {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0.556vw 0 0;
      @media ${device.desktopL} {
        margin: 8px 0 0;
      }
    }
    &__middle-left {
      width: 48%;
    }
    &__middle-right {
      width: 48%;
      text-align: right;
    }
    &__middle-text {
      line-height: 1;
      margin: 0.694vw 0 0;
      &--sp {
        margin: 0.278vw 0 0;
      }
      @media ${device.desktopL} {
        margin: 10px 0 0;
        &--sp {
          margin: 4px 0 0;
        }
      }
    }
  }
`;

interface IStyleCon {
  isTab1: boolean;
}

const Container = styled.div<IStyleCon>`
  width: cal(100% + 4.444vw);
  margin: -2.222vw -2.222vw 0;
  @media ${device.desktopL} {
    width: cal(100% + 64px);
    margin: -32px -32px 0;
  }
  .detail {
    &__head {
      border-radius: 2.778vw 0px 0px 0;
      padding: 2.222vw 2.222vw 1.389vw;
      background: #ffffff;
      @media ${device.desktopL} {
        border-radius: 40px 0px 0px 0;
        padding: 32px 32px 20px;
      }
      @media ${device.laptop} {
        border-radius: 0px;
        padding: 20px 32px;
      }
      @media ${device.mobileLL} {
        padding: 18px;
      }
    }
    &__top {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media ${device.mobileLL} {
        flex-wrap: wrap;
      }
    }
    &__top-head {
      width: 280px;
      display: flex;
      align-items: center;
      > img {
        width: 2.083vw;
        height: 2.083vw;
        margin: 0 0.833vw 0 0;
        cursor: pointer;
      }
      @media ${device.desktopL} {
        > img {
          width: 30px;
          height: 30px;
          margin: 0 12px 0 0;
        }
      }
    }
    // &__text {
    //   display: flex;
    //   align-items: center;
    //   line-height: 3.75vw;
    //   > img {
    //     width: 1.528vw;
    //     margin: 0 0.208vw 0 0;
    //   }
    //   > span {
    //     margin: 0 0 0 0.139vw;
    //   }
    //   @media ${device.desktopL} {
    //     line-height: 54px;
    //     > span {
    //       margin: 0 0 0 2px;
    //     }
    //     > img {
    //       width: 22px;
    //       margin: 0 3px 0 0;
    //     }
    //   }
    // }
    &__text {
      display: flex;
      align-items: center;
      line-height: 3.75vw;
      > img {
        width: 1.528vw;
        margin: 0 0.208vw 0 0;
      }
      > span {
        margin: 0 0 0 0.139vw;
      }
      @media ${device.desktopL} {
        line-height: 54px;
        > span {
          margin: 0 0 0 2px;
        }
        > img {
          width: 22px;
          margin: 0 3px 0 0;
        }
      }
    }
    &__alert {
      width: 100%;
      padding: 0.972vw 1.111vw;
      background: #c41616;
      box-shadow: 0px 0.278vw 0.972vw rgba(0, 0, 0, 0.08);
      border-radius: 0.833vw;
      margin: 1.389vw 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media ${device.desktopL} {
        padding: 14px 16px;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
        border-radius: 12px;
        margin: 20px 0 0;
      }
    }
    &__alert-left {
      width: 1.111vw;
      margin: -0.069vw 0.486vw 0 0;
      &--hide {
        opacity: 0;
      }
      > img {
        max-width: 100% !important;
      }
      @media ${device.desktopL} {
        width: 16px;
        margin: -1px 7px 0 0;
      }
    }
    &__alert-middle {
      width: 2.361vw;
      margin: 0 0 0 0;
      line-height: 1.3;
      @media ${device.desktopL} {
        width: 34px;
        margin: 0 0 0 0;
      }
    }
    &__alert-right {
      width: calc(100% - 1.25vw - 2.361vw - 0.486vw);
      line-height: 1.3;
      @media ${device.desktopL} {
        width: calc(100% - 18px - 34px - 7px);
      }
    }
    &__filter {
      margin: 0.694vw 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &--ismobile {
        display: none;
        margin: 0;
        & .detail__filter-left,
        .detail__search {
          width: 100%;
        }
        & .detail__date {
          margin: 10px 0 0;
          width: 100%;
        }
      }
      @media ${device.desktopL} {
        margin: 10px 0 0;
      }
      @media ${device.mobileLL} {
        &--ismobile {
          &.show {
            display: flex;
          }
        }
      }
    }
    &__filter-left {
      width: ${(props) => (props.isTab1 ? "100%" : "49%")};
      display: flex;
      align-items: center;
      justify-content: ${(props) => (props.isTab1 ? "space-between" : "unset")};
      flex-wrap: wrap;
      @media ${device.mobileLL} {
        width: ${(props) => (props.isTab1 ? "100%" : "calc(100% - 50px)")};
      }
    }
    &__filter-right {
      width: 49%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      @media ${device.mobileLL} {
        width: ${(props) => (props.isTab1 ? "49%" : "50px")};
      }
    }
    &__switch {
      width: 12.778vw;
      margin: 0.694vw ${(props) => (props.isTab1 ? "0" : "1.528vw")} 0 0;
      @media ${device.desktopL} {
        width: 184px;
        margin: 10px ${(props) => (props.isTab1 ? "0" : "22px")} 0 0;
      }
      @media ${device.mobileLL} {
        width: 100%;
      }
    }
    &__search {
      width: 20.833vw;
      margin: 0.694vw 0 0;
      @media ${device.desktopL} {
        width: 300px;
        margin: 10px 0 0;
      }
      @media ${device.mobileLL} {
        &--desktop {
          display: none;
        }
      }
    }
    &__date {
      margin: 0.694vw 0 0 1.389vw;
      @media ${device.desktopL} {
        margin: 10px 0 0 20px;
      }
      @media ${device.mobileLL} {
        &--desktop {
          display: none;
        }
      }
    }
    &__download {
      width: 3.472vw;
      height: 3.889vw;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 0.694vw 0 0;
      > img {
        width: 100%;
        display: block;
      }
      @media ${device.desktopL} {
        width: 50px;
        height: 56px;
        margin: 10px 0 0;
      }
    }
    &__card {
      width: 100%;
      padding: 1.389vw;
      background: #ffffff;
      box-shadow: 0px 0.251vw 1.255vw rgba(224, 224, 224, 0.42);
      border-radius: 1.111vw;
      &--margin {
        margin: 1.667vw 0 0;
      }
      @media ${device.desktopL} {
        &--margin {
          margin: 24px 0 0;
        }
        padding: 20px;
        box-shadow: 0px 3.61513px 18.0757px rgba(224, 224, 224, 0.42);
        border-radius: 16px;
      }
    }
    &__period {
      width: calc((93% - 12.778vw) / 3);
      margin: 0.694vw 0 0;
      @media ${device.desktopL} {
        width: calc((93% - 184px) / 3);
        margin: 10px 0 0;
      }
      @media ${device.mobileLL} {
        width: 100%;
        margin: 16px 0 0;
      }
    }
    &__content {
      padding: 1.389vw 2.222vw;
      @media ${device.desktopL} {
        padding: 20px 32px;
      }
      @media ${device.mobileLL} {
        padding: 18px;
      }
    }
    &__comming {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 3.472vw 0;
      @media ${device.desktopL} {
        padding: 50px 0;
      }
    }
    &__dot {
      width: 0.972vw;
      height: 0.972vw;
      margin: 0px 0.972vw 0px 0px;
      border-radius: 50%;
      @media ${device.desktopL} {
        width: 14px;
        height: 14px;
        margin: 0px 3px 0px 0px;
      }
    }
    &__card-head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__card-name {
      width: 50%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      > img {
        width: 2.778vw;
        margin: 0 1.111vw 0 0;
        display: block;
      }
      @media ${device.desktopL} {
        > img {
          width: 40px;
          margin: 0 16px 0 0;
        }
      }
    }
    &__card-filter {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > img {
        margin: 0 0.972vw 0 0;
        cursor: pointer;
      }
      & .w-50 {
        width: 3.472vw;
      }
      & .w-33 {
        width: 2.292vw;
      }
      & .last {
        margin: 0;
      }
      @media ${device.desktopL} {
        > img {
          margin: 0 14px 0 0;
        }
        & .w-50 {
          width: 50px;
        }
        & .w-33 {
          width: 33px;
        }
      }
    }
    &__row-chart {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      > div {
        width: 48%;
      }
      @media ${device.mobileLL} {
        > div {
          width: 100%;
        }
      }
    }
    &__breadclumb {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0.278vw 0 0;
      & .pointer {
        cursor: pointer;
      }
      & .dot {
        width: 0.278vw;
        height: 0.278vw;
        background: #919eab;
        border-radius: 50%;
        margin: 0 1.111vw;
      }
      & .active {
        color: #919eab;
      }
      @media ${device.desktopL} {
        margin: 4px 0 0;
        & .dot {
          width: 4px;
          height: 4px;
          margin: 0 16px;
        }
      }
    }
  }
`;
