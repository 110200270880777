import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

initializeApp(firebaseConfig);
const messaging = getMessaging();
export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BJFCI2Qw8aPl1PTrVs2CjrGSNUOOV7QdaWie5YrloOIq3oToqCHLzy1yAha8YF8oSJoQfhI2ZI3rVg_WOxujUmk",
  })
    .then((currentToken) => {
      if (currentToken) {
        // Perform any other neccessary action with the token
        return {
          status: true,
          message: currentToken,
        };
      } else {
        // Show permission request UI
        return {
          status: false,
          message:
            "No registration token available. Request permission to generate one.",
        };
      }
    })
    .catch((err) => {
      return {
        status: false,
        message: `An error occurred while retrieving token. ${err}`,
      };
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
