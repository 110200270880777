import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { imgList } from "@constants/imgList";
import { ButtonBlock } from "@components/Buttons";
import { device } from "@constants/styles";

export default function Notfound() {
  const navigate = useNavigate();
  return (
    <Page className="notfound">
      <img src={imgList.ico_404} alt="" className="notfound__img" />
      <div className="size-24 font-sukhumvitSet-bold notfound__text">
        PAGE NOT FOUND
      </div>
      <ButtonBlock
        text="Go Back"
        className="notfound__btn"
        onClick={() => navigate("/")}
      />
    </Page>
  );
}

const Page = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .notfound {
    &__img {
      width: 26.25vw;
      max-width: 90%;
      @media ${device.desktopL} {
        width: 378px;
      }
    }
    &__text {
      color: #444446;
    }
    &__btn {
      width: 11.806vw;
      max-width: 90%;
      margin: 2.083vw 0 0;
      @media ${device.desktopL} {
        width: 170px;
        margin: 30px 0 0;
      }
    }
  }
`;
