import styled from "styled-components";

import { colors } from "@constants/colors";
import { device } from "@constants/styles";

interface IButtonBlock {
  className?: string;
  bgColor?: string;
  color?: string;
  bgColorDisable?: string;
  text: string;
  disable?: boolean;
  onClick?: () => void;
}

export default function ButtonBlock({
  className = "",
  bgColor = colors.primary,
  color = "#000",
  bgColorDisable = colors.grayca4,
  text = "",
  disable = false,
  onClick = () => {},
}: IButtonBlock) {
  return (
    <Button
      className={className}
      bgColor={bgColor}
      disable={disable}
      bgColorDisable={bgColorDisable}
      color={color}
      onClick={onClick}
    >
      {text}
    </Button>
  );
}

interface IButton {
  bgColor: string;
  bgColorDisable: string;
  disable: boolean;
  color: string;
}

const Button = styled.div<IButton>`
  width: 100%;
  line-height: 3.333vw;
  background: ${(props) =>
    props.disable ? props.bgColorDisable : props.bgColor};
  border: 1px solid
    ${(props) => (props.disable ? props.bgColorDisable : props.bgColor)}${(props) => (props.disable ? " !important" : "")};
  border-radius: 0.694vw;
  color: ${(props) => props.color}${(props) => (props.disable ? " !important" : "")};
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 0.972vw;
  text-align: center;
  cursor: ${(props) => (props.disable ? "" : "pointer")};
  @media ${device.desktopL} {
    line-height: 48px;
    border-radius: 10px;
    border: 0.069vw solid
      ${(props) => (props.disable ? props.bgColorDisable : props.bgColor)}${(props) => (props.disable ? " !important" : "")};
    font-size: 14px;
  }
`;
