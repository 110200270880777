import { Modal, ModalProps } from "antd";
import styled from "styled-components";

import { device } from "@constants/styles";
import { colors } from "@constants/colors";
import { ButtonBlock } from "@components/Buttons";
type Props = {
  open: boolean;
  onOk: () => void;
  onClose: () => void;
};

const ModalReadAll = ({
  open = false,
  onOk = () => {},
  onClose = () => {},
  ...props
}: Props & ModalProps) => {
  return (
    <Content id="model-read-all" className="modal">
      <Modal
        title=""
        open={open}
        closable={false}
        footer={null}
        centered={true}
        getContainer={() =>
          document.getElementById("model-read-all") as HTMLElement
        }
        {...props}
      >
        <div className="modal__content">
          <div className="color-black font-sukhumvitSet-bold size-20">
            คุณแน่ใจหรือไม่ว่าต้องการทำเครื่องหมายว่าอ่าน
            การแจ้งเตือนทั้งหมดแล้ว?
          </div>
          <div className="modal__btn">
            <ButtonBlock
              text="ยกเลิก"
              bgColor="#fff"
              className="modal__btn--cancel"
              onClick={onClose}
            />
            <ButtonBlock
              text="ยืนยัน"
              className="modal__btn--cf"
              onClick={onOk}
            />
          </div>
        </div>
      </Modal>
    </Content>
  );
};

export default ModalReadAll;

const Content = styled.div`
  position: relative;
  z-index: 999999;
  width: 100%;
  max-width: 90% !important;
  & .ant-modal-content {
    border-radius: 0.833vw;
    @media ${device.desktopL} {
      border-radius: 12px;
    }
  }
  & .ant-modal {
    width: 30.556vw !important;
    @media ${device.desktopL} {
      width: 440px !important;
    }
  }
  & .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  .modal {
    &__content {
      text-align: center;
    }
    &__btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 2.083vw 0 0;
      &--cancel {
        color: ${colors.ppDark};
        border: 0.139vw solid ${colors.ppDark};
        width: 48%;
        font-family: "SukhumvitSet-Bold", sans-serif;
      }
      &--cf {
        width: 48%;
        font-family: "SukhumvitSet-Bold", sans-serif;
      }
      @media ${device.desktopL} {
        margin: 30px 0 0;
        &--cancel {
          border: 2px solid ${colors.ppDark};
        }
      }
    }
  }
`;
