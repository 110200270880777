import { Modal, ModalProps } from "antd";
import styled from "styled-components";

import { device } from "@constants/styles";
import { colors } from "@constants/colors";
import { ButtonBlock } from "@components/Buttons";
type Props = {
  open: boolean;
  head?: string;
  subHead?: string;
  onOk: () => void;
  onClose: () => void;
};

const ModalConfirm = ({
  open = false,
  head = "",
  subHead = "",
  onOk = () => {},
  onClose = () => {},
  ...props
}: Props & ModalProps) => {
  return (
    <Content id="model-confirm" className="modal">
      <Modal
        title=""
        open={open}
        closable={false}
        footer={null}
        centered={true}
        getContainer={() =>
          document.getElementById("model-confirm") as HTMLElement
        }
        {...props}
      >
        <div className="modal__content">
          <div className="color-black font-sukhumvitSet-bold size-20">
            {head}
          </div>
          {subHead && (
            <div className="modal__sub-head color-black font-sukhumvitSet-text size-16 color-gray381">
              {subHead}
            </div>
          )}
          <div className="modal__btn">
            <ButtonBlock
              text="ยกเลิก"
              bgColor="#fff"
              className="modal__btn--cancel"
              onClick={onClose}
            />
            <ButtonBlock
              text="ยืนยัน"
              className="modal__btn--cf"
              onClick={onOk}
            />
          </div>
        </div>
      </Modal>
    </Content>
  );
};

export default ModalConfirm;

const Content = styled.div`
  width: 100%;
  max-width: 90% !important;
  & .ant-modal-content {
    border-radius: 0.833vw;
    @media ${device.desktopL} {
      border-radius: 12px;
    }
  }
  & .ant-modal {
    width: 25vw !important;
    @media ${device.desktopL} {
      width: 360px !important;
    }
  }
  & .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  .modal {
    &__content {
      text-align: center;
    }
    &__sub-head {
      width: 100%;
      margin: 20px 0px 0px;
    }
    &__btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 2.083vw 0 0;
      > div {
        font-family: "SukhumvitSet-Bold", sans-serif;
      }
      &--cancel {
        color: ${colors.ppDark};
        border: 0.139vw solid ${colors.ppDark};
        width: 48%;
      }
      &--cf {
        width: 48%;
      }
      @media ${device.desktopL} {
        margin: 24px 0 0;
        &--cancel {
          border: 2px solid ${colors.ppDark};
        }
      }
    }
  }
`;
