import { Modal, ModalProps } from "antd";
import styled from "styled-components";

import { device } from "@constants/styles";
import { ButtonBlock } from "@components/Buttons";
import { imgList } from "@constants/imgList";

type Props = {
  open: boolean;
  onOk: () => void;
  onClose: () => void;
};

const ModalNoti = ({
  open = false,
  onOk = () => {},
  onClose = () => {},
  ...props
}: Props & ModalProps) => {
  return (
    <Content id="model-noti" className="modal">
      <Modal
        title=""
        open={open}
        closable={false}
        footer={null}
        centered={true}
        getContainer={() =>
          document.getElementById("model-noti") as HTMLElement
        }
        {...props}
      >
        <div className="modal__head">
          <div className="font-sukhumvitSet-text size-24 weight-700 color-black">
            วิธีการอนุญาตเปิดใช้งานแจ้งเตือน
          </div>
          <img src={imgList.ico_close_modal} alt="" onClick={onClose} />
        </div>
        <div className="modal__content">
          <img src={imgList.noti_info} alt="" className="modal__image" />
        </div>
        <ButtonBlock text="ปิด" onClick={onClose} className="modal__btn" />
      </Modal>
    </Content>
  );
};

export default ModalNoti;

const Content = styled.div`
  width: 100%;
  max-width: 90% !important;
  & .ant-modal-content {
    border-radius: 0.833vw;
    background-color: #f8f8f8;
    @media ${device.desktopL} {
      border-radius: 12px;
    }
  }
  & .ant-modal {
    width: 41.667vw !important;
    padding: 1.389vw 0;
    @media ${device.desktopL} {
      width: 600px !important;
      padding: 20px 0;
    }
  }
  & .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  .modal {
    &__head {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      border-bottom: 0.069vw solid rgba(145, 158, 171, 0.24);
      padding: 0px 0px 0.972vw;
      margin: 0 0 0.972vw;
      > img {
        width: 1.667vw;
        height: 1.667vw;
        cursor: pointer;
      }
      > div {
        width: calc(100% - 1.667vw - 0.417vw);
        margin: 0 0.417vw 0 0;
        line-height: 1.2;
      }
      @media ${device.desktopL} {
        border-bottom: 1px solid rgba(145, 158, 171, 0.24);
        padding: 0px 0px 14px;
        margin: 0 0 14px;
        > img {
          width: 24px;
          height: 24px;
        }
        > div {
          width: calc(100% - 24px - 6px);
          margin: 0 6px 0 0;
        }
      }
    }
    &__content {
      width: 100%;
    }
    &__image {
      width: 100%;
      margin: 0 0 0.972vw;
      @media ${device.desktopL} {
        margin: 0 0 14px;
      }
    }
    &__btn {
      font-family: "SukhumvitSet-Bold", sans-serif;
      font-weight: 500;
    }
  }
`;
