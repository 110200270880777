import { io } from "socket.io-client";
import Cookies from "js-cookie";

const URL = process.env.REACT_APP_SOCKET_DOMAIN;

export const socket = io(URL, {
  query: {
    language: "th", // 'th
    access_token: Cookies?.get("jwt_token_control") ?? "",
  },
});
