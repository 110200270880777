import { Dayjs } from "dayjs";
import {
  countInterface,
  notificationInterface,
  filterInterface,
  listStatusInterface,
  listLocationInterface,
  mapLocationDevicesInterface,
  filterDeviceDetailInterface,
  filterTempInterface,
  filterOpeInterface,
  filterIotInterface,
  filterPowerInterface,
  paginationInterface,
} from "../interface";

export function updateDateAction(data: Dayjs) {
  return {
    type: "update_date",
    data: data,
  };
}

export function countAction(data: countInterface) {
  return {
    type: "count",
    data: data,
  };
}

export function notificationAction(data: notificationInterface[]) {
  return {
    type: "notification",
    data: data,
  };
}

export function monitoringAction(data: any[]) {
  return {
    type: "monitoring",
    data: data,
  };
}

export function monitoringFilterAction(data: any[]) {
  return {
    type: "monitoring_filter",
    data: data,
  };
}

export function filterAction(data: filterInterface) {
  return {
    type: "filter",
    data: data,
  };
}
export function listStatusAction(data: listStatusInterface[]) {
  return {
    type: "list_status",
    data: data,
  };
}
export function listLocationAction(data: listLocationInterface[]) {
  return {
    type: "list_location",
    data: data,
  };
}
export function mapLocationDevicesAction(data: mapLocationDevicesInterface[]) {
  return {
    type: "map_location_device",
    data: data,
  };
}
export function collapsedAction(data: boolean) {
  return {
    type: "collapsed",
    data: data,
  };
}
export function pauseAction(data: boolean) {
  return {
    type: "pause",
    data: data,
  };
}
export function deviceDetailAction(data: any) {
  return {
    type: "device_detail",
    data: data,
  };
}
export function filterDeviceDetailAction(data: filterDeviceDetailInterface) {
  return {
    type: "filter_device_detail",
    data: data,
  };
}
export function filterTempAction(data: filterTempInterface) {
  return {
    type: "filter_temp",
    data: data,
  };
}
export function filterOpeAction(data: filterOpeInterface) {
  return {
    type: "filter_ope",
    data: data,
  };
}
export function filterIotAction(data: filterIotInterface) {
  return {
    type: "filter_iot",
    data: data,
  };
}
export function filterPowerAction(data: filterPowerInterface) {
  return {
    type: "filter_power",
    data: data,
  };
}
export function paginationAction(data: paginationInterface) {
  return {
    type: "pagination",
    data: data,
  };
}
export function dataDevicesAction(data: any[]) {
  return {
    type: "data_devices",
    data: data,
  };
}
export function isSoundPlayingAction(data: boolean) {
  return {
    type: "is_sound_playing",
    data: data,
  };
}
export function isPlayAction(data: boolean) {
  return {
    type: "is_play",
    data: data,
  };
}
export function permissionNotiAction(data: boolean) {
  return {
    type: "permission_noti",
    data: data,
  };
}
