import _ from "lodash";
import dayjs from "dayjs";
import { MathRound } from "@utils/string";

export const ModMonitoringTemp = (data: any[], mod = 5) => {
  let arr: any[] = [];
  const uniqBy: any = _.uniqBy(data, "time");
  _(uniqBy)
    .groupBy((v) => dayjs(v.time).format("DD/MM/YYYY"))
    .mapValues((v) => {
      let number = 0;
      let realNumber = 0;
      let temp = {
        evapCoil: 0,
        highestTemp: 0,
        insideTemp: 0,
        lowestTemp: 0,
        setPointTemp: 0,
        realTemp: 0,
        probe2R: 0,
        id: "",
        time: null,
      };
      v.forEach((element) => {
        number++;
        if (number === mod) {
          if (
            (element?.evapCoil ?? null) !== null ||
            (element?.highestTemp ?? null) !== null ||
            (element?.insideTemp ?? null) !== null ||
            (element?.lowestTemp ?? null) !== null ||
            (element?.setPointTemp ?? null) !== null ||
            (element?.realTemp ?? null) !== null ||
            (element?.probe2R ?? null) !== null
          ) {
            realNumber++;
          }
          arr.push({
            ...temp,
            evapCoil:
              temp.evapCoil === null && (element?.evapCoil ?? null) === null
                ? null
                : MathRound(
                    (temp.evapCoil + element?.evapCoil ?? 0) / realNumber,
                    1
                  ),
            highestTemp:
              temp.highestTemp === null &&
              (element?.highestTemp ?? null) === null
                ? null
                : MathRound(
                    (temp.highestTemp + element?.highestTemp ?? 0) / realNumber,
                    1
                  ),
            insideTemp:
              temp.insideTemp === null && (element?.insideTemp ?? null) === null
                ? null
                : MathRound(
                    (temp.insideTemp + element?.insideTemp ?? 0) / realNumber,
                    1
                  ),
            lowestTemp:
              temp.lowestTemp === null && (element?.lowestTemp ?? null) === null
                ? null
                : MathRound(
                    (temp.lowestTemp + element?.lowestTemp ?? 0) / realNumber,
                    1
                  ),
            setPointTemp:
              temp.setPointTemp === null &&
              (element?.setPointTemp ?? null) === null
                ? null
                : MathRound(
                    (temp.setPointTemp + element?.setPointTemp ?? 0) /
                      realNumber,
                    1
                  ),
            realTemp:
              temp.realTemp === null && (element?.realTemp ?? null) === null
                ? null
                : MathRound(
                    (temp.realTemp + element?.realTemp ?? 0) / realNumber,
                    1
                  ),
            probe2R:
              temp.probe2R === null && (element?.probe2R ?? null) === null
                ? null
                : MathRound(
                    (temp.probe2R + element?.probe2R ?? 0) / realNumber,
                    1
                  ),
          });
          // console.log("number", number);
          // console.log("realNumber", realNumber);
          number = 0;
          realNumber = 0;
        } else {
          if (number === 1) {
            if (
              (element?.evapCoil ?? null) !== null ||
              (element?.highestTemp ?? null) !== null ||
              (element?.insideTemp ?? null) !== null ||
              (element?.lowestTemp ?? null) !== null ||
              (element?.setPointTemp ?? null) !== null ||
              (element?.realTemp ?? null) !== null ||
              (element?.probe2R ?? null) !== null
            ) {
              realNumber++;
            }
            temp = {
              evapCoil: element?.evapCoil ?? null,
              highestTemp: element?.highestTemp ?? null,
              insideTemp: element?.insideTemp ?? null,
              lowestTemp: element?.lowestTemp ?? null,
              setPointTemp: element?.setPointTemp ?? null,
              realTemp: element?.realTemp ?? null,
              probe2R: element?.probe2R ?? null,
              id: element?.id ?? "",
              time: element?.time ?? null,
            };
          } else {
            if (
              (element?.evapCoil ?? null) !== null ||
              (element?.highestTemp ?? null) !== null ||
              (element?.insideTemp ?? null) !== null ||
              (element?.lowestTemp ?? null) !== null ||
              (element?.setPointTemp ?? null) !== null ||
              (element?.realTemp ?? null) !== null ||
              (element?.probe2R ?? null) !== null
            ) {
              realNumber++;
            }
            temp = {
              ...temp,
              evapCoil:
                temp.evapCoil === null && (element?.evapCoil ?? null) === null
                  ? null
                  : temp.evapCoil + element?.evapCoil ?? 0,
              highestTemp:
                temp.highestTemp === null &&
                (element?.highestTemp ?? null) === null
                  ? null
                  : temp.highestTemp + element?.highestTemp ?? 0,
              insideTemp:
                temp.insideTemp === null &&
                (element?.insideTemp ?? null) === null
                  ? null
                  : temp.insideTemp + element?.insideTemp ?? 0,
              lowestTemp:
                temp.lowestTemp === null &&
                (element?.lowestTemp ?? null) === null
                  ? null
                  : temp.lowestTemp + element?.lowestTemp ?? 0,
              setPointTemp:
                temp.setPointTemp === null &&
                (element?.setPointTemp ?? null) === null
                  ? null
                  : temp.setPointTemp + element?.setPointTemp ?? 0,
              realTemp:
                temp.realTemp === null && (element?.realTemp ?? null) === null
                  ? null
                  : temp.realTemp + element?.realTemp ?? 0,
              probe2R:
                temp.probe2R === null && (element?.probe2R ?? null) === null
                  ? null
                  : temp.probe2R + element?.probe2R ?? 0,
            };
          }
        }
      });
      return v;
    })
    .value();
  return arr;
};
export const ModMonitoringOparetion = (data: any[], mod = 5) => {
  let arr: any[] = [];
  const uniqBy: any = _.uniqBy(data, "time");
  _(uniqBy)
    .groupBy((v) => dayjs(v.time).format("DD/MM/YYYY"))
    .mapValues((v) => {
      let number = 0;
      let temp = {
        active: undefined,
        comp: undefined,
        defrost: undefined,
        door: undefined,
        fan: undefined,
        light: undefined,
        id: "",
        recordType: "",
        time: null,
      };
      v.forEach((element) => {
        number++;
        if (number === mod) {
          arr.push({
            ...temp,
            active:
              temp.active === undefined &&
              (element?.active ?? undefined) === undefined
                ? undefined
                : temp?.active ?? false
                ? true
                : element?.active ?? false,
            comp:
              temp.comp === undefined &&
              (element?.comp ?? undefined) === undefined
                ? undefined
                : temp?.comp ?? false
                ? true
                : element?.comp ?? false,
            defrost:
              temp.defrost === undefined &&
              (element?.defrost ?? undefined) === undefined
                ? undefined
                : temp?.defrost ?? false
                ? true
                : element?.defrost ?? false,
            door:
              temp.door === undefined &&
              (element?.door ?? undefined) === undefined
                ? undefined
                : temp?.door ?? false
                ? true
                : element?.door ?? false,
            fan:
              temp.fan === undefined &&
              (element?.fan ?? undefined) === undefined
                ? undefined
                : temp?.fan ?? false
                ? true
                : element?.fan ?? false,
            light:
              temp.light === undefined &&
              (element?.light ?? undefined) === undefined
                ? undefined
                : temp?.light ?? false
                ? true
                : element?.light ?? false,
          });
          number = 0;
        } else {
          if (number === 1) {
            temp = {
              active: element?.active ?? undefined,
              comp: element?.comp ?? undefined,
              defrost: element?.defrost ?? undefined,
              door: element?.door ?? undefined,
              fan: element?.fan ?? undefined,
              light: element?.light ?? undefined,
              id: element?.id ?? "",
              time: element?.time ?? null,
              recordType: element?.recordType ?? "",
            };
          } else {
            temp = {
              ...temp,
              active:
                (temp?.active ?? undefined) === undefined &&
                (element?.active ?? undefined) === undefined
                  ? undefined
                  : temp?.active ?? false
                  ? true
                  : element?.active ?? false,
              comp:
                (temp?.comp ?? undefined) === undefined &&
                (element?.comp ?? undefined) === undefined
                  ? undefined
                  : temp?.comp ?? false
                  ? true
                  : element?.comp ?? false,
              defrost:
                (temp?.defrost ?? undefined) === undefined &&
                (element?.defrost ?? undefined) === undefined
                  ? undefined
                  : temp?.defrost ?? false
                  ? true
                  : element?.defrost ?? false,
              door:
                (temp?.door ?? undefined) === undefined &&
                (element?.door ?? undefined) === undefined
                  ? undefined
                  : temp?.door ?? false
                  ? true
                  : element?.door ?? false,
              fan:
                (temp?.fan ?? undefined) === undefined &&
                (element?.fan ?? undefined) === undefined
                  ? undefined
                  : temp?.fan ?? false
                  ? true
                  : element?.fan ?? false,
              light:
                (temp?.light ?? undefined) === undefined &&
                (element?.light ?? undefined) === undefined
                  ? undefined
                  : temp?.light ?? false
                  ? true
                  : element?.light ?? false,
            };
          }
        }
      });
      return v;
    })
    .value();
  return arr;
};
export const ModMonitoringIot = (data: any[], mod = 5) => {
  let arr: any[] = [];
  const uniqBy: any = _.reverse(_.uniqBy(_.reverse(data), "time"));
  _(uniqBy)
    .groupBy((v) => dayjs(v.time).format("DD/MM/YYYY"))
    .mapValues((v) => {
      let number = 0;
      let temp = {
        wifi: false,
        cloudStatus: false,
        id: "",
        time: null,
      };
      v.forEach((element) => {
        number++;
        if (number === mod) {
          arr.push({
            ...temp,
            cloudStatus:
              temp?.cloudStatus === undefined &&
              (element?.cloudStatus ?? undefined) === undefined
                ? undefined
                : temp?.cloudStatus ?? false
                ? true
                : element?.cloudStatus ?? false,
            wifi:
              temp?.wifi === undefined &&
              (element?.wifi ?? undefined) === undefined
                ? undefined
                : temp?.wifi ?? false
                ? true
                : element?.wifi ?? false,
          });
          number = 0;
        } else {
          if (number === 1) {
            temp = {
              cloudStatus: element?.cloudStatus ?? undefined,
              wifi: element?.wifi ?? undefined,
              id: element?.id ?? "",
              time: element?.time ?? null,
            };
          } else {
            temp = {
              ...temp,
              cloudStatus:
                temp?.cloudStatus === undefined &&
                (element?.cloudStatus ?? undefined) === undefined
                  ? undefined
                  : temp?.cloudStatus ?? false
                  ? true
                  : element?.cloudStatus ?? false,
              wifi:
                temp?.wifi === undefined &&
                (element?.wifi ?? undefined) === undefined
                  ? undefined
                  : temp?.wifi ?? false
                  ? true
                  : element?.wifi ?? false,
            };
          }
        }
      });
      return v;
    })
    .value();
  return arr;
};
export const ModMonitoringRssi = (data: any[], mod = 5) => {
  let arr: any[] = [];
  const uniqBy: any = _.uniqBy(data, "time");
  _(uniqBy)
    .groupBy((v) => dayjs(v.time).format("DD/MM/YYYY"))
    .mapValues((v) => {
      let number = 0;
      let temp = {
        rssi: 0,
        id: "",
        time: null,
      };
      v.forEach((element) => {
        number++;
        if (number === mod) {
          arr.push({
            ...temp,
            rssi:
              temp.rssi === null && (element?.rssi ?? null) === null
                ? null
                : MathRound((temp.rssi + element?.rssi ?? 0) / number, 1),
          });
          number = 0;
        } else {
          if (number === 1) {
            temp = {
              rssi: element?.rssi ?? null,
              id: element?.id ?? "",
              time: element?.time ?? null,
            };
          } else {
            temp = {
              ...temp,
              rssi:
                temp.rssi === null && (element?.rssi ?? null) === null
                  ? null
                  : temp.rssi + element?.rssi ?? 0,
            };
          }
        }
      });
      return v;
    })
    .value();
  return arr;
};
export const ModMonitoringPower = (data: any[], mod = 5) => {
  let arr: any[] = [];
  const uniqBy: any = _.uniqBy(data, "time");
  _(uniqBy)
    .groupBy((v) => dayjs(v.time).format("DD/MM/YYYY"))
    .mapValues((v) => {
      let number = 0;
      let temp = {
        current: 0,
        kw: 0,
        power: 0,
        voltage: 0,
        id: "",
        time: null,
      };
      v.forEach((element) => {
        number++;
        if (number === mod) {
          arr.push({
            ...temp,
            current:
              temp.current === null && (element?.current ?? null) === null
                ? null
                : MathRound((temp.current + element?.current ?? 0) / number, 1),
            kw:
              temp.kw === null && (element?.kw ?? null) === null
                ? null
                : MathRound((temp.kw + element?.kw ?? 0) / number, 1),
            power:
              temp.power === null && (element?.power ?? null) === null
                ? null
                : MathRound((temp.power + element?.power ?? 0) / number, 1),
            voltage:
              temp.voltage === null && (element?.voltage ?? null) === null
                ? null
                : MathRound((temp.voltage + element?.voltage ?? 0) / number, 1),
          });
          number = 0;
        } else {
          if (number === 1) {
            temp = {
              current: element?.current ?? null,
              kw: element?.kw ?? null,
              power: element?.power ?? null,
              voltage: element?.voltage ?? null,
              id: element?.id ?? "",
              time: element?.time ?? null,
            };
          } else {
            temp = {
              ...temp,
              current:
                temp.current === null && (element?.current ?? null) === null
                  ? null
                  : temp.current + element?.current ?? 0,
              kw:
                temp.kw === null && (element?.kw ?? null) === null
                  ? null
                  : temp.kw + element?.kw ?? 0,
              power:
                temp.power === null && (element?.power ?? null) === null
                  ? null
                  : temp.power + element?.power ?? 0,
              voltage:
                temp.voltage === null && (element?.voltage ?? null) === null
                  ? null
                  : temp.voltage + element?.voltage ?? 0,
            };
          }
        }
      });
      return v;
    })
    .value();
  return arr;
};
