import { Modal, ModalProps } from "antd";
import styled from "styled-components";
import dayjs from "dayjs";

import { device } from "@constants/styles";
import { colors } from "@constants/colors";
import Coming from "@components/Coming";
import { ButtonBlock } from "@components/Buttons";
import { imgList } from "@constants/imgList";
import { displayImage } from "@utils/image";
import GoogleMapSM from "@components/GoogleMapSM";

type Props = {
  open: boolean;
  data?: any;
  name?: string;
  onOk: () => void;
  onClose: () => void;
  onZoomImage?: (e: string) => void;
};

const ModalDeviceDetail = ({
  open = false,
  data = {},
  name = "",
  onOk = () => {},
  onClose = () => {},
  onZoomImage = () => {},
  ...props
}: Props & ModalProps) => {
  return (
    <Content id="model-device-detail" className="modal">
      <Modal
        title=""
        open={open}
        closable={false}
        footer={null}
        centered={true}
        getContainer={() =>
          document.getElementById("model-device-detail") as HTMLElement
        }
        {...props}
      >
        <div className="modal__head">
          <div className="font-sukhumvitSet-text size-24 weight-700 color-black">
            รายละเอียดอุปกรณ์
          </div>
          <img src={imgList.ico_close_modal} alt="" onClick={onClose} />
        </div>
        <div className="modal__content">
          <div className="modal__card">
            <div className="font-sukhumvitSet-text size-16 color-graye6e">
              Device Name
            </div>
            <div className="font-sukhumvitSet-text size-16 weight-700 color-black">
              {name}
            </div>
          </div>
          {/* <div className="modal__group">
            <div className="modal__card modal__card--small">
              <div className="font-sukhumvitSet-text size-16 color-graye6e">
                Device Model Name
              </div>
              <div className="font-sukhumvitSet-text size-16 weight-700 color-black">
                LSRF001
              </div>
              <div className="font-sukhumvitSet-text size-12 color-gray797">
                LSRF001
              </div>
            </div>
            <div className="modal__card modal__card--small">
              <div className="font-sukhumvitSet-text size-16 color-graye6e">
                Device Serial No.
              </div>
              <div className="font-sukhumvitSet-text size-16 weight-700 color-black">
                32874-1244-5554
              </div>
            </div>
          </div> */}
          <div className="modal__card">
            <div className="font-sukhumvitSet-text size-16 color-graye6e">
              Description
            </div>
            <div className="font-sukhumvitSet-text size-16 weight-700 color-black">
              {data?.description ?? "-"}
            </div>
          </div>
          <div className="modal__card">
            <div className="font-sukhumvitSet-text size-16 color-graye6e">
              Device Mac Address
            </div>
            <div className="font-sukhumvitSet-text size-16 weight-700 color-black">
              {data?.macAddress ?? "-"}
            </div>
          </div>
          <div className="modal__card">
            <div className="font-sukhumvitSet-text size-16 color-graye6e">
              Image
            </div>
            <Image className="img">
              <img
                src={
                  data?.image ?? ""
                    ? displayImage(data?.image ?? "")
                    : imgList.ex
                }
                alt=""
                className="img__cover"
              />
              <img
                src={imgList.btn_zoom_image}
                alt=""
                className="img__btn"
                onClick={() =>
                  onZoomImage(
                    data?.image ?? ""
                      ? displayImage(data?.image ?? "")
                      : imgList.ex
                  )
                }
              />
            </Image>
          </div>
          <div className="modal__card">
            <div className="font-sukhumvitSet-text size-16 color-graye6e">
              Map location of Device
            </div>
            <div className="modal__location">
              <img src={imgList.ico_location_gray} alt="" />
              <div>
                <div className="font-sukhumvitSet-text size-16 weight-700 color-black">
                  {data?.addressText ?? "-"}
                </div>
                {/* <div className="font-sukhumvitSet-text size-12 color-gray797">
                  70/32 หมู่บ้านฉะเชิงเทรา 70/32 หมู่บ้านฉะเชิงเทรา
                </div> */}
              </div>
            </div>
            <GoogleMapSM
              lat={data?.coordinates?.[1] ?? 0}
              lng={data?.coordinates?.[0] ?? 0}
              googlePlaceId={data?.googlePlaceId ?? ""}
            />
          </div>
          <div className="modal__card">
            <div className="font-sukhumvitSet-text size-16 color-graye6e">
              LINE Notify token
            </div>
            <div className="font-sukhumvitSet-text size-16 weight-700 color-black">
              {data?.lineIds?.[0] ?? "-"}
            </div>
          </div>
          {/* <div className="modal__card">
            <div className="font-sukhumvitSet-text size-16 color-graye6e">
              Email Notify
            </div>
            <div className="font-sukhumvitSet-text size-16 weight-700 color-black">
              -
            </div>
          </div> */}
          <div className="modal__card">
            <div className="font-sukhumvitSet-text size-16 color-graye6e">
              Interval Recording
            </div>
            <div className="font-sukhumvitSet-text size-16 weight-700 color-black">
              {data?.setRecordTime ?? 0}
              {(data?.setRecordTime ?? 0) > 1 ? " Mins" : " Min"}
            </div>
          </div>
          <div className="modal__card">
            <div className="font-sukhumvitSet-text size-16 color-graye6e">
              Keep Alive
            </div>
            <div className="font-sukhumvitSet-text size-16 weight-700 color-black">
              {data?.keepAlive ?? 0}
              {(data?.keepAlive ?? 0) > 1 ? " Mins" : " Min"}
            </div>
          </div>
          <div className="modal__card">
            <div className="font-sukhumvitSet-text size-16 color-graye6e">
              Firmware
            </div>
            <div className="font-sukhumvitSet-text size-16 weight-700 color-black">
              {`Wi-Fi Firmware released version ${
                data?.deviceFirmware?.wifiDisplayNum ?? ""
              } (${dayjs(data?.deviceFirmware?.wifiUpdate).format('MMM D, YYYY h:MM A')})`}
              <br />
              {`Main Firmware released version ${
                data?.deviceFirmware?.mainDisplayNum ?? ""
              } (${dayjs(data?.deviceFirmware?.mainUpdate).format('MMM D, YYYY h:MM A')})`}
            </div>
            {(data?.deviceFirmware?.hasNewerVersion ?? false) === true && (
              <NewVersion className="new">
                <div className="new__dot" />
                <div className="font-sukhumvitSet-text size-12 weight-600 color-blac new__text">
                  A new version is available on application.
                </div>
              </NewVersion>
            )}
          </div>
        </div>
      </Modal>
    </Content>
  );
};

export default ModalDeviceDetail;

const NewVersion = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 8px 0 0;
  .new {
    &__dot {
      width: 14px;
      height: 14px;
      background: #febc11;
      border-radius: 50%;
      margin: 0 4px 0 0;
    }
    &__text {
      line-height: 1;
    }
  }
`;
const Image = styled.div`
  position: relative;
  width: 24%;
  padding-top: 24%;
  overflow: hidden;
  border-radius: 8px;
  margin: 6px 0 0;
  .img {
    &__cover {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      max-width: unset;
      max-height: unset;
      transform: translate(-50%, -50%);
      object-fit: cover;
    }
    &__btn {
      position: absolute;
      bottom: 6%;
      right: 6%;
      width: 42%;
      cursor: pointer;
    }
  }
`;
const Content = styled.div`
  position: relative;
  z-index: 999999;
  width: 100%;
  max-width: 90% !important;
  & .ant-modal-content {
    border-radius: 0.833vw;
    background-color: #f8f8f8;
    @media ${device.desktopL} {
      border-radius: 12px;
    }
  }
  & .ant-modal {
    width: 41.667vw !important;
    padding: 1.389vw 0;
    @media ${device.desktopL} {
      width: 600px !important;
      padding: 20px 0;
    }
  }
  & .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  .modal {
    &__head {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      > img {
        width: 1.667vw;
        height: 1.667vw;
        cursor: pointer;
      }
      > div {
        width: calc(100% - 1.667vw - 0.417vw);
        margin: 0 0.417vw 0 0;
        line-height: 1.2;
      }
      @media ${device.desktopL} {
        > img {
          width: 24px;
          height: 24px;
        }
        > div {
          width: calc(100% - 24px - 6px);
          margin: 0 6px 0 0;
        }
      }
    }
    &__content {
      width: 100%;
    }
    &__group {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &__card {
      width: 100%;
      border-radius: 8px;
      background: #fff;
      padding: 16px 24px;
      margin: 12px 0 0;
      &--small {
        width: 49%;
      }
    }
    &__location {
      width: 100%;
      display: flex;
      align-items: flex-start;
      > img {
        width: 20px;
        margin: 0 6px 0 0;
      }
      > div {
        width: calc(100% - 20px - 6px);
      }
    }
  }
`;
