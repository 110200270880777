import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Resizer from "react-image-file-resizer";

import { device } from "@constants/styles";
import { imgList } from "@constants/imgList";
import { InputSimple, TextareaSimple } from "@components/Inputs";
import { ButtonBlock } from "@components/Buttons";
import { ModalConfirm } from "@components/Modals";
import {
  CheckValue,
  textLengthIsOk,
  textContainsUppercase,
  textContainsLowercase,
  textContainsNumber,
  textContainsSpecialCharactor,
} from "@utils/validate";
import { SignOutChangePassword } from "@utils/signout";
import { apiGet, apiPut, apiPost } from "@apis/gateway";
import { loadingAction } from "@reduxs/Actions/storeAction";
import { infoAction } from "@reduxs/Actions/userAction";
import { stateInterface } from "@reduxs/interface";

interface apiPutUserMe {
  user_fullname: string;
  telephone?: string;
  bio?: string;
  image_id?: string;
}

const initalValues = {
  name: {
    value: "",
    error: "",
  },
  email: {
    value: "",
    error: "",
  },
  phone: {
    value: "",
    error: "",
  },
  bio: {
    value: "",
    error: "",
  },
};
const initalValuesPwd = {
  old: {
    value: "",
    error: "",
  },
  new: {
    value: "",
    error: "",
  },
  confirm: {
    value: "",
    error: "",
  },
};
const initalImageCover = {
  id: "",
  url: "",
};
export default function Profile() {
  const dispatch = useDispatch();
  const language = useSelector(
    (state: stateInterface) => state.storage.language
  );
  const [tab, setTab] = useState(1);
  const [values, setValues] = useState(initalValues);
  const [valuesPwd, setValuesPwd] = useState(initalValuesPwd);
  const [imageCover, setImageCover] = useState(initalImageCover);
  const [userID, setUserID] = useState("");
  const [error, setError] = useState("");
  const [canSave, setCanSave] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    head: "",
    subHead: "",
  });
  const [validateField, setValidate] = useState({
    lengthIsValid: false,
    uppercaseIsValid: false,
    lowercaseIsValid: false,
    numberIsValid: false,
    specialCharIsValid: false,
    passwordNotSame: false,
  });
  useEffect(() => {
    init();
  }, [tab]);
  useEffect(() => {
    setError("");
    setValues(initalValues);
    setValuesPwd(initalValuesPwd);
  }, [tab]);
  useEffect(() => {
    const {
      lengthIsValid,
      uppercaseIsValid,
      lowercaseIsValid,
      numberIsValid,
      specialCharIsValid,
      passwordNotSame,
    } = validateField;
    if (
      lengthIsValid &&
      uppercaseIsValid &&
      lowercaseIsValid &&
      numberIsValid &&
      numberIsValid &&
      specialCharIsValid &&
      passwordNotSame
    ) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [validateField]);
  useEffect(() => {
    const password = valuesPwd.new.value;
    const passwordConfirm = valuesPwd.confirm.value;
    const _lengthIsValid = textLengthIsOk(password, 8, 20);
    const _uppercaseIsValid = textContainsUppercase(password);
    const _lowercaseIsValid = textContainsLowercase(password);
    const _numberIsValid = textContainsNumber(password);
    const _specialCharIsValid = textContainsSpecialCharactor(password);

    setValidate({
      ...validateField,
      lengthIsValid: _lengthIsValid,
      uppercaseIsValid: _uppercaseIsValid !== null ? true : false,
      lowercaseIsValid: _lowercaseIsValid !== null ? true : false,
      numberIsValid: _numberIsValid !== null ? true : false,
      specialCharIsValid: _specialCharIsValid !== null ? true : false,
      passwordNotSame:
        password === passwordConfirm && password !== "" ? true : false,
    });
  }, [valuesPwd]);
  const init = async () => {
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/users/me`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      setUserID(res?.data?.data?.id ?? "");
      setImageCover(res?.data?.data?.image ?? {});
      setValues({
        name: {
          value: res?.data?.data?.user_fullname ?? "",
          error: "",
        },
        email: {
          value: res?.data?.data?.email ?? "",
          error: "",
        },
        phone: {
          value: res?.data?.data?.telephone ?? "",
          error: "",
        },
        bio: {
          value: res?.data?.data?.bio ?? "",
          error: "",
        },
      });
      dispatch(
        infoAction({
          email: res?.data?.data?.email ?? "",
          user_fullname: res?.data?.data?.user_fullname ?? "",
          user_firstname: res?.data?.data?.user_firstname ?? "",
          user_lastname: res?.data?.data?.user_firstname ?? "",
          image: {
            id: res?.data?.data?.image?.id ?? "",
            url: res?.data?.data?.image?.url ?? "",
            updatedAt: res?.data?.data?.image?.updatedAt ?? null,
          },
          image_id: {
            id: res?.data?.data?.image_id?.id ?? "",
            url: res?.data?.data?.image_id?.url ?? "",
            updatedAt: res?.data?.data?.image_id?.updatedAt ?? null,
          },
        })
      );
    }
  };
  const handleChange = async () => {
    if (!CheckValue(userID)) {
      notifyDanger({ title: "something Went Wrong" });
      return;
    }
    if (!CheckValue(values.name.value)) {
      setValues({
        ...values,
        name: {
          value: values.name.value,
          error: "Please Input Your Name",
        },
      });
      return;
    }
    let params: apiPutUserMe = {
      user_fullname: values.name.value,
    };
    if (CheckValue(values.phone.value)) {
      params = {
        ...params,
        telephone: values.phone.value,
      };
    }
    if (CheckValue(values.bio.value)) {
      params = {
        ...params,
        bio: values.bio.value,
      };
    }
    if (CheckValue(imageCover.id)) {
      params = {
        ...params,
        image_id: imageCover.id,
      };
    }
    dispatch(loadingAction(true));
    const res = await apiPut(
      `${process.env.REACT_APP_API_DOMAIN}/users/${userID}`,
      { ...params },
      {},
      language
    );
    if (res.status && res.code === 200) {
      notifySuccess({ title: "เปลี่ยนแปลงข้อมูลส่วนตัวสำเร็จ" });
      init();
    } else {
      notifyDanger({ title: "something Went Wrong" });
    }
    dispatch(loadingAction(false));
  };
  const handleChangeProfile = async () => {
    setModal({
      open: true,
      head: "ยืนยันการเปลี่ยนแปลงข้อมูลส่วนตัว",
      subHead: "",
    });
  };
  const handleChangePassword = async () => {
    setModal({
      open: true,
      head: "ยืนยันการเปลี่ยนรหัสผ่าน",
      subHead:
        "เมื่อมีการเปลี่ยนรหัสผ่าน คุณจำเป็นที่จะต้องเข้าสู่ระบบใน CORNTROL Application อีกครั้ง",
    });
  };
  const handleApiChangePassword = async () => {
    const params = {
      old_password: valuesPwd.old.value,
      new_password: valuesPwd.new.value,
    };
    dispatch(loadingAction(true));
    const res = await apiPut(
      `${process.env.REACT_APP_API_DOMAIN}/users/${userID}/password`,
      { ...params },
      {},
      language
    );
    if (res.status && res.code === 200) {
      SignOutChangePassword();
    } else {
      notifyDanger({ title: res?.data.message ?? "" });
    }
    dispatch(loadingAction(false));
  };
  const onSelectImageChange = async () => {
    const limitSize = 5000000;
    const inputSelectImage: any = document?.getElementById(
      "frm-input-select-image"
    );
    try {
      if (inputSelectImage?.files?.length > 0) {
        for (const file of inputSelectImage?.files) {
          const formData = new FormData();
          if (file.size > limitSize) {
            notifyDanger({ title: "Image too big than 5.0 MB" });
            return;
          }
          dispatch(loadingAction(true));
          try {
            const image: any = await resizeFile(file);
            formData.append("image", image);
            const res = await apiPost(
              `${process.env.REACT_APP_API_DOMAIN}/image`,
              formData,
              {
                "Content-Type": "multipart/form-data",
              },
              language
            );
            if (res.status) {
              setImageCover({
                id: res?.data?.data?.id,
                url: "",
              });
            } else {
              if (res?.error?.message === "Network Error") {
                notifyDanger({ title: "Image too big than 5.0 MB" });
              } else {
                notifyDanger({ title: res?.data?.message ?? "" });
              }
            }
          } catch (err) {
            console.log(err);
          }
          dispatch(loadingAction(false));
        }
      }
      inputSelectImage.val("");
    } catch (err) {}
  };
  const resizeFile = (file: any) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        file.type.replace("image/", ""),
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  const notifySuccess = ({ title = "" }) =>
    toast(
      <div className="toast-config">
        <img
          src={imgList.ico_toast_success2}
          alt=""
          className="toast-config__icon"
        />
        <div className="toast-config__detail">
          <div className="font-sukhumvitSet-bold size-14 color-grayb36">
            {title}
          </div>
        </div>
        <img src={imgList.ic_close} alt="" className="toast-config__arrow" />
      </div>,
      {
        className: "toast-config-success",
        containerId: "profile",
      }
    );
  const notifyDanger = ({ title = "" }) =>
    toast(
      <div className="toast-config">
        <img
          src={imgList.ico_toast_fail2}
          alt=""
          className="toast-config__icon"
        />
        <div className="toast-config__detail">
          <div className="font-sukhumvitSet-bold size-14 color-grayb36">
            {title}
          </div>
        </div>
        <img src={imgList.ic_close} alt="" className="toast-config__arrow" />
      </div>,
      {
        className: "toast-config-danger",
        containerId: "profile",
      }
    );
  return (
    <Container className="profile">
      <div className="font-inter size-30 color-blue828 weight-600">Profile</div>
      <TabHead className="tab">
        <div className="tab__box">
          <div
            className={`tab__text tab__text--1 ${tab === 1 ? "active" : ""}`}
            onClick={() => setTab(1)}
          >
            <img
              src={imgList[tab === 1 ? "ico_user_t_active" : "ico_user_t"]}
              alt=""
            />
            <div className="size-14 weight-700 font-sukhumvitSet-bold">
              Information
            </div>
          </div>
          <div
            className={`tab__text ${tab === 2 ? "active" : ""}`}
            onClick={() => setTab(2)}
          >
            <img
              src={imgList[tab === 2 ? "ico_key_active" : "ico_key"]}
              alt=""
            />
            <div className="size-14 weight-700 font-sukhumvitSet-bold">
              Change Password
            </div>
          </div>
        </div>
      </TabHead>
      {tab === 1 && (
        <div className="profile__box">
          <div className="profile__card profile__left">
            <div
              className="profile__upload"
              onClick={() =>
                document?.getElementById("frm-input-select-image")?.click()
              }
            >
              <div className="profile__upload-inner">
                {!!(imageCover?.id ?? "") && (
                  <img
                    src={`${process.env.REACT_APP_API_DOMAIN}/image/asset/${
                      imageCover?.id ?? ""
                    }`}
                    alt=""
                  />
                )}
                <div
                  className={`profile__upload-content ${
                    !!(imageCover?.id ?? "") ? "hover-upload" : ""
                  }`}
                >
                  <img src={imgList.upload} alt="" />
                </div>
              </div>
            </div>
            <div className="size-12 weight-400 font-sukhumvitSet-text profile__etc">
              Allowed *.jpeg, *.jpg, *.png, *.gif
              <br />
              Maximum file size 5 MB
            </div>
            <input
              id="frm-input-select-image"
              type="file"
              className="d-none"
              value=""
              // accept="image/*"
              onChange={() => {
                onSelectImageChange();
              }}
            />
          </div>
          <div className="profile__card profile__right">
            <div className="profile__row">
              <InputSimple
                type="text"
                labelText="Name"
                classNameWarp="first"
                status={values.name.error ? "error" : ""}
                errorText={values.name.error}
                value={values.name.value}
                placeholder="Input Your Name"
                onChange={(e) => {
                  setValues({
                    ...values,
                    name: {
                      value: e.target.value,
                      error: "",
                    },
                  });
                  setError("");
                }}
              />
              <InputSimple
                type="text"
                labelText="Email"
                value={values.email.value}
                placeholder="Input Your Email"
                disabled
              />
            </div>
            <div className="profile__row profile__row--mg">
              <InputSimple
                type="text"
                labelText="Phone Number (Optional)"
                status={values.phone.error ? "error" : ""}
                errorText={values.phone.error}
                value={values.phone.value}
                placeholder="Input Your Phone Number"
                onChange={(e) => {
                  setValues({
                    ...values,
                    phone: {
                      value: e.target.value,
                      error: "",
                    },
                  });
                  setError("");
                }}
              />
            </div>
            <div className="profile__row profile__row--large profile__row--mg">
              <TextareaSimple
                labelText="Bio (Optional)"
                status={values.bio.error ? "error" : ""}
                errorText={values.bio.error}
                value={values.bio.value}
                placeholder="Input Your Bio"
                rows={6}
                onChange={(e) => {
                  setValues({
                    ...values,
                    bio: {
                      value: e.target.value,
                      error: "",
                    },
                  });
                  setError("");
                }}
              />
            </div>
            <div className="profile__btn">
              <ButtonBlock
                text="Save Changes"
                onClick={() => handleChangeProfile()}
              />
            </div>
          </div>
        </div>
      )}
      {tab === 2 && (
        <div className="profile__box">
          <div className="profile__card profile__card--large">
            <div className="profile__row profile__row--large">
              <InputSimple
                type="password"
                labelText="Old Password"
                classNameWarp="first"
                status={valuesPwd.old.error ? "error" : ""}
                errorText={valuesPwd.old.error}
                value={valuesPwd.old.value}
                placeholder="Input Your Old Password"
                onChange={(e) => {
                  setValuesPwd({
                    ...valuesPwd,
                    old: {
                      value: e.target.value,
                      error: "",
                    },
                  });
                  setError("");
                }}
              />
            </div>
            <div className="profile__row profile__row--large profile__row--mg">
              <InputSimple
                type="password"
                labelText="New Password"
                status={valuesPwd.new.error ? "error" : ""}
                errorText={valuesPwd.new.error}
                value={valuesPwd.new.value}
                placeholder="Input Your New Password"
                onChange={(e) => {
                  setValuesPwd({
                    ...valuesPwd,
                    new: {
                      value: e.target.value,
                      error: "",
                    },
                  });
                  setError("");
                }}
              />
            </div>
            <div className="profile__row profile__row--large profile__row--mg">
              <InputSimple
                type="password"
                labelText="Confirm Password"
                status={valuesPwd.confirm.error ? "error" : ""}
                errorText={valuesPwd.confirm.error}
                value={valuesPwd.confirm.value}
                placeholder="Input Your Confirm Password"
                onChange={(e) => {
                  setValuesPwd({
                    ...valuesPwd,
                    confirm: {
                      value: e.target.value,
                      error: "",
                    },
                  });
                  setError("");
                }}
              />
            </div>
            <Etc className="etc">
              <div className="etc__left first">
                <img
                  src={
                    validateField.lengthIsValid
                      ? imgList.ico_check
                      : imgList.ico_checkgray
                  }
                  alt=""
                />
                <div className="size-14 font-sukhumvitSet-text">
                  Minimum 8 Characters
                </div>
              </div>
              <div className="etc__left">
                <img
                  src={
                    validateField.uppercaseIsValid
                      ? imgList.ico_check
                      : imgList.ico_checkgray
                  }
                  alt=""
                />
                <div className="size-14 font-sukhumvitSet-text">
                  Minimum 1 Uppercase
                </div>
              </div>
              <div className="etc__left">
                <img
                  src={
                    validateField.lowercaseIsValid
                      ? imgList.ico_check
                      : imgList.ico_checkgray
                  }
                  alt=""
                />
                <div className="size-14 font-sukhumvitSet-text">
                  Minimum 1 Lowercase
                </div>
              </div>
              <div className="etc__left">
                <img
                  src={
                    validateField.numberIsValid
                      ? imgList.ico_check
                      : imgList.ico_checkgray
                  }
                  alt=""
                />
                <div className="size-14 font-sukhumvitSet-text">
                  Minimum 1 number
                </div>
              </div>
              <div className="etc__left">
                <img
                  src={
                    validateField.specialCharIsValid
                      ? imgList.ico_check
                      : imgList.ico_checkgray
                  }
                  alt=""
                />
                <div className="size-14 font-sukhumvitSet-text">
                  Special Characters
                </div>
              </div>
              <div className="etc__left">
                <img
                  src={
                    validateField.passwordNotSame
                      ? imgList.ico_check
                      : imgList.ico_checkgray
                  }
                  alt=""
                />
                <div className="size-14 font-sukhumvitSet-text">
                  The password must be the same
                </div>
              </div>
            </Etc>
            {error && (
              <div className="profile__error size-14 weight-500 font-jakarta-display color-red text-center">
                {error}
              </div>
            )}
            <div className="profile__btn">
              <ButtonBlock
                text="Change Password"
                disable={!canSave}
                onClick={canSave ? () => handleChangePassword() : () => {}}
              />
            </div>
          </div>
        </div>
      )}
      <ModalConfirm
        {...modal}
        onOk={() => {
          modal.subHead ? handleApiChangePassword() : handleChange();
          setModal({ open: false, head: "", subHead: "" });
        }}
        onClose={() => setModal({ open: false, head: "", subHead: "" })}
      />
      <ToastWarp>
        <ToastContainer
          autoClose={10000}
          hideProgressBar={true}
          containerId="profile"
          enableMultiContainer
        />
      </ToastWarp>
    </Container>
  );
}

const ToastWarp = styled.div`
  position: relative;
  z-index: 99999;
  & .Toastify__toast-container {
    right: 28px;
    top: 28px;
  }
  & .Toastify__toast {
    border-radius: 8px;
    padding: 16px;
  }
  & .toast-config-success {
    background: #fff;
    border: none;
  }
  & .toast-config-info {
    background: #fff;
    border: none;
  }
  & .toast-config-danger {
    background: #fff;
    border: none;
  }
  & .toast-config-warning {
    background: #fff;
    border: none;
  }
  & .Toastify__close-button {
    display: none;
  }
  & .Toastify__toast-body {
    padding: 0;
  }
  .toast-config {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__icon {
      width: 40px;
      margin: 0 12px 0 0;
    }
    &__arrow {
      width: 24px;
      margin: 0 0 0 12px;
      cursor: pointer;
    }
    &__detail {
      width: calc(100% - 40px - 24px - 12px - 12px);
      > div {
        line-height: 1.2;
      }
      & .etc {
        margin: 4px 0 12px;
      }
    }
    &__next {
      display: flex;
      align-items: center;
      width: 100%;
      > img {
        width: 20px;
        margin: 0 0 0 8px;
      }
    }
  }
`;

const Etc = styled.div`
  width: 100%;
  margin: 16px 0 0;
  .etc {
    &__left {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 16px 0 0;
      color: #c1c1c1;
      &.first {
        margin: 0;
      }
      > img {
        width: 20px;
        display: block;
        margin: 0 8px 0 0;
      }
      > div {
        width: calc(100% - 28px);
      }
    }
  }
`;

const TabHead = styled.div`
  width: 100%;
  margin: 10px 0 0;
  .tab {
    &__box {
      width: 100%;
      display: flex;
      align-items: center;
    }
    &__text {
      color: #637381;
      display: flex;
      align-items: center;
      padding: 8px 0;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      &--1 {
        margin: 0 40px 0 0;
      }
      > img {
        width: 20px;
        margin: 0 8px 0 0;
      }
      &.active {
        color: #212b36;
        border-bottom: 2px solid #febc11;
      }
    }
  }
`;
const Container = styled.div`
  width: 100%;
  & .hover-upload {
    display: none;
  }
  & textarea {
    resize: none;
  }
  .profile {
    &__card {
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12),
        0px 0px 2px 0px rgba(145, 158, 171, 0.2);
      padding: 24px;
      &--large {
        width: 100%;
      }
    }
    &__box {
      width: 100%;
      margin: 24px 0 0;
      display: flex;
      @media ${device.tablet} {
        flex-wrap: wrap;
      }
    }
    &__left {
      width: 344px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media ${device.tablet} {
        width: 100%;
      }
    }
    &__right {
      width: calc(100% - 344px - 24px);
      margin: 0 0 0 24px;
      @media ${device.tablet} {
        width: 100%;
        margin: 16px 0 0;
      }
    }
    &__upload {
      position: relative;
      width: 144px;
      height: 144px;
      border: 1px dashed rgba(145, 158, 171, 0.32);
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
    }
    &__upload-inner {
      position: absolute;
      width: 90%;
      height: 90%;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      border-radius: 50%;
      background: rgba(22, 28, 36, 0.6);
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(
        circle,
        white 100%,
        black 100%
      );
      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        object-fit: cover;
      }
      &:hover {
        & .hover-upload {
          display: block !important;
        }
      }
    }
    &__upload-content {
      position: absolute;
      width: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &__etc {
      width: 100%;
      text-align: center;
      margin: 20px 0 0;
      color: #637381;
    }
    &__row {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      > div {
        width: 48%;
      }
      &--mg {
        margin: 16px 0 0;
      }
      &--large {
        > div {
          width: 100%;
        }
      }
      @media ${device.mobileLL} {
        flex-wrap: wrap;
        & .first {
          margin: 0 0 0;
        }
        > div {
          width: 100%;
          margin: 16px 0px 0px;
        }
        &--mg {
          margin: 0;
        }
      }
    }
    &__btn {
      text-align: right;
      margin: 16px 0 0;
      > div {
        width: auto;
        display: inline-block;
        padding: 0 22px;
      }
      @media ${device.mobileLL} {
        width: 100%;
        > div {
          width: 100%;
        }
      }
    }
    &__error {
      margin: 16px 0 0;
    }
  }
`;
