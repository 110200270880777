import * as FileSaver from "file-saver";
import * as XLSX from "sheetjs-style";

const Excel = ({ fileName = "", data = [], button = <></> }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportExcel = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { Sheet1: ws }, SheetNames: ["Sheet1"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };
  return <div onClick={() => exportExcel(data, fileName)}>{button}</div>;
};

export default Excel;
