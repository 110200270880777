import { combineReducers } from "redux";

import store from "./store";
import user from "./user";
import storage from "./storage";
import monitoring from "./monitoring";

const reducers = {
  store,
  user,
  storage,
  monitoring,
};

export default combineReducers(reducers);
