import styled from "styled-components";
import { useState } from "react";
import Cookies from "js-cookie";
import { useSelector, useDispatch } from "react-redux";

import { imgList } from "@constants/imgList";
import { device } from "@constants/styles";
import { InputSimple } from "@components/Inputs";
import { ButtonBlock } from "@components/Buttons";
import { ModalRegister } from "@components/Modals";
import { CheckValue } from "@utils/validate";
import { regexEmail } from "@utils/regex";
import { apiPost } from "@apis/gateway";
import { loadingAction } from "@reduxs/Actions/storeAction";
import { stateInterface } from "@reduxs/interface";

export default function SignIn() {
  const dispatch = useDispatch();
  const language = useSelector(
    (state: stateInterface) => state.storage.language
  );
  const [values, setValues] = useState({
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  });
  const [error, setError] = useState("");
  const [register, setRegister] = useState(false);
  const validate = () => {
    const { email, password } = values;
    let canPass = true;
    let temp = values;
    if (!CheckValue(email.value)) {
      canPass = false;
      temp = {
        ...temp,
        email: {
          value: email.value,
          error: "กรุณาตรวจสอบ Email ให้ครบถ้วน",
        },
      };
    } else if (CheckValue(email.value) && !regexEmail.test(email.value)) {
      canPass = false;
      temp = {
        ...temp,
        email: {
          value: email.value,
          error: "กรุณาตรวจสอบ Email ให้ถูกต้อง",
        },
      };
    }
    if (password.value.length <= 7) {
      canPass = false;
      temp = {
        ...temp,
        password: {
          value: password.value,
          error: "กรุณาตรวจสอบ Password ให้ครบถ้วน",
        },
      };
    }
    if (canPass) {
      handleSignin();
    }
    setValues(temp);
  };
  const handleSignin = async () => {
    const params = {
      email: values.email.value,
      password: values.password.value,
      type: "password",
    };
    dispatch(loadingAction(true));
    const res = await apiPost(
      `${process.env.REACT_APP_API_DOMAIN}/authentications/accesstoken`,
      { ...params },
      {},
      language
    );
    if (res.status && res.code === 201) {
      Cookies.set("jwt_token_control", res.data?.data?.token ?? "", {
        expires: parseInt(process.env?.REACT_APP_JWT_EXP ?? "7"),
      });
      Cookies.set(
        "jwt_refresh_token_control",
        res.data?.data?.refresh_token ?? "",
        {
          expires: parseInt(process.env?.REACT_APP_JWT_REFRESH_EXP ?? "14"),
        }
      );
      localStorage.setItem("showNoti", "1");
      window.location.href = "/";
    } else {
      setError(res.data?.message ?? "");
    }
    dispatch(loadingAction(false));
  };
  return (
    <Container className="signin">
      <div className="signin__logo">
        <img src={imgList.logo} alt="" />
      </div>
      <div className="text-center size-24 weight-700 color-black522 font-jakarta-sans">
        Welcome To Corntrol
      </div>
      <div className="signin__etc--1 text-center size-14 weight-400 color-grayca4 font-jakarta-display">
        Login to Continue
      </div>
      <form id="signin">
        <InputSimple
          type="text"
          labelText="Email Address"
          status={values.email.error ? "error" : ""}
          errorText={values.email.error}
          value={values.email.value}
          placeholder="Input Your Email Address"
          onChange={(e) => {
            setValues({
              ...values,
              email: {
                value: e.target.value,
                error: "",
              },
            });
            setError("");
          }}
        />
        <InputSimple
          type="password"
          labelText="Password"
          status={values.password.error ? "error" : ""}
          errorText={values.password.error}
          autoComplete="off"
          classNameWarp="signin__second-input"
          value={values.password.value}
          placeholder="Input Your Password"
          onChange={(e) => {
            setValues({
              ...values,
              password: {
                value: e.target.value,
                error: "",
              },
            });
            setError("");
          }}
        />
      </form>
      {error && (
        <div className="signin__second-input size-14 weight-500 font-jakarta-display color-red text-center">
          {error}
        </div>
      )}
      <div className="signin__btn-group">
        {/* <div className="color-grayca4 font-jakarta-display weight-400 size-14">
          Forgot password?
        </div>
        <ButtonBlock
          className="signin__btn"
          text="Sign In"
          onClick={() => validate()}
        /> */}
        <ButtonBlock
          className="signin__btn signin__btn--large"
          text="Sign In"
          onClick={() => validate()}
        />
      </div>
      <div className="signin__etc--2 text-center font-jakarta-display size-14 weight-400 color-black522">
        New user?&nbsp;
        <div
          className="weight-500 color-primary"
          onClick={() => setRegister(true)}
        >
          Create an acount
        </div>
      </div>
      <ModalRegister
        open={register}
        onOk={() => setRegister(false)}
        onClose={() => setRegister(false)}
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  .signin {
    &__logo {
      width: 52%;
      margin: 0 auto;
      > img {
        width: 100%;
      }
    }
    &__etc {
      &--1 {
        margin: 0.694vw 0 0;
      }
      &--2 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2.292vw 0 0;
        > div {
          cursor: pointer;
        }
      }
      @media ${device.desktopL} {
        &--1 {
          margin: 10px 0 0;
        }
        &--2 {
          margin: 33px 0 0;
        }
      }
    }
    &__second-input {
      margin: 2.083vw 0 0;
      @media ${device.desktopL} {
        margin: 30px 0 0;
      }
    }
    &__btn-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 2.083vw 0 0;
      @media ${device.desktopL} {
        margin: 30px 0 0;
      }
    }
    &__btn {
      width: 44%;
      &--large {
        width: 100%;
      }
    }
  }
`;
