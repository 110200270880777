import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import dayjs from "dayjs";
import { registerables, Chart } from "chart.js";
import { useSelector } from "react-redux";

import { chartColors } from "@constants/colors";
import { capitalizeFirstLetter } from "@utils/string";
import { device } from "@constants/styles";

Chart.register(...registerables);

const DATETIME_TYPE = {
  hours: "Minutes",
  days: "Hours",
  months: "Days",
  years: "Months",
};

const DATETIME_LABEL_FORMAT = {
  hours: "HH:mm",
  days: "HH:mm",
  months: "DD/MM",
  years: "MM/YYYY",
};

const TEMP_TYPE = {
  c: "°C",
  f: "°F",
};

const VIEW_TYPE = {
  inside: "inside",
  coil: "coil",
};

const VALUE_TYPE = {
  avg: "avg",
  min: "min",
  max: "max",
};

const DEFAULT_DATETIME_TYPE = "hour";
const DEFAULT_DATETIME_LABEL_FORMAT = "HH:mm";
const DEFAULT_TEMP_TYPE = "°C";
const DEFAULT_VIEW_TYPE = "inside";
const DEFAULT_VALUE_TYPE = "avg";

export const CHART_COLORS = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};

const Temperature = ({ data }) => {
  const filterDeviceDetail = useSelector(
    (state) => state.monitoring.filterDeviceDetail
  );
  const filterTemp = useSelector((state) => state.monitoring.filterTemp);

  const [datas, setDatas] = useState({ labels: [], datasets: [] });
  const datetime = filterDeviceDetail.date;
  const datetimeunit = filterDeviceDetail.unit;
  const tempunit = filterTemp.unit;
  const view = filterTemp.view;
  const value = filterTemp.value;

  const datetime_type = DATETIME_TYPE?.[datetimeunit] ?? DEFAULT_DATETIME_TYPE;
  const datetime_format =
    DATETIME_LABEL_FORMAT?.[datetimeunit] ?? DEFAULT_DATETIME_LABEL_FORMAT;
  const temp_type = TEMP_TYPE?.[tempunit] ?? DEFAULT_TEMP_TYPE;
  const view_type = VIEW_TYPE?.[view] ?? DEFAULT_VIEW_TYPE;
  const value_type = VALUE_TYPE?.[value] ?? DEFAULT_VALUE_TYPE;

  useEffect(() => {
    setDatas({
      labels: genLabels(),
      datasets: genDatasets(),
    });
  }, [data]);

  const genLabels = () => {
    return (data ?? []).map((e) => {
      return e?.start ?? ""
        ? dayjs(e?.start ?? "").format(datetime_format)
        : "-";
    });
  };

  const genDates = () => {
    return (data ?? []).map((e) => {
      return e?.start ?? ""
        ? dayjs(e?.start ?? "").format("DD/MM/YYYY HH:mm")
        : "-";
    });
  };

  const genDatasets = () => {
    if (view_type === "inside") {
      return [
        {
          label: `High Temp Alarm`,
          data: genDatas("highestTemp"),
          borderColor: chartColors.danger,
          pointBackgroundColor: chartColors.danger,
          fill: false,
          cubicInterpolationMode: "monotone",
          tension: 0.4,
          date: genDates(),
          borderWidth: 1,
        },
        {
          label: `Room Temp`,
          data: genDatas("insideTemp"),
          borderColor: chartColors.blue,
          pointBackgroundColor: chartColors.blue,
          fill: false,
          tension: 0.4,
          date: genDates(),
        },
        {
          label: `Low Temp Alarm`,
          data: genDatas("lowestTemp"),
          borderColor: chartColors.primary,
          pointBackgroundColor: chartColors.primary,
          fill: false,
          tension: 0.4,
          date: genDates(),
          borderWidth: 1,
        },
      ];
    } else {
      return [
        {
          label: `Evap Temp`,
          data: genDatas("evapCoil"),
          borderColor: chartColors.blue,
          pointBackgroundColor: chartColors.blue,
          fill: false,
          tension: 0.4,
          date: genDates(),
        },
      ];
    }
  };

  const genDatas = (viewType) => {
    return (data ?? []).map((e) => {
      return e?.data?.[viewType]?.[value_type] ?? NaN;
    });
  };

  const options = {
    hover: {
      intersect: false,
    },
    maintainAspectRatio: false,
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        pointRadius: 0,
      },
    },
    plugins: {
      // title: {
      //   display: true,
      //   text: `${dayjs(datetime).format(
      //     "DD/MM/YYYY"
      //   )} (Per ${datetime_type}) (${temp_type}) (${capitalizeFirstLetter(
      //     value_type
      //   )}) (Version ${process.env.REACT_APP_VERSION})`,
      // },
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            return (
              tooltipItem?.[0]?.dataset?.date?.[
                tooltipItem?.[0]?.dataIndex ?? 0
              ] ?? "-"
            );
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          precision: 1,
        },
        display: true,
      },
    },
  };

  return (
    <Container className="temperature">
      <Line data={datas} options={options} className="temperature__canvas" />
    </Container>
  );
};
export default Temperature;

const Container = styled.div`
  width: 100%;
  .temperature {
    &__canvas {
      height: 40vh;
      min-height: 20.833vw;
      @media ${device.desktopL} {
        min-height: 300px;
      }
    }
  }
`;
