import styled from "styled-components";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useWindowSize } from "react-use";

import { imgList } from "@constants/imgList";
import { device } from "@constants/styles";
import { ButtonBlock } from "@components/Buttons";
import { stateInterface } from "@reduxs/interface";

export default function Pause() {
  const info = useSelector((state: stateInterface) => state.user.info);
  const { height } = useWindowSize();
  const [counter, setCounter] = useState(30);
  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);
    if (counter <= 0) {
      clearInterval(interval);
      window.location.reload();
    }
    return () => clearInterval(interval);
  }, [counter]);
  return (
    <Container className="pause" height={height}>
      <div className="pause__avatar">
        <img
          src={info?.image?.url ?? "" ? info?.image?.url ?? "" : imgList.avatar}
          alt=""
        />
      </div>
      <div className="pause__name font-sukhumvitSet-text weight-500 size-16 color-white">
        {/* {info?.email?.split("@")?.[0] ?? "-"} */}
        {info?.user_fullname ?? "-"}
      </div>
      <div className="pause__line" />
      <ButtonBlock
        text={`Proceed to the monitoring system. (${
          counter <= 9 ? `0${counter}` : counter
        })`}
        className="pause__btn"
        // onClick={() => dispatch(pauseAction(false))}
        onClick={() => window.location.reload()}
      />
      <img src={imgList.logo2} alt="" className="pause__logo" />
    </Container>
  );
}

interface IStyle {
  height: number;
}

const Container = styled.div<IStyle>`
  width: 100%;
  background: #101828;
  position: fixed;
  width: 100vw;
  height: ${(props) => props.height}px;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .pause {
    &__avatar {
      position: relative;
      width: 6.944vw;
      height: 6.944vw;
      overflow: hidden;
      border-radius: 50%;
      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        object-fit: cover;
      }
      @media ${device.desktopL} {
        width: 100px;
        height: 100px;
      }
    }
    &__name {
      margin: 0.556vw 0 0;
      @media ${device.desktopL} {
        margin: 8px 0 0;
      }
    }
    &__line {
      width: 0.069vw;
      height: 1.667vw;
      margin: 1.111vw 0 1.528vw;
      border-right: 0.069vw solid #fff;
      @media ${device.desktopL} {
        width: 1px;
        height: 24px;
        margin: 16px 0 22px;
        border-right: 1px solid #fff;
      }
    }
    &__btn {
      width: 20.833vw;
      cursor: pointer;
      @media ${device.desktopL} {
        width: 300px;
      }
    }
    &__logo {
      position: absolute;
      width: 11.389vw;
      bottom: 1.25vw;
      left: 50%;
      transform: translate(-50%, 0);
      @media ${device.desktopL} {
        width: 164px;
        bottom: 18px;
      }
    }
  }
`;
