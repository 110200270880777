import { useState, useEffect } from "react";
import { Bar, Line } from "react-chartjs-2";
import styled from "styled-components";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { chartColors } from "@constants/colors";
import { capitalizeFirstLetter } from "@utils/string";

const DATETIME_TYPE = {
  hours: "Minutes",
  days: "Hours",
  months: "Days",
  years: "Months",
};

const DATETIME_LABEL_FORMAT = {
  hours: "HH:mm",
  days: "HH:mm",
  months: "DD/MM",
  years: "MM/YYYY",
};

const VIEW_TYPE = {
  kw: "kw",
  power: "power",
  voltage: "voltage",
  current: "current",
};

const VALUE_TYPE = {
  avg: "avg",
  min: "min",
  max: "max",
};

const DEFAULT_DATETIME_TYPE = "hour";
const DEFAULT_DATETIME_LABEL_FORMAT = "HH:mm";
const DEFAULT_VIEW_TYPE = "kw";
const DEFAULT_VALUE_TYPE = "avg";

export const CHART_COLORS = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
};

const Power = ({ data,type }) => {
  const filterDeviceDetail = useSelector(
    (state) => state.monitoring.filterDeviceDetail
  );
  const filterPower = useSelector((state) => state.monitoring.filterPower);
  const [datas, setDatas] = useState({ labels: [], datasets: [] });
  const datetime = filterDeviceDetail.date;
  const datetimeunit = filterDeviceDetail.unit;
  const value = filterPower.value;
  const view = type;

  const datetime_type = DATETIME_TYPE?.[datetimeunit] ?? DEFAULT_DATETIME_TYPE;
  const datetime_format =
    DATETIME_LABEL_FORMAT?.[datetimeunit] ?? DEFAULT_DATETIME_LABEL_FORMAT;
  const view_type = VIEW_TYPE?.[view] ?? DEFAULT_VIEW_TYPE;
  const value_type = VALUE_TYPE?.[value] ?? DEFAULT_VALUE_TYPE;

  useEffect(() => {
    setDatas({
      labels: genLabels(),
      datasets: genDatasets(view_type),
    });
  }, [data]);

  const genLabels = () => {
    return (data ?? []).map((e) => {
      return e?.start ?? ""
        ? dayjs(e?.start ?? "").format(datetime_format)
        : "-";
    });
  };

  const genDates = (t) => {
    return (data ?? []).map((e) => {
      return e?.start ?? ""
        ? dayjs(e?.start ?? "").format("DD/MM/YYYY HH:mm")
        : "-";
    });
  };

  const genDatasets = (view_type) => {
    if (view_type === "kw") {
      return [
        {
          label: `KWH`,
          data: genDatas("kw"),
          borderColor: chartColors.danger,
          pointBackgroundColor: chartColors.danger,
          fill: {
            target: "origin",
            above: chartColors.dangerLow,
          },
          cubicInterpolationMode: "monotone",
          tension: 0.4,
          date: genDates(),
          borderWidth: 2,
        },
      ];
    } else if (view_type === "voltage") {
      return [
        {
          label: `Voltage`,
          data: genDatas("voltage"),
          borderColor: chartColors.danger,
          pointBackgroundColor: chartColors.danger,
          fill: false,
          tension: 0.4,
          date: genDates(),
        },
      ];
    } else if (view_type === "power") {
      return [
        {
          label: `Watt`,
          data: genDatas("power"),
          borderColor: chartColors.danger,
          pointBackgroundColor: chartColors.danger,
          radius: 0,
          date: genDates(),
        },
      ];
    } else {
      return [
        {
          label: `Amp`,
          data: genDatas("current"),
          borderColor: chartColors.danger,
          pointBackgroundColor: chartColors.danger,
          radius: 0,
          date: genDates(),
        },
      ];
    }
  };

  const genDatas = (viewType) => {
    return (data ?? []).map((e) => {
      return e?.data?.[viewType]?.[value_type] ?? NaN;
    });
  };

  const totalDuration = 5000;
  const delayBetweenPoints = totalDuration / data.length;
  const previousY = (ctx) =>
    ctx.index === 0
      ? ctx.chart.scales.y.getPixelForValue(100)
      : ctx.chart
          .getDatasetMeta(ctx.datasetIndex)
          .data[ctx.index - 1].getProps(["y"], true).y;
  const animation = {
    x: {
      type: "number",
      easing: "linear",
      duration: delayBetweenPoints,
      from: NaN, // the point is initially skipped
      delay(ctx) {
        if (ctx.type !== "data" || ctx.xStarted) {
          return 0;
        }
        ctx.xStarted = true;
        return ctx.index * delayBetweenPoints;
      },
    },
    y: {
      type: "number",
      easing: "linear",
      duration: delayBetweenPoints,
      from: previousY,
      delay(ctx) {
        if (ctx.type !== "data" || ctx.yStarted) {
          return 0;
        }
        ctx.yStarted = true;
        return ctx.index * delayBetweenPoints;
      },
    },
  };

  const options = {
    maintainAspectRatio: false,
  };

  const optionsKw = {
    hover: {
      intersect: false,
    },
    maintainAspectRatio: false,
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        pointRadius: 0,
      },
    },
    plugins: {
      // title: {
      //   display: true,
      //   text: `${dayjs(datetime).format(
      //     "DD/MM/YYYY"
      //   )} (Per ${datetime_type}) (${capitalizeFirstLetter(
      //     value_type
      //   )}) (Version ${process.env.REACT_APP_VERSION})`,
      // },
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            return (
              tooltipItem?.[0]?.dataset?.date?.[
                tooltipItem?.[0]?.dataIndex ?? 0
              ] ?? "-"
            );
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          precision: 1,
        },
        afterDataLimits(scale) {
          scale.max += 0.1;
        },
        display: true,
      },
    },
  };

  const optionsVolt = {
    ...options,
    animation,
    interaction: {
      intersect: false,
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        pointRadius: 0,
      },
    },
    plugins: {
      // title: {
      //   display: true,
      //   text: `${dayjs(datetime).format(
      //     "DD/MM/YYYY"
      //   )} (Per ${datetime_type}) (${capitalizeFirstLetter(
      //     value_type
      //   )}) (Version ${process.env.REACT_APP_VERSION})`,
      // },
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            return (
              tooltipItem?.[0]?.dataset?.date?.[
                tooltipItem?.[0]?.dataIndex ?? 0
              ] ?? "-"
            );
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          precision: 1,
        },
        afterDataLimits(scale) {
          scale.max += 1;
        },
        display: true,
      },
    },
  };

  const optionsWatt = {
    ...options,
    animation,
    interaction: {
      intersect: false,
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        pointRadius: 0,
      },
    },
    plugins: {
      // title: {
      //   display: true,
      //   text: `${dayjs(datetime).format(
      //     "DD/MM/YYYY"
      //   )} (Per ${datetime_type}) (${capitalizeFirstLetter(
      //     value_type
      //   )}) (Version ${process.env.REACT_APP_VERSION})`,
      // },
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            return (
              tooltipItem?.[0]?.dataset?.date?.[
                tooltipItem?.[0]?.dataIndex ?? 0
              ] ?? "-"
            );
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          precision: 1,
        },
        afterDataLimits(scale) {
          scale.max += 50;
        },
        display: true,
      },
    },
  };

  const optionsCurrent = {
    ...options,
    animation,
    interaction: {
      intersect: false,
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        pointRadius: 0,
      },
    },
    plugins: {
      // title: {
      //   display: true,
      //   text: `${dayjs(datetime).format(
      //     "DD/MM/YYYY"
      //   )} (Per ${datetime_type}) (${capitalizeFirstLetter(
      //     value_type
      //   )}) (Version ${process.env.REACT_APP_VERSION})`,
      // },
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            return (
              tooltipItem?.[0]?.dataset?.date?.[
                tooltipItem?.[0]?.dataIndex ?? 0
              ] ?? "-"
            );
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          usePointStyle: true,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          precision: 1,
        },
        display: true,
      },
    },
  };

  return (
    <Container className="power">
      {view_type === "kw" && (
        <Line data={datas} options={optionsKw} className="power__canvas" />
      )}
      {view_type === "voltage" && (
        <Line data={datas} options={optionsVolt} className="power__canvas" />
      )}
      {view_type === "power" && (
        <Line data={datas} options={optionsWatt} className="power__canvas" />
      )}
      {view_type === "current" && (
        <Line data={datas} options={optionsCurrent} className="power__canvas" />
      )}
    </Container>
  );
};
export default Power;

const Container = styled.div`
  width: 100%;
  .power {
    &__canvas {
      height: 40vh;
      min-height: 300px;
    }
  }
`;
