import dayjs, { Dayjs } from "dayjs";

export const dateConvert = (e: any) => {
  if (e === "" || e === null) {
    return "-";
  } else {
    return dayjs(e).format("DD/MM/YYYY HH:mm");
  }
};
export const dateConvertDiff = (e: any) => {
  if (e === "" || e === null) {
    return "-";
  } else {
    const date1 = dayjs();
    const diff = date1.diff(e, "minute");
    if (diff <= 1) {
      return "Now";
    } else {
      return dateConvert(e);
    }
  }
};
export const getTimeArray = (date: any, unit = "", interval = 1) => {
  let array = [];
  let s = "";
  let e = "";
  if (unit === "hours") {
    s = dayjs(date).startOf("hour").format();
    e = dayjs(date).endOf("hour").format();
  } else if (unit === "days") {
    s = dayjs(date).startOf("day").format();
    e = dayjs(date).endOf("day").format();
  } else if (unit === "weeks") {
    s = dayjs(date).subtract(1, "week").startOf("day").format();
    e = dayjs(date).endOf("day").format();
  } else if (unit === "months") {
    s = dayjs(date).subtract(1, "month").startOf("day").format();
    e = dayjs(date).endOf("day").format();
  }
  for (var m = dayjs(s); m.isBefore(e); m = m.add(interval, "minute")) {
    array.push(m.format("YYYY-MM-DD HH:mm:ss"));
  }
  return array;
};
