import styled from "styled-components";

import BoxDevice from "@components/BoxDevice";
import { device } from "@constants/styles";

export default function Tooltip({
  show = false,
  name = "",
  addressText = "",
  showFull = false,
  data = {},
  onMute = () => {},
}: any) {
  return showFull ? (
    <ContainerFull show={show}>
      <BoxDevice
        selected={true}
        data={data}
        onMute={onMute}
        locationID={data?.locationID ?? ""}
      />
    </ContainerFull>
  ) : (
    <Container show={show}>
      <div className="size-12 font-sukhumvitSet-text color-white weight-700">
        {name}
      </div>
      <div className="size-12 font-sukhumvitSet-text color-white weight-500">
        {addressText}
      </div>
    </Container>
  );
}
interface IStyle {
  show: boolean;
}
const ContainerFull = styled.span<IStyle>`
  position: absolute;
  width: 20.556vw;
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  top: 50%;
  right: 105%;
  transform: translate(0, -50%);
  z-index: 99;
  &::after {
    content: " ";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -0.347vw;
    border-width: 0.417vw;
    border-style: solid;
    border-color: transparent transparent transparent #101828;
  }
  @media ${device.desktopL} {
    width: 296px;
    &::after {
      content: " ";
      position: absolute;
      top: 50%;
      left: 99%;
      margin-top: -5px;
      border-width: 6px;
      border-style: solid;
      border-color: transparent transparent transparent #101828;
    }
  }
  @media ${device.mobileLL} {
    top: -320px;
    left: 50%;
    right: unset;
    transform: translate(-50%, 0);
    &::after {
      content: " ";
      position: absolute;
      top: unset;
      bottom: -10px;
      left: 48%;
      margin-top: -5px;
      border-width: 6px;
      border-style: solid;
      border-color: #101828 transparent transparent transparent;
      // display: none;
    }
  }
`;
const Container = styled.span<IStyle>`
  visibility: ${(props) => (props.show ? "visible" : "hidden")};
  background-color: #101828;
  color: #fff;
  text-align: center;
  width: 13.889vw;
  border-radius: 0.556vw;
  padding: 0.556vw 0.833vw;
  position: absolute;
  z-index: 1;
  bottom: 98%;
  left: 50%;
  transform: translate(-50%, 0);
  &::after {
    content: "";
    position: absolute;
    top: 99%;
    left: 50%;
    margin-left: -0.347vw;
    border-width: 0.347vw;
    border-style: solid;
    border-color: #101828 transparent transparent transparent;
  }
  @media ${device.desktopL} {
    width: 200px;
    border-radius: 8px;
    padding: 8px 12px;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #101828 transparent transparent transparent;
    }
  }
`;
