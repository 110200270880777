import axios from "axios";
import Cookies from "js-cookie";

const axiosBlacklistBearer = [
  `${process.env.REACT_APP_API_DOMAIN}/authentications/accesstoken`,
];

axios.interceptors.request.use(
  (config: any) => {
    const url = config.url ?? "";
    const jwt_token = Cookies?.get("jwt_token_control") ?? "";
    if (axiosBlacklistBearer.some((v) => !url.includes(v))) {
      const token = jwt_token ?? "";
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const apiGet = async (url: string, headers: any, language: string) => {
  try {
    const res = await axios.get(url, {
      headers: { "Accept-Language": language, ...headers },
    });
    return {
      status: true,
      code: res?.status ?? 200,
      data: res?.data ?? {},
    };
  } catch (error: any) {
    console.log("error", error);
    return {
      status: false,
      code: error?.response?.status ?? 500,
      data: error?.response?.data ?? {},
    };
  }
};

export const apiPost = async (
  url: string,
  params: any,
  headers: any,
  language: string
) => {
  try {
    const res = await axios.post(url, params, {
      headers: { "Accept-Language": language, ...headers },
    });
    return {
      status: true,
      code: res?.status ?? 200,
      data: res?.data ?? {},
    };
  } catch (error: any) {
    console.log("error", error);
    return {
      status: false,
      code: error?.response?.status ?? 500,
      data: error?.response?.data ?? {},
      error: error,
    };
  }
};

export const apiPut = async (
  url: string,
  params: any,
  headers: any,
  language: string
) => {
  try {
    const res = await axios.put(url, params, {
      headers: { "Accept-Language": language, ...headers },
    });
    return {
      status: true,
      code: res?.status ?? 200,
      data: res?.data ?? {},
    };
  } catch (error: any) {
    console.log("error", error);
    return {
      status: false,
      code: error?.response?.status ?? 500,
      data: error?.response?.data ?? {},
    };
  }
};

export const apiDelete = async (url: string, language: string) => {
  try {
    const res = await axios.delete(url, {
      headers: {
        "Accept-Language": language,
      },
    });
    return {
      status: true,
      code: res?.status ?? 200,
      data: res?.data ?? {},
    };
  } catch (error: any) {
    console.log("error", error);
    return {
      status: false,
      code: error?.response?.status ?? 500,
      data: error?.response?.data ?? {},
    };
  }
};
