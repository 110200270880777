import { monitoringInterface } from "../interface";

const initState = {
  updateDate: null,
  count: {
    alarm: 0,
    normal: 0,
    offline: 0,
    powerOff: 0,
    totalDevices: 0,
  },
  notification: [],
  monitoring: [],
  monitoringFilter: [],
  filter: {
    name: "",
    status: [],
    location: [],
    area: [],
    view: "list",
    sort: "char_asc",
  },
  listStatus: [
    {
      filterStatus: "all",
      filterStatusDisplayText: "All",
    },
  ],
  listLocation: [
    {
      name: "all",
      id: "all",
    },
  ],
  mapLocationDevices: [],
  collapsed: false,
  pause: false,
  deviceDetail: {},
  filterDeviceDetail: {
    unit: "hours",
    days: "",
    months: "",
    years: "",
    period: 0,
    date: "",
  },
  filterTemp: {
    unit: "c",
    view: "inside",
    value: "avg",
  },
  filterOpe: {
    recordType: "record",
  },
  filterIot: {
    value: "avg",
  },
  filterPower: {
    unit: "kw",
    value: "avg",
  },
  pagination: {
    page: 1,
    pageSize: 10,
    total: 0,
    pageSizeOptions: [10, 20, 50, 100],
  },
  dataDevices: [],
  isSoundPlaying: false,
  isPlay: false,
  permissionNoti: undefined,
};

const monitoring = (
  state = initState,
  action: { type: string; data: monitoringInterface }
) => {
  switch (action.type) {
    case "update_date":
      return { ...state, updateDate: action.data };
    case "count":
      return { ...state, count: action.data };
    case "notification":
      return { ...state, notification: action.data };
    case "monitoring":
      return { ...state, monitoring: action.data };
    case "monitoring_filter":
      return { ...state, monitoringFilter: action.data };
    case "filter":
      return { ...state, filter: action.data };
    case "list_status":
      return { ...state, listStatus: action.data };
    case "list_location":
      return { ...state, listLocation: action.data };
    case "map_location_device":
      return { ...state, mapLocationDevices: action.data };
    case "collapsed":
      return { ...state, collapsed: action.data };
    case "pause":
      return { ...state, pause: action.data };
    case "device_detail":
      return { ...state, deviceDetail: action.data };
    case "filter_device_detail":
      return { ...state, filterDeviceDetail: action.data };
    case "filter_temp":
      return { ...state, filterTemp: action.data };
    case "filter_ope":
      return { ...state, filterOpe: action.data };
    case "filter_iot":
      return { ...state, filterIot: action.data };
    case "filter_power":
      return { ...state, filterPower: action.data };
    case "pagination":
      return { ...state, pagination: action.data };
    case "data_devices":
      return { ...state, dataDevices: action.data };
    case "is_sound_playing":
      return { ...state, isSoundPlaying: action.data };
    case "is_play":
      return { ...state, isPlay: action.data };
    case "permission_noti":
      return { ...state, permissionNoti: action.data };
    default:
      return state;
  }
};

export default monitoring;
