import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import _ from "lodash";

import { imgList } from "@constants/imgList";
import { device } from "@constants/styles";
import { colors } from "@constants/colors";
import { SelectSimple } from "@components/Selects";
import DatePicker from "@components/DatePicker/DatePickerCustom";
import Excel from "@components/Excel";
import Table from "@components/Table";
import { ModalDeviceDetail, ModalZoomImage } from "@components/Modals";
import { displayImage } from "@utils/image";
import { getTimeArray } from "@utils/date";
import { ModMonitoringIot, ModMonitoringRssi } from "@utils/monitoring";
import { apiGet, apiPut } from "@apis/gateway";
import { loadingAction } from "@reduxs/Actions/storeAction";
import {
  deviceDetailAction,
  filterDeviceDetailAction,
  mapLocationDevicesAction,
} from "@reduxs/Actions/monitoringAction";
import { stateInterface } from "@reduxs/interface";

export default function IOTDetail() {
  const navigate = useNavigate();
  const { locationID, id } = useParams();
  const dispatch = useDispatch();
  const language = useSelector(
    (state: stateInterface) => state.storage.language
  );
  const deviceDetail = useSelector(
    (state: stateInterface) => state.monitoring.deviceDetail
  );
  const filterDeviceDetail = useSelector(
    (state: stateInterface) => state.monitoring.filterDeviceDetail
  );
  const [init, setInit] = useState(false);
  const [plan, setPlan] = useState(1);
  const [dataTemp, setDataTemp] = useState<any>({});
  const [listUnit, setListUnit] = useState<{ value: string; label: string }[]>(
    []
  );
  const [listPeriod, setListPeriod] = useState<
    { value: string; label: string }[]
  >([]);
  const [modal, setModal] = useState({
    open: "",
  });
  const [zoomImage, setZoomImage] = useState("");
  const [dataReportDetail, setDataReportDetail] = useState<any>({});
  useEffect(() => {
    if (id) {
      initData();
    }
  }, [id]);
  const initData = async () => {
    dispatch(loadingAction(true));
    await getDeviceDetail();
    await getPlan();
    await getDeviceDetailReport();
    setInit(true);
    dispatch(loadingAction(false));
  };
  useEffect(() => {
    // renderListUnit();
    const d = new Date();
    dispatch(
      filterDeviceDetailAction({
        ...filterDeviceDetail,
        ...{
          unit: "hours",
          days: d.getDate().toString(),
          months: (d.getMonth() + 1).toString(),
          years: d.getFullYear().toString(),
          period: d.getHours(),
          date: dayjs(),
        },
      })
    );
  }, [plan]);
  useEffect(() => {
    renderListUnit();
    renderListPeriod();
    if (init) {
      getReport();
    }
  }, [filterDeviceDetail, init]);
  const getReport = async () => {
    dispatch(loadingAction(true));
    await getReportIot();
    dispatch(loadingAction(false));
  };
  const getDeviceDetail = async () => {
    // const res = await apiGet(
    //   `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device-report/detail/${locationID}/${id}`,
    //   {},
    //   language
    // );
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device-report/detail/${id}`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      dispatch(deviceDetailAction(res?.data?.data ?? {}));
      dispatch(
        mapLocationDevicesAction([
          {
            macAddress: res?.data?.data?.deviceInfo?.macAddress ?? "",
            index1: 0,
            index2: 0,
          },
        ])
      );
    } else {
      navigate("/404");
    }
  };
  const getDeviceDetailReport = async () => {
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device-report/device-detail/${id}`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      setDataReportDetail(res?.data?.data ?? {});
    }
  };
  const getPlan = async () => {
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device-report/plan/${id}`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      setPlan((res?.data?.data?.plan ?? "standard") === "standard" ? 1 : 2);
    }
  };
  const getReportIot = async () => {
    const res = await apiGet(
      `${
        process.env.REACT_APP_API_DOMAIN
      }/monitoring-api/device-report/${id}/iot-status?summarize=false&dateTime=${dayjs(
        filterDeviceDetail.date
      ).format("YYYY-MM-DD")} ${
        filterDeviceDetail.period.toString().length < 2
          ? `0${filterDeviceDetail.period}:00:00`
          : `${filterDeviceDetail.period}:00:00`
      }&dateTimeUnit=${filterDeviceDetail.unit}`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      let arrWifiResults: any[] = [];
      let arrRssiResults: any[] = [];
      const array = getTimeArray(
        `${dayjs(filterDeviceDetail.date).format("YYYY-MM-DD")} ${
          filterDeviceDetail.period.toString().length < 2
            ? `0${filterDeviceDetail.period}:00:00`
            : `${filterDeviceDetail.period}:00:00`
        }`,
        filterDeviceDetail.unit,
        1
      );
      array.forEach((e) => {
        arrWifiResults.push({
          time: e,
          ..._.uniqBy(res?.data?.data?.result ?? [], "time").filter(
            (e1: any) => e1.time === e
          )?.[0],
        });
        arrRssiResults.push({
          time: e,
          ..._.uniqBy(res?.data?.data?.result ?? [], "time").filter(
            (e1: any) => e1.time === e
          )?.[0],
        });
      });
      if (
        filterDeviceDetail.unit !== "weeks" &&
        filterDeviceDetail.unit !== "months"
      ) {
        setDataTemp({
          wifiResults: arrWifiResults,
          rssiResults: arrRssiResults,
        });
      } else if (filterDeviceDetail.unit === "months") {
        setDataTemp({
          wifiResults: ModMonitoringIot(arrWifiResults, 10),
          rssiResults: ModMonitoringRssi(arrRssiResults, 10),
        });
      } else {
        setDataTemp({
          wifiResults: ModMonitoringIot(arrWifiResults, 5),
          rssiResults: ModMonitoringRssi(arrRssiResults, 5),
        });
      }
    }
  };
  const renderSound = (macAddress = "", soundAlarm = false, alarm = false) => {
    if (alarm) {
      if (soundAlarm) {
        return (
          <img
            src={imgList.ico_sound}
            alt=""
            className="detail__sound detail__sound--active"
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              onMute(macAddress);
            }}
          />
        );
      } else {
        return (
          <img
            src={imgList.ico_sound_disable}
            alt=""
            className="detail__sound"
          />
        );
      }
    } else {
      return <></>;
    }
  };
  const onMute = async (macAddress = "") => {
    const params = {};
    dispatch(loadingAction(true));
    const res = await apiPut(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device/${macAddress}/mute-alarm`,
      { ...params },
      {},
      language
    );
    dispatch(loadingAction(false));
  };
  const renderListUnit = () => {
    if (plan === 1) {
      setListUnit([
        {
          value: "hours",
          label: "Hours",
        },
        {
          value: "days",
          label: "Days",
        },
        {
          value: "weeks",
          label: "Weeks",
        },
        {
          value: "months",
          label: "Months",
        },
      ]);
    } else if (plan === 2) {
      setListUnit([
        {
          value: "hours",
          label: "Hours",
        },
        {
          value: "days",
          label: "Days",
        },
        {
          value: "weeks",
          label: "Weeks",
        },
        {
          value: "months",
          label: "Months",
        },
        {
          value: "years",
          label: "Years",
        },
      ]);
    }
  };
  const renderListPeriod = () => {
    const d = new Date();
    let a: any = [];
    if (dayjs().isSame(filterDeviceDetail.date, "date")) {
      new Array(24).fill("").forEach((e, index) => {
        if (index <= d.getHours()) {
          a.push({
            value: index,
            label: `${
              index.toString().length < 2 ? `0${index}` : index.toString()
            }:00 - ${
              (index + 1).toString().length < 2
                ? `0${index + 1}`
                : (index + 1).toString()
            }:00`,
          });
        }
      });
    } else {
      new Array(24).fill("").forEach((e, index) => {
        a.push({
          value: index,
          label: `${
            index.toString().length < 2 ? `0${index}` : index.toString()
          }:00 - ${
            (index + 1).toString().length < 2
              ? `0${index + 1}`
              : (index + 1).toString()
          }:00`,
        });
      });
    }
    setListPeriod(a);
  };
  const columns = [
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      sorter: (a: any, b: any) => {
        return (
          dayjs(a.time, "DD/MM/YYYY HH:mm").valueOf() -
          dayjs(b.time, "DD/MM/YYYY HH:mm").valueOf()
        );
      },
    },
    {
      title: "Wi-Fi",
      dataIndex: "wifi",
      key: "wifi",
    },
    {
      title: "Device status",
      dataIndex: "cloudStatus",
      key: "cloudStatus",
    },
    {
      title: "RSSI (dBm)",
      dataIndex: "rssi",
      key: "rssi",
      sorter: (a: any, b: any) =>
        (a.rssi === "-" ? 0 : a.rssi) - (b.rssi === "-" ? 0 : b.rssi),
    },
  ];
  return (
    <Container>
      <div className="detail__head">
        <div className="detail__top">
          <img
            src={imgList.ico_back}
            alt=""
            // onClick={() => navigate(`/device-detail/${locationID}/${id}`)}
            onClick={() => navigate(`/device-detail/${id}`)}
          />
          <div className="font-inter size-30 color-blue828 weight-600">
            IOT Detail
          </div>
        </div>
        <div className="detail__breadclumb">
          <div
            className="font-sukhumvitSet-text size-14 color-black pointer"
            onClick={() => navigate(`/devices`)}
          >
            Device List
          </div>
          <div className="dot" />
          <div
            className="font-sukhumvitSet-text size-14 color-black pointer"
            // onClick={() => navigate(`/device-detail/${locationID}/${id}`)}
            onClick={() => navigate(`/device-detail/${id}`)}
          >
            {deviceDetail?.deviceInfo?.name ?? ""}
          </div>
          <div className="dot" />
          <div className="font-sukhumvitSet-text size-14 active">IOT</div>
        </div>
        <div className="detail__headline">
          <div className="detail__left">
            <div className="detail__cover">
              <img
                src={
                  deviceDetail?.deviceInfo?.image ?? ""
                    ? displayImage(deviceDetail?.deviceInfo?.image ?? "")
                    : imgList.ex
                }
                alt=""
              />
            </div>
            <div className="detail__left-detail">
              <div className="detail__row-head">
                <div className="detail__name size-16 weight-600 font-sukhumvitSet-text color-black">
                  {deviceDetail?.deviceInfo?.name ?? ""}
                </div>
                {renderSound(
                  deviceDetail?.controlInfo?.macAddress ?? "",
                  deviceDetail?.controlInfo?.soundAlarm ?? false,
                  deviceDetail?.controlInfo?.control?.alarm ?? false
                )}
              </div>
              <div className="detail__text detail__text--sp">
                <img src={imgList.ico_location_gray} alt="" />
                <div className="color-grayf4f size-12 font-sukhumvitSet-text location">
                  {deviceDetail?.name ?? ""}
                </div>
                {/* {(deviceDetail?.coordinates?.[0] ?? 0) !== 0 &&
                  (deviceDetail?.coordinates?.[1] ?? 0) !== 0 && (
                    <div
                      className="color-black size-12 font-sukhumvitSet-text current"
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/search/?api=1&query=${
                            deviceDetail?.coordinates?.[1] ?? 0
                          },${deviceDetail?.coordinates?.[0] ?? 0}`,
                          "_blank"
                        )
                      }
                    >
                      ดูตำแหน่ง
                    </div>
                  )} */}
              </div>
            </div>
          </div>
          {/* <div className="detail__right">
            <div className="size-14 weight-600 font-sukhumvitSet-text color-black">
              แสดงรายละเอียด
            </div>
            <img src={imgList.ico_back} alt="" className="arrow" />
          </div> */}
          <div className="detail__right">
            <div
              className="detail__btn"
              onClick={() => setModal({ open: "device_detail" })}
            >
              <div className="size-12 weight-600 font-sukhumvitSet-text color-black">
                ดูเพิ่มเติม
              </div>
              <img src={imgList.ico_arrow_yellow} alt="" />
            </div>
          </div>
        </div>
        <div className="detail__filter">
          <div className="color-black size-20 font-sukhumvitSet-bold detail__head-2">
            IOT Detail
          </div>
          <Excel
            fileName={`${id}-IOT-${dayjs().format("YYYYMMDDHHmmss")}`}
            data={(dataTemp?.wifiResults ?? []).map((e: any, index: number) => {
              const wifi =
                (e?.wifi ?? "-") === "-"
                  ? "-"
                  : e?.wifi
                  ? "Connected"
                  : "Disconnected";
              const cloudStatus =
                (e?.cloudStatus ?? "-") === "-"
                  ? "-"
                  : e?.cloudStatus
                  ? "Online"
                  : "Offline";
              const rssi = (dataTemp?.rssiResults ?? [])?.[index]?.rssi ?? "-";
              return {
                Time: dayjs(e?.time ?? "").format("DD/MM/YYYY HH:mm"),
                wifi: wifi,
                "Device Status": cloudStatus,
                rssi: rssi,
              };
            })}
            button={
              <img
                src={imgList.ico_download}
                alt=""
                className="detail__download"
              />
            }
          />
          <div className="detail__period">
            <SelectSimple
              id="unit"
              labelText="Unit"
              value={filterDeviceDetail.unit}
              options={listUnit}
              placeholder="แสดงทั้งหมด"
              classNameWarp=""
              onChange={(e) => {
                dispatch(
                  filterDeviceDetailAction({
                    ...filterDeviceDetail,
                    unit: e,
                  })
                );
              }}
            />
          </div>
          <div className="detail__period">
            <DatePick id="date-search">
              <TextDatePick>Period Date</TextDatePick>
              <DatePicker
                allowClear={false}
                value={filterDeviceDetail.date}
                picker={
                  filterDeviceDetail.unit === "hours" ||
                  filterDeviceDetail.unit === "days" ||
                  filterDeviceDetail.unit === "weeks"
                    ? "date"
                    : "month"
                }
                format={
                  filterDeviceDetail.unit === "hours" ||
                  filterDeviceDetail.unit === "days" ||
                  filterDeviceDetail.unit === "weeks"
                    ? "DD/MM/YYYY"
                    : "MM/YYYY"
                }
                disabledDate={(current) => {
                  return (
                    current > dayjs() ||
                    current < dayjs().subtract(plan === 1 ? 3 : 12, "month")
                  );
                }}
                getPopupContainer={() =>
                  document.getElementById("date-search") as HTMLElement
                }
                onChange={(e) => {
                  const d = new Date();
                  let period = filterDeviceDetail.period;
                  if (
                    dayjs().isSame(e, "date") &&
                    filterDeviceDetail.period > d.getHours()
                  ) {
                    period = d.getHours();
                  }
                  dispatch(
                    filterDeviceDetailAction({
                      ...filterDeviceDetail,
                      date: e,
                      period: period,
                    })
                  );
                }}
              />
            </DatePick>
          </div>
          <div className="detail__period">
            <SelectSimple
              id="period"
              labelText="Period Time"
              value={filterDeviceDetail.period}
              options={listPeriod}
              placeholder="แสดงทั้งหมด"
              classNameWarp=""
              disabled={filterDeviceDetail.unit === "hours" ? false : true}
              onChange={(e) => {
                dispatch(
                  filterDeviceDetailAction({
                    ...filterDeviceDetail,
                    period: e,
                  })
                );
              }}
            />
          </div>
        </div>
        <div className="detail__table">
          <Table
            pagination={{ showLessItems: true }}
            showSorterTooltip={false}
            columns={columns}
            dataSource={(dataTemp?.wifiResults ?? []).map(
              (e: any, index: number) => {
                const wifi =
                  (e?.wifi ?? "-") === "-"
                    ? "-"
                    : e?.wifi
                    ? "Connected"
                    : "Disconnected";
                const cloudStatus =
                  (e?.cloudStatus ?? "-") === "-"
                    ? "-"
                    : e?.cloudStatus
                    ? "Online"
                    : "Offline";
                const rssi =
                  (dataTemp?.rssiResults ?? [])?.[index]?.rssi ?? "-";
                return {
                  key: index,
                  time: dayjs(e?.time ?? "").format("DD/MM/YYYY HH:mm"),
                  wifi: wifi,
                  cloudStatus: cloudStatus,
                  rssi: rssi,
                };
              }
            )}
          />
        </div>
      </div>
      <ModalDeviceDetail
        open={modal.open === "device_detail"}
        data={dataReportDetail}
        name={deviceDetail?.deviceInfo?.name ?? "-"}
        onOk={() => {}}
        onClose={() =>
          setModal({
            ...modal,
            open: "",
          })
        }
        onZoomImage={(e) => setZoomImage(e)}
      />
      <ModalZoomImage
        open={zoomImage !== ""}
        image={zoomImage}
        onClose={() => setZoomImage("")}
      />
    </Container>
  );
}

const Container = styled.div`
  width: cal(100% + 4.444vw);
  min-height: 100vh;
  margin: -2.222vw;
  background: #ffffff;
  @media ${device.desktopL} {
    width: cal(100% + 64px);
    margin: -32px;
  }
  .detail {
    &__head {
      border-radius: 2.778vw 0px 0px 0;
      padding: 2.222vw 2.222vw 1.389vw;
      background: #ffffff;
      @media ${device.desktopL} {
        border-radius: 40px 0px 0px 0;
        padding: 32px 32px 20px;
      }
    }
    &__top {
      width: 100%;
      display: flex;
      align-items: center;
      > img {
        width: 2.083vw;
        height: 2.083vw;
        margin: 0 0.833vw 0 0;
        cursor: pointer;
      }
      @media ${device.desktopL} {
        > img {
          width: 30px;
          height: 30px;
          margin: 0 12px 0 0;
        }
      }
    }
    &__breadclumb {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 1.389vw 0 0;
      & .pointer {
        cursor: pointer;
      }
      & .dot {
        width: 0.278vw;
        height: 0.278vw;
        background: #919eab;
        border-radius: 50%;
        margin: 0 1.111vw;
      }
      & .acitve {
        color: #919eab;
      }
      @media ${device.desktopL} {
        margin: 20px 0 0;
        & .dot {
          width: 4px;
          height: 4px;
          margin: 0 16px;
        }
      }
    }
    &__headline {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 1.389vw 0 0;
      padding: 1.389vw;
      border-radius: 1.111vw;
      border: 0.069vw solid #dedede;
      background: #fff;
      box-shadow: 0px 0.278vw 0.278vw 0px rgba(205, 205, 205, 0.25);
      @media ${device.desktopL} {
        margin: 20px 0 0;
        padding: 20px;
        border-radius: 16px;
        border: 1px solid #dedede;
        box-shadow: 0px 4px 12px 0px rgba(205, 205, 205, 0.25);
      }
      @media ${device.mobileLL} {
        flex-wrap: wrap;
      }
    }
    &__cover {
      position: relative;
      width: 4.097vw;
      padding-top: 4.097vw;
      border-radius: 0.556vw;
      margin: 0 1.389vw 0 0;
      overflow: hidden;
      border-radius: 0.556vw;
      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        object-fit: cover;
      }
      @media ${device.desktopL} {
        width: 59px;
        padding-top: 59px;
        border-radius: 8px;
        margin: 0 20px 0 0;
        border-radius: 8px;
      }
    }
    &__row-head {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }
    &__name {
      max-width: calc(100% - 1.736vw - 0.972vw);
      @media ${device.desktopL} {
        max-width: calc(100% - 25px - 14px);
      }
    }
    &__sound {
      width: 1.736vw;
      display: block;
      margin: 0 0 0 0.972vw;
      &--active {
        cursor: pointer;
      }
      @media ${device.desktopL} {
        width: 25px;
        margin: 0 0 0 14px;
      }
    }
    &__left {
      width: calc(100% - 9.028vw);
      display: flex;
      align-items: flex-start;
      @media ${device.desktopL} {
        width: calc(100% - 130px);
      }
      @media ${device.mobileLL} {
        width: 100%;
      }
    }
    &__left-detail {
      width: calc(100% - 4.097vw- 1.389vw);
      @media ${device.desktopL} {
        width: calc(100% - 59px - 20px);
      }
    }
    &__right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 8.472vw;
      & .arrow {
        width: 1.389vw;
        height: 1.389vw;
        transform: rotate(270deg);
        margin: 0 0 0 0.139vw;
        cursor: pointer;
      }
      @media ${device.desktopL} {
        width: 122px;
        & .arrow {
          width: 20px;
          height: 20px;
          margin: 0 0 0 2px;
        }
      }
      @media ${device.mobileLL} {
        width: 100%;
      }
    }
    &__text {
      width: 48%;
      display: flex;
      align-items: flex-start;
      margin: 10px 0 0;
      > img {
        width: 1.111vw;
        margin: 0 0.417vw 0 0;
      }
      & .location {
      }
      &--sp {
        width: 100%;
      }
      & .current {
        margin: 0 0 0 0.417vw;
        cursor: pointer;
      }
      @media ${device.desktopL} {
        > img {
          width: 16px;
          margin: 0 6px 0 0;
        }
        & .location {
        }
        & .current {
          text-decoration: underline;
          margin: 0 0 0 6px;
          cursor: pointer;
        }
      }
    }
    &__filter {
      width: 100%;
      margin: 1.389vw 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media ${device.desktopL} {
        margin: 20px 0 0;
      }
      @media ${device.mobileLL} {
        flex-wrap: wrap;
      }
    }
    &__period {
      width: calc((93% - 12.778vw) / 3);
      margin: 0.694vw 0 0;
      @media ${device.desktopL} {
        width: calc((93% - 184px) / 3);
        margin: 10px 0 0;
      }
      @media ${device.mobileLL} {
        width: 100%;
        margin: 16px 0 0;
      }
    }
    &__download {
      width: 3.472vw;
      margin: 0.694vw 0 0;
      cursor: pointer;
      @media ${device.desktopL} {
        width: 50px;
        margin: 10px 0 0;
      }
    }
    &__head-2 {
      margin: 0.694vw 0 0;
      @media ${device.desktopL} {
        margin: 10px 0 0;
      }
    }
    &__table {
      margin: 1.389vw 0 0;
      & .ant-table-content {
        overflow: auto;
      }
      @media ${device.desktopL} {
        margin: 20px 0 0;
      }
    }
    &__btn {
      display: inline-flex;
      align-items: center;
      background: #ffffff;
      border: 0.139vw solid #ffe6a4;
      box-shadow: 0px 0.278vw 0.972vw rgba(165, 165, 165, 0.08);
      border-radius: 3.472vw;
      padding: 0.278vw 0.694vw;
      margin: 0.833vw 0px 0px;
      cursor: pointer;
      > div {
        line-height: 1.3;
        margin: 1px 0 0;
      }
      > img {
        width: 1.389vw;
        display: block;
        margin: 0 0 0 0.417vw;
      }
      @media ${device.desktopL} {
        border: 2px solid #ffe6a4;
        box-shadow: 0px 4px 14px rgba(165, 165, 165, 0.08);
        border-radius: 50px;
        padding: 4px 10px;
        margin: 12px 0px 0px;
        > div {
          margin: 1px 0 0;
        }
        > img {
          width: 20px;
          margin: 0 0 0 6px;
        }
      }
      @media ${device.mobileLL} {
        width: 100%;
        justify-content: center;
      }
    }
  }
`;

const TextDatePick = styled.div`
  position: absolute;
  z-index: 1;
  color: #919eab;
  font-family: "SukhumvitSet-Text";
  font-weight: 400;
  font-size: 0.833vw;
  left: 0.972vw;
  top: -0.556vw;
  background: #fff;
  display: inline-block;
  padding: 0 0.278vw;
  @media ${device.desktopL} {
    padding: 0 4px;
    font-size: 12px;
    left: 14px;
    top: -8px;
  }
`;

const DatePick = styled.div`
  position: relative;
  & .ant-picker {
    width: 100%;
    border-radius: 0.556vw;
    padding: 1.007vw 1.111vw;
    font-size: 1.389vw;
    border: 0.069vw solid rgba(145, 158, 171, 0.32);
    &:hover {
      border-color: rgb(255, 187, 84);
    }
    @media ${device.desktopL} {
      border-radius: 8px;
      padding: 14.5px 16px;
      font-size: 20px;
      border: 1px solid rgba(145, 158, 171, 0.32);
    }
  }
  & .ant-picker-input > input {
    font-size: 0.972vw;
    font-family: SukhumvitSet-Text, sans-serif;
    color: ${colors.gray333};
    @media ${device.desktopL} {
      font-size: 14px;
    }
  }
  & .ant-picker-focused {
    box-shadow: none;
    border-color: rgb(255, 187, 84);
  }
  & .ant-picker-range .ant-picker-active-bar {
    background: rgb(255, 187, 84);
  }
  &
    .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner:before {
    border: 1px solid rgb(255, 187, 84);
  }
  & .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: rgb(255, 187, 84);
  }
  & .ant-picker-cell-in-view.ant-picker-cell-in-range:before {
    background: rgba(255, 187, 84, 0.2);
  }
  &
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    ):before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    ):before {
    background: rgba(255, 187, 84, 0.2);
  }
`;
