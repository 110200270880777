import styled from "styled-components";
import { Select as SelectAntd, SelectProps } from "antd";

import { colors } from "@constants/colors";
import { TextSimple } from "@components/Texts";
import { imgList } from "@constants/imgList";
import { device } from "@constants/styles";

interface ISelectSimple {
  id: string;
  classNameWarp?: string;
  labelText?: string;
}

export default function SelectSimple({
  classNameWarp = "",
  labelText = "",
  id = "",
  ...rest
}: ISelectSimple & SelectProps) {
  return (
    <Selects className={classNameWarp} id={`SelectSimple-${id}`}>
      {labelText && <Text disabled={rest?.disabled ?? false}>{labelText}</Text>}
      <SelectAntd
        suffixIcon={<img src={imgList.arrowDropdown} alt="" />}
        placeholder="โปรดระบุ"
        getPopupContainer={() =>
          document.getElementById(`SelectSimple-${id}`) as HTMLElement
        }
        {...rest}
      />
      {rest?.mode === "multiple" && (
        <Arrow>
          <img src={imgList.arrowDropdown} alt="" />
        </Arrow>
      )}
    </Selects>
  );
}

interface IStyle {
  disabled: boolean;
}
const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 0.764vw;
  > img {
    width: 1.667vw;
  }
  @media ${device.desktopL} {
    right: 11px;
    > img {
      width: 24px;
    }
  }
`;
const Text = styled.div<IStyle>`
  position: absolute;
  z-index: 1;
  color: #919eab;
  font-family: "SukhumvitSet-Text";
  font-weight: 400;
  font-size: 0.833vw;
  left: 0.972vw;
  top: -0.556vw;
  background: ${(props) =>
    props.disabled
      ? "linear-gradient(180deg, rgba(245,245,245,0.06066176470588236) 40%, rgba(245,245,245,1) 40%)"
      : "#fff"};
  display: inline-block;
  padding: 0 0.278vw;
  @media ${device.desktopL} {
    padding: 0 4px;
    font-size: 12px;
    left: 14px;
    top: -8px;
  }
`;

const Selects = styled.div`
  width: 100%;
  position: relative;
  & .ant-select-dropdown {
    min-width: 15.486vw !important;
    @media ${device.desktopL} {
      min-width: 223px !important;
    }
  }
  & .rc-virtual-list-holder {
    max-height: 17.778vw !important;
    @media ${device.desktopL} {
      max-height: 256px !important;
    }
  }
  & .ant-select-item {
    padding: 0.694vw 0.972vw;
    min-height: 2.222vw;
    line-height: 1.528vw;
    @media ${device.desktopL} {
      padding: 10px 14px;
      min-height: 32px;
      line-height: 22px;
    }
  }
  & .ant-select {
    position: relative;
    width: 100%;
  }
  & .ant-select-arrow {
    height: 1.667vw;
    font-size: 1.667vw;
    transform: translate(0, -50%);
    margin-top: 0;
    @media ${device.desktopL} {
      height: 24px;
      font-size: 24px;
    }
  }
  &
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
    border: 0.069vw solid ${colors.primary2} !important;
    @media ${device.desktopL} {
      border: 1px solid ${colors.primary2} !important;
    }
  }
  & .ant-select-disabled {
    & .ant-select-selection-item {
      color: rgba(0, 0, 0, 0.25);
    }
  }
  > * {
    > .ant-select-selector {
      width: initial !important;
      height: 3.889vw !important;
      align-items: center;
      border: 0.069vw solid rgba(145, 158, 171, 0.32) !important;
      border-radius: 0.556vw !important;
      font-family: SukhumvitSet-Text !important;
      @media ${device.desktopL} {
        height: 56px !important;
        border: 1px solid rgba(145, 158, 171, 0.32) !important;
        border-radius: 8px !important;
      }
    }
  }
  & .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 0.972vw;
    @media ${device.desktopL} {
      padding: 0 14px;
    }
  }
  & .ant-select-selection-item {
    font-family: "SukhumvitSet-Text", sans-serif;
    font-size: 0.972vw;
    font-weight: 400;
    color: #000;
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    height: 1.667vw;
    margin-top: 0.139vw;
    margin-bottom: 0.139vw;
    line-height: 1.528vw;
    margin-inline-end: 0.278vw;
    padding-inline-start: 0.833vw;
    padding-inline-end: 0.278vw;

    @media ${device.desktopL} {
      font-size: 14px;
      height: 24px;
      margin-top: 2px;
      margin-bottom: 2px;
      line-height: 22px;
      margin-inline-end: 4px;
      padding-inline-start: 12px;
      padding-inline-end: 4px;
    }
  }
  & .ant-select-selection-placeholder {
    font-family: "SukhumvitSet-Text", sans-serif;
    font-size: 0.972vw;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.25);
    @media ${device.desktopL} {
      font-size: 14px;
    }
  }
  & .ant-select-item-option-content {
    font-family: "SukhumvitSet-Text", sans-serif;
    font-size: 0.972vw;
    font-weight: 400;
    color: #000;
    @media ${device.desktopL} {
      font-size: 14px;
    }
  }
`;
