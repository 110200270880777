import styled from "styled-components";

import Coming from "@components/Coming";

export default function Dashboard() {
  return (
    <Container>
      <Coming />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;
