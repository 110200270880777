import { Modal, ModalProps } from "antd";
import styled from "styled-components";

import { device } from "@constants/styles";
import { colors } from "@constants/colors";
import { ButtonBlock } from "@components/Buttons";
import { imgList } from "@constants/imgList";

type Props = {
  open: boolean;
  onOk: () => void;
  onClose: () => void;
};

const ModalRegister = ({
  open = false,
  onOk = () => {},
  onClose = () => {},
  ...props
}: Props & ModalProps) => {
  return (
    <Content id="model-register" className="modal">
      <Modal
        title=""
        open={open}
        closable={false}
        footer={null}
        centered={true}
        getContainer={() =>
          document.getElementById("model-register") as HTMLElement
        }
        {...props}
      >
        <img src={imgList.register} alt="" className="modal__bg" />
        <img src={imgList.register_logo} alt="" className="modal__logo" />
        <div className="modal__content">
          <div className="modal__head font-sukhumvitSet-bold size-18">
            สมัครใช้งานได้ผ่านแอปฯ CORNTROL
          </div>
          <div className="modal__sub-head font-sukhumvitSet-text size-16">
            เข้าสู่ระบบด้วยบัญชีเดียวกันกับแอปพลิเคชัน
          </div>
          <ButtonBlock
            text="เปิด Corntrol App"
            className="modal__btn"
            onClick={() => {
              window.open(process.env.REACT_APP_DOWNLOAD, "_blank");
              onClose();
            }}
          />
          <div
            className="modal__cancel font-sukhumvitSet-bold size-18"
            onClick={onClose}
          >
            ไว้ก่อน
          </div>
        </div>
      </Modal>
    </Content>
  );
};

export default ModalRegister;

const Content = styled.div`
  width: 100%;
  max-width: 90% !important;
  & .ant-modal-content {
    border-radius: 0.833vw;
    overflow: hidden;
    @media ${device.desktopL} {
      border-radius: 12px;
    }
  }
  & .ant-modal {
    width: 25vw !important;
    @media ${device.desktopL} {
      width: 445px !important;
    }
    @media ${device.tablet} {
      max-width: calc(100vw - 36px) !important;
    }
  }
  & .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  & .ant-modal-body {
    padding: 0;
  }
  .modal {
    &__content {
      text-align: center;
      padding: 18px;
    }
    &__bg {
      width: 100%;
    }
    &__logo {
      width: 28%;
      margin: -13% auto 0;
    }
    &__head {
      margin: -6% 0 12px;
      color: #3d4245;
    }
    &__sub-head {
      color: #656c6f;
    }
    &__btn {
      width: 100%;
      margin: 24px 0 12px;
      font-family: "SukhumvitSet-Bold", sans-serif;
      font-weight: 500;
      font-size: 18px;
    }
    &__cancel {
      color: #656c6f;
      cursor: pointer;
    }
  }
`;
