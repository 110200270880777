import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

const powerFeePerUnit = 4;
export const computePowerUnit = (previousData, currentData) => {
  let powerUsage = 0;
  let diffTime = 0;
  if (previousData !== undefined) {
    const currentTime = moment(currentData["time"]);
    const previousTime = moment(previousData["time"]);
    diffTime = moment.duration(currentTime.diff(previousTime)).asHours();
  }
  if (currentData["power"] !== undefined) {
    powerUsage = currentData["power"];
  }
  return computeKWUnitUse(powerUsage, diffTime);
};
export const computeKWUnitUse = (powerUsage, hoursDuration) => {
  return (powerUsage * hoursDuration) / 1000;
};
export const computePowerCost = (kwh) => {
  return kwh * powerFeePerUnit;
};
export const computeSumOperationRecordUsed = (
  currentDeviceReport,
  previousDeviceReport,
  dateTimeUnit
) => {
  let compressorPeriodUseInHour = 0;
  let defrostPeriodUseInHour = 0;
  let fanPeriodUseInHour = 0;
  let activePeriodUseInHour = 0;

  compressorPeriodUseInHour = computePeriodOfUse(
    previousDeviceReport,
    currentDeviceReport,
    "comp",
    dateTimeUnit
  );
  defrostPeriodUseInHour = computePeriodOfUse(
    previousDeviceReport,
    currentDeviceReport,
    "defrost",
    dateTimeUnit
  );
  fanPeriodUseInHour = computePeriodOfUse(
    previousDeviceReport,
    currentDeviceReport,
    "fan",
    dateTimeUnit
  );
  activePeriodUseInHour = computePeriodOfUse(
    previousDeviceReport,
    currentDeviceReport,
    "active",
    dateTimeUnit
  );

  return {
    compressorPeriodUseInHourResult: compressorPeriodUseInHour,
    defrostPeriodUseInHourResult: defrostPeriodUseInHour,
    fanPeriodUseInHourResult: fanPeriodUseInHour,
    activePeriodUseInHourResult: activePeriodUseInHour,
  };
};
export const computePeriodOfUse = (
  previousData,
  currentData,
  fieldKey,
  unitTime
) => {
  if (previousData !== undefined) {
    const currentTime = moment(currentData["time"]);
    const previousTime = moment(previousData["time"]);
    const diffTime = moment
      .duration(currentTime.diff(previousTime))
      .asMilliseconds();
    if (currentData[fieldKey] !== false) {
      return diffTime;
    }
  }
  return 0;
};
export const formatDuration = (milliseconds) => {
  return milliseconds > 0
    ? moment.duration(milliseconds).format("h:mm [hrs]", { trim: false })
    : "0:00 hrs";
};
export const computeSumOperationEventUsed = (
  currentDeviceReport,
  previousDeviceReport
) => {
  let doorOpenPeriodInHour = 0;
  let doorOpenTime = 0;

  doorOpenPeriodInHour = computePeriodDoorOpen(
    previousDeviceReport,
    currentDeviceReport
  );
  doorOpenTime = computeDoorOpenTime(
    previousDeviceReport,
    currentDeviceReport,
    "door"
  );

  return {
    doorOpenPeriodInHourResult: doorOpenPeriodInHour,
    doorOpenTimeResult: doorOpenTime,
  };
};
export const computePeriodDoorOpen = (previousData, currentData) => {
  if (previousData !== undefined) {
    const currentTime = moment(currentData["time"]);
    const previousTime = moment(previousData["time"]);
    if (currentData["door"] === false && previousData["door"] === true) {
      const diffTime = moment
        .duration(currentTime.diff(previousTime))
        .asMilliseconds();

      return diffTime;
    }
  }
  return 0;
};
export const computeDoorOpenTime = (previousData, currentData) => {
  if (previousData !== undefined) {
    if (currentData["door"] === true) {
      return 1;
    }
  }
  return 0;
};
