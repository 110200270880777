import styled from "styled-components";

import { imgList } from "@constants/imgList";
import { device } from "@constants/styles";

export default function Coming() {
  return (
    <Container>
      <img src={imgList.ico_clock} alt="" />
      <div className="size-48 text-center color-black font-sukhumvitSet-text">
        Coming soon...
      </div>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  > img {
    width: 4.444vw;
    margin: 0 auto 1.944vw;
  }
  > div {
    line-height: 1;
  }
  @media ${device.desktopL} {
    > img {
      width: 64px;
      margin: 0 auto 28px;
    }
  }
`;
