import { Iuser } from "../interface";

const initState = {
  isLogin: false,
  info: {
    email: "",
    user_fullname: "",
    user_firstname: "",
    user_lastname: "",
    image: {
      id: "",
      url: "",
      updatedAt: null,
    },
    image_id: {
      id: "",
      url: "",
      updatedAt: null,
    },
  },
};

const store = (state = initState, action: { type: string; data: Iuser }) => {
  switch (action.type) {
    case "is_login":
      return { ...state, isLogin: action.data };
    case "info":
      return { ...state, info: action.data };
    default:
      return state;
  }
};

export default store;
