import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import styled from "styled-components";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import { chartColors } from "@constants/colors";
import { capitalizeFirstLetter } from "@utils/string";

const DATETIME_TYPE = {
  hours: "Minutes",
  days: "Hours",
  months: "Days",
  years: "Months",
};

const DATETIME_LABEL_FORMAT = {
  hours: "HH:mm",
  days: "HH:mm",
  months: "DD/MM",
  years: "MM/YYYY",
};

const VALUE_TYPE = {
  avg: "avg",
  min: "min",
  max: "max",
};

const DEFAULT_DATETIME_TYPE = "hour";
const DEFAULT_DATETIME_LABEL_FORMAT = "HH:mm";
const DEFAULT_VALUE_TYPE = "avg";

export const CHART_COLORS = {
  cloudStatus: chartColors.sky,
  wifi: chartColors.blue,
};

const Iot = ({ data }) => {
  const filterDeviceDetail = useSelector(
    (state) => state.monitoring.filterDeviceDetail
  );
  const filterIot = useSelector((state) => state.monitoring.filterIot);
  const [dataCloud, setDataCloud] = useState({ labels: [], datasets: [] });
  const [dataWifi, setDataWifi] = useState({ labels: [], datasets: [] });
  const datetime = filterDeviceDetail.date;
  const datetimeunit = filterDeviceDetail.unit;
  const value = filterIot.value;

  const datetime_type = DATETIME_TYPE?.[datetimeunit] ?? DEFAULT_DATETIME_TYPE;
  const datetime_format =
    DATETIME_LABEL_FORMAT?.[datetimeunit] ?? DEFAULT_DATETIME_LABEL_FORMAT;
  const value_type = VALUE_TYPE?.[value] ?? DEFAULT_VALUE_TYPE;

  useEffect(() => {
    setDataCloud({
      labels: genLabels("cloudStatus"),
      datasets: genDatasets("cloudStatus"),
    });
    setDataWifi({
      labels: genLabels("wifi"),
      datasets: genDatasets("wifi"),
    });
  }, [data]);

  const genDates = () => {
    return (data ?? []).map((e) => {
      return e?.start ?? ""
        ? dayjs(e?.start ?? "").format("DD/MM/YYYY HH:mm")
        : "-";
    });
  };

  const genLabels = () => {
    return (data ?? []).map((e) => {
      return e?.start ?? ""
        ? dayjs(e?.start ?? "").format(datetime_format)
        : "-";
    });
  };

  const genDash = (viewType) => {
    return (data ?? []).map((e) => {
      return e?.data?.[viewType]?.hasCondition ?? false;
    });
  };

  const skipped = (type, ctx, value, data) => {
    let result = undefined;
    // if (type === "wifi") {
    //   result =
    //     (dataWifi?.datasets?.[ctx?.datasetIndex ?? 0]?.showDash?.[
    //       ctx?.p0DataIndex ?? 0
    //     ] ?? false) === true ||
    //     (dataWifi?.datasets?.[ctx?.datasetIndex ?? 0]?.showDash?.[
    //       ctx?.p1DataIndex ?? 0
    //     ] ?? false) === true
    //       ? value
    //       : undefined;
    // } else {
    //   result =
    //     (dataCloud?.datasets?.[ctx?.datasetIndex ?? 0]?.showDash?.[
    //       ctx?.p0DataIndex ?? 0
    //     ] ?? false) === true ||
    //     (dataCloud?.datasets?.[ctx?.datasetIndex ?? 0]?.showDash?.[
    //       ctx?.p1DataIndex ?? 0
    //     ] ?? false) === true
    //       ? value
    //       : undefined;
    // }
    result =
      (data?.[ctx?.p0DataIndex ?? 0] ?? false) === true ||
      (data?.[ctx?.p1DataIndex ?? 0] ?? false) === true
        ? value
        : undefined;
    return result;
  };

  const genDatasets = (type) => {
    return [
      {
        date: genDates(),
        label: type === "cloudStatus" ? "Device Status" : "Wi-Fi",
        data: genDatas(type),
        showDash: genDash(type),
        borderColor: CHART_COLORS[type],
        backgroundColor: CHART_COLORS[type],
        stepped: true,
        yAxisID: "y",
        segment: {
          borderColor: (ctx) =>
            skipped(type, ctx, "rgb(0,0,0,0.2)", genDash(type)),
          borderDash: (ctx) => skipped(type, ctx, [6, 6], genDash(type)),
        },
      },
    ];
  };

  const genDatas = (viewType) => {
    return (data ?? []).map((e) => {
      return (e?.data?.[viewType]?.value ?? undefined) === undefined
        ? NaN
        : e?.data?.[viewType]?.value ?? NaN
        ? `ON`
        : `OFF`;
    });
  };

  const options = {
    hover: {
      intersect: false,
    },
    interaction: {
      intersect: false,
    },
    maintainAspectRatio: false,
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        pointRadius: 0,
      },
    },
    plugins: {
      // title: {
      //   display: true,
      //   text: `${dayjs(datetime).format(
      //     "DD/MM/YYYY"
      //   )} (Per ${datetime_type}) (${capitalizeFirstLetter(
      //     value_type
      //   )}) (Version ${process.env.REACT_APP_VERSION})`,
      // },
      tooltip: {
        callbacks: {
          title: function (tooltipItem) {
            return (
              tooltipItem?.[0]?.dataset?.date?.[
                tooltipItem?.[0]?.dataIndex ?? 0
              ] ?? "-"
            );
          },
        },
      },
      legend: {
        labels: {
          boxWidth: 5,
          boxHeight: 5,
          usePointStyle: true,
        },
      },
    },
  };

  const optionsWifi = {
    ...options,
    scales: {
      y: {
        type: "category",
        labels: ["ON", "OFF"],
        offset: true,
        position: "left",
        stack: "demo",
        stackWeight: 1,
        grid: {
          borderColor: CHART_COLORS.blue,
        },
      },
    },
  };

  const optionsCloud = {
    ...options,
    // plugins: {
    //   ...options.plugins,
    //   title: {
    //     display: true,
    //   },
    // },
    scales: {
      y: {
        type: "category",
        labels: ["ON", "OFF"],
        offset: true,
        position: "left",
        stack: "demo",
        stackWeight: 1,
        grid: {
          borderColor: CHART_COLORS.sky,
        },
      },
    },
  };

  return (
    <ContainerWarp>
      <Container className="operation">
        <Line
          data={dataWifi}
          options={optionsWifi}
          className="operation__canvas"
        />
      </Container>
      <Container className="operation">
        <Line
          data={dataCloud}
          options={optionsCloud}
          className="operation__canvas"
        />
      </Container>
    </ContainerWarp>
  );
};
export default Iot;

const ContainerWarp = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Container = styled.div`
  width: 48%;
  .operation {
    &__canvas {
      height: 31vh;
      min-height: 200px;
      @media (max-width: 450px) {
        height: 180px;
        min-height: unset;
      }
    }
  }
`;
