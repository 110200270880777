import styled from "styled-components";
import { Input as InputAntd } from "antd";
import { TextAreaProps } from "antd/lib/input";

import { colors } from "@constants/colors";
import { device } from "@constants/styles";

const { TextArea } = InputAntd;

interface IInputSimple {
  classNameWarp?: string;
  labelText?: string;
  errorText?: string;
}

export default function TextAreaSimple({
  classNameWarp = "",
  labelText = "",
  errorText = "",
  ...rest
}: IInputSimple & TextAreaProps) {
  return (
    <Input className={classNameWarp}>
      {labelText && (
        <Text>
          <div className="size-14 weight-500 font-jakarta-display color-black522">
            {labelText}
          </div>
        </Text>
      )}
      <TextArea {...rest}></TextArea>
      {errorText && (
        <div className="size-12 weight-500 font-jakarta-display color-red">
          {errorText}
        </div>
      )}
    </Input>
  );
}

interface IInput {}

const Text = styled.div`
  margin: 0 0 0.694vw;
  @media ${device.desktopL} {
    margin: 0 0 10px;
  }
`;

const Input = styled.div<IInput>`
  & .ant-input-affix-wrapper {
    font-family: "Mitr", sans-serif;
    border: 1px solid #d9d9d9;
    border-radius: 0.694vw;
    padding: 0.903vw 1.389vw;
    border: 0.069vw solid ${colors.gray5f7};
    @media ${device.desktopL} {
      border-radius: 10px;
      padding: 13px 20px;
      border: 1px solid ${colors.gray5f7};
    }
  }
  & .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${colors.primary2};
  }
  & .ant-input-password {
    & .ant-input {
      border: none !important;
      border-radius: 0 !important;
    }
  }
  & .ant-input {
    font-family: "Plus Jakarta Display", sans-serif;
    font-size: 0.833vw;
    font-weight: 400;
    color: ${colors.blackd33};
    padding: 1.007vw 1.389vw;
    border-radius: 0.694vw;
    border: 0.069vw solid ${colors.gray5f7};
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(223, 223, 223, 1);
      opacity: 1; /* Firefox */
    }
    &::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(223, 223, 223, 1);
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(223, 223, 223, 1);
    }
    @media ${device.desktopL} {
      font-size: 12px;
      padding: 14.5px 20px;
      border-radius: 10px;
      border: 1px solid ${colors.gray5f7};
    }
  }
  & .ant-input:hover {
    border-color: ${colors.primary2};
  }
  & .ant-input-focused,
  .ant-input:focus {
    border-color: ${colors.primary2};
    box-shadow: none;
  }
  & .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: ${colors.primary2};
    box-shadow: none;
  }
  & .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
`;
