import { Modal, ModalProps } from "antd";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

import { device } from "@constants/styles";
import { colors } from "@constants/colors";
import { ButtonBlock } from "@components/Buttons";
import { SelectNotContainer } from "@components/Selects";
import { InputNew } from "@components/Inputs";
import { imgList } from "@constants/imgList";
import { filterAction } from "@reduxs/Actions/monitoringAction";
import { stateInterface } from "@reduxs/interface";

type Props = {
  open: boolean;
  onOk: () => void;
  onClose: () => void;
};

const ModalFilterMonitoring = ({
  open = false,
  onOk = () => {},
  onClose = () => {},
  ...props
}: Props & ModalProps) => {
  const dispatch = useDispatch();
  const listStatus = useSelector(
    (state: stateInterface) => state.monitoring.listStatus
  );
  const listLocation = useSelector(
    (state: stateInterface) => state.monitoring.listLocation
  );
  const filter = useSelector(
    (state: stateInterface) => state.monitoring.filter
  );
  const [temp, setTemp] = useState<any>({
    status: [],
    location: [],
    area: [],
    name: "",
  });
  const { name, status, location, area } = temp;
  useEffect(() => {
    if (open) {
      setTemp(filter);
    }
  }, [open]);
  const handleReset = () => {
    setTemp(filter);
  };
  const handleChangeValue = (e: any) => {
    setTemp({
      ...temp,
      ...e,
    });
  };
  return (
    <Content id="modal-filter-monitoring" className="modal">
      <Modal
        title=""
        open={open}
        closable={false}
        footer={null}
        centered={true}
        getContainer={() =>
          document.getElementById("modal-filter-monitoring") as HTMLElement
        }
        {...props}
      >
        <div className="modal__head">
          <div className="modal__headline">
            <div className="font-sukhumvitSet-bold size-14 weight-700 color-black modal__name">
              Filter
            </div>
            <div
              className="modal__reset font-sukhumvitSet-bold size-12 weight-800 color-primary"
              onClick={() => handleReset()}
            >
              <img src={imgList.ico_refresh} alt="" />
              Reset
            </div>
          </div>
          <img src={imgList.ico_close_modal} alt="" onClick={() => onClose()} />
        </div>
        <div className="modal__content">
          <div className="modal__row">
            <InputNew
              type="text"
              labelText="Search"
              placeholder="ค้นหาชื่ออุปกรณ์"
              value={name}
              onChange={(e) => handleChangeValue({ name: e.target.value })}
            />
          </div>
          <div className="modal__row">
            <SelectNotContainer
              mode="multiple"
              id=""
              labelText="Status"
              value={status}
              options={listStatus.map((e) => {
                return {
                  label: e?.filterStatusDisplayText ?? "",
                  value: e?.filterStatus ?? "",
                };
              })}
              placeholder="แสดงทั้งหมด"
              maxTagCount={0}
              maxTagPlaceholder={
                <div>
                  {status.length > 1
                    ? `แสดง (${status.length})`
                    : `${
                        listStatus.filter(
                          (e) => e.filterStatus === status?.[0] ?? ""
                        )?.[0]?.filterStatusDisplayText ?? ""
                      }`}
                </div>
              }
              classNameWarp=""
              onChange={(e) => handleChangeValue({ status: e })}
              dropdownStyle={{ zIndex: 9999 }}
            />
          </div>
          <div className="modal__row">
            <SelectNotContainer
              mode="multiple"
              id=""
              labelText="Location"
              value={location}
              options={listLocation.map((e) => {
                return {
                  label: e?.name ?? "",
                  value: e?.id ?? "",
                };
              })}
              placeholder="แสดงทั้งหมด"
              maxTagCount={0}
              maxTagPlaceholder={
                <div>
                  {location.length > 1
                    ? `แสดง (${location.length})`
                    : `${
                        listLocation.filter(
                          (e) => e.id === location?.[0] ?? ""
                        )?.[0]?.name ?? ""
                      }`}
                </div>
              }
              classNameWarp=""
              dropdownStyle={{ zIndex: 9999 }}
              onChange={(e) => handleChangeValue({ location: e })}
            />
          </div>
          <div className="modal__row">
            <SelectNotContainer
              mode="multiple"
              id="area"
              labelText="Area"
              value={area}
              options={[
                {
                  label: "All",
                  value: "all",
                },
              ]}
              placeholder="แสดงทั้งหมด"
              classNameWarp=""
              disabled
            />
          </div>
          <div className="modal__btn">
            <ButtonBlock
              text="Apply"
              className="modal__btn--cf"
              onClick={() => {
                dispatch(
                  filterAction({
                    ...filter,
                    ...temp,
                  })
                );
                onOk();
              }}
            />
          </div>
        </div>
      </Modal>
    </Content>
  );
};

export default ModalFilterMonitoring;

interface IStyle {
  active: boolean;
}

const CheckBox = styled.div<IStyle>`
  width: 100%;
  display: flex;
  border: 0.069vw solid ${(props) => (props.active ? "#FEBC11" : "#c1c1c1")};
  border-radius: 0.347vw;
  padding: 0.694vw;
  cursor: pointer;
  @media ${device.desktopL} {
    border: 1px solid ${(props) => (props.active ? "#FEBC11" : "#c1c1c1")};
    border-radius: 5px;
    padding: 10px;
  }
  .check {
    &__dot {
      position: relative;
      width: 1.667vw;
      height: 1.667vw;
      margin: 0 1.111vw 0 0;
      border: 0.069vw solid ${(props) => (props.active ? "#FEBC11" : "#c1c1c1")};
      border-radius: 50%;
      @media ${device.desktopL} {
        width: 24px;
        height: 24px;
        margin: 0 16px 0 0;
        border: 1px solid ${(props) => (props.active ? "#FEBC11" : "#c1c1c1")};
      }
    }
    &__active {
      position: absolute;
      width: 70%;
      height: 70%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: ${(props) => (props.active ? "#FEBC11" : "transparent")};
      border-radius: 50%;
    }
  }
`;
const Content = styled.div`
  width: 100%;
  max-width: 90% !important;
  & .ant-modal-content {
    border-radius: 0.833vw;
    @media ${device.desktopL} {
      border-radius: 12px;
    }
  }
  & .ant-modal {
    width: 26.042vw !important;
    @media ${device.desktopL} {
      width: 375px !important;
    }
  }
  & .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  & .ant-modal-body {
    padding: 1.667vw;
    font-size: 0.972vw;
    @media ${device.desktopL} {
      padding: 24px;
      font-size: 14px;
    }
  }
  .modal {
    &__head {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      > img {
        width: 1.667vw;
        height: 1.667vw;
        cursor: pointer;
      }
      @media ${device.desktopL} {
        > img {
          width: 24px;
          height: 24px;
        }
      }
    }
    &__headline {
      width: calc(100% - 1.667vw - 0.417vw);
      margin: 0 0.417vw 0 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @media ${device.desktopL} {
        width: calc(100% - 24px - 6px);
        margin: 0 6px 0 0;
      }
    }
    &__name {
      margin: 0 0.694vw 0 0;
      @media ${device.desktopL} {
        margin: 0 10px 0 0;
      }
    }
    &__reset {
      display: flex;
      align-items: center;
      padding: 0.139vw 0.556vw;
      background: #ffffff;
      border: 0.069vw solid #febc11;
      border-radius: 0.278vw;
      cursor: pointer;
      > img {
        width: 1.111vw;
        margin: 0 0.278vw 0 0;
      }
      @media ${device.desktopL} {
        padding: 2px 8px;
        border: 1px solid #febc11;
        border-radius: 4px;
        > img {
          width: 16px;
          margin: 0 4px 0 0;
        }
      }
    }
    &__row {
      width: 100%;
      margin: 24px 0 0;
    }
    &__row-text {
      width: 100%;
    }
    &__group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 1.111vw 0 0;
      > div {
        width: 47%;
      }
      &--large {
        > div {
          width: 100%;
        }
      }
      @media ${device.desktopL} {
        margin: 16px 0 0;
      }
    }
    &__content {
    }
    &__btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 2.083vw 0 0;
      &--cancel {
        color: ${colors.ppDark};
        border: 0.139vw solid ${colors.ppDark};
        width: 48%;
      }
      &--cf {
        width: 100%;
      }
      @media ${device.desktopL} {
        margin: 30px 0 0;
        &--cancel {
          border: 2px solid ${colors.ppDark};
        }
      }
    }
  }
`;
