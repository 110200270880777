import React from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SwitchSelector from "react-switch-selector";
import _ from "lodash";
// import useSound from "use-sound";
import { Pagination } from "antd";
import dayjs from "dayjs";
import { useMedia } from "react-use";

import { imgList } from "@constants/imgList";
// import { soundList } from "@constants/soundList";
import { device } from "@constants/styles";
import { InputIcon } from "@components/Inputs";
import { SelectSimple } from "@components/Selects";
import { ButtonBlock } from "@components/Buttons";
import { displayImage } from "@utils/image";
import { toFixed } from "@utils/string";
import { dateConvert } from "@utils/date";
import { apiGet, apiPut } from "@apis/gateway";
import { loadingAction } from "@reduxs/Actions/storeAction";
import {
  filterAction,
  listStatusAction,
  listLocationAction,
  paginationAction,
  mapLocationDevicesAction,
  dataDevicesAction,
  isSoundPlayingAction,
  updateDateAction,
  countAction,
  isPlayAction,
} from "@reduxs/Actions/monitoringAction";
import {
  switchViewAction,
  switchSortAction,
} from "@reduxs/Actions/storageAction";
import { stateInterface } from "@reduxs/interface";

export default function Device() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const language = useSelector(
    (state: stateInterface) => state.storage.language
  );
  const filter = useSelector(
    (state: stateInterface) => state.monitoring.filter
  );
  const listStatus = useSelector(
    (state: stateInterface) => state.monitoring.listStatus
  );
  const listLocation = useSelector(
    (state: stateInterface) => state.monitoring.listLocation
  );
  const view = useSelector(
    (state: stateInterface) => state?.storage?.view ?? "list"
  );
  const sort = useSelector(
    (state: stateInterface) => state?.storage?.sort ?? "char_asc"
  );
  const pagination = useSelector(
    (state: stateInterface) => state.monitoring.pagination
  );
  const dataRaw = useSelector(
    (state: stateInterface) => state.monitoring.dataDevices
  );
  const sound = useSelector(
    (state: stateInterface) => state?.storage?.sound ?? "off"
  );
  const isSoundPlaying = useSelector(
    (state: stateInterface) => state.monitoring.isSoundPlaying
  );
  const updateDate = useSelector(
    (state: stateInterface) => state.monitoring.updateDate
  );
  const count = useSelector((state: stateInterface) => state.monitoring.count);
  const { name, status, location, area } = filter;
  const [dataFilter, setDataFilter] = useState<any[]>([]);
  const [dataPagination, setPagination] = useState<any[]>([]);
  const [loadSound, setLoadSound] = useState(false);
  // const [play, { stop }] = useSound(soundList.beeps, {
  //   loop: true,
  //   interrupt: true,
  //   onload: () => setLoadSound(true),
  // });
  const [stuck, setStuck] = useState(false);
  const ref: any = React.createRef();
  const styles = stuck ? {} : {};
  const isTablet = useMedia("(max-width: 1024px)");
  const isMobile = useMedia("(max-width: 500px)");

  const position = "top";

  const inlineStyles: any = {
    position: "sticky",
    zIndex: 999,
    [position]: isMobile ? -414 : isTablet ? -218 : -70,
    ...styles,
  };

  const options = [
    {
      label: (
        <div className="sw__choose">
          <img src={imgList.ico_list} alt="" />
        </div>
      ),
      value: "list",
    },
    {
      label: (
        <div className="sw__choose">
          <img src={imgList.ico_grid} alt="" />
        </div>
      ),
      value: "grid",
    },
  ];
  const onChangeSwitch = (newValue: any) => {
    dispatch(switchViewAction(newValue));
  };
  useEffect(() => {
    const cachedRef: any = ref.current;
    const observer = new IntersectionObserver(
      ([e]) => {
        setStuck(e.intersectionRatio < 1);
      },
      { threshold: [1] }
    );
    observer.observe(cachedRef);
    return () => observer.unobserve(cachedRef);
  }, [ref]);
  useEffect(() => {
    return () => {
      dispatch(dataDevicesAction([]));
      setDataFilter([]);
      setPagination([]);
    };
  }, []);
  useEffect(() => {
    if (dataFilter.length > 0) {
      checkSound();
    }
  }, [dataFilter, sound]);
  const checkSound = async () => {
    let soundAlarm = false;
    (dataFilter ?? []).forEach((e) => {
      const soundAlarmVal = e?.controlInfo?.soundAlarm ?? false;
      const alarmVal = e?.controlInfo?.control?.alarm ?? false;
      if (soundAlarmVal && alarmVal) {
        soundAlarm = true;
      }
    });
    if (soundAlarm && sound === "on" && !isSoundPlaying) {
      // play();
      dispatch(isPlayAction(true));
      dispatch(isSoundPlayingAction(true));
    } else if (soundAlarm && sound === "on" && isSoundPlaying) {
      dispatch(isSoundPlayingAction(true));
    } else {
      // stop();
      dispatch(isPlayAction(false));
      dispatch(isSoundPlayingAction(false));
    }
  };
  useEffect(() => {
    initData();
  }, []);
  useEffect(() => {
    dispatch(updateDateAction(dayjs()));
  }, [dataRaw]);
  useEffect(() => {
    let arr = dataRaw;
    if (name !== "") {
      arr = arr.filter((e) =>
        (e?.deviceInfo?.name ?? "").toLowerCase().includes(name.toLowerCase())
      );
    }
    if (status.length > 0) {
      arr = arr.filter(
        (e) => status.indexOf(e?.deviceStatus?.status ?? "") > -1
      );
    }
    if (location.length > 0) {
      arr = arr.filter((e) => location.indexOf(e?.locationID) > -1);
    }
    if (sort === "char_asc") {
      arr = _.orderBy(arr, "deviceInfo.name", "asc");
    }
    if (sort === "char_desc") {
      arr = _.orderBy(arr, "deviceInfo.name", "desc");
    }
    if (sort === "update_desc") {
      arr = _.orderBy(arr, "createdAt", "desc");
    }
    if (sort === "update_asc") {
      arr = _.orderBy(arr, "createdAt", "asc");
    }
    setDataFilter(arr);
    // dispatch(
    //   paginationAction({
    //     ...pagination,
    //     page: 1,
    //   })
    // );
  }, [dataRaw, sort, filter]);
  useEffect(() => {
    let totalDevices = 0;
    let alarm = 0;
    let normal = 0;
    let offline = 0;
    let powerOff = 0;
    (dataFilter ?? []).forEach((e) => {
      totalDevices = totalDevices + 1;
      if (e?.deviceStatus?.filterStatus === "normal") {
        normal = normal + 1;
      } else if (e?.deviceStatus?.filterStatus === "offline") {
        offline = offline + 1;
      } else if (e?.deviceStatus?.filterStatus === "powerOff") {
        powerOff = powerOff + 1;
      } else if (e?.deviceStatus?.filterStatus === "alarm") {
        alarm = alarm + 1;
      }
    });
    dispatch(
      countAction({
        alarm: alarm,
        normal: normal,
        offline: offline,
        powerOff: powerOff,
        totalDevices: totalDevices,
      })
    );
  }, [dataFilter]);
  useEffect(() => {
    const start =
      pagination.page === 1 ? 0 : (pagination.page - 1) * pagination.pageSize;
    const end = pagination.page * pagination.pageSize - 1;
    setPagination(
      (dataFilter ?? []).filter((e, index) => index >= start && index <= end)
    );
  }, [dataFilter, pagination]);
  const initData = async () => {
    dispatch(loadingAction(true));
    await getMonitoring();
    await getStatus();
    await getLocation();
    dispatch(loadingAction(false));
  };
  const getMonitoring = async () => {
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/monitoring`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      let array: any[] = [];
      let arrayMac: any[] = [];
      (res?.data?.data?.data ?? []).forEach((e: any, index: number) => {
        (e?.devices ?? []).forEach((e2: any, index2: number) => {
          let obj = {
            ...e2,
            location: e?.name ?? "",
            locationID: e?.id ?? "",
          };
          let objMac = {
            macAddress: e2?.deviceInfo?.macAddress ?? "",
          };
          array.push(obj);
          arrayMac.push(objMac);
        });
      });
      dispatch(mapLocationDevicesAction(arrayMac));
      dispatch(dataDevicesAction(array));
    }
  };
  const getStatus = async () => {
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/monitoring/device-status`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      dispatch(
        listStatusAction(
          (res?.data?.data ?? []).filter((e: any) => e?.filterStatus !== "all")
        )
      );
    }
  };
  const getLocation = async () => {
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/monitoring/location-filter`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      dispatch(listLocationAction(res?.data?.data ?? []));
    }
  };
  const renderStatus = (status = "", statusText = "") => {
    let color = "";
    switch (status) {
      case "normal":
        color = "color-status-normal";
        break;
      case "powerOff":
        color = "color-status-poweroff";
        break;
      case "offline":
        color = "color-status-offline";
        break;
      case "alarm":
        color = "color-status-alarm";
        break;
      case "updating":
        color = "color-status-updating";
        break;
      default:
        color = "color-status-normal";
        break;
    }
    return (
      <div className={`weight-600 size-20 font-sukhumvitSet-text ${color}`}>
        {statusText}
      </div>
    );
  };
  const renderWifi = (wifi = false, rssi = 0, wifiStrength = "") => {
    if (wifiStrength === "offline") {
      return (
        <Mode active={wifi}>
          <img src={imgList[`ico_wifi_offline`]} alt="" />
        </Mode>
      );
    } else {
      if (rssi > -50) {
        return (
          <Mode active={true}>
            <img src={imgList[`ico_wifi_active`]} alt="" />
          </Mode>
        );
      } else if (rssi <= -50 && rssi >= -59) {
        return (
          <Mode active={true}>
            <img src={imgList[`ico_wifi_1`]} alt="" />
          </Mode>
        );
      } else if (rssi <= -60 && rssi >= -69) {
        return (
          <Mode active={true} isError={true}>
            <img src={imgList[`ico_wifi_2`]} alt="" />
          </Mode>
        );
      } else {
        return (
          <Mode active={true} isError={true}>
            <img src={imgList[`ico_wifi_3`]} alt="" />
          </Mode>
        );
      }
    }
  };
  const renderLight = (light = false) => {
    return (
      <Mode active={light}>
        <img
          src={imgList[`ico_light_bulb_${light ? "active" : "disable"}`]}
          alt=""
        />
      </Mode>
    );
  };
  const renderComp = (status = "", comp = false, flash = false) => {
    const active = status !== "updating" && (comp || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_snow_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderFan = (status = "", fan = false, flash = false) => {
    const active = status !== "updating" && (fan || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_fan_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderDefrost = (status = "", defrost = false, flash = false) => {
    const active = status !== "updating" && (defrost || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_bulb_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderAlarm = (alarm = false, flash = false) => {
    return (
      <Mode active={alarm || flash} isError={false}>
        <img
          src={imgList[`ico_shield_${alarm || flash ? "active" : "disable"}`]}
          alt=""
          className={`${alarm || flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderDoor = (status = "", door = false, flash = false) => {
    const active = status !== "updating" && (door || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_door_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderSound = (macAddress = "", soundAlarm = false, alarm = false) => {
    if (alarm) {
      if (soundAlarm) {
        return (
          <img
            className="pointer"
            src={imgList.ico_sound}
            alt=""
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              onMute(macAddress);
            }}
          />
        );
      } else {
        return <img src={imgList.ico_sound_disable} alt="" />;
      }
    } else {
      return <></>;
    }
  };
  const onMute = async (macAddress = "") => {
    const params = {};
    dispatch(loadingAction(true));
    await apiPut(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device/${macAddress}/mute-alarm`,
      { ...params },
      {},
      language
    );
    dispatch(loadingAction(false));
  };
  const listSort = [
    {
      label: "ตัวอักษร (A-Z)",
      value: "char_asc",
    },
    {
      label: "ตัวอักษร (Z-A)",
      value: "char_desc",
    },
    {
      label: "ใหม่ไปเก่า",
      value: "update_desc",
    },
    {
      label: "เก่าไปใหม่",
      value: "update_asc",
    },
  ];
  return (
    <Container className="detail" isList={view === "list"}>
      <div className="detail__head" ref={ref} style={inlineStyles}>
        <div className="detail__top">
          <div>
            <div className="font-inter size-30 color-blue828 weight-600">
              Device List
            </div>
          </div>
          {!stuck && !isMobile && !isTablet && (
            <div className="detail__text font-sukhumvitSet-text size-16 color-gray474">
              <img src={imgList.ico_clock} alt="" />
              อัปเดตล่าสุด:
              <span className="color-black">{dateConvert(updateDate)}</span>
            </div>
          )}
          <div className="detail__text detail__text--tablet font-sukhumvitSet-text size-16 color-gray474">
            <img src={imgList.ico_clock} alt="" />
            อัปเดตล่าสุด:
            <span className="color-black">{dateConvert(updateDate)}</span>
          </div>
        </div>
        <Filter className="filter">
          <div className="filter__box filter__box--1">
            <InputIcon
              type="text"
              value={name}
              placeholder="ค้นหาชื่ออุปกรณ์"
              onChange={(e) => {
                dispatch(
                  filterAction({
                    ...filter,
                    name: e.target.value,
                  })
                );
                dispatch(
                  paginationAction({
                    ...pagination,
                    page: 1,
                  })
                );
              }}
            />
          </div>
          <div className="filter__box filter__box--2">
            <SelectSimple
              mode="multiple"
              id="status"
              labelText="Status"
              value={status}
              options={listStatus.map((e) => {
                return {
                  label: e?.filterStatusDisplayText ?? "",
                  value: e?.filterStatus ?? "",
                };
              })}
              placeholder="แสดงทั้งหมด"
              maxTagCount={0}
              maxTagPlaceholder={
                <div>
                  {status.length > 1
                    ? `แสดง (${status.length})`
                    : `${
                        listStatus.filter(
                          (e) => e.filterStatus === status?.[0] ?? ""
                        )?.[0]?.filterStatusDisplayText ?? ""
                      }`}
                </div>
              }
              classNameWarp=""
              onChange={(e) => {
                dispatch(
                  filterAction({
                    ...filter,
                    status: e,
                  })
                );
                dispatch(
                  paginationAction({
                    ...pagination,
                    page: 1,
                  })
                );
              }}
            />
          </div>
          <div className="filter__box filter__box--2">
            <SelectSimple
              optionFilterProp="label"
              mode="multiple"
              id="location"
              labelText="Location"
              value={location}
              options={listLocation.map((e) => {
                return {
                  label: e?.name ?? "",
                  value: e?.id ?? "",
                };
              })}
              placeholder="แสดงทั้งหมด"
              maxTagCount={0}
              maxTagPlaceholder={
                <div>
                  {location.length > 1
                    ? `แสดง (${location.length})`
                    : `${
                        listLocation.filter(
                          (e) => e.id === location?.[0] ?? ""
                        )?.[0]?.name ?? ""
                      }`}
                </div>
              }
              classNameWarp=""
              onChange={(e) => {
                dispatch(
                  filterAction({
                    ...filter,
                    location: e,
                  })
                );
                dispatch(
                  paginationAction({
                    ...pagination,
                    page: 1,
                  })
                );
              }}
            />
          </div>
          <div className="filter__box filter__box--2">
            <SelectSimple
              mode="multiple"
              id="area"
              labelText="Area"
              value={area}
              options={[
                {
                  label: "All",
                  value: "all",
                },
              ]}
              placeholder="แสดงทั้งหมด"
              classNameWarp=""
              disabled
            />
          </div>
        </Filter>
        <Headline className="headline">
          <div className="headline__left">
            <div className="headline__head size-20 weight-700 font-sukhumvitSet-bold">
              อุปกรณ์ ({dataFilter.length})
            </div>
            <TotalDevices className="total">
              <div className="total__box total__box--1">
                <img src={imgList.icoCatNormal} alt="" className="total__img" />
                <div className="font-sukhumvitSet-bold size-16 total__text-number">
                  {count?.normal ?? 0}
                </div>
              </div>
              <div className="total__box total__box--2">
                <img src={imgList.icoCatPower} alt="" className="total__img" />
                <div className="font-sukhumvitSet-bold size-16 total__text-number">
                  {count?.powerOff ?? 0}
                </div>
              </div>
              <div className="total__box total__box--3">
                <img
                  src={imgList.icoCatOffline}
                  alt=""
                  className="total__img"
                />
                <div className="font-sukhumvitSet-bold size-16 total__text-number">
                  {count?.offline ?? 0}
                </div>
              </div>
              <div className="total__box total__box--4 last">
                <img src={imgList.icoCatAlarm} alt="" className="total__img" />
                <div className="font-sukhumvitSet-bold size-16 total__text-number">
                  {count?.alarm ?? 0}
                </div>
              </div>
            </TotalDevices>
            {stuck && (
              <div
                className={`detail__text ${
                  stuck ? "detail__text--show" : "detail__text--hide"
                } font-sukhumvitSet-text size-16 color-gray474`}
              >
                <img src={imgList.ico_clock} alt="" />
                อัปเดตล่าสุด:
                <span className="color-black">{dateConvert(updateDate)}</span>
              </div>
            )}
          </div>
          <OrderBy>
            <SwitchWarp className="sw">
              <SwitchSelector
                forcedSelectedIndex={options.findIndex(
                  ({ value }) => value === view
                )}
                onChange={onChangeSwitch}
                options={options}
                backgroundColor={"#353b48"}
                fontColor={"#f5f6fa"}
              />
            </SwitchWarp>
            <SelectSimple
              id="sort"
              labelText=""
              value={sort}
              options={listSort}
              classNameWarp="select-sort"
              placement="bottomRight"
              onChange={(e) => {
                dispatch(switchSortAction(e));
              }}
            />
          </OrderBy>
        </Headline>
      </div>
      <div
        className={`detail__warp ${
          (dataPagination ?? []).length <= 0 && dataFilter.length > 0
            ? "detail__warp--100"
            : ""
        }`}
      >
        <div className="detail__content">
          {(dataPagination ?? []).length <= 0 && dataFilter.length > 0 ? (
            <div className="detail__btn">
              <ButtonBlock
                text="แสดงรายการอุปกรณ์"
                className="modal__btn--cf"
                onClick={() =>
                  dispatch(
                    paginationAction({
                      ...pagination,
                      page: 1,
                    })
                  )
                }
              />
            </div>
          ) : (dataPagination ?? []).length <= 0 ? (
            <NoDevice>
              <img src={imgList.icoFaq} alt="" />
              <div className="color-black size-16 font-sukhumvitSet-bold">
                ไม่พบอุปกรณ์ของคุณ
              </div>
            </NoDevice>
          ) : (
            <></>
          )}
          {(dataPagination ?? []).map((e, index) => (
            <List
              className="list"
              key={index}
              isList={view === "list"}
              // onClick={
              //   view === "list"
              //     ? () => {}
              //     : () =>
              //         navigate(
              //           `/device-detail/${e?.locationID ?? ""}/${
              //             e?.deviceInfo?.macAddress ?? ""
              //           }`
              //         )
              // }
              onClick={
                view === "list"
                  ? () => {}
                  : () =>
                      navigate(
                        `/device-detail/${e?.deviceInfo?.macAddress ?? ""}`
                      )
              }
            >
              <div className="list__cover">
                <img
                  src={
                    e?.deviceInfo?.image ?? ""
                      ? displayImage(e?.deviceInfo?.image ?? "")
                      : imgList.ex
                  }
                  alt=""
                />
              </div>
              <div className="list__left">
                <div className="list__name">
                  <div className="size-16 weight-600 font-sukhumvitSet-text color-black">
                    {e?.deviceInfo?.name ?? ""}
                  </div>
                  {renderSound(
                    e?.controlInfo?.macAddress ?? "",
                    e?.controlInfo?.soundAlarm ?? false,
                    e?.controlInfo?.control?.alarm ?? false
                  )}
                </div>
                <div className="list__text-group">
                  <div className="list__text list__text--sp">
                    <img src={imgList.ico_location_gray} alt="" />
                    <div className="color-grayf4f size-12 font-sukhumvitSet-text">
                      {e?.location ?? ""}
                    </div>
                  </div>
                </div>
                <div className="list__text-group">
                  <div className="list__text list__text--sp">
                    <img src={imgList.ico_user_gray} alt="" />
                    <div className="color-grayf4f size-12 font-sukhumvitSet-text">
                      {e?.isOwner ?? false ? "My Device" : "Shared with me"}
                    </div>
                  </div>
                </div>
              </div>
              <div className="list__right">
                <div>
                  <div className="list__status">
                    <div className="list__status-group">
                      <div className="list__status-render">
                        <div className="color-grayf4f size-12 font-sukhumvitSet-text">
                          Current Temp
                        </div>
                        <div className="color-grayf4f weight-600 size-20 font-sukhumvitSet-text">
                          {toFixed(e?.deviceInfo?.currentTemp ?? 0, 1)}
                          <span className="size-12">°C</span>
                        </div>
                      </div>
                      <div className="list__status-render">
                        <div className="color-grayf4f size-12 font-sukhumvitSet-text">
                          Status
                        </div>
                        {renderStatus(
                          e?.deviceStatus?.status ?? "",
                          e?.deviceStatus?.statusDisplayText ?? ""
                        )}
                      </div>
                      <div className="list__status-render">
                        <div className="color-grayf4f size-12 font-sukhumvitSet-text">
                          Mode
                        </div>
                        <div className="color-grayf4f weight-600 size-20 font-sukhumvitSet-text">
                          {e?.deviceStatus?.modeDisplayText ?? ""}
                        </div>
                      </div>
                    </div>
                    <div className="list__group">
                      {renderWifi(
                        e?.controlInfo?.control?.wifi ?? false,
                        e?.controlInfo?.rssi ?? 0,
                        e?.controlInfo?.wifiStrength ?? "good"
                      )}
                      {renderLight(e?.controlInfo?.control?.light ?? false)}
                      {renderDefrost(
                        e?.deviceStatus?.status ?? "",
                        e?.controlInfo?.control?.defrost ?? false,
                        e?.controlInfo?.flashing?.defrost ?? false
                      )}
                      {renderFan(
                        e?.deviceStatus?.status ?? "",
                        e?.controlInfo?.control?.fan ?? false,
                        e?.controlInfo?.flashing?.fan ?? false
                      )}
                      {renderComp(
                        e?.deviceStatus?.status ?? "",
                        e?.controlInfo?.control?.comp ?? false,
                        e?.controlInfo?.flashing?.comp ?? false
                      )}
                      {renderAlarm(
                        e?.controlInfo?.control?.alarm ?? false,
                        e?.controlInfo?.flashing?.alarm ?? false
                      )}
                      {renderDoor(
                        e?.deviceStatus?.status ?? "",
                        e?.controlInfo?.control?.door ?? false,
                        e?.controlInfo?.flashing?.door ?? false
                      )}
                    </div>
                  </div>
                  <div
                    className="list__btn color-black size-14 weight-600 font-sukhumvitSet-text"
                    // onClick={() =>
                    //   navigate(
                    //     `/device-detail/${e?.locationID ?? ""}/${
                    //       e?.deviceInfo?.macAddress ?? ""
                    //     }`
                    //   )
                    // }
                    onClick={() =>
                      navigate(
                        `/device-detail/${e?.deviceInfo?.macAddress ?? ""}`
                      )
                    }
                  >
                    ดูรายละเอียด
                  </div>
                </div>
              </div>
            </List>
          ))}
        </div>
      </div>
      {(dataPagination ?? []).length > 0 && (
        <PaginationWarp>
          <Pagination
            current={pagination.page}
            total={dataFilter.length}
            pageSize={pagination.pageSize}
            pageSizeOptions={pagination.pageSizeOptions}
            showSizeChanger={true}
            onChange={(page, pageSize) => {
              dispatch(
                paginationAction({
                  ...pagination,
                  page: page,
                  pageSize: pageSize,
                })
              );
            }}
          />
        </PaginationWarp>
      )}
    </Container>
  );
}

interface IList {
  isList: boolean;
}
const Stuck = styled.div`
  width: 100%;
`;
const TotalDevices = styled.div`
  // position: fixed;
  max-width: 23.889vw;
  background: #ffffff;
  border-radius: 0.417vw;
  display: flex;
  align-items: center;
  padding: 0.347vw 0;
  margin: 0 1.528vw;
  border: 0.035vw solid #dce0e4;
  > img {
    width: 1.389vw;
    margin: 0 0.556vw 0 0;
  }
  @media ${device.desktopL} {
    max-width: 344px;
    border-radius: 6px;
    padding: 5px 0;
    margin: 0 22px;
    border: 0.5px solid #dce0e4;
    > img {
      width: 20px;
      margin: 0 8px 0 0;
    }
  }
  @media (max-width: 395px) {
    margin: 0;
  }
  .total {
    &__box {
      display: flex;
      align-items: center;
      border-right: 0.035vw solid rgba(0, 0, 0, 0.2);
      padding: 0 0.833vw;
      height: 1.944vw;
      &.last {
        border-right: none;
      }
      &--1 {
        color: #12b76a;
      }
      &--2 {
        color: #656565;
      }
      &--3 {
        color: #959595;
      }
      &--4 {
        color: #d93a36;
      }
      @media ${device.desktopL} {
        border-right: 0.5px solid rgba(0, 0, 0, 0.2);
        padding: 0 12px;
        height: 28px;
      }
    }
    &__text {
      margin: 0 0 -0.486vw;
      @media ${device.desktopL} {
        margin: 0 0 -7px;
      }
    }
    &__number {
      margin: 0 0 0 0.347vw;
      @media ${device.desktopL} {
        margin: 0 0 0 5px;
      }
    }
    &__img {
      width: 0.972vw;
      margin: 0 0.417vw 0 0;
      @media ${device.desktopL} {
        width: 14px;
        margin: 0 6px 0 0;
      }
    }
    &__text-number {
      margin: 0.139vw 0 0;
      @media ${device.desktopL} {
        margin: 2px 0 0;
      }
    }
    &__box-total {
      margin: 0 0 -0.278vw;
      @media ${device.desktopL} {
        margin: 0 0 -4px;
      }
    }
  }
`;
const NoDevice = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 20px;
    display: block;
    margin: -4px 0 0;
  }
`;
const PaginationWarp = styled.div`
  width: 100%;
  text-align: right;
  padding: 24px 40px;
  margin: 0 0 -32px;
  background: #fff;
  border-radius: 0 0px 0 40px;
  & .ant-pagination-item-active a {
    color: rgba(0, 0, 0, 0.85);
  }
  & .ant-pagination-item-active {
    background: #febc11;
    border-color: #febc11;
  }
  & .ant-pagination-item:hover {
    background: #febc11;
    border-color: #febc11;
  }
  & .ant-pagination-item:hover a {
    color: rgba(0, 0, 0, 0.85);
  }
  & .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.85);
    border-color: #febc11;
    background: #febc11;
  }
  & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #febc11;
  }
  & .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #febc11;
  }
`;
const OrderBy = styled.div`
  width: calc(180px + 16px + 84px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .select-sort {
    width: 180px;
    margin: 0 0 0 16px;
  }
  & .ant-select-selector {
    height: 40px !important;
  }
`;
const SwitchWarp = styled.div`
  width: 84px;
  & .react-switch-selector-wrapper {
    background: #f9fafb;
    border-radius: 0.556vw;
  }
  & .react-switch-selector-wrapper::before {
    background: #ffffff;
    border: none;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
      0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 0.556vw;
    height: calc(100% - 0.417vw);
    width: calc(50% - 0.417vw);
    margin-left: 0.208vw;
  }
  @media ${device.desktopL} {
    & .react-switch-selector-wrapper {
      border-radius: 8px;
    }
    & .react-switch-selector-wrapper::before {
      height: calc(100% - 6px);
      width: calc(50% - 6px);
      border-radius: 8px;
      margin-left: 3px;
    }
  }
  .sw {
    &__choose {
      width: 100%;
      height: 2.778vw;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 1.111vw;
      }
      @media ${device.desktopL} {
        height: 40px;
        > img {
          width: 16px;
        }
      }
    }
  }
`;
const List = styled.div<IList>`
  position: relative;
  width: ${(props) => (props.isList ? "100%" : "calc(50% - 16px)")};
  border-radius: 1.111vw;
  border: 0.069vw solid #eaeaea;
  background: #fff;
  box-shadow: 0px 0.278vw 1.389vw 0px rgba(227, 227, 227, 0.25);
  padding: 1.389vw;
  margin: 1.111vw ${(props) => (props.isList ? "0" : "0.556vw")} 0;
  display: flex;
  flex-wrap: ${(props) => (props.isList ? "unset" : "wrap")};
  cursor: ${(props) => (props.isList ? "default" : "pointer")};
  &:hover {
    border: 0.069vw solid #febc11;
  }
  & .pointer {
    cursor: pointer;
  }
  @media ${device.desktopL} {
    width: ${(props) => (props.isList ? "100%" : "calc(25% - 16px)")};
    border-radius: 16px;
    border: 1px solid #eaeaea;
    box-shadow: 0px 4px 20px 0px rgba(227, 227, 227, 0.25);
    padding: 20px;
    margin: 16px ${(props) => (props.isList ? "0" : "8px")} 0;
    &:hover {
      border: 1px solid #febc11;
    }
  }
  @media ${device.desktopLL} {
    width: ${(props) => (props.isList ? "100%" : "calc(50% - 16px)")};
    padding: ${(props) => (props.isList ? "20px" : "16px")};
  }
  @media ${device.laptop} {
    width: ${(props) => (props.isList ? "100%" : "calc(50% - 16px)")};
    // margin: 16px 0 0px;
    flex-wrap: wrap;
  }
  @media ${device.mobileLL} {
    width: 100%;
    flex-wrap: wrap;
  }
  .list {
    &__cover {
      position: relative;
      width: 5.556vw;
      height: 5.556vw;
      border-radius: 0.556vw;
      margin: 0 1.667vw 0 0;
      overflow: hidden;
      > img {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        object-fit: cover;
      }
      @media ${device.desktopL} {
        width: 80px;
        height: 80px;
        border-radius: 8px;
        margin: 0 24px 0 0;
      }
      @media ${device.laptop} {
        margin: 0 14px 0 0;
      }
    }
    &__left {
      width: calc(
        ${(props) => (props.isList ? "45%" : "100%")} - 5.556vw - 1.667vw
      );
      padding: 0 ${(props) => (props.isList ? "1.667vw" : "0")} 0 0;
      border-right: ${(props) =>
        props.isList ? "0.069vw solid #e4e4e4" : "none"};
      @media ${device.desktopL} {
        width: calc(
          ${(props) => (props.isList ? "45%" : "100%")} - 80px - 24px
        );
        padding: 0 ${(props) => (props.isList ? "24px" : "0")} 0 0;
        border-right: ${(props) =>
          props.isList ? "1px solid #e4e4e4" : "none"};
      }
      @media ${device.laptop} {
        width: calc(
          ${(props) => (props.isList ? "100%" : "100%")} - 80px - 14px
        );
        border-right: none;
        padding: 0;
      }
      @media ${device.mobileLL} {
        width: ${(props) =>
          props.isList
            ? "calc(100% - 80px - 14px)"
            : "calc(100% - 80px - 14px)"};
        margin: ${(props) => (props.isList ? "0" : "0")} 0 0;
      }
    }
    &__name {
      width: 100%;
      display: flex;
      align-items: flex-start;
      > div {
        max-width: calc(100% - 1.736vw - 0.972vw);
        margin: 0 0.972vw 0 0;
      }
      > img {
        width: 1.736vw;
      }
      @media ${device.desktopL} {
        > div {
          max-width: calc(100% - 25px - 14px);
          margin: 0 14px 0 0;
        }
        > img {
          width: 25px;
        }
      }
    }
    &__text-group {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0.833vw 0 0;
      @media ${device.desktopL} {
        margin: 12px 0 0;
      }
    }
    &__text {
      width: 48%;
      display: flex;
      align-items: flex-start;
      > img {
        width: 1.111vw;
        margin: 0 0.417vw 0 0;
      }
      > div {
        width: calc(100% - 1.528vw);
      }
      &--sp {
        width: 100%;
      }
      @media ${device.desktopL} {
        > img {
          width: 16px;
          margin: 0 6px 0 0;
        }
        > div {
          width: calc(100% - 22px);
        }
      }
    }
    &__right {
      width: ${(props) => (props.isList ? "55%" : "100%")};
      padding: ${(props) => (props.isList ? "0" : "0.833vw")} 0 0
        ${(props) => (props.isList ? "1.667vw" : "7.361vw")};
      > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      @media ${device.desktopL} {
        padding: ${(props) => (props.isList ? "0" : "12px")} 0 0
          ${(props) => (props.isList ? "24px" : "106px")};
      }
      @media ${device.laptop} {
        width: ${(props) => (props.isList ? "100%" : "100%")};
        padding: ${(props) => (props.isList ? "0" : "12px")} 0 0
          ${(props) => (props.isList ? "0" : "96px")};
        margin: ${(props) => (props.isList ? "12px" : "0")} 0 0;
      }
      @media ${device.tablet} {
        padding: ${(props) => (props.isList ? "0" : "12px")} 0 0
          ${(props) => (props.isList ? "0" : "0")};
      }
      @media ${device.mobileLL} {
        width: 100%;
        padding: 0;
        margin: 16px 0 0;
        > div {
          flex-wrap: wrap;
        }
      }
    }
    &__status {
      width: ${(props) => (props.isList ? "calc(100% - 7.639vw)" : "100%")};
      @media ${device.desktopL} {
        width: ${(props) => (props.isList ? "calc(100% - 110px)" : "100%")};
        padding: ${(props) => (props.isList ? "0 20px 0 0" : "0")};
      }
      @media ${device.mobileLL} {
        width: 100%;
      }
    }
    &__status-group {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &__status-render {
      width: calc(95% / 3);
      & .size-20 {
        line-height: 1.2;
      }
    }
    &__btn {
      display: ${(props) => (props.isList ? "flex" : "none")};
      align-items: center;
      justify-content: center;
      width: 7.639vw;
      height: 2.778vw;
      border-radius: 0.556vw;
      border: 0.069vw solid #b7b7b7;
      cursor: pointer;
      @media ${device.desktopL} {
        width: 110px;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #b7b7b7;
      }
      @media ${device.laptop} {
        position: ${(props) => (props.isList ? "absolute" : "relative")};
        right: ${(props) => (props.isList ? "16px" : "unset")};
        top: ${(props) => (props.isList ? "50%" : "unset")};
        transform: ${(props) =>
          props.isList ? "translate(0, -50%)" : "unset"};
      }
      @media ${device.mobileLL} {
        margin: ${(props) => (props.isList ? "12px" : "0")} 0
          ${(props) => (props.isList ? "0 auto" : "0")};
        position: relative;
        right: unset;
        top: unset;
        transform: unset;
      }
    }
    &__group {
      width: 100%;
      max-width: ${(props) => (props.isList ? "318px" : "unset")};
      margin: 0.556vw 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        width: 1.806vw;
        padding-top: 1.806vw;
      }
      @media ${device.desktopL} {
        margin: 8px 0 0;
        > div {
          width: 26px;
          padding-top: 26px;
        }
      }
      @media ${device.laptopL} {
        > div {
          width: 33px;
          padding-top: 33px;
        }
      }
      @media ${device.tabletL} {
        > div {
          width: 28px;
          padding-top: 28px;
        }
      }
      @media ${device.tablet} {
        > div {
          width: 33px;
          padding-top: 33px;
        }
      }
    }
  }
`;
interface IStyle {
  active: boolean;
  isError?: boolean;
}
const Mode = styled.div<IStyle>`
  & .animate-flicker {
    -webkit-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
  }
  position: relative;
  background: ${(props) =>
    props.active
      ? props.isError
        ? "rgba(255, 221, 221, 0.3)"
        : "#eef6ff"
      : "rgba(208, 209, 211, 0.4)"};
  border-radius: 0.417vw;
  > img {
    position: absolute;
    width: 58%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media ${device.desktopL} {
    border-radius: 6px;
  }
`;
const Headline = styled.div`
  width: 100%;
  margin: 1.25vw 0 0;
  display: flex;
  align-items: center;
  @media ${device.desktopL} {
    margin: 18px 0 0;
  }
  @media ${device.laptop} {
    flex-wrap: wrap;
    margin: 0;
    justify-content: space-between;
  }
  @media ${device.mobileLL} {
    justify-content: unset;
  }
  .headline {
    &__left {
      width: calc(100% - 12.5vw - 1.111vw - 5.833vw);
      display: flex;
      align-items: center;
      @media ${device.desktopL} {
        width: calc(100% - 180px - 16px - 84px);
      }
      @media ${device.laptop} {
        width: auto;
        flex-wrap: wrap;
      }
      @media ${device.mobileLL} {
        width: 100%;
        margin: 0 0 10px;
      }
    }
    &__head {
      @media (max-width: 395px) {
        margin: 0 22px 0 0;
      }
    }
  }
`;
const Filter = styled.div`
  position: relative;
  z-index: 999;
  width: 100%;
  background: #ffffff;
  margin: 1.25vw 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.desktopL} {
    margin: 18px 0 0;
  }
  @media ${device.laptop} {
    flex-wrap: wrap;
  }
  .filter {
    &__box {
      &--1 {
        width: 28%;
      }
      &--2 {
        width: 21%;
      }
      @media ${device.laptop} {
        width: calc(50% - 8px);
        margin: 0 0 16px;
      }
      @media ${device.mobileLL} {
        width: 100%;
      }
    }
  }
`;
const Container = styled.div<IList>`
  width: cal(100% + 4.444vw);
  margin: -2.222vw -2.222vw 0;
  @media ${device.desktopL} {
    width: cal(100% + 64px);
    margin: -32px -32px 0;
  }
  .detail {
    &__btn {
      width: 12.5vw;
      margin: 0 auto;
      font-family: "SukhumvitSet-Bold", sans-serif;
      font-weight: 500;
      @media ${device.desktopL} {
        width: 180px;
      }
    }
    &__head {
      border-radius: 2.778vw 0px 0px 0;
      padding: 2.222vw 2.222vw 1.389vw;
      background: #ffffff;
      @media ${device.desktopL} {
        border-radius: 40px 0px 0px 0;
        padding: 32px 32px 20px;
      }
      @media ${device.laptop} {
        border-radius: 0px;
        padding: 20px 32px;
        // top: -140px !important;
      }
      @media ${device.mobileLL} {
        padding: 18px;
        // top: -274px !important;
        // top: -356px !important;
      }
    }
    &__top {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      @media ${device.mobileLL} {
        flex-wrap: wrap;
      }
    }
    &__text {
      display: flex;
      align-items: center;
      line-height: 3.75vw;
      > img {
        width: 1.528vw;
        margin: 0 0.208vw 0 0;
      }
      > span {
        margin: 0 0 0 0.139vw;
      }
      &--hide {
        opacity: 0;
      }
      &--show {
        opacity: 1;
      }
      &--tablet {
        display: none;
      }
      @media ${device.desktopL} {
        line-height: 54px;
        > span {
          margin: 0 0 0 2px;
        }
        > img {
          width: 22px;
          margin: 0 3px 0 0;
        }
      }
      @media ${device.laptop} {
        &--tablet {
          display: flex;
        }
        &--show {
          display: none;
        }
        &--hide {
          display: none;
        }
      }
    }
    &__warp {
      min-height: 70vh;
      &--100 {
        display: flex;
        align-items: center;
      }
    }
    &__content {
      width: ${(props) => (props.isList ? "100%" : "calc(100% + 16px)")};
      padding: 0.556vw 2.778vw 1.667vw;
      margin: 0 ${(props) => (props.isList ? "0" : "-0.556vw")};
      display: ${(props) => (props.isList ? "block" : "flex")};
      flex-wrap: ${(props) => (props.isList ? "unset" : "wrap")};
      @media ${device.desktopL} {
        padding: 8px 40px 24px;
        margin: 0 ${(props) => (props.isList ? "0" : "-8px")};
      }
      @media ${device.mobileLL} {
        width: 100%;
        padding: 8px 18px 24px;
        margin: 0;
      }
    }
  }
`;
