import styled from "styled-components";

import { colors } from "@constants/colors";

interface IButtonBlock {
  className?: string;
  bgColor?: string;
  bgColorDisable?: string;
  text: string;
  icon?: string;
  iconSuffix?: string;
  disable?: boolean;
  onClick?: () => void;
}

export default function ButtonBlock({
  className = "",
  bgColor = colors.ppDark,
  bgColorDisable = colors.gray02,
  text = "",
  icon = "",
  iconSuffix = "",
  disable = false,
  onClick = () => {},
}: IButtonBlock) {
  return (
    <Button
      className={className}
      bgColor={bgColor}
      disable={disable}
      bgColorDisable={bgColorDisable}
      onClick={onClick}
    >
      {icon && <img src={icon} />}
      {text}
      {iconSuffix && <img src={iconSuffix} />}
    </Button>
  );
}

interface IButton {
  bgColor: string;
  bgColorDisable: string;
  disable: boolean;
}

const Button = styled.div<IButton>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 56px;
  background: ${(props) =>
    props.disable ? props.bgColorDisable : props.bgColor};
  border-radius: 30px;
  color: #fff;
  font-family: "Mitr", sans-serif;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  cursor: ${(props) => (props.disable ? "" : "pointer")};
  & img {
    width: 16px;
    margin: 0 4px 0 0;
    display: block;
  }
`;
