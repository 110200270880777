import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

import reducers from "../reduxs/Reducers";

export default function configureStore() {
  const persistConfig = {
    key: "root",
    storage,
    whitelist: ["storage"],
  };

  const persistorReducer = persistReducer(persistConfig, reducers);

  const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const middlewares = [thunk];

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));

  const store = createStore(persistorReducer as any, enhancer);

  const persistor = persistStore(store);

  return { store, persistor };
}
