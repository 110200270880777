import { Ttheme, TLang, TSound, TView, TSort } from "../interface";

export function switchThemeAction(data: Ttheme) {
  return {
    type: "switch-theme",
    data: data,
  };
}

export function switchLanguageAction(data: TLang) {
  return {
    type: "switch-language",
    data: data,
  };
}

export function switchSoundAction(data: TSound) {
  return {
    type: "switch-sound",
    data: data,
  };
}

export function switchViewAction(data: TView) {
  return {
    type: "switch-view",
    data: data,
  };
}

export function switchSortAction(data: TSort) {
  return {
    type: "switch-sort",
    data: data,
  };
}
