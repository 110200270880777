import { IInfo } from "../interface";

export function isLoginAction(data: boolean) {
  return {
    type: "is_login",
    data: data,
  };
}

export function infoAction(data: IInfo) {
  return {
    type: "info",
    data: data,
  };
}
