import Cookies from "js-cookie";

export const SignOut = () => {
  Cookies.remove("jwt_token_control");
  Cookies.remove("jwt_refresh_token_control");
  window.location.href = "/signin";
};
export const SignOutChangePassword = () => {
  Cookies.remove("jwt_token_control");
  Cookies.remove("jwt_refresh_token_control");
  window.location.href = "/signin?from=changepassword";
};
