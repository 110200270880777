export const CheckValue = (e: string) => {
  if (!e || !e.split(" ").join("")) {
    return false;
  } else {
    return true;
  }
};

export const CheckValueNumber = (e: any) => {
  if (!e || !e.split(" ").join("") || e === "0") {
    return false;
  } else {
    return true;
  }
};

export const textLengthIsOk = (
  value: string,
  minLength = 8,
  maxLength = 20
) => {
  const val = String(value);
  return val.length >= minLength && val.length <= maxLength;
};

export const textContainsUppercase = (value: string) => {
  return String(value).match(/(?=.*[A-Z])/);
};

export const textContainsLowercase = (value: string) => {
  return String(value).match(/(?=.*[a-z])/);
};

export const textContainsNumber = (value: string) => {
  return String(value).match(/(?=.*[0-9])/);
};

export const textContainsSpecialCharactor = (value: string) => {
  return String(value).match(/[!@#$%^&*()_+~\-=\[\]`{};':"\\|,.<>\/?]+/);
};
