import {
  categoryInterface,
  receivedBoxInterface,
  selectedBuyInterface,
  cartInterface,
  itemsInterface,
} from "../interface";

export function loadingAction(data: boolean) {
  return {
    type: "loading",
    data: data,
  };
}

export function categoryAction(data: categoryInterface) {
  return {
    type: "category",
    data: data,
  };
}

export function openBoxStepAction(data: number) {
  return {
    type: "open_box_step",
    data: data,
  };
}

export function selectedBoxAction(data: number) {
  return {
    type: "selected_box",
    data: data,
  };
}

export function receivedBoxAction(data: receivedBoxInterface[]) {
  return {
    type: "received_box",
    data: data,
  };
}

export function selectedBuyAction(data: selectedBuyInterface) {
  return {
    type: "selected_buy",
    data: data,
  };
}

export function marqueeAction(data: string[]) {
  return {
    type: "marquee",
    data: data,
  };
}

export function cartAction(data: cartInterface[]) {
  return {
    type: "cart",
    data: data,
  };
}

export function resalePercentAction(data: string) {
  return {
    type: "resale_percent",
    data: data,
  };
}

export function shippingPriceAction(data: string) {
  return {
    type: "shipping_price",
    data: data,
  };
}

export function selectedResaleOrShippingAction(data: string[]) {
  return {
    type: "selected_resale_shipping",
    data: data,
  };
}

export function goBackAction(data: string) {
  return {
    type: "goback",
    data: data,
  };
}

export function itemsAction(data: itemsInterface[]) {
  return {
    type: "items",
    data: data,
  };
}

export function tabOrderAction(data: string) {
  return {
    type: "tab_order",
    data: data,
  };
}

export function playAnimationAction(data: number) {
  return {
    type: "play_animation",
    data: data,
  };
}
