import React, { useLayoutEffect } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import _, { forEach } from "lodash";

import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

//chart type
//import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5radar from "@amcharts/amcharts5/radar";

import * as am5xy from "@amcharts/amcharts5/xy";

//import am5themes_Micro from "@amcharts/amcharts5/themes/Micro";
dayjs.extend(customParseFormat);

const Temperature = ({ datas }) => {
  const chartID = "props.chartID";

  useLayoutEffect(() => {
    let root = am5.Root.new(chartID);
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);
    root.dateFormatter.setAll({
      dateFormat: "DD/MM/YYYY HH:mm",
      dateFields: ["valueX"],
    });

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        pinchZoomX: true,
        layout: root.verticalLayout
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    let cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.5,
        baseInterval: {
          timeUnit: "minute",
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          pan: "zoom",
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: am5xy.AxisRendererY.new(root, {
          pan: "zoom",
        }),
      })
    );

    // const values = ["highestTemp", "insideTemp", "lowestTemp"];
    const values = [
      {
        value: "highestTemp",
        label: "High Temp Alarm",
        color: "red",
      },
      {
        value: "insideTemp",
        label: "Room Temp",
        color: "rgb(35, 149, 255)",
      },
      {
        value: "setPointTemp",
        label: "Set Point Temp Alarm",
        color: "blue",
      },
      {
        value: "lowestTemp",
        label: "Low Temp Alarm",
        color: "red",
      },
    ];

    values.forEach((val) => {
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: val.label,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value",
          valueXField: "date",
          // tooltip: am5.Tooltip.new(root, {
          //   labelText: "{valueY}",
          // }),
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{valueY}",
          }),
        })
      );

      series.strokes.template.setAll({
        templateField: "strokeSettings",
        strokeWidth: 2,
        strokeDasharray: val.value === "setPointTemp" ? [10, 10] : [0],
      });

      // series.fills.template.setAll({
      //   visible: true,
      //   fillOpacity: 0.2,
      // });

      // series.bullets.push(function () {
      //   return am5.Bullet.new(root, {
      //     locationY: 0,
      //     sprite: am5.Circle.new(root, {
      //       radius: 4,
      //       stroke: root.interfaceColors.get("background"),
      //       strokeWidth: 2,
      //       fill: series.get("fill"),
      //     }),
      //   });
      // });

      const arr = (datas ?? []).map((e) => {
        return {
          date: dayjs(e?.time).format("DD/MM/YYYY HH:mm"),
          dateString: dayjs(e?.time).format("DD/MM/YYYY HH:mm"),
          value: e?.[val.value] ?? 0,
          strokeSettings: {
            stroke: val.color,
          },
        };
      });
      series.data.processor = am5.DataProcessor.new(root, {
        dateFormat: "DD/MM/YYYY HH:mm",
        dateFields: ["date"],
      });
      // console.log("arr", arr);
      series.data.setAll(arr);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear(1000);
    });

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
      })
    );

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    let legend = chart.children.unshift(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        layout: root.gridLayout,
      })
    );

    // // When legend item container is hovered, dim all the series except the hovered one
    // legend.itemContainers.template.events.on("pointerover", function (e) {
    //   let itemContainer = e.target;

    //   // As series list is data of a legend, dataContext is series
    //   let series = itemContainer.dataItem.dataContext;

    //   chart.series.each(function (chartSeries) {
    //     if (chartSeries != series) {
    //       chartSeries.strokes.template.setAll({
    //         strokeOpacity: 0.15,
    //         stroke: am5.color(0x000000),
    //       });
    //     } else {
    //       chartSeries.strokes.template.setAll({
    //         strokeWidth: 3,
    //       });
    //     }
    //   });
    // });

    // // When legend item container is unhovered, make all series as they are
    // legend.itemContainers.template.events.on("pointerout", function (e) {
    //   let itemContainer = e.target;
    //   let series = itemContainer.dataItem.dataContext;

    //   chart.series.each(function (chartSeries) {
    //     chartSeries.strokes.template.setAll({
    //       strokeOpacity: 1,
    //       strokeWidth: 1,
    //       stroke: chartSeries.get("fill"),
    //     });
    //   });
    // });

    // legend.itemContainers.template.set("width", am5.p100);
    // legend.valueLabels.template.setAll({
    //   width: am5.p100,
    //   textAlign: "right",
    // });

    // It's is important to set legend data after all the events are set on template, otherwise events won't be copied
    legend.data.setAll(chart.series.values);

    chart.appear(1000, 100);
  }, [chartID]);

  return (
    <Container>
      <div id={chartID}></div>
    </Container>
  );
};
export default Temperature;

const Container = styled.div`
  width: 100%;
  > div {
    height: 40vh;
  }
`;
