import { Istorage } from "../interface";

const initState = {
  theme: "dark",
  language: "th",
  sound: "off",
  view: "list",
  sort: "char_asc",
};

const store = (state = initState, action: { type: string; data: Istorage }) => {
  switch (action.type) {
    case "switch-theme":
      return { ...state, theme: action.data };
    case "switch-language":
      return { ...state, language: action.data };
    case "switch-sound":
      return { ...state, sound: action.data };
    case "switch-view":
      return { ...state, view: action.data };
    case "switch-sort":
      return { ...state, sort: action.data };
    default:
      return state;
  }
};

export default store;
