import styled, { createGlobalStyle } from "styled-components";
import { useState, useEffect, useCallback, useRef } from "react";
import SwitchSelector from "react-switch-selector";
import { useSelector, useDispatch } from "react-redux";
import { Layout, Switch, Spin } from "antd";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { socket } from "../../socket";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useSound from "use-sound";
import { soundList } from "@constants/soundList";
import { LoadingOutlined } from "@ant-design/icons";
import { useWindowSize } from "react-use";

import { device } from "@constants/styles";
import { colors } from "@constants/colors";
import { imgList } from "@constants/imgList";
import PauseScreen from "@components/PauseScreen";
import {
  ModalLogout,
  ModalReadAll,
  ModalDeleteAccount,
} from "@components/Modals";
import { dateConvertDiff, dateConvert } from "@utils/date";
import { apiGet, apiPost, apiPut, apiDelete } from "@apis/gateway";
import { SignOut } from "@utils/signout";
import {
  switchThemeAction,
  switchSoundAction,
} from "@reduxs/Actions/storageAction";
import { infoAction } from "@reduxs/Actions/userAction";
import {
  notificationAction,
  monitoringAction,
  collapsedAction,
  pauseAction,
  deviceDetailAction,
  dataDevicesAction,
  permissionNotiAction,
  isPlayAction,
  isSoundPlayingAction,
} from "@reduxs/Actions/monitoringAction";
import { stateInterface, notificationInterface } from "@reduxs/interface";
import { requestForToken, onMessageListener } from "../../firebase-config";

const { Sider } = Layout;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function LayoutDefault({ children }: any) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [collapsed, setCollapsed] = useState(false);
  const [collapsedMobile, setCollapsedMobile] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const [showNoti, setShowNoti] = useState(false);
  const [showLogout, setShowLogout] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const [showReadAll, setShowReadAll] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);

  const [page, setPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const [userID, setUserID] = useState<string>("");
  const [loadSound, setLoadSound] = useState(false);
  const [notiLoading, setNotiloading] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [play, { stop }] = useSound(soundList.beeps, {
    loop: true,
    interrupt: true,
    onload: () => setLoadSound(true),
  });

  const theme = useSelector((state: stateInterface) => state.storage.theme);
  const loading = useSelector((state: stateInterface) => state.store.loading);
  const language = useSelector(
    (state: stateInterface) => state.storage.language
  );
  const sound = useSelector(
    (state: stateInterface) => state?.storage?.sound ?? "off"
  );
  const isPlay = useSelector(
    (state: stateInterface) => state.monitoring.isPlay
  );
  const info = useSelector((state: stateInterface) => state.user.info);
  const notification = useSelector(
    (state: stateInterface) => state.monitoring.notification
  );
  const mapLocationDevices = useSelector(
    (state: stateInterface) => state.monitoring.mapLocationDevices
  );
  const monitoring = useSelector(
    (state: stateInterface) => state.monitoring.monitoring
  );
  const pause = useSelector((state: stateInterface) => state.monitoring.pause);
  const deviceDetail = useSelector(
    (state: stateInterface) => state.monitoring.deviceDetail
  );
  const dataDevices = useSelector(
    (state: stateInterface) => state.monitoring.dataDevices
  );
  const updateDate = useSelector(
    (state: stateInterface) => state.monitoring.updateDate
  );
  const isSoundPlaying = useSelector(
    (state: stateInterface) => state.monitoring.isSoundPlaying
  );
  const options = [
    {
      label: (
        <div className="sw__choose">
          <img
            src={imgList[theme === "light" ? "icoLightActive" : "icoLight"]}
            alt=""
          />
          <div
            className={`font-inter weight-600 size-14 ${
              theme === "light" ? "color-blackd1f" : "color-gray67e "
            }`}
          >
            Light
          </div>
        </div>
      ),
      value: "light",
    },
    {
      label: (
        <div className="sw__choose">
          <img
            src={imgList[theme === "dark" ? "icoDarkActive" : "icoDark"]}
            alt=""
          />
          <div
            className={`font-inter weight-600 size-14 ${
              theme === "dark" ? "color-whitecfc" : "color-gray67e"
            }`}
          >
            Dark
          </div>
        </div>
      ),
      value: "dark",
    },
  ];

  const myElementRef = useRef(null);
  const [positionTop, setPositionTop] = useState(0);
  const [positionLeft, setPositionLeft] = useState(0);
  const { width } = useWindowSize();
  const interval = useRef<any>(null);

  useEffect(() => {
    if (seconds >= 15) {
      stopCounter();
      dispatch(pauseAction(true));
    }
  }, [seconds]);

  const startCounter = () =>
    (interval.current = setInterval(() => {
      setSeconds((prevState) => prevState + 1);
    }, 1000));

  const stopCounter = () => {
    clearInterval(interval.current);
    setSeconds(0);
  };

  useEffect(() => {
    if (width <= 1024) {
      setCollapsed(false);
    }
  }, [width]);

  useEffect(() => {
    const handleScroll = () => {
      const el: any = myElementRef.current;
      setPositionTop(el?.scrollTop);
      setPositionLeft(el?.scrollLeft);
    };

    const element: any = myElementRef.current;
    element.addEventListener("scroll", handleScroll);

    return () => {
      element.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = pause ? "hidden" : "unset";
  }, [pause]);

  const handleOnDocumentBottom = useCallback(() => {
    // console.log("I am at bottom! " + Math.round(performance.now()));
    // getNoti();
  }, [page]);

  const handleContainerOnBottom = useCallback(() => {
    // console.log(
    //   "I am at bottom in optional container! " + Math.round(performance.now())
    // );
    getNoti();
  }, [page]);

  useBottomScrollListener(handleOnDocumentBottom);

  const scrollRef = useBottomScrollListener(handleContainerOnBottom);

  const onChangeSwitch = (newValue: any) => {
    dispatch(switchThemeAction(newValue));
  };

  useEffect(() => {
    if (mapLocationDevices.length > 0 && userID) {
      const onEmit = (e: any) => {
        // console.log("socketEmit");
      };
      const onConnect = () => {
        // console.log("socketIsconnect");
        stopCounter();
      };
      const onDisconnect = (reason: any) => {
        // console.log("socketIsDisconnect", reason);
        // dispatch(pauseAction(true));
        startCounter();
      };
      const onNotificationEvent = (value: any) => {
        // console.log("onNotificationEvent", value);
        setTotal(value?.count ?? 0);
      };
      const onDeviceEvent = (value: any) => {
        // console.log("onDeviceEvent", value);
        // console.log("onDeviceEvent");
        if (location.pathname.includes("/device-detail")) {
          if ((value?.deviceInfo?.macAddress ?? "") === id) {
            dispatch(
              deviceDetailAction({
                ...deviceDetail,
                ...value,
              })
            );
          }
        } else if (location.pathname === "/devices") {
          // console.log("onDeviceEvent");
          let arr = dataDevices;
          const find = _.findIndex(arr, function (o) {
            return (
              o?.deviceInfo?.macAddress === value?.deviceInfo?.macAddress ?? ""
            );
          });
          arr[find] = {
            ...arr[find],
            ...value,
          };
          dispatch(dataDevicesAction([...arr]));
        } else {
          const find = _.findIndex(mapLocationDevices, function (o) {
            return o.macAddress === value?.deviceInfo?.macAddress ?? "";
          });
          if (find > -1) {
            let tmp: any[] = monitoring;
            tmp[mapLocationDevices?.[find]?.index1].devices[
              mapLocationDevices?.[find]?.index2
            ] = {
              ...value,
              isOwner:
                tmp[mapLocationDevices?.[find]?.index1].devices[
                  mapLocationDevices?.[find]?.index2
                ].isOwner ?? false,
            };
            dispatch(monitoringAction([...tmp]));
          }
        }
      };

      // const rooms = mapLocationDevices.map((e) => {
      //   return "device-" + e?.macAddress;
      // });

      socket.emit("join", userID, onEmit);

      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("notification", onNotificationEvent);
      socket.on("devices", onDeviceEvent);
      return () => {
        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("devices", onDeviceEvent);
      };
    }
  }, [mapLocationDevices, userID]);

  useEffect(() => {
    firebase();
  }, []);

  useEffect(() => {
    setDropdown(location.pathname === "/profile" ? true : false);
    setShowNoti(false);
    setShowSupport(false);
    setCollapsedMobile(true);
  }, [location.pathname]);

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    dispatch(collapsedAction(collapsed));
  }, [collapsed]);

  useEffect(() => {
    if (loadSound) {
      if (isPlay) {
        play();
      } else {
        stop();
      }
    }
  }, [loadSound, isPlay]);

  const firebase = async () => {
    const res = await requestForToken();
    const params = {
      token: res.message,
      device: "web",
    };
    if (res.status) {
      dispatch(permissionNotiAction(true));
      const resRegister = await apiPost(
        `${process.env.REACT_APP_API_DOMAIN}/messaging/device`,
        { ...params },
        {},
        language
      );
      if (resRegister.status) {
      }
    } else {
      dispatch(permissionNotiAction(false));
    }
  };

  onMessageListener()
    .then((payload) => {
      // console.log("payload", payload);
      const title = payload?.notification?.title ?? "";
      const body = payload?.notification?.body ?? "";
      const deviceLocationId = payload?.data?.deviceLocationId ?? "";
      const deviceMacAddress = payload?.data?.deviceMacAddress ?? "";
      const type = payload?.data?.actionType ?? "info";
      // const isAlarm =
      //   (payload?.data?.type ?? "") === "errorAlarm" ? true : false;
      if (type === "danger") {
        // if (isAlarm && sound === "on" && !isSoundPlaying) {
        //   dispatch(isPlayAction(true));
        //   dispatch(isSoundPlayingAction(true));
        // }
        notifyDanger({
          title: title,
          body: body,
          macAddress: deviceMacAddress,
          locationID: deviceLocationId,
        });
      } else if (type === "warning") {
        notifyWarning({
          title: title,
          body: body,
          macAddress: deviceMacAddress,
          locationID: deviceLocationId,
        });
      } else {
        notifyInfo({
          title: title,
          body: body,
          macAddress: deviceMacAddress,
          locationID: deviceLocationId,
        });
      }
    })
    .catch((err) => console.log("failed: ", err));

  const initData = async () => {
    const token: any = jwt_decode(Cookies?.get("jwt_token_control") ?? "");
    const params = {
      refresh_token: Cookies?.get("jwt_refresh_token_control") ?? "",
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };
    setUserID(token?.user_id ?? "");
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/users/${token?.user_id ?? ""}`,
      {},
      language
    );
    const resReToken = await apiPost(
      `${process.env.REACT_APP_API_DOMAIN}/authentications/refreshtoken`,
      { ...params },
      {},
      language
    );
    if (res.status && res.code === 200) {
      dispatch(
        infoAction({
          email: res?.data?.data?.email ?? "",
          user_fullname: res?.data?.data?.user_fullname ?? "",
          user_firstname: res?.data?.data?.user_firstname ?? "",
          user_lastname: res?.data?.data?.user_firstname ?? "",
          image: {
            id: res?.data?.data?.image?.id ?? "",
            url: res?.data?.data?.image?.url ?? "",
            updatedAt: res?.data?.data?.image?.updatedAt ?? null,
          },
          image_id: {
            id: res?.data?.data?.image_id?.id ?? "",
            url: res?.data?.data?.image_id?.url ?? "",
            updatedAt: res?.data?.data?.image_id?.updatedAt ?? null,
          },
        })
      );
    }
    if (resReToken.status && resReToken.code === 201) {
      Cookies.set("jwt_token_control", resReToken.data?.data?.token ?? "", {
        expires: parseInt(process.env?.REACT_APP_JWT_EXP ?? "7"),
      });
      Cookies.set(
        "jwt_refresh_token_control",
        resReToken.data?.data?.refresh_token ?? "",
        {
          expires: parseInt(process.env?.REACT_APP_JWT_REFRESH_EXP ?? "14"),
        }
      );
    }
    getNoti();
  };
  const getNoti = async () => {
    if (page >= totalPage) {
      return;
    }
    const res = await apiGet(
      `${
        process.env.REACT_APP_API_DOMAIN
      }/monitoring-api/notification/notification-tab?page=${page + 1}&limit=20`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      dispatch(
        notificationAction([
          ...notification,
          ...(res?.data?.data?.data ?? []).map((e: notificationInterface) => {
            return {
              createdAt: e?.createdAt ?? null,
              detail: e?.detail ?? "",
              id: e?.id ?? "",
              linkTo: e?.linkTo ?? "",
              read: e?.read ?? false,
              softDelete: false,
              title: e?.title ?? "",
              type: e?.type ?? "",
              updatedAt: e?.updatedAt ?? null,
              userId: e?.userId ?? "",
              deviceMacAddress: e?.deviceMacAddress ?? "",
            };
          }),
        ])
      );
      setPage(res?.data?.data?.page ?? 1);
      setTotalPage(res?.data?.data?.totalPages ?? 1);
      // setTotal(res?.data?.data?.totalResults ?? 0);
    }
  };
  const getNotiNew = async () => {
    setPage(0);
    setShowSupport(false);
    setShowNoti(!showNoti);
    setNotiloading(true);
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/notification/notification-tab?page=1&limit=20`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      dispatch(
        notificationAction([
          ...(res?.data?.data?.data ?? []).map((e: notificationInterface) => {
            return {
              createdAt: e?.createdAt ?? null,
              detail: e?.detail ?? "",
              id: e?.id ?? "",
              linkTo: e?.linkTo ?? "",
              read: e?.read ?? false,
              softDelete: false,
              title: e?.title ?? "",
              type: e?.type ?? "",
              updatedAt: e?.updatedAt ?? null,
              userId: e?.userId ?? "",
              deviceMacAddress: e?.deviceMacAddress ?? "",
            };
          }),
        ])
      );
      setPage(res?.data?.data?.page ?? 1);
      setTotalPage(res?.data?.data?.totalPages ?? 1);
    }
    setNotiloading(false);
  };
  const markAsRead = async (notiID: string, deviceMacAddress: string) => {
    await apiPut(
      `${process.env.REACT_APP_API_DOMAIN}/notification/${notiID}/mark-as-read`,
      {},
      {},
      language
    );
    setShowNoti(false);
    navigate(`/device-detail/${deviceMacAddress}`);
  };
  const handleReadAll = async () => {
    setShowReadAll(false);
    setShowNoti(false);
    await apiPut(
      `${process.env.REACT_APP_API_DOMAIN}/notification/mark-all-as-read`,
      {},
      {},
      language
    );
  };
  const mapTitle: any = {
    monitoring: {
      title: "CORNTROL - Monitoring",
    },
    dashboard: {
      title: "CORNTROL - Dashboard",
    },
    devices: {
      title: "CORNTROL - Devices",
    },
    support: {
      title: "CORNTROL - Support",
    },
    profile: {
      title: "CORNTROL - Profile",
    },
    // "account-setting": {
    //   title: "CORNTROL - Account Setting",
    // },
    "device-detail": {
      title: "CORNTROL - Device Detail",
    },
  };
  const notifySuccess = ({
    title = "",
    body = "",
    macAddress = "",
    locationID = "",
  }) =>
    toast(
      <div className="toast-config">
        <img
          src={imgList.toast_ico_success}
          alt=""
          className="toast-config__icon"
        />
        <div className="toast-config__detail">
          <div className="font-sukhumvitSet-bold size-14 color-greena48">
            {title}
          </div>
          <div className="font-sukhumvitSet-text size-14 color-grayf4f etc">
            {body}
          </div>
          <div
            className="toast-config__next"
            // onClick={() =>
            //   navigate(`/device-detail/${locationID}/${macAddress}`)
            // }
            onClick={() => navigate(`/device-detail/${macAddress}`)}
          >
            <div className="font-sukhumvitSet-bold size-14 color-greena48">
              View changes
            </div>
            <img src={imgList.toast_ico_next_success} alt="" />
          </div>
        </div>
        <img
          src={imgList.toast_ico_close_success}
          alt=""
          className="toast-config__arrow"
          onClick={() => {
            console.log("close");
          }}
        />
      </div>,
      {
        className: "toast-config-success",
      }
    );
  const notifyInfo = ({
    title = "",
    body = "",
    macAddress = "",
    locationID = "",
  }) =>
    toast(
      <div className="toast-config">
        <img
          src={imgList.toast_ico_info}
          alt=""
          className="toast-config__icon"
        />
        <div className="toast-config__detail">
          <div className="font-sukhumvitSet-bold size-14 color-blue5ff">
            {title}
          </div>
          <div className="font-sukhumvitSet-text size-14 color-grayf4f etc">
            {body}
          </div>
          <div
            className="toast-config__next"
            // onClick={() =>
            //   navigate(`/device-detail/${locationID}/${macAddress}`)
            // }
            onClick={() => navigate(`/device-detail/${macAddress}`)}
          >
            <div className="font-sukhumvitSet-bold size-14 color-blue5ff">
              View changes
            </div>
            <img src={imgList.toast_ico_next_info} alt="" />
          </div>
        </div>
        <img
          src={imgList.toast_ico_close_info}
          alt=""
          className="toast-config__arrow"
          onClick={() => {
            console.log("close");
          }}
        />
      </div>,
      {
        className: "toast-config-info",
      }
    );
  const notifyWarning = ({
    title = "",
    body = "",
    macAddress = "",
    locationID = "",
  }) =>
    toast(
      <div className="toast-config">
        <img
          src={imgList.toast_ico_warning}
          alt=""
          className="toast-config__icon"
        />
        <div className="toast-config__detail">
          <div className="font-sukhumvitSet-bold size-14 color-orange708">
            {title}
          </div>
          <div className="font-sukhumvitSet-text size-14 color-orange803 etc">
            {body}
          </div>
          <div
            className="toast-config__next"
            // onClick={() =>
            //   navigate(`/device-detail/${locationID}/${macAddress}`)
            // }
            onClick={() => navigate(`/device-detail/${macAddress}`)}
          >
            <div className="font-sukhumvitSet-bold size-14 color-orange708">
              View changes
            </div>
            <img src={imgList.toast_ico_next_warning} alt="" />
          </div>
        </div>
        <img
          src={imgList.toast_ico_close_warning}
          alt=""
          className="toast-config__arrow"
          onClick={() => {
            console.log("close");
          }}
        />
      </div>,
      {
        className: "toast-config-warning",
      }
    );
  const notifyDanger = ({
    title = "",
    body = "",
    macAddress = "",
    locationID = "",
  }) =>
    toast(
      <div className="toast-config">
        <img
          src={imgList.toast_ico_danger}
          alt=""
          className="toast-config__icon"
        />
        <div className="toast-config__detail">
          <div className="font-sukhumvitSet-bold size-14 color-red318">
            {title}
          </div>
          <div className="font-sukhumvitSet-text size-14 color-redd20 etc">
            {body}
          </div>
          <div
            className="toast-config__next"
            // onClick={() =>
            //   navigate(`/device-detail/${locationID}/${macAddress}`)
            // }
            onClick={() => navigate(`/device-detail/${macAddress}`)}
          >
            <div className="font-sukhumvitSet-bold size-14 color-red318">
              View changes
            </div>
            <img src={imgList.toast_ico_next_danger} alt="" />
          </div>
        </div>
        <img
          src={imgList.toast_ico_close_danger}
          alt=""
          className="toast-config__arrow"
          onClick={() => {
            console.log("close");
          }}
        />
      </div>,
      {
        className: "toast-config-danger",
      }
    );

  const handleDeleteAccount = async () => {
    const resDelete = await apiDelete(
      `${process.env.REACT_APP_API_DOMAIN}/users`,
      language
    );
    if (resDelete.status) {
      SignOut();
    }
  };
  return (
    <>
      <Helmet>
        <title>
          {mapTitle?.[location.pathname.split("/")?.[1] ?? ""]?.title ??
            "CORNTROL - Monitoring (ค่าเริ่มต้น)"}
        </title>
      </Helmet>
      {pause && <PauseScreen />}
      <Backdrop hide={!loading}>
        <Loading className="loading" color="#FFF4D9">
          <div className="loading__inner" />
        </Loading>
      </Backdrop>
      <Container
        className="layout"
        collapsed={collapsed}
        theme={theme}
        collapsedMobile={collapsedMobile}
        ref={myElementRef}
      >
        <GlobalStyles
          theme={theme}
          collapsed={collapsed}
          collapsedMobile={collapsedMobile}
          showNoti={showNoti}
        />
        <Noti className="noti" show={showNoti} collapsed={collapsed}>
          <div className="noti__top">
            <div className="font-sukhumvitSet-text weight-600 size-20 color-black">
              Notification
            </div>
            <div className="noti__top-right">
              <img
                className="noti__top-btn"
                src={imgList.readall}
                alt=""
                onClick={() => setShowReadAll(true)}
              />
              <img
                className="noti__top-close"
                src={imgList.ico_close}
                alt=""
                onClick={() => setShowNoti(false)}
              />
            </div>
          </div>
          <div className="noti__content" ref={scrollRef as any}>
            {notiLoading && (
              <NotiLoading>
                <Spin indicator={antIcon} />
              </NotiLoading>
            )}
            {!notiLoading ? (
              notification.length > 0 ? (
                notification.map((e, key) => {
                  return (
                    <ListNoti
                      className={`list ${key === 0 ? "first" : ""}`}
                      key={key}
                      onClick={() =>
                        markAsRead(e?.id ?? "", e?.deviceMacAddress ?? "")
                      }
                    >
                      {!e.read && <div className="list__dot" />}
                      <div className="list__left">
                        <div className="line-1 font-sukhumvitSet-text weight-500 size-14 color-graye6e">
                          {e.title}
                        </div>
                        <div className="list__text-detail line-2 font-sukhumvitSet-text weight-500 size-12 color-gray797">
                          {e.detail}
                        </div>
                      </div>
                      <div className="list__right">
                        <div className="font-sukhumvitSet-text weight-500 size-10 color-gray797">
                          {dateConvertDiff(e?.createdAt ?? "")}
                        </div>
                      </div>
                    </ListNoti>
                  );
                })
              ) : (
                <Empty>
                  <img src={imgList.icoFaq} alt="" />
                  <div className="color-black size-16 font-sukhumvitSet-bold">
                    ไม่พบอุปกรณ์ของคุณ
                  </div>
                </Empty>
              )
            ) : (
              <></>
            )}
          </div>
        </Noti>
        <Support className="support" show={showSupport} collapsed={collapsed}>
          <div className="support__top">
            <div className="font-sukhumvitSet-text weight-600 size-20 color-black">
              Support
            </div>
            <img
              src={imgList.ico_close}
              alt=""
              onClick={() => setShowSupport(false)}
            />
          </div>
          <div className="support__content">
            <div className="support__content-top">
              <img src={imgList.support_qrcode} alt="" />
              <div>
                <div className="font-sukhumvitSet-bold weight-700 color-gray333 size-18">
                  LINE:{" "}
                  <span className="font-sukhumvitSet-text weight-400">
                    @Corntrol
                  </span>
                </div>
                <a
                  href="https://line.me/R/ti/p/@559aueei?from=page&accountId=559aueei"
                  target="_blank"
                  className="support__btn"
                >
                  <div className="size-12 weight-600 font-sukhumvitSet-text color-black">
                    เพิ่มเพื่อน
                  </div>
                  <img src={imgList.ico_arrow_yellow} alt="" />
                </a>
              </div>
            </div>
            <div className="support__btn-side">
              {/* <div>
                <img src={imgList.ico_support_email} alt="" />
              </div> */}
              <img src={imgList.ico_support_email} alt="" />
              <div>
                <div className="color-gray270 size-16 font-sukhumvitSet-bold weight-600">
                  Email
                </div>
                <a
                  href="mailto:info@corntrol.com"
                  className="color-black size-16 font-sukhumvitSet-text"
                >
                  info@corntrol.com
                </a>
              </div>
            </div>
            <div className="support__btn-side">
              {/* <div>
                <img src={imgList.ico_support_email} alt="" />
              </div> */}
              <img src={imgList.ico_support_website} alt="" />
              <div>
                <div className="color-gray270 size-16 font-sukhumvitSet-bold weight-600">
                  Website
                </div>
                <a
                  href="https://corntrol.com/"
                  target="_blank"
                  className="color-black size-16 font-sukhumvitSet-text"
                >
                  www.corntrol.com
                </a>
              </div>
            </div>
            <div className="support__etc color-black size-18 font-sukhumvitSet-bold weight-600">
              เชื่อมต่อกับเรา
            </div>
            <div className="support__btn-group">
              <a
                href="https://www.facebook.com/corntrol.official"
                target="_blank"
              >
                <img src={imgList.ico_support_fb} alt="" />
              </a>
              <a
                href="https://www.instagram.com/corntrol.official"
                target="_blank"
              >
                <img src={imgList.ico_support_ig} alt="" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCf7qRu3zqEOwN-eY_Qx0S4g"
                target="_blank"
              >
                <img src={imgList.ico_support_yt} alt="" />
              </a>
            </div>
          </div>
        </Support>
        <HeaderMobile className="header" theme={theme}>
          <div className="header__top">
            <div
              className="header__menu"
              onClick={() => {
                setCollapsedMobile(!collapsedMobile);
                // setDropdown(false);
              }}
            >
              <img
                src={theme === "light" ? imgList.icoMenu2 : imgList.icoMenu}
                alt=""
              />
            </div>
            <div className="header__logo">
              <img
                src={theme === "light" ? imgList.logo : imgList.logo2}
                alt=""
              />
            </div>
            <div className="header__logo-mobile">
              <img
                src={
                  theme === "light" ? imgList.logo_small : imgList.logo2_small
                }
                alt=""
              />
            </div>
            {positionTop >= 60 && (
              <div className="header__text font-sukhumvitSet-text size-14">
                <img
                  src={theme === "light" ? imgList.ico_clock : imgList.clock}
                  alt=""
                />
                <span
                  className={`${
                    theme === "light" ? "color-black" : "color-white"
                  }`}
                >
                  {dateConvert(updateDate)}
                </span>
              </div>
            )}
          </div>
        </HeaderMobile>
        <Layout>
          <Sider
            className="layout__left"
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <ContainerSideBar
              className="sidebar"
              collapsed={collapsed}
              collapsedMobile={collapsedMobile}
              theme={theme}
            >
              <div className="sidebar__top">
                <div className="sidebar__logo">
                  <img
                    src={theme === "light" ? imgList.logo : imgList.logo2}
                    alt=""
                    // onClick={notify}
                  />
                </div>
                <div
                  className="sidebar__menu"
                  onClick={() => {
                    setCollapsed(!collapsed);
                    // setDropdown(false);
                  }}
                >
                  <img
                    src={theme === "light" ? imgList.icoMenu2 : imgList.icoMenu}
                    alt=""
                  />
                </div>
              </div>
              {collapsed && (
                <img
                  src={
                    theme === "light" ? imgList.logo_small : imgList.logo2_small
                  }
                  alt=""
                  className="sidebar__logo-small"
                />
              )}
              <MainMenu
                className="menu"
                collapsed={collapsed}
                theme={theme}
                collapsedMobile={collapsedMobile}
              >
                <div
                  className="menu__profile"
                  onClick={() => {
                    setDropdown(!dropdown);
                    setCollapsed(false);
                  }}
                >
                  <div className="menu__avatar">
                    <img
                      src={
                        info?.image?.url ?? ""
                          ? info?.image?.url ?? ""
                          : imgList.avatar
                      }
                      alt=""
                    />
                  </div>
                  <div className="menu__name">
                    <div
                      className={`size-14 font-inter weight-500 ${
                        theme === "light" ? "color-black" : "color-white"
                      }`}
                    >
                      {/* {(info?.user_firstname ?? "") ||
                      (info?.user_lastname ?? "")
                        ? `${info?.user_firstname ?? ""} ${
                            info?.user_lastname ?? ""
                          }`
                        : "-"} */}
                      {/* {info?.email?.split("@")?.[0] ?? "-"} */}
                      {info?.user_fullname ?? "-"}
                    </div>
                    <div
                      className={`size-14 font-inter weight-400 ${
                        theme === "light" ? "color-grayd9d" : "color-white4f7"
                      }`}
                    >
                      {info?.email ?? "-"}
                    </div>
                  </div>
                  <div className="menu__arrow">
                    <img
                      src={
                        theme === "light"
                          ? imgList.arrowLight
                          : imgList.arrowDark
                      }
                      alt=""
                    />
                  </div>
                </div>
                {dropdown && !collapsed && (
                  <div className="menu__drop">
                    <div
                      className={`menu__drop-name size-15 weight-600 font-inter ${
                        location.pathname === "/profile"
                          ? "color-primary"
                          : theme === "light"
                          ? "color-black"
                          : "color-gray67e"
                      }`}
                      onClick={() => {
                        navigate("/profile");
                        setCollapsedMobile(true);
                      }}
                    >
                      Profile
                    </div>
                    {/* <div
                      className={`menu__drop-name size-15 weight-600 font-inter ${
                        theme === "light" ? "color-black" : "color-gray67e"
                      }`}
                      onClick={() => {
                        navigate("/account-setting");
                        setCollapsedMobile(true);
                      }}
                    >
                      Account settings
                    </div> */}
                    <div
                      className={`menu__drop-name size-15 weight-600 font-inter ${
                        theme === "light" ? "color-black" : "color-gray67e"
                      }`}
                      onClick={() => {
                        setShowDeleteAccount(true);
                        setCollapsedMobile(true);
                      }}
                    >
                      Delete account
                    </div>
                    <div
                      className={`menu__drop-name size-15 weight-600 font-inter ${
                        theme === "light" ? "color-black" : "color-gray67e"
                      }`}
                      onClick={() => {
                        setShowLogout(true);
                        setCollapsedMobile(true);
                      }}
                    >
                      Sign out
                    </div>
                  </div>
                )}
                <div
                  className="menu__list menu__border"
                  onClick={() => {
                    setCollapsedMobile(true);
                    if (showNoti) {
                      setShowNoti(!showNoti);
                    } else {
                      getNotiNew();
                    }
                  }}
                >
                  <img
                    src={
                      theme === "light"
                        ? imgList.icoMenuBellLight
                        : imgList.icoMenuBellDark
                    }
                    alt=""
                  />
                  {!collapsed && (
                    <div
                      className={`weight-500 size-14 font-inter ${
                        theme === "light" ? "color-black909" : "color-white4f7"
                      }`}
                    >
                      Notification
                    </div>
                  )}
                  {total > 0 && (
                    <div className="menu__dot size-12 color-white">
                      {total >= 1000 ? "999+" : total}
                    </div>
                  )}
                </div>
                <div className="menu__line" />
                <Link
                  to="/monitoring"
                  className={`menu__list ${
                    location.pathname === "/monitoring" && !showSupport
                      ? "active"
                      : ""
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/monitoring" && !showSupport
                        ? imgList.icoMenuMoniActive
                        : theme === "light"
                        ? imgList.icoMenuMoniLight
                        : imgList.icoMenuMoniDark
                    }
                    alt=""
                  />
                  {!collapsed && (
                    <div
                      className={`weight-500 size-14 font-inter ${
                        location.pathname === "/monitoring" && !showSupport
                          ? "color-black"
                          : theme === "light"
                          ? "color-black747"
                          : "color-white4f7"
                      }`}
                    >
                      Monitoring
                    </div>
                  )}
                </Link>
                <Link
                  to="/devices"
                  className={`menu__list ${
                    (location.pathname === "/devices" ||
                      location.pathname.includes("device-detail")) &&
                    !showSupport
                      ? "active"
                      : ""
                  }`}
                >
                  <img
                    src={
                      (location.pathname === "/devices" ||
                        location.pathname.includes("device-detail")) &&
                      !showSupport
                        ? imgList.icoMenuDeviceActive
                        : theme === "light"
                        ? imgList.icoMenuDeviceLight
                        : imgList.icoMenuDeviceDark
                    }
                    alt=""
                  />
                  {!collapsed && (
                    <div
                      className={`weight-500 size-14 font-inter ${
                        (location.pathname === "/devices" ||
                          location.pathname.includes("device-detail")) &&
                        !showSupport
                          ? "color-black"
                          : theme === "light"
                          ? "color-black747"
                          : "color-white4f7"
                      }`}
                    >
                      Devices
                    </div>
                  )}
                </Link>
                <Link
                  to="/dashboard"
                  className={`menu__list ${
                    location.pathname === "/dashboard" && !showSupport
                      ? "active"
                      : ""
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/dashboard" && !showSupport
                        ? imgList.icoMenuDashActive
                        : theme === "light"
                        ? imgList.icoMenuDashLight
                        : imgList.icoMenuDashDark
                    }
                    alt=""
                  />
                  {!collapsed && (
                    <div
                      className={`weight-500 size-14 font-inter ${
                        location.pathname === "/dashboard" && !showSupport
                          ? "color-black"
                          : theme === "light"
                          ? "color-black747"
                          : "color-white4f7"
                      }`}
                    >
                      Dashboard
                    </div>
                  )}
                </Link>
                <div className="menu__line" />
                <div
                  className={`menu__list ${showSupport ? "active" : ""}`}
                  onClick={() => {
                    setShowNoti(false);
                    setShowSupport(!showSupport);
                    setCollapsedMobile(true);
                  }}
                >
                  <img
                    src={
                      showSupport
                        ? imgList.icoMenuSupportActive
                        : theme === "light"
                        ? imgList.icoMenuSupportLight
                        : imgList.icoMenuSupportDark
                    }
                    alt=""
                  />
                  {!collapsed && (
                    <div
                      className={`weight-500 size-14 font-inter ${
                        showSupport
                          ? "color-black"
                          : theme === "light"
                          ? "color-black747"
                          : "color-white4f7"
                      }`}
                    >
                      Support
                    </div>
                  )}
                </div>
              </MainMenu>
              <div className="sidebar__bottom">
                <SoundWarp
                  theme={theme}
                  collapsed={collapsed}
                  collapsedMobile={collapsedMobile}
                >
                  <div
                    className={`font-inter weight-600 size-14 ${
                      theme === "dark" ? "color-whitecfc" : "color-gray67e"
                    }`}
                  >
                    Sound
                  </div>
                  <Switch
                    checked={sound === "on" ? true : false}
                    onChange={(e) =>
                      dispatch(switchSoundAction(e ? "on" : "off"))
                    }
                  />
                </SoundWarp>
                <SwitchWarp
                  className="sw"
                  theme={theme}
                  collapsed={collapsed}
                  collapsedMobile={collapsedMobile}
                >
                  <SwitchSelector
                    forcedSelectedIndex={options.findIndex(
                      ({ value }) => value === theme
                    )}
                    onChange={onChangeSwitch}
                    options={options}
                    backgroundColor={"#353b48"}
                    fontColor={"#f5f6fa"}
                  />
                </SwitchWarp>
                <img
                  src={
                    sound === "on"
                      ? theme === "light"
                        ? imgList.icoSoundOnLight
                        : imgList.icoSoundOn
                      : theme === "light"
                      ? imgList.icoSoundOffLight
                      : imgList.icoSoundOff
                  }
                  alt=""
                  className="sidebar__switch"
                  onClick={() =>
                    dispatch(switchSoundAction(sound === "on" ? "off" : "on"))
                  }
                />
                <img
                  src={
                    theme === "light"
                      ? imgList.icoLightMobile
                      : imgList.icoDarkMobile
                  }
                  alt=""
                  className="sidebar__switch"
                  onClick={() =>
                    dispatch(
                      switchThemeAction(theme === "light" ? "dark" : "light")
                    )
                  }
                />
                {!collapsed && (
                  <div
                    className={`sidebar__etc size-14 weight-400 font-inter text-center ${
                      theme === "light" ? "color-blackf0f" : "color-white"
                    }`}
                  >
                    © 2023 MONITORING FROM CORNTROL{" "}
                    {process.env.REACT_APP_VERSION}
                  </div>
                )}
              </div>
            </ContainerSideBar>
          </Sider>
          <Layout className="layout__right">{children}</Layout>
        </Layout>
        <ModalLogout
          open={showLogout}
          onOk={() => {
            setShowLogout(false);
            SignOut();
          }}
          onClose={() => setShowLogout(false)}
        />
        <ModalDeleteAccount
          open={showDeleteAccount}
          onOk={() => {
            setShowDeleteAccount(false);
            handleDeleteAccount();
          }}
          onClose={() => setShowDeleteAccount(false)}
        />
        <ModalReadAll
          open={showReadAll}
          onOk={() => handleReadAll()}
          onClose={() => setShowReadAll(false)}
        />
        <ToastWarp>
          <ToastContainer
            autoClose={10000}
            hideProgressBar={true}
            enableMultiContainer
          />
        </ToastWarp>
      </Container>
    </>
  );
}
interface IstyleGlobal {
  collapsed: boolean;
  collapsedMobile: boolean;
  theme: string;
  showNoti: boolean;
}
interface Istyle {
  collapsed: boolean;
  collapsedMobile: boolean;
  theme: string;
}
interface IStyleBackdrop {
  hide: boolean;
}
interface IStyleLoading {
  color: string;
}
interface IStyleNoti {
  show: boolean;
  collapsed: boolean;
}
interface IHeaderMobile {
  theme: string;
}
const Support = styled.div<IStyleNoti>`
  position: fixed;
  width: 27.222vw;
  top: 0;
  left: ${(props) => (props.collapsed ? "5.556vw" : "19.444vw")};
  height: 100vh;
  z-index: 9999;
  background: #f8f8f8;
  box-shadow: 0px 2.778vw 4.444vw -0.833vw rgba(0, 0, 0, 0.08),
    0px 0px 0.972vw -0.278vw rgba(0, 0, 0, 0.05),
    0px 2.222vw 3.333vw -0.556vw rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.111vw);
  border-radius: 1.111vw;
  padding: 3.472vw 1.667vw 1.667vw;
  display: ${(props) => (props.show ? "block" : "none")};
  @media ${device.desktopL} {
    width: 392px;
    left: ${(props) => (props.collapsed ? "80px" : "280px")};
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08),
      0px 0px 14px -4px rgba(0, 0, 0, 0.05),
      0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(16px);
    border-radius: 16px;
    padding: 50px 24px 24px;
  }
  @media ${device.laptop} {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 999999;
    border-radius: 0;
  }
  .support {
    &__top {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.833vw 1.667vw;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > img {
        width: 1.667vw;
        cursor: pointer;
      }
      @media ${device.desktopL} {
        padding: 12px 24px;
        > img {
          width: 24px;
        }
      }
    }
    &__content {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    &__content-top {
      width: 100%;
      display: flex;
      align-items: center;
      > img {
        width: 140px;
        margin: 0 16px 0 0;
      }
      > div {
        width: calc(100% - 140px - 16px);
      }
    }
    &__btn {
      display: inline-flex;
      align-items: center;
      background: #ffffff;
      border: 0.139vw solid #ffe6a4;
      box-shadow: 0px 0.278vw 0.972vw rgba(165, 165, 165, 0.08);
      border-radius: 3.472vw;
      padding: 0.278vw 0.694vw;
      margin: 0.833vw 0px 0px;
      cursor: pointer;
      > div {
        line-height: 1.3;
        margin: 1px 0 0;
      }
      > img {
        width: 1.389vw;
        display: block;
        margin: 0 0 0 0.417vw;
      }
      @media ${device.desktopL} {
        border: 2px solid #ffe6a4;
        box-shadow: 0px 4px 14px rgba(165, 165, 165, 0.08);
        border-radius: 50px;
        padding: 4px 10px;
        margin: 12px 0px 0px;
        > div {
          margin: 1px 0 0;
        }
        > img {
          width: 20px;
          margin: 0 0 0 6px;
        }
      }
    }
    &__btn-group {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 14px 0 0;
      > a {
        width: 42px;
        margin: 0 24px 0 0;
        display: block;
        > img {
          width: 100%;
        }
      }
    }
    &__btn-side {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 24px 0 0;
      > img {
        width: 48px;
        margin: 0 10px 0 0;
        display: block;
      }
      > div {
        width: calc(100% - 48px - 10px);
      }
    }
    &__etc {
      margin: 24px 0 0;
    }
  }
`;
const Empty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 20px;
    display: block;
    margin: -4px 0 0;
  }
`;
const NotiLoading = styled.div`
  width: 100%;
  text-align: center;
`;
const SoundWarp = styled.div<Istyle>`
  width: 100%;
  height: 32px;
  background: ${(props) =>
    props.theme === "light" ? colors.white4f4 : "#111315"};
  border-radius: 18px;
  margin: 0 0 6px;
  display: ${(props) => (props.collapsed ? "none" : "flex")};
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  & .ant-switch {
    background-color: #bfbfbf;
  }
  & .ant-switch-checked {
    background-color: #febc11;
  }
  @media ${device.laptop} {
    max-width: 350px;
    height: 38px;
    margin: 0 auto 6px;
  }
  @media ${device.mobileLL} {
    max-width: unset;
    height: 38px;
    margin: 0 0 6px;
  }
`;

const ToastWarp = styled.div`
  position: relative;
  z-index: 99999;
  & .Toastify__toast-container {
    right: 28px;
    top: 28px;
  }
  & .Toastify__toast {
    border-radius: 8px;
    padding: 16px;
  }
  & .toast-config-success {
    background: #f6fef9;
    border: 1px solid #6ce9a6;
  }
  & .toast-config-info {
    background: #fcfcfd;
    border: 1px solid #00b8d9;
  }
  & .toast-config-danger {
    border: 1px solid #fda29b;
    background: #fffbfa;
  }
  & .toast-config-warning {
    border: 1px solid #fec84b;
    background: #fffcf5;
  }
  & .Toastify__close-button {
    display: none;
  }
  & .Toastify__toast-body {
    padding: 0;
  }
  .toast-config {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    &__icon {
      width: 20px;
      margin: 0 12px 0 0;
    }
    &__arrow {
      width: 20px;
      margin: 0 0 0 12px;
      cursor: pointer;
    }
    &__detail {
      width: calc(100% - 20px - 20px - 12px - 12px);
      > div {
        line-height: 1.2;
      }
      & .etc {
        margin: 4px 0 12px;
      }
    }
    &__next {
      display: flex;
      align-items: center;
      width: 100%;
      > img {
        width: 20px;
        margin: 0 0 0 8px;
      }
    }
  }
`;

const Backdrop = styled.div<IStyleBackdrop>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: ${(props) => (props.hide ? `none` : `flex`)};
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const Loading = styled.div<IStyleLoading>`
  @keyframes load {
    100% {
      transform: rotate(360deg);
    }
  }
  width: 6.944vw;
  height: 6.944vw;
  border: 0.347vw solid transparent;
  border-top: 0.347vw solid ${(props) => props.color};
  border-bottom: 0.347vw solid ${(props) => props.color};
  border-radius: 3.819vw;
  animation: load 1.5s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${device.desktopL} {
    width: 100px;
    height: 100px;
    border: 5px solid transparent;
    border-top: 5px solid ${(props) => props.color};
    border-bottom: 5px solid ${(props) => props.color};
    border-radius: 55px;
  }
  .loading {
    &__inner {
      width: 4.861vw;
      height: 4.861vw;
      border: 0.347vw solid transparent;
      border-left: 0.347vw solid ${(props) => props.color};
      border-right: 0.347vw solid ${(props) => props.color};
      border-radius: 2.778vw;
      animation: load 0.75s linear infinite reverse;
      @media ${device.desktopL} {
        width: 70px;
        height: 70px;
        border: 5px solid transparent;
        border-left: 5px solid ${(props) => props.color};
        border-right: 5px solid ${(props) => props.color};
        border-radius: 40px;
      }
    }
  }
`;
export const GlobalStyles = createGlobalStyle<IstyleGlobal>`
body {
  overflow: ${(props) =>
    props.showNoti || !props.collapsedMobile ? "hidden" : "unset"} !important;
  background: ${(props) =>
    props.theme === "light" ? colors.white : colors.blue828};
}
`;
const HeaderMobile = styled.div<IHeaderMobile>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  background: ${(props) =>
    props.theme === "light" ? colors.white : colors.blue828};
  z-index: 99999;
  display: none;
  @media ${device.laptop} {
    display: block;
  }
  .header {
    &__top {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 20px;
    }
    &__logo {
      width: 163px;
      cursor: pointer;
      > img {
        width: 100%;
      }
      @media ${device.mobileLL} {
        display: none;
      }
    }
    &__logo-mobile {
      width: 48px;
      display: none;
      cursor: pointer;
      > img {
        width: 100%;
      }
      @media ${device.mobileLL} {
        display: block;
      }
    }
    &__menu {
      width: 24px;
      cursor: pointer;
      margin: 0 12px 0 0;
      > img {
        width: 100%;
      }
    }
    &__text {
      width: calc(100% - 163px - 24px - 12px);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      > img {
        width: 1.528vw;
        margin: 0 0.208vw 0 0;
      }
      > span {
        margin: 0 0 0 0.139vw;
      }
      &--hide {
        opacity: 0;
      }
      &--show {
        opacity: 1;
      }
      @media ${device.desktopL} {
        > span {
          margin: 0 0 0 2px;
        }
        > img {
          width: 22px;
          margin: 0 3px 0 0;
        }
      }
      @media ${device.mobileLL} {
        width: calc(100% - 48px - 24px - 12px);
      }
    }
  }
`;
const ListNoti = styled.div`
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 0.556vw;
  margin: 1.111vw 0 0;
  padding: 1.111vw;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  &.first {
    margin: 0;
  }
  @media ${device.desktopL} {
    border-radius: 8px;
    margin: 16px 0 0;
    padding: 16px;
  }
  .list {
    &__left {
      width: calc(100% - 20% - 1.111vw);
      margin: 0 1.111vw 0 0;
      @media ${device.desktopL} {
        width: calc(100% - 20% - 16px);
        margin: 0 16px 0 0;
      }
    }
    &__right {
      width: 20%;
      text-align: right;
    }
    &__text-detail {
      margin: 0.278vw 0 0;
      line-height: 1.5;
      @media ${device.desktopL} {
        margin: 4px 0 0;
      }
    }
    &__dot {
      position: absolute;
      background: #fd4e51;
      border-radius: 50%;
      width: 0.417vw;
      height: 0.417vw;
      right: 0.556vw;
      top: 0.556vw;
      @media ${device.desktopL} {
        width: 6px;
        height: 6px;
        right: 8px;
        top: 8px;
      }
    }
  }
`;
const Noti = styled.div<IStyleNoti>`
  position: fixed;
  width: 27.222vw;
  top: 0;
  left: ${(props) => (props.collapsed ? "5.556vw" : "19.444vw")};
  height: 100vh;
  z-index: 9999;
  background: #f8f8f8;
  box-shadow: 0px 2.778vw 4.444vw -0.833vw rgba(0, 0, 0, 0.08),
    0px 0px 0.972vw -0.278vw rgba(0, 0, 0, 0.05),
    0px 2.222vw 3.333vw -0.556vw rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(1.111vw);
  border-radius: 1.111vw;
  padding: 3.472vw 1.667vw 1.667vw;
  display: ${(props) => (props.show ? "block" : "none")};
  @media ${device.desktopL} {
    width: 392px;
    left: ${(props) => (props.collapsed ? "80px" : "280px")};
    box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08),
      0px 0px 14px -4px rgba(0, 0, 0, 0.05),
      0px 32px 48px -8px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(16px);
    border-radius: 16px;
    padding: 50px 24px 24px;
  }
  @media ${device.laptop} {
    width: 100%;
    z-index: 999999;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 0;
  }
  // & .List {
  //   width: 100% !important;
  //   height: 100% !important;
  // }
  .noti {
    &__top {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0.833vw 1.667vw;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > img {
        width: 1.667vw;
        cursor: pointer;
      }
      @media ${device.desktopL} {
        > img {
          width: 24px;
        }
      }
    }
    &__top-right {
      display: flex;
      align-items: center;
    }
    &__top-close {
      width: 1.667vw;
      cursor: pointer;
      @media ${device.desktopL} {
        width: 24px;
      }
    }
    &__top-btn {
      width: 60px;
      margin: 0 16px 0 0;
      cursor: pointer;
    }
    &__content {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
  }
`;
const MainMenu = styled.div<Istyle>`
  width: 100%;
  margin: 1.389vw 0 0;
  @media ${device.desktopL} {
    margin: 20px 0 0;
  }
  .menu {
    &__drop {
      width: 100%;
      background: ${(props) => (props.theme === "light" ? "#ECECEC" : "#000")};
      border-radius: 1.111vw;
      padding: 1.111vw;
      margin: 0 0 1.111vw;
      display: ${(props) => (props.collapsed ? "none" : "block")};
      @media ${device.desktopL} {
        border-radius: 16px;
        padding: 16px;
        margin: 0 0 16px;
      }
    }
    &__drop-name {
      height: 3.472vw;
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 0.833vw;
      cursor: pointer;
      @media ${device.desktopL} {
        height: 50px;
        padding: 0 12px;
      }
    }
    &__profile {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 0.694vw 0 1.25vw;
      cursor: pointer;
      @media ${device.desktopL} {
        margin: 10px 0 18px;
      }
    }
    &__avatar {
      position: relative;
      width: 2.778vw;
      height: 2.778vw;
      overflow: hidden;
      border-radius: 50%;
      margin: 0 ${(props) => (props.collapsed ? "auto" : "0")};
      > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        max-width: unset;
        max-height: unset;
        object-fit: cover;
      }
      @media ${device.desktopL} {
        width: 40px;
        height: 40px;
      }
    }
    &__name {
      width: calc(100% - 2.778vw - 1.667vw - 1.667vw);
      margin: 0 0.833vw;
      display: ${(props) => (props.collapsed ? "none" : "block")};
      > div {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      @media ${device.desktopL} {
        width: calc(100% - 40px - 24px - 24px);
        margin: 0 12px;
      }
    }
    &__arrow {
      width: 1.667vw;
      display: ${(props) => (props.collapsed ? "none" : "block")};
      > img {
        width: 100%;
      }
      @media ${device.desktopL} {
        width: 24px;
      }
    }
    &__line {
      margin: 1.667vw 0;
      border-bottom: 0.069vw solid
        ${(props) =>
          props.theme === "light"
            ? "rgba(0, 0, 0, 0.17)"
            : "rgba(255, 255, 255, 0.17)"};
      @media ${device.desktopL} {
        margin: 24px 0;
        border-bottom: 1px solid
          ${(props) =>
            props.theme === "light"
              ? "rgba(0, 0, 0, 0.17)"
              : "rgba(255, 255, 255, 0.17)"};
      }
    }
    &__list {
      position: relative;
      width: 100%;
      height: 3.472vw;
      display: flex;
      align-items: center;
      border-radius: 0.417vw;
      padding: 0 0.833vw;
      cursor: pointer;
      > img {
        width: 1.667vw;
        margin: 0 ${(props) => (props.collapsed ? "auto" : "0")};
      }
      > div {
        margin: 0 0 0 0.833vw;
      }
      &.active {
        background: ${colors.primary};
      }
      @media ${device.desktopL} {
        height: 50px;
        border-radius: 6px;
        padding: 0 12px;
        > img {
          width: 24px;
        }
        > div {
          margin: 0 0 0 12px;
        }
      }
    }
    &__border {
      border: ${(props) => (props.collapsed ? "0" : "0.069vw")} solid
        ${(props) =>
          props.theme === "light"
            ? "rgba(83, 83, 83, 0.21)"
            : "rgba(255, 255, 255, 0.21)"};
      border-radius: 0.556vw;
      @media ${device.desktopL} {
        border: ${(props) => (props.collapsed ? "0" : "1px")} solid
          ${(props) =>
            props.theme === "light"
              ? "rgba(83, 83, 83, 0.21)"
              : "rgba(255, 255, 255, 0.21)"};
        border-radius: 8px;
      }
    }
    &__dot {
      position: absolute;
      transform: translate(0, -50%);
      top: ${(props) => (props.collapsed ? "30%" : "50%")};
      right: ${(props) => (props.collapsed ? "0.556vw" : "0.833vw")};
      padding: 0.069vw ${(props) => (props.collapsed ? "0.347vw" : "0.521vw")};
      background: #f04438;
      border-radius: 6.944vw;
      text-align: center;
      margin: 0 !important;
      font-size: ${(props) =>
        props.collapsed ? "0.556vw" : "0.833vw"} !important;
      @media ${device.desktopL} {
        right: ${(props) => (props.collapsed ? "8px" : "12px")};
        padding: 1px ${(props) => (props.collapsed ? "5px" : "7.5px")};
        border-radius: 100px;
        font-size: ${(props) => (props.collapsed ? "8px" : "12px")} !important;
      }
    }
  }
`;
const SwitchWarp = styled.div<Istyle>`
  width: 100%;
  & .react-switch-selector-wrapper {
    background: ${(props) =>
      props.theme === "light" ? colors.white4f4 : "#111315"};
  }
  & .react-switch-selector-wrapper::before {
    background: ${(props) =>
      props.theme === "light" ? colors.whitecfc : "#272b30"};
    border: none;
    box-shadow: ${(props) =>
      props.theme === "light"
        ? `0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)`
        : `0px 3.71233px 7.42466px -3.71233px
    rgba(0, 0, 0, 0.25),
  inset 0px -0.928082px 0.928082px rgba(0, 0, 0, 0.49),
  inset 0px 1.85616px 0.928082px rgba(255, 255, 255, 0.06)`};
  }
  display: ${(props) => (props.collapsed ? "none" : "block")};
  @media ${device.desktopL} {
    & .react-switch-selector-wrapper::before {
      box-shadow: ${(props) =>
        props.theme === "light"
          ? `0px 4px 8px -4px rgba(0, 0, 0, 0.25), inset 0px -1px 1px rgba(0, 0, 0, 0.04), inset 0px 2px 0px rgba(255, 255, 255, 0.25)`
          : `0px 3.71233px 7.42466px -3.71233px
      rgba(0, 0, 0, 0.25),
    inset 0px -0.928082px 0.928082px rgba(0, 0, 0, 0.49),
    inset 0px 1.85616px 0.928082px rgba(255, 255, 255, 0.06)`};
    }
  }
  @media ${device.laptop} {
    max-width: 350px;
    height: 38px;
    margin: 0 auto 6px;
  }
  @media ${device.mobileLL} {
    max-width: unset;
    height: 38px;
    margin: 0 0 6px;
  }
  .sw {
    &__choose {
      width: 100%;
      height: 2.222vw;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        width: 1.528vw;
        margin: 0 0.556vw 0 0;
      }
      @media ${device.desktopL} {
        height: 32px;
        > img {
          width: 22px;
          margin: 0 8px 0 0;
        }
      }
    }
  }
`;
const ContainerSideBar = styled.div<Istyle>`
  position: absolute;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  padding: 4% 1.111vw 50%;
  @media ${device.desktopL} {
    padding: 4% 16px 50%;
  }
  @media ${device.laptop} {
    padding: 0 16px 50%;
  }
  .sidebar {
    &__logo-small {
      width: 100%;
      margin-left: -8%;
    }
    &__top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: ${(props) =>
        props.collapsed ? "center" : "space-between"};
      @media ${device.laptop} {
        display: none;
      }
    }
    &__bottom {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 1%;
      padding: 4% 1.111vw;
      @media ${device.desktopL} {
        padding: 4% 16px;
      }
    }
    &__logo {
      width: 9.444vw;
      cursor: pointer;
      > img {
        width: 100%;
      }
      transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
        opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      opacity: ${(props) => (props.collapsed ? "0" : "blo1ck")};
      margin-left: ${(props) => (props.collapsed ? "-9.444vw" : "0")};
      @media ${device.desktopL} {
        width: 136px;
        margin-left: ${(props) => (props.collapsed ? "-136px" : "0")};
      }
    }
    &__menu {
      width: 1.667vw;
      cursor: pointer;
      margin: 0 ${(props) => (props.collapsed ? "auto" : "0")};
      > img {
        width: 100%;
      }
      @media ${device.desktopL} {
        width: 24px;
      }
    }
    &__etc {
      margin: 1.319vw 0 0;
      @media ${device.desktopL} {
        margin: 19px 0 0;
      }
    }
    &__switch {
      width: 2.778vw;
      margin: 0 auto;
      cursor: pointer;
      display: ${(props) => (props.collapsed ? "block" : "none")};
      @media ${device.desktopL} {
        width: 40px;
      }
    }
  }
`;

const Container = styled.div<Istyle>`
  width: 100%;
  height: 100vh;
  overflow: scroll;
  @media ${device.laptop} {
    padding: 80px 0 0;
  }
  & .ant-menu {
    background: ${(props) =>
      props.theme === "light" ? colors.white : colors.blue828} !important;
  }
  & .ant-layout {
    background: ${(props) =>
      props.theme === "light" ? colors.white : colors.blue828};
  }
  & .ant-layout-sider {
    z-index: 9999;
  }
  .layout {
    &__left {
      position: fixed;
      left: 0px;
      top: 0px;
      bottom: 0px;
      flex: 0 0 ${(props) => (props.collapsed ? "5.556vw" : "19.444vw")} !important;
      max-width: ${(props) =>
        props.collapsed ? "5.556vw" : "19.444vw"} !important;
      min-width: ${(props) =>
        props.collapsed ? "5.556vw" : "19.444vw"} !important;
      width: ${(props) =>
        props.collapsed ? "5.556vw" : "19.444vw"} !important;
      height: 100vh;
      overflow: auto;
      background: ${(props) =>
        props.theme === "light" ? colors.white : colors.blue828};
      @media ${device.desktopL} {
        flex: 0 0 ${(props) => (props.collapsed ? "80px" : "280px")} !important;
        max-width: ${(props) =>
          props.collapsed ? "80px" : "280px"} !important;
        min-width: ${(props) =>
          props.collapsed ? "80px" : "280px"} !important;
        width: ${(props) => (props.collapsed ? "80px" : "280px")} !important;
      }
      @media ${device.laptop} {
        width: 100% !important;
        height: calc(100% - 80px);
        max-width: unset !important;
        top: 80px;
        transform: translate(
          0,
          ${(props) => (props.collapsedMobile ? "-200%" : "0")}
        );
      }
    }
    &__right {
      // overflow: hidden;
      margin-left: ${(props) => (props.collapsed ? "5.556vw" : "19.444vw")};
      min-height: 100vh;
      background: ${colors.white8fd};
      border-radius: 2.778vw 0px 0px 2.778vw;
      padding: 2.222vw 2.222vw;
      transition: 0.3s ease all;
      @media ${device.desktopL} {
        margin-left: ${(props) => (props.collapsed ? "80px" : "280px")};
        border-radius: 40px 0px 0px 40px;
        padding: 32px 32px;
      }
      @media ${device.laptop} {
        margin-left: 0;
        border-radius: 0;
      }
    }
  }
`;
