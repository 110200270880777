const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  mobileLL: "500px",
  tablet: "768px",
  tabletL: "850px",
  laptop: "1024px",
  laptopSL: "1200px",
  laptopL: "1440px",
  desktopLL: "1600px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileLL: `(max-width: ${size.mobileLL})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletL: `(max-width: ${size.tabletL})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  laptopSL: `(max-width: ${size.laptopSL})`,
  desktopLL: `(max-width: ${size.desktopLL})`,
  desktop: `(max-width: ${size.desktop})`,
  desktopL: `(max-width: ${size.desktop})`,
};
