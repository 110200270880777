import styled from "styled-components";
import { Table as TableAntd } from "antd";

const Table = ({ ...rest }) => {
  return (
    <TableWarp>
      <TableAntd {...rest} />
    </TableWarp>
  );
};

export default Table;

const TableWarp = styled.div`
  width: 100%;
  & .ant-table-thead > tr > th {
    background: #eaeaea;
  }
  &
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    content: unset;
  }
  & .ant-table-container,
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }
  & .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  & .ant-table-tbody tr:nth-child(odd) {
    background: #fff;
  }
  & .ant-table-tbody tr:nth-child(even) {
    background: #fafafa;
  }
  & .ant-pagination-item-active a {
    color: rgba(0, 0, 0, 0.85);
  }
  & .ant-pagination-item-active {
    background: #febc11;
    border-color: #febc11;
  }
  & .ant-pagination-item:hover {
    background: #febc11;
    border-color: #febc11;
  }
  & .ant-pagination-item:hover a {
    color: rgba(0, 0, 0, 0.85);
  }
  & .ant-pagination-next:focus-visible .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link,
  .ant-pagination-prev:focus-visible .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link {
    color: rgba(0, 0, 0, 0.85);
    border-color: #febc11;
    background: #febc11;
  }
  & .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #febc11;
  }
  & .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #febc11;
  }
`;
