import styled from "styled-components";
import { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import { useMedia } from "react-use";

import { imgList } from "@constants/imgList";
import Tooltip from "@components/Tooltip";
import { device } from "@constants/styles";

export default function GoogleMap({
  selectedDevice,
  setSelectedDevice,
  devices = [],
  showFull = false,
  onMute,
}) {
  const isMobile = useMedia("(max-width: 1024px)");
  const [currentLocation, setCurrentLocation] = useState({ lat: 0, lng: 0 });
  const [zoom, setZoom] = useState(null);
  const [opacity, setOpacity] = useState(1);
  const defaultProps = {
    center: {
      lat: 13.7387289,
      lng: 100.5715471,
    },
    zoom: 6,
  };

  useEffect(() => {
    if (
      selectedDevice.macAddress &&
      selectedDevice.lat !== 0 &&
      selectedDevice.lng !== 0
    ) {
      // setOpacity(0);
      setZoom(15);
      setCurrentLocation({ lat: selectedDevice.lat, lng: selectedDevice.lng });
    }
  }, [selectedDevice]);

  const onGoogleApiLoaded = (map, maps, places) => {
    // navigator?.geolocation.getCurrentPosition(
    //   ({ coords: { latitude: lat, longitude: lng } }) => {
    //     const pos = { lat, lng };
    //     setCurrentLocation(pos);
    //   }
    // );
  };

  const renderCenter = () => {
    return currentLocation.lat !== 0 && currentLocation.lng !== 0
      ? currentLocation
      : null;
  };

  const Marker = ({
    macAddress = "",
    status = "",
    currentTemp = "",
    name = "",
    addressText = "",
    data = {},
  }) => {
    let img = "";
    switch (status) {
      case "normal":
        img =
          selectedDevice.macAddress === macAddress
            ? imgList.marker_normal_active
            : imgList.marker_normal;
        break;
      case "powerOff":
        img =
          selectedDevice.macAddress === macAddress
            ? imgList.marker_poweroff_active
            : imgList.marker_poweroff;
        break;
      case "offline":
        img =
          selectedDevice.macAddress === macAddress
            ? imgList.marker_offline_active
            : imgList.marker_offline;
        break;
      case "alarm":
        img =
          selectedDevice.macAddress === macAddress
            ? imgList.marker_alarm_active
            : imgList.marker_alarm;
        break;
      default:
        img =
          selectedDevice.macAddress === macAddress
            ? imgList.marker_normal_active
            : imgList.marker_normal;
        break;
    }

    const renderScale = () => {
      let scale = 1;
      switch (zoom) {
        case null:
          scale = 0.4;
          break;
        case 1:
          scale = 0.1;
          break;
        case 2:
          scale = 0.1;
          break;
        case 3:
          scale = 0.1;
          break;
        case 4:
          scale = 0.2;
          break;
        case 5:
          scale = 0.3;
          break;
        case 6:
          scale = 0.4;
          break;
        case 7:
          scale = 0.5;
          break;
        case 8:
          scale = 0.6;
          break;
        case 9:
          scale = 0.7;
          break;
        case 10:
          scale = 0.8;
          break;
        case 11:
          scale = 0.9;
          break;
        default:
          scale = 1;
          break;
      }
      return scale;
    };
    return (
      <MarkerContainer
        className="mark"
        style={{ opacity: opacity }}
        onClick={(e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          onChildClick(macAddress);
        }}
        active={selectedDevice.macAddress === macAddress}
        scale={renderScale()}
      >
        <img src={img} alt="" className="mark__marker" />
        {selectedDevice.macAddress === macAddress && (
          <img src={imgList.ico_marker_active} alt="" className="mark__tool" />
        )}
        {zoom >= 12 && selectedDevice.macAddress !== macAddress && (
          <div className="mark__title font-sukhumvitSet-text weight-700 color-black size-12">
            {data?.deviceInfo?.name ?? ""}
          </div>
        )}
        {zoom >= 12 && (
          <>
            <div className="mark__text color-white font-sukhumvitSet-text size-14">
              {(currentTemp ?? 0).toFixed(1)}
              <span className="size-12">°C</span>
            </div>
            {zoom >= 15 && (
              <Tooltip
                show={selectedDevice.macAddress === macAddress}
                addressText={addressText}
                name={name}
                showFull={showFull | isMobile}
                data={data}
                onMute={() => onMute(macAddress)}
              />
            )}
          </>
        )}
      </MarkerContainer>
    );
  };

  const onChildClick = (key) => {
    const filter = devices.filter((e) => e?.deviceInfo?.macAddress === key);
    setSelectedDevice({
      macAddress: key,
      lat: filter?.[0]?.deviceInfo?.coordinates?.[1] ?? 0,
      lng: filter?.[0]?.deviceInfo?.coordinates?.[0] ?? 0,
    });
  };

  return (
    <Container className="layout">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        zoom={zoom}
        onGoogleApiLoaded={onGoogleApiLoaded}
        center={renderCenter()}
        // onChildClick={onChildClick}
        yesIWantToUseGoogleMapApiInternals
        // options={{
        //   backgroundColor: "red",
        // }}
        onDrag={() => setOpacity(0)}
        onDragEnd={() => setOpacity(1)}
        // onChange={() => setOpacity(1)}
        onZoomAnimationEnd={(e) => setZoom(e)}
      >
        {devices.map((e, key) => {
          return (
            <Marker
              key={e?.deviceInfo?.macAddress ?? ""}
              lat={e?.coordinates?.[1] ?? 0}
              lng={e?.coordinates?.[0] ?? 0}
              macAddress={e?.deviceInfo?.macAddress ?? ""}
              status={e?.deviceStatus?.status ?? ""}
              currentTemp={e?.deviceInfo?.currentTemp ?? ""}
              addressText={e?.deviceInfo?.addressText ?? ""}
              name={e?.deviceInfo?.name ?? ""}
              data={e}
            />
          );
        })}
      </GoogleMapReact>
    </Container>
  );
}

const MarkerContainer = styled.div`
  position: absolute;
  transform: translate(-50%, 0)
    scale(${(props) => props.scale}, ${(props) => props.scale});
  bottom: 0;
  width: 3.889vw;
  cursor: pointer;
  z-index: ${(props) => (props.active ? "9" : "unset")};
  > img {
    width: 100%;
  }
  @media ${device.desktopL} {
    width: 56px;
  }
  .mark {
    &__text {
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      > span {
        vertical-align: super;
      }
    }
    &__marker {
      position: relative;
      z-index: 1;
    }
    &__tool {
      position: absolute;
      bottom: -20%;
    }
    &__title {
      position: absolute;
      bottom: 94%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 300%;
      text-align: center;
    }
  }
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
`;
