import styled from "styled-components";
import { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { imgList } from "@constants/imgList";
import { device } from "@constants/styles";
import { stateInterface } from "@reduxs/interface";

export default function Layout({ children }: any) {
  const loading = useSelector((state: stateInterface) => state.store.loading);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.has("from")) {
      const from = searchParams.get("from");
      if (from) {
        if (from === "changepassword") {
          notifySuccess({ title: "เปลี่ยนรหัสผ่านสำเร็จ" });
        }
        searchParams.delete("from");
        setSearchParams(searchParams);
      }
    }
  }, [location.pathname]);
  const notifySuccess = ({ title = "" }) =>
    toast(
      <div className="toast-config">
        <img
          src={imgList.ico_toast_success2}
          alt=""
          className="toast-config__icon"
        />
        <div className="toast-config__detail">
          <div className="font-sukhumvitSet-bold size-14 color-grayb36">
            {title}
          </div>
        </div>
        <img src={imgList.ic_close} alt="" className="toast-config__arrow" />
      </div>,
      {
        className: "toast-config-success",
        containerId: "auth",
      }
    );
  const notifyDanger = ({ title = "" }) =>
    toast(
      <div className="toast-config">
        <img
          src={imgList.ico_toast_fail2}
          alt=""
          className="toast-config__icon"
        />
        <div className="toast-config__detail">
          <div className="font-sukhumvitSet-bold size-14 color-grayb36">
            {title}
          </div>
        </div>
        <img src={imgList.ic_close} alt="" className="toast-config__arrow" />
      </div>,
      {
        className: "toast-config-danger",
        containerId: "auth",
      }
    );
  return (
    <>
      <Helmet>
        <title>CORNTROL - Monitoring</title>
      </Helmet>
      <Backdrop hide={!loading}>
        <Loading className="loading" color="#FFF4D9">
          <div className="loading__inner" />
        </Loading>
      </Backdrop>
      <Container className="layout">
        <div className="layout__inner">{children}</div>
      </Container>
      <ToastWarp>
        <ToastContainer
          autoClose={10000}
          hideProgressBar={true}
          containerId="auth"
          enableMultiContainer
        />
      </ToastWarp>
    </>
  );
}

interface IStyle {
  hide: boolean;
}
interface IStyleLoading {
  color: string;
}

const ToastWarp = styled.div`
  position: relative;
  z-index: 99999;
  & .Toastify__toast-container {
    right: 28px;
    top: 28px;
  }
  & .Toastify__toast {
    border-radius: 8px;
    padding: 16px;
  }
  & .toast-config-success {
    background: #fff;
    border: none;
  }
  & .toast-config-info {
    background: #fff;
    border: none;
  }
  & .toast-config-danger {
    background: #fff;
    border: none;
  }
  & .toast-config-warning {
    background: #fff;
    border: none;
  }
  & .Toastify__close-button {
    display: none;
  }
  & .Toastify__toast-body {
    padding: 0;
  }
  .toast-config {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__icon {
      width: 40px;
      margin: 0 12px 0 0;
    }
    &__arrow {
      width: 24px;
      margin: 0 0 0 12px;
      cursor: pointer;
    }
    &__detail {
      width: calc(100% - 40px - 24px - 12px - 12px);
      > div {
        line-height: 1.2;
      }
      & .etc {
        margin: 4px 0 12px;
      }
    }
    &__next {
      display: flex;
      align-items: center;
      width: 100%;
      > img {
        width: 20px;
        margin: 0 0 0 8px;
      }
    }
  }
`;

const Backdrop = styled.div<IStyle>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: ${(props) => (props.hide ? `none` : `flex`)};
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

const Loading = styled.div<IStyleLoading>`
  @keyframes load {
    100% {
      transform: rotate(360deg);
    }
  }
  width: 100px;
  height: 100px;
  border: 5px solid transparent;
  border-top: 5px solid ${(props) => props.color};
  border-bottom: 5px solid ${(props) => props.color};
  border-radius: 55px;
  animation: load 1.5s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
  .loading {
    &__inner {
      width: 70px;
      height: 70px;
      border: 5px solid transparent;
      border-left: 5px solid ${(props) => props.color};
      border-right: 5px solid ${(props) => props.color};
      border-radius: 40px;
      animation: load 0.75s linear infinite reverse;
    }
  }
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: url(${imgList.bgLogin}) no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4% 0;
  .layout {
    &__inner {
      width: 90%;
      max-width: 30.903vw;
      max-height: 100%;
      overflow: auto;
      margin: 0 auto;
      padding: 2.083vw 2.083vw 3.472vw;
      background: #ffffff;
      border-radius: 1.667vw;
      @media ${device.desktopL} {
        max-width: 445px;
        padding: 30px 30px 50px;
        border-radius: 24px;
      }
    }
  }
`;
