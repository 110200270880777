import { Modal, ModalProps } from "antd";
import styled from "styled-components";

import { device } from "@constants/styles";
import { imgList } from "@constants/imgList";

type Props = {
  open: boolean;
  image: string;
  onClose?: () => void;
};

const ModalZoomImage = ({
  open = false,
  image = "",
  onClose = () => {},
  ...props
}: Props & ModalProps) => {
  return (
    <Content id="modal-zoom-image" className="modal">
      <Modal
        title=""
        open={open}
        closable={false}
        footer={null}
        centered={true}
        getContainer={() =>
          document.getElementById("modal-zoom-image") as HTMLElement
        }
        {...props}
      >
        <img
          src={imgList.ico_close_modal}
          alt=""
          onClick={onClose}
          className="modal__close"
        />
        <img src={image} alt="" className="modal__img" />
      </Modal>
    </Content>
  );
};

export default ModalZoomImage;

const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 90% !important;
  z-index: 999999;
  & .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
  }
  & .ant-modal {
    width: 26.042vw !important;
    @media ${device.desktopL} {
      width: 375px !important;
    }
  }
  & .ant-modal-mask {
    background-color: rgba(0, 0, 0, 0.7) !important;
  }
  & .ant-modal-body {
    padding: 0;
  }
  .modal {
    &__img {
      width: 100%;
    }
    &__close {
      position: absolute;
      width: 1.667vw;
      height: 1.667vw;
      cursor: pointer;
      top: -2.083vw;
      right: -2.083vw;
      @media ${device.desktopL} {
        width: 24px;
        height: 24px;
      }
    }
  }
`;
