import styled from "styled-components";
import { Input as InputAntd, InputProps } from "antd";
import { useState, useEffect, useCallback, useRef } from "react";

import { colors } from "@constants/colors";
import { device } from "@constants/styles";
import { imgList } from "@constants/imgList";

interface IInputSimple {
  classNameWarp?: string;
  labelText?: string;
  errorText?: string;
}

export default function InputSimple({
  classNameWarp = "",
  labelText = "",
  errorText = "",
  ...rest
}: IInputSimple & InputProps) {
  const [values, setValues] = useState({});
  useEffect(() => {
    if (rest.type === "textarea") {
      setValues(rest);
    }
  }, [rest.type]);
  return (
    <Input className={classNameWarp}>
      {labelText && <Text disabled={rest?.disabled ?? false}>{labelText}</Text>}
      <Icon>
        <img src={imgList.icoSearch} alt="" />
      </Icon>
      {rest.type === "password" ? (
        <InputAntd.Password placeholder="โปรดระบุ" {...rest} />
      ) : rest.type === "textarea" ? (
        <InputAntd.TextArea placeholder="โปรดระบุ" rows={6} {...values} />
      ) : (
        <InputAntd placeholder="โปรดระบุ" {...rest} />
      )}
      {errorText && (
        <div className="size-12 weight-500 font-jakarta-display color-red">
          {errorText}
        </div>
      )}
    </Input>
  );
}

interface IInput {}
interface IStyle {
  disabled: boolean;
}

const Text = styled.div<IStyle>`
  position: absolute;
  z-index: 1;
  color: #919eab;
  font-family: "SukhumvitSet-Text";
  font-weight: 400;
  font-size: 0.833vw;
  left: 0.972vw;
  top: -0.556vw;
  background: ${(props) =>
    props.disabled
      ? "linear-gradient(180deg, rgba(245,245,245,0.06066176470588236) 40%, rgba(245,245,245,1) 40%)"
      : "#fff"};
  display: inline-block;
  padding: 0 0.278vw;
  @media ${device.desktopL} {
    padding: 0 4px;
    font-size: 12px;
    left: 14px;
    top: -8px;
  }
`;

const Icon = styled.div`
  position: absolute;
  left: 0.694vw;
  z-index: 1;
  transform: translate(0, -50%);
  top: 50%;
  > img {
    width: 1.667vw;
  }
  @media ${device.desktopL} {
    left: 10px;
    > img {
      width: 24px;
    }
  }
`;

const Input = styled.div<IInput>`
  position: relative;
  & .ant-input-affix-wrapper {
    font-family: "Mitr", sans-serif;
    border: 1px solid #d9d9d9;
    border-radius: 0.694vw;
    padding: 0.903vw 1.389vw;
    border: 0.069vw solid rgba(145, 158, 171, 0.32);
    @media ${device.desktopL} {
      border-radius: 10px;
      padding: 13px 20px;
      border: 1px solid rgba(145, 158, 171, 0.32);
    }
  }
  & .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${colors.primary2};
  }
  & .ant-input-password {
    & .ant-input {
      border: none !important;
      border-radius: 0 !important;
    }
  }
  & .ant-input {
    font-family: SukhumvitSet-Text;
    font-size: 0.833vw;
    font-weight: 400;
    color: ${colors.blackd33};
    padding: 1.111vw 2.778vw;
    border-radius: 0.694vw;
    border: 0.069vw solid rgba(145, 158, 171, 0.32);
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(0, 0, 0, 0.25);
    }
    &::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(0, 0, 0, 0.25);
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(0, 0, 0, 0.25);
    }
    @media ${device.desktopL} {
      font-size: 14px;
      padding: 16px 40px;
      border-radius: 10px;
      border: 1px solid rgba(145, 158, 171, 0.32);
    }
  }
  & .ant-input:hover {
    border-color: ${colors.primary2};
  }
  & .ant-input-focused,
  .ant-input:focus {
    border-color: ${colors.primary2};
    box-shadow: none;
  }
  & .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: ${colors.primary2};
    box-shadow: none;
  }
  & .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
`;
