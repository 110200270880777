export const colors: { [key: string]: string } = {
  black522: "#141522",
  blackd33: "#262D33",
  grayca4: "#9C9CA4",
  primary: "#FEBC11",
  primary2: "#FFBB54",
  gray5f7: "#F5F5F7",
  blue828: "#101828",
  white8fd: "#f7f8fd",
  whitecfc: "#FCFCFC",
  gray67e: "#6F767E",
  blackd1f: "#1A1D1F",
  white4f4: "#F4F4F4",
  white: "#fff",
  blackf0f: "#0F0F0F",
  white4f7: "#f2f4f7",
  grayd9d: "#9D9D9D",
  black747: "#474747",
  black909: "#090909",
  gray085: "#667085",
  gray474: "#747474",
  grayf4f: "#4F4F4F",
  grayaba: "#BABABA",
  graye6e: "#6E6E6E",
  gray797: "#979797",
  gray565: "#656565",
  gray595: "#959595",
  reda36: "#d93a36",
  gray333: "#333333",
  grayd7d: "#7D7D7D",
  gray282: "#828282",
};

export const chartColors = {
  primary: "rgb(254, 188, 17)",
  blue: "rgb(35, 149, 255)",
  success: "rgb(120, 172, 10)",
  purple: "rgb(205, 80, 225)",
  danger: "rgb(240, 101, 41)",
  darkBlue: "rgb(93, 41, 240)",
  red: "rgb(240, 41, 41)",
  sky: "rgb(41, 204, 240)",
  darkPurple: "rgb(112, 4, 178)",
  dangerLow: "rgba(240, 101, 41, 0.2)",
  roomTemp: "#2395FF",
  setTemp: "rgb(0,0,0,0.2)",
  hignTemp: "#F02929",
  lowTemp: "#FEBC11",
  evapTemp: "#29CCF0",
};
