import { storeInterface } from "../interface";

const initState = {
  loading: false,
  category: {
    boxes: [],
    title: "",
    slug: "",
  },
  openBoxStep: 1,
  selectedBox: 0,
  receivedBox: [],
  selectedBuy: {
    uuid: "",
    amount: 0,
    price: "",
  },
  marquee: [],
  cart: [],
  resalePercent: "80",
  shippingPrice: "0",
  selectedResaleOrShipping: [],
  goBack: "",
  items: [],
  tabOrder: "all",
  playAnimation: 0,
};

const store = (
  state = initState,
  action: { type: string; data: storeInterface }
) => {
  switch (action.type) {
    case "loading":
      return { ...state, loading: action.data };
    case "category":
      return { ...state, category: action.data };
    case "open_box_step":
      return { ...state, openBoxStep: action.data };
    case "selected_box":
      return { ...state, selectedBox: action.data };
    case "received_box":
      return { ...state, receivedBox: action.data };
    case "selected_buy":
      return { ...state, selectedBuy: action.data };
    case "marquee":
      return { ...state, marquee: action.data };
    case "cart":
      return { ...state, cart: action.data };
    case "resale_percent":
      return { ...state, resalePercent: action.data };
    case "shipping_price":
      return { ...state, shippingPrice: action.data };
    case "selected_resale_shipping":
      return { ...state, selectedResaleOrShipping: action.data };
    case "goback":
      return { ...state, goBack: action.data };
    case "items":
      return { ...state, items: action.data };
    case "tab_order":
      return { ...state, tabOrder: action.data };
    case "play_animation":
      return { ...state, playAnimation: action.data };
    default:
      return state;
  }
};

export default store;
