import { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import ProgressBar from "react-topbar-progress-indicator";
import Cookies from "js-cookie";

import { LayoutAuth, LayoutDefault } from "./components/Layout";
import SignIn from "./pages/SignIn";
import Monitoring from "./pages/Monitoring";
import Dashboard from "./pages/Dashboard";
import Device from "./pages/Device";
import Support from "./pages/Support";
import Profile from "./pages/ProfileNew";
import AccountSetting from "./pages/AccountSetting";
import DeviceDetail from "./pages/DeviceDetail";
import Empty from "./pages/Empty";
import TemperatureDetail from "./pages/TempartureDetail";
import OperationDetail from "./pages/OperationDetail";
import IotDetail from "./pages/IotDetail";
import PowerDetail from "./pages/PowerDetail";
import Notfound from "./pages/Notfound";

const canAuth = Cookies?.get("jwt_token_control") ?? "";

const RoutesComponent = () => {
  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    if (!canAuth) {
      return <Navigate to="/signin" state={{ from: location }} replace />;
    }
    return children;
  };

  const NotRequireAuth = ({ children }: { children: JSX.Element }) => {
    const location = useLocation();
    if (canAuth) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  };
  return (
    <Router>
      <Suspense fallback={<ProgressBar />}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/monitoring" />} />
          <Route
            path="/monitoring"
            element={
              <RequireAuth>
                <LayoutDefault>
                  <Monitoring />
                </LayoutDefault>
              </RequireAuth>
            }
          />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <LayoutDefault>
                  <Dashboard />
                </LayoutDefault>
              </RequireAuth>
            }
          />
          <Route
            path="/devices"
            element={
              <RequireAuth>
                <LayoutDefault>
                  <Device />
                </LayoutDefault>
              </RequireAuth>
            }
          />
          <Route
            path="/support"
            element={
              <RequireAuth>
                <LayoutDefault>
                  <Support />
                </LayoutDefault>
              </RequireAuth>
            }
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <LayoutDefault>
                  <Profile />
                </LayoutDefault>
              </RequireAuth>
            }
          />
          <Route
            path="/account-setting"
            element={
              <RequireAuth>
                <LayoutDefault>
                  <AccountSetting />
                </LayoutDefault>
              </RequireAuth>
            }
          />
          <Route
            // path="/device-detail/:locationID/:id"
            path="/device-detail/:id"
            element={
              <RequireAuth>
                <LayoutDefault>
                  <DeviceDetail />
                </LayoutDefault>
              </RequireAuth>
            }
          />
          <Route
            // path="/device-detail/:locationID/:id/temperature"
            path="/device-detail/:id/temperature"
            element={
              <RequireAuth>
                <LayoutDefault>
                  <TemperatureDetail />
                </LayoutDefault>
              </RequireAuth>
            }
          />
          <Route
            // path="/device-detail/:locationID/:id/operation"
            path="/device-detail/:id/operation"
            element={
              <RequireAuth>
                <LayoutDefault>
                  <OperationDetail />
                </LayoutDefault>
              </RequireAuth>
            }
          />
          <Route
            // path="/device-detail/:locationID/:id/power"
            path="/device-detail/:id/power"
            element={
              <RequireAuth>
                <LayoutDefault>
                  <PowerDetail />
                </LayoutDefault>
              </RequireAuth>
            }
          />
          <Route
            // path="/device-detail/:locationID/:id/iot"
            path="/device-detail/:id/iot"
            element={
              <RequireAuth>
                <LayoutDefault>
                  <IotDetail />
                </LayoutDefault>
              </RequireAuth>
            }
          />
          <Route
            path="/signin"
            element={
              <NotRequireAuth>
                <LayoutAuth>
                  <SignIn />
                </LayoutAuth>
              </NotRequireAuth>
            }
          />
          <Route path="/404" element={<Notfound />} />
          <Route path="*" element={<Empty />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RoutesComponent;
