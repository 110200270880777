import bgLogin from "../images/bg_login.png";
import logo from "../images/logo.png";
import logo2 from "../images/logo_2.png";
import icoMenu from "../images/ico_menu.png";
import icoMenu2 from "../images/ico_menu_2.png";
import icoLight from "../images/ico_light.png";
import icoDarkActive from "../images/ico_dark_active.png";
import icoLightActive from "../images/ico_light_active.png";
import icoDark from "../images/ico_dark.png";
import icoLightMobile from "../images/ico_light_mobile.png";
import icoDarkMobile from "../images/ico_dark_mobile.png";
import icoMenuDashDark from "../images/ico_menu_dash_dark.png";
import icoMenuBellDark from "../images/ico_menu_bell_dark.png";
import icoMenuBellLight from "../images/ico_menu_bell_light.png";
import icoMenuMoniDark from "../images/ico_menu_moni_dark.png";
import icoMenuSupportDark from "../images/ico_menu_support_dark.png";
import icoMenuDeviceDark from "../images/ico_menu_device_dark.png";
import icoMenuMoniActive from "../images/ico_menu_moni_active.png";
import icoMenuMoniLight from "../images/ico_menu_moni_light.png";
import icoMenuDeviceLight from "../images/ico_menu_device_light.png";
import icoMenuDashLight from "../images/ico_menu_dash_light.png";
import icoMenuSupportLight from "../images/ico_menu_support_light.png";
import icoMenuDeviceActive from "../images/ico_menu_device_active.png";
import icoMenuDashActive from "../images/ico_menu_dash_active.png";
import icoMenuSupportActive from "../images/ico_menu_support_active.png";
import avatar from "../images/avatar.png";
import arrowDark from "../images/arrow_dark.png";
import arrowLight from "../images/arrow_light.png";
import icoCatAlarm from "../images/ico_cat_alarm.png";
import icoCatOffline from "../images/ico_cat_offline.png";
import icoCatPower from "../images/ico_cat_power.png";
import icoCatNormal from "../images/ico_cat_normal.png";
import arrowDropdown from "../images/arrow_dropdown.png";
import icoSearch from "../images/ico_search.png";
import ico_fan_disable from "../images/ico_fan_disable.png";
import ico_fan_active from "../images/ico_fan_active.png";
import ico_collap from "../images/ico_collap.png";
import ico_sound_disable from "../images/ico_sound_disable.png";
import ico_light_bulb_active from "../images/ico_light_bulb_active.png";
import ico_bulb_active from "../images/ico_bulb_active.png";
import ico_location_bold from "../images/ico_location_bold.png";
import ico_light_bulb_disable from "../images/ico_light_bulb_disable.png";
import ico_bulb_disable from "../images/ico_bulb_disable.png";
import ico_snow_active from "../images/ico_snow_active.png";
import ico_wifi_active from "../images/ico_wifi_active.png";
import ico_shield_disable from "../images/ico_shield_disable.png";
import ico_sound from "../images/ico_sound.png";
import ico_snow_disable from "../images/ico_snow_disable.png";
import ico_wifi_disable from "../images/ico_wifi_disable.png";
import ico_shield_active from "../images/ico_shield_active.png";
import ico_location from "../images/ico_location.png";
import ex from "../images/ex.png";
import ico_warning from "../images/ico_warning.png";
import ico_danger from "../images/ico_danger.png";
import ico_folder_fray from "../images/ico_folder_fray.png";
import ico_location_gray from "../images/ico_location_gray.png";
import ico_build_gray from "../images/ico_build_gray.png";
import ico_user from "../images/ico_user.png";
import ico_clock from "../images/ico_clock.png";
import ico_close from "../images/ico_close.png";
import marker_normal from "../images/marker_normal.png";
import marker_alarm from "../images/marker_alarm.png";
import marker_poweroff from "../images/marker_poweroff.png";
import marker_offline from "../images/marker_offline.png";
import ico_emty from "../images/ico_emty.png";
import ico_wifi_offline from "../images/ico_wifi_offline.png";
import ico_wifi_3 from "../images/ico_wifi_3.png";
import ico_wifi_2 from "../images/ico_wifi_2.png";
import ico_wifi_1 from "../images/ico_wifi_1.png";
import logo_small from "../images/logo_small.png";
import logo2_small from "../images/logo2_small.png";
import ico_marker_active from "../images/ico_marker_active.png";
import marker_alarm_active from "../images/marker_alarm_active.png";
import marker_offline_active from "../images/marker_offline_active.png";
import marker_poweroff_active from "../images/marker_poweroff_active.png";
import marker_normal_active from "../images/marker_normal_active.png";
import ico_toast_success from "../images/ico_toast_success.png";
import ico_toast_error from "../images/ico_toast_error.png";
import ico_toast_warn from "../images/ico_toast_warn.png";
import ico_toast_arrow from "../images/ico_toast_arrow.png";
import icoSoundOn from "../images/ico-sound-on.png";
import icoSoundOff from "../images/ico-sound-off.png";
import icoSoundOffLight from "../images/ico-sound-off-light.png";
import icoSoundOnLight from "../images/ico-sound-on-light.png";
import icoFaq from "../images/ico-faq.png";
import ico_user_gray from "../images/ico_user_gray.png";
import ico_arrow_yellow from "../images/ico_arrow_yellow.png";
import ico_download from "../images/ico_download.png";
import ico_close_modal from "../images/ico_close_modal.png";
import ico_filter from "../images/ico_filter.png";
import ico_temperature from "../images/ico_temperature.png";
import ico_high from "../images/ico_high.png";
import ico_avg from "../images/ico_avg.png";
import ico_low from "../images/ico_low.png";
import ico_refresh from "../images/ico_refresh.png";
import ico_operation from "../images/ico_operation.png";
import ico_snow from "../images/ico_snow.png";
import ico_water from "../images/ico_water.png";
import ico_door from "../images/ico_door.png";
import ico_fan from "../images/ico_fan.png";
import ico_power from "../images/ico_power.png";
import ico_iot from "../images/ico_iot.png";
import ico_elec from "../images/ico_elec.png";
import ico_bill from "../images/ico_bill.png";
import ico_offline from "../images/ico_offline.png";
import ico_mode from "../images/ico_mode.png";
import ico_max_offline from "../images/ico_max_offline.png";
import ico_mttr from "../images/ico_mttr.png";
import ico_back from "../images/ico_back.png";
import ico_404 from "../images/404.png";
import btn_zoom_image from "../images/btn_zoom_image.png";
import marker_sm from "../images/marker_sm.png";
import ico_grid from "../images/grid.png";
import ico_list from "../images/list.png";
import toast_ico_danger from "../images/toast_ico_danger.png";
import toast_ico_warning from "../images/toast_ico_warning.png";
import toast_ico_success from "../images/toast_ico_success.png";
import toast_ico_info from "../images/toast_ico_info.png";
import toast_ico_close_success from "../images/toast_ico_close_success.png";
import toast_ico_close_warning from "../images/toast_ico_close_warning.png";
import toast_ico_close_danger from "../images/toast_ico_close_danger.png";
import toast_ico_close_info from "../images/toast_ico_close_info.png";
import toast_ico_next_info from "../images/toast_ico_next_info.png";
import toast_ico_next_warning from "../images/toast_ico_next_warning.png";
import toast_ico_next_danger from "../images/toast_ico_next_danger.png";
import toast_ico_next_success from "../images/toast_ico_next_success.png";
import noti_info from "../images/noti_info.png";
import ico_support_email from "../images/ico_support_email.png";
import support_qrcode from "../images/support_qrcode.png";
import ico_support_ig from "../images/ico_support_ig.png";
import ico_support_fb from "../images/ico_support_fb.png";
import ico_support_website from "../images/ico_support_website.png";
import ico_support_yt from "../images/ico_support_yt.png";
import readall from "../images/readall.png";
import ico_check_blue from "../images/ico_check_blue.png";
import clock from "../images/clock.png";
import ico_door_active from "../images/ico_door_active.png";
import ico_door_disable from "../images/ico_door_disable.png";
import upload from "../images/upload.png";
import ico_key from "../images/ico_key.png";
import ico_key_active from "../images/ico_key_active.png";
import ico_user_t from "../images/ico_user_t.png";
import ico_user_t_active from "../images/ico_user_t_active.png";
import ico_check from "../images/ico-check.png";
import ico_checkgray from "../images/ico-checkgray.png";
import ico_bar_filter from "../images/ico_bar_filter.png";
import ico_bar_list from "../images/ico_bar_list.png";
import ico_bar_map from "../images/ico_bar_map.png";
import register from "../images/register.png";
import register_logo from "../images/register_logo.png";
import ico_toast_success2 from "../images/ico_toast_success2.png";
import ico_toast_fail2 from "../images/ico_toast_fail2.png";
import ic_close from "../images/ic_close.png";
import ico_hour from "../images/ico_hour.png";
import ico_ice from "../images/ico_ice.png";
import ico_water_2 from "../images/ico_water_2.png";
import ico_fan_2 from "../images/ico_fan_2.png";

export const imgList: { [key: string]: string } = {
  bgLogin,
  logo,
  logo2,
  icoMenu,
  icoMenu2,
  icoLight,
  icoDarkActive,
  icoDark,
  icoLightActive,
  icoLightMobile,
  icoDarkMobile,
  icoMenuDashDark,
  icoMenuBellDark,
  icoMenuBellLight,
  icoMenuMoniDark,
  icoMenuSupportDark,
  icoMenuDeviceDark,
  icoMenuMoniActive,
  icoMenuMoniLight,
  icoMenuDeviceLight,
  icoMenuDashLight,
  icoMenuSupportLight,
  icoMenuDeviceActive,
  icoMenuDashActive,
  icoMenuSupportActive,
  avatar,
  arrowDark,
  arrowLight,
  icoCatAlarm,
  icoCatOffline,
  icoCatPower,
  icoCatNormal,
  arrowDropdown,
  icoSearch,
  ico_fan_disable,
  ico_fan_active,
  ico_collap,
  ico_sound_disable,
  ico_sound,
  ico_light_bulb_active,
  ico_bulb_active,
  ico_location_bold,
  ico_light_bulb_disable,
  ico_bulb_disable,
  ico_snow_active,
  ico_wifi_active,
  ico_shield_disable,
  ico_snow_disable,
  ico_wifi_disable,
  ico_shield_active,
  ico_location,
  ex,
  ico_warning,
  ico_danger,
  ico_folder_fray,
  ico_location_gray,
  ico_build_gray,
  ico_user,
  ico_clock,
  ico_close,
  marker_normal,
  marker_alarm,
  marker_poweroff,
  marker_offline,
  ico_emty,
  ico_wifi_offline,
  ico_wifi_3,
  ico_wifi_2,
  ico_wifi_1,
  logo_small,
  logo2_small,
  ico_marker_active,
  marker_alarm_active,
  marker_offline_active,
  marker_poweroff_active,
  marker_normal_active,
  ico_toast_success,
  ico_toast_error,
  ico_toast_warn,
  ico_toast_arrow,
  icoSoundOn,
  icoSoundOff,
  icoFaq,
  ico_user_gray,
  ico_arrow_yellow,
  ico_download,
  ico_close_modal,
  ico_filter,
  ico_temperature,
  ico_high,
  ico_avg,
  ico_low,
  ico_refresh,
  ico_operation,
  ico_snow,
  ico_water,
  ico_door,
  ico_fan,
  ico_power,
  ico_iot,
  ico_elec,
  ico_bill,
  ico_offline,
  ico_mode,
  ico_max_offline,
  ico_mttr,
  ico_back,
  ico_404,
  btn_zoom_image,
  marker_sm,
  ico_grid,
  ico_list,
  toast_ico_danger,
  toast_ico_warning,
  toast_ico_success,
  toast_ico_info,
  toast_ico_close_success,
  toast_ico_close_warning,
  toast_ico_close_danger,
  toast_ico_close_info,
  toast_ico_next_info,
  toast_ico_next_warning,
  toast_ico_next_danger,
  toast_ico_next_success,
  icoSoundOffLight,
  icoSoundOnLight,
  noti_info,
  ico_support_email,
  support_qrcode,
  ico_support_ig,
  ico_support_fb,
  ico_support_website,
  ico_support_yt,
  readall,
  ico_check_blue,
  clock,
  ico_door_active,
  ico_door_disable,
  upload,
  ico_key,
  ico_key_active,
  ico_user_t,
  ico_user_t_active,
  ico_check,
  ico_checkgray,
  ico_bar_filter,
  ico_bar_list,
  ico_bar_map,
  register,
  register_logo,
  ico_toast_success2,
  ico_toast_fail2,
  ic_close,
  ico_hour,
  ico_ice,
  ico_water_2,
  ico_fan_2,
};
