import React from "react";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import Geocode from "react-geocode";
// import useSound from "use-sound";
import _ from "lodash";
import { useMedia } from "react-use";

import { imgList } from "@constants/imgList";
// import { soundList } from "@constants/soundList";
import { device } from "@constants/styles";
import { InputIcon } from "@components/Inputs";
import BoxDevice from "@components/BoxDevice";
import { SelectSimple } from "@components/Selects";
import GoogleMap from "@components/GoogleMap";
import { dateConvert } from "@utils/date";
import { ModalNoti, ModalFilterMonitoring } from "@components/Modals";
import { apiGet, apiPut } from "@apis/gateway";
import { loadingAction } from "@reduxs/Actions/storeAction";
import {
  countAction,
  updateDateAction,
  monitoringAction,
  monitoringFilterAction,
  filterAction,
  listStatusAction,
  listLocationAction,
  mapLocationDevicesAction,
  isSoundPlayingAction,
  isPlayAction,
} from "@reduxs/Actions/monitoringAction";
import { stateInterface, mapLocationDevicesInterface } from "@reduxs/interface";

const initSelectedDevice = {
  macAddress: "",
  lat: 0,
  lng: 0,
};

export default function Monitoring() {
  const dispatch = useDispatch();
  const language = useSelector(
    (state: stateInterface) => state.storage.language
  );
  const count = useSelector((state: stateInterface) => state.monitoring.count);
  const updateDate = useSelector(
    (state: stateInterface) => state.monitoring.updateDate
  );
  const monitoring = useSelector(
    (state: stateInterface) => state.monitoring.monitoring
  );
  const monitoringFilter = useSelector(
    (state: stateInterface) => state.monitoring.monitoringFilter
  );
  const filter = useSelector(
    (state: stateInterface) => state.monitoring.filter
  );
  const listStatus = useSelector(
    (state: stateInterface) => state.monitoring.listStatus
  );
  const listLocation = useSelector(
    (state: stateInterface) => state.monitoring.listLocation
  );
  const [stuck, setStuck] = useState(false);
  const ref: any = React.createRef();
  const { name, status, location, area } = filter;
  const info = useSelector((state: stateInterface) => state.user.info);
  const collapsed = useSelector(
    (state: stateInterface) => state.monitoring.collapsed
  );
  const sound = useSelector(
    (state: stateInterface) => state?.storage?.sound ?? "off"
  );
  const isSoundPlaying = useSelector(
    (state: stateInterface) => state.monitoring.isSoundPlaying
  );
  const permissionNoti = useSelector(
    (state: stateInterface) => state.monitoring.permissionNoti
  );
  const [open, setOpen] = useState(true);
  const [selectedDevice, setSelectedDevice] = useState(initSelectedDevice);
  const [devices, setDevices] = useState<any[]>([]);
  const [nameAddress, setNameAddress] = useState("เขตวัฒนา, กรุงเทพฯ");
  // const [loadSound, setLoadSound] = useState(false);
  const [modalNoti, setModalNoti] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [listOrMap, setListOrMap] = useState(false);
  // const [play, { stop }] = useSound(soundList.beeps, {
  //   loop: true,
  //   interrupt: true,
  //   onload: () => setLoadSound(true),
  // });
  const showNotiInfo = localStorage?.getItem("showNoti") ?? "1";

  const styles = stuck ? {} : {};

  const position = "top";

  const inlineStyles: any = {
    position: "sticky",
    zIndex: 999,
    [position]: -1,
    ...styles,
  };

  const isTablet = useMedia("(max-width: 1024px)");
  const isMobile = useMedia("(max-width: 500px)");

  useEffect(() => {
    return () => {
      dispatch(monitoringAction([]));
    };
  }, []);

  useEffect(() => {
    if (monitoring.length > 0) {
      checkSound();
    }
  }, [monitoring, sound]);

  const checkSound = async () => {
    const res = await _.find(monitoring, function (o) {
      return _.some(o.devices, function (e) {
        return (
          e.controlInfo.soundAlarm === true &&
          e.controlInfo.control.alarm === true
        );
      });
    });
    if (res !== undefined && sound === "on" && !isSoundPlaying) {
      // play();
      dispatch(isPlayAction(true));
      dispatch(isSoundPlayingAction(true));
    } else if (res !== undefined && sound === "on" && isSoundPlaying) {
      dispatch(isSoundPlayingAction(true));
    } else {
      dispatch(isPlayAction(false));
      dispatch(isSoundPlayingAction(false));
      // stop();
    }
  };

  useEffect(() => {
    const cachedRef: any = ref.current;
    const observer = new IntersectionObserver(
      ([e]) => setStuck(e.intersectionRatio < 1),
      { threshold: [1] }
    );
    observer.observe(cachedRef);
    return () => observer.unobserve(cachedRef);
  }, [ref]);

  useEffect(() => {
    if (monitoringFilter.length > 0) {
      let arr: any[] = [];
      let totalDevices = 0;
      let alarm = 0;
      let normal = 0;
      let offline = 0;
      let powerOff = 0;
      monitoringFilter.forEach((e) => {
        e.devices.forEach((e2: any) => {
          arr.push({
            ...e2,
            locationID: e?.id ?? "",
          });
          totalDevices = totalDevices + 1;
          if (e2?.deviceStatus?.filterStatus === "normal") {
            normal = normal + 1;
          } else if (e2?.deviceStatus?.filterStatus === "offline") {
            offline = offline + 1;
          } else if (e2?.deviceStatus?.filterStatus === "powerOff") {
            powerOff = powerOff + 1;
          } else if (e2?.deviceStatus?.filterStatus === "alarm") {
            alarm = alarm + 1;
          }
        });
      });
      setDevices(arr);
      dispatch(
        countAction({
          alarm: alarm,
          normal: normal,
          offline: offline,
          powerOff: powerOff,
          totalDevices: totalDevices,
        })
      );
    }
  }, [monitoringFilter]);

  useEffect(() => {
    dispatch(updateDateAction(dayjs()));
    let totalDevices = 0;
    let alarm = 0;
    let normal = 0;
    let offline = 0;
    let powerOff = 0;
    monitoring.forEach((e) => {
      e.devices.forEach((e2: any) => {
        totalDevices = totalDevices + 1;
        if (e2?.deviceStatus?.filterStatus === "normal") {
          normal = normal + 1;
        } else if (e2?.deviceStatus?.filterStatus === "offline") {
          offline = offline + 1;
        } else if (e2?.deviceStatus?.filterStatus === "powerOff") {
          powerOff = powerOff + 1;
        } else if (e2?.deviceStatus?.filterStatus === "alarm") {
          alarm = alarm + 1;
        }
      });
    });
    dispatch(
      countAction({
        alarm: alarm,
        normal: normal,
        offline: offline,
        powerOff: powerOff,
        totalDevices: totalDevices,
      })
    );
  }, [monitoring]);
  useEffect(() => {
    let arr: any[] = monitoring;
    if (name !== "" || status.length > 0 || location.length > 0) {
      if (name !== "") {
        arr = arr.map((e) => {
          return {
            ...e,
            devices: (e?.devices ?? []).filter((e2: any) =>
              (e2?.deviceInfo?.name ?? "")
                .toLowerCase()
                .includes(name.toLowerCase())
            ),
          };
        });
      }
      if (status.length > 0) {
        arr = arr.map((e) => {
          return {
            ...e,
            devices: (e?.devices ?? []).filter(
              (e2: any) => status.indexOf(e2?.deviceStatus?.status ?? "") > -1
            ),
          };
        });
      }
      if (location.length > 0) {
        arr = arr.filter((e) => location.indexOf(e?.id) > -1);
      }
      const sort = _.orderBy(arr, ["name"], ["asc"]).map((e) => {
        return {
          ...e,
          devices: _.orderBy(e?.devices ?? [], ["deviceInfo.name"], ["asc"]),
        };
      });
      dispatch(monitoringFilterAction(sort));
    } else {
      const sort = _.orderBy(monitoring, ["name"], ["asc"]).map((e) => {
        return {
          ...e,
          devices: _.orderBy(e?.devices ?? [], ["deviceInfo.name"], ["asc"]),
        };
      });
      dispatch(monitoringFilterAction(sort));
    }
  }, [monitoring, filter]);

  useEffect(() => {
    if (
      selectedDevice.macAddress &&
      selectedDevice.lat !== 0 &&
      selectedDevice.lng !== 0
    ) {
      getNameAddress();
    }
  }, [selectedDevice]);

  useEffect(() => {
    initData();
  }, []);
  useEffect(() => {
    if (permissionNoti !== undefined && loaded) {
      if (showNotiInfo === "1" && !permissionNoti) {
        setModalNoti(true);
      } else {
        localStorage.setItem("showNoti", "0");
      }
    }
  }, [permissionNoti, loaded]);
  const initData = async () => {
    dispatch(loadingAction(true));
    await getMonitoring();
    await getStatus();
    await getLocation();
    dispatch(loadingAction(false));
    setLoaded(true);
  };
  const getMonitoring = async () => {
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/monitoring`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      let array: mapLocationDevicesInterface[] = [];
      (res?.data?.data?.data ?? []).forEach((e: any, index: number) => {
        (e?.devices ?? []).forEach((e2: any, index2: number) => {
          let obj = {
            macAddress: e2?.deviceInfo?.macAddress ?? "",
            index1: index,
            index2: index2,
          };
          array.push(obj);
        });
      });
      dispatch(mapLocationDevicesAction(array));
      dispatch(
        countAction({
          alarm: res?.data?.data?.alarm ?? 0,
          normal: res?.data?.data?.normal ?? 0,
          offline: res?.data?.data?.offline ?? 0,
          powerOff: res?.data?.data?.powerOff ?? 0,
          totalDevices: res?.data?.data?.totalDevices ?? 0,
        })
      );
      dispatch(monitoringAction(res?.data?.data?.data ?? []));
    }
  };
  const getStatus = async () => {
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/monitoring/device-status`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      dispatch(
        listStatusAction(
          (res?.data?.data ?? []).filter((e: any) => e?.filterStatus !== "all")
        )
      );
    }
  };
  const getLocation = async () => {
    const res = await apiGet(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/monitoring/location-filter`,
      {},
      language
    );
    if (res.status && res.code === 200) {
      dispatch(listLocationAction(res?.data?.data ?? []));
    }
  };
  const getNameAddress = () => {
    const key = process.env.REACT_APP_GOOGLE_MAP_KEY ?? "";
    Geocode.setApiKey(key);
    Geocode.setLanguage("th");
    // Geocode.setLocationType("ROOFTOP");
    Geocode.fromLatLng(
      selectedDevice.lat.toString(),
      selectedDevice.lng.toString()
    ).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "sublocality_level_1":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_2":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        if (city === "" && state === "") {
          setNameAddress(`undefined`);
        } else {
          setNameAddress(`${city}, ${state}`);
        }
      },
      (error) => {
        console.error(error);
      }
    );
  };
  const handleMute = async (macAddress = "") => {
    const params = {};
    dispatch(loadingAction(true));
    const res = await apiPut(
      `${process.env.REACT_APP_API_DOMAIN}/monitoring-api/device/${macAddress}/mute-alarm`,
      { ...params },
      {},
      language
    );
    dispatch(loadingAction(false));
  };
  return (
    <Container className="moni" open={open} stuck={stuck} collapsed={collapsed}>
      <div className="moni__top">
        <div>
          <div className="font-inter size-30 color-blue828 weight-600">
            Welcome back,{" "}
            {/* {info?.user_firstname ?? "" ? info?.user_firstname ?? "" : "-"} */}
            {/* {info?.email?.split("@")?.[0] ?? "-"} */}
            {info?.user_fullname ?? "-"}
          </div>
          <div className="font-inter size-16 color-gray085 weight-400">
            Track, manage and forecast your devices
          </div>
        </div>
        <div className="moni__text font-sukhumvitSet-text size-16 color-gray474">
          <img src={imgList.ico_clock} alt="" />
          อัปเดตล่าสุด:
          <span className="color-black">{dateConvert(updateDate)}</span>
        </div>
      </div>
      <Headline className="head">
        <div className="head__box first">
          <div className="head__name color-black size-16 font-sukhumvitSet-bold">
            Total Devices
          </div>
          <div className="head__etc color-black size-36 font-sukhumvitSet-bold">
            {count?.totalDevices ?? 0}
          </div>
        </div>
        <div className="head__box head__box--1">
          <div className="head__name head__name--center size-16 font-sukhumvitSet-bold">
            <img src={imgList.icoCatNormal} alt="" />
            <div>Normal</div>
          </div>
          <div className="head__etc size-36 font-sukhumvitSet-bold">
            {count?.normal ?? 0}
          </div>
        </div>
        <div className="head__box head__box--2">
          <div className="head__name head__name--center size-16 font-sukhumvitSet-bold">
            <img src={imgList.icoCatPower} alt="" />
            <div>Power off</div>
          </div>
          <div className="head__etc size-36 font-sukhumvitSet-bold">
            {count?.powerOff ?? 0}
          </div>
        </div>
        <div className="head__box head__box--3">
          <div className="head__name head__name--center size-16 font-sukhumvitSet-bold">
            <img src={imgList.icoCatOffline} alt="" />
            <div>Offline</div>
          </div>
          <div className="head__etc size-36 font-sukhumvitSet-bold">
            {count?.offline ?? 0}
          </div>
        </div>
        <div className="head__box head__box--4 last">
          <div className="head__name head__name--center size-16 font-sukhumvitSet-bold">
            <img src={imgList.icoCatAlarm} alt="" />
            <div>Alarm</div>
          </div>
          <div className="head__etc size-36 font-sukhumvitSet-bold">
            {count?.alarm ?? 0}
          </div>
        </div>
      </Headline>
      <div ref={ref} style={inlineStyles}>
        <Filter className="filter">
          <div className="filter__box filter__box--1">
            <InputIcon
              type="text"
              value={name}
              placeholder="ค้นหาชื่ออุปกรณ์"
              onChange={(e) => {
                setSelectedDevice(initSelectedDevice);
                dispatch(
                  filterAction({
                    ...filter,
                    name: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className="filter__box filter__box--2">
            <SelectSimple
              mode="multiple"
              id="status"
              labelText="Status"
              value={status}
              options={listStatus.map((e) => {
                return {
                  label: e?.filterStatusDisplayText ?? "",
                  value: e?.filterStatus ?? "",
                };
              })}
              placeholder="แสดงทั้งหมด"
              maxTagCount={0}
              maxTagPlaceholder={
                <div>
                  {status.length > 1
                    ? `แสดง (${status.length})`
                    : `${
                        listStatus.filter(
                          (e) => e.filterStatus === status?.[0] ?? ""
                        )?.[0]?.filterStatusDisplayText ?? ""
                      }`}
                </div>
              }
              classNameWarp=""
              onChange={(e) => {
                dispatch(
                  filterAction({
                    ...filter,
                    status: e,
                  })
                );
              }}
            />
          </div>
          <div className="filter__box filter__box--2">
            <SelectSimple
              optionFilterProp="label"
              mode="multiple"
              id="location"
              labelText="Location"
              value={location}
              options={listLocation.map((e) => {
                return {
                  label: e?.name ?? "",
                  value: e?.id ?? "",
                };
              })}
              placeholder="แสดงทั้งหมด"
              maxTagCount={0}
              maxTagPlaceholder={
                <div>
                  {location.length > 1
                    ? `แสดง (${location.length})`
                    : `${
                        listLocation.filter(
                          (e) => e.id === location?.[0] ?? ""
                        )?.[0]?.name ?? ""
                      }`}
                </div>
              }
              classNameWarp=""
              onChange={(e) => {
                dispatch(
                  filterAction({
                    ...filter,
                    location: e,
                  })
                );
              }}
            />
          </div>
          <div className="filter__box filter__box--2">
            <SelectSimple
              mode="multiple"
              id="area"
              labelText="Area"
              value={area}
              options={[
                {
                  label: "All",
                  value: "all",
                },
              ]}
              placeholder="แสดงทั้งหมด"
              classNameWarp=""
              disabled
            />
          </div>
        </Filter>
      </div>
      <div className="moni__content">
        <img
          src={imgList.ico_collap}
          alt=""
          className={`moni__close ${!open ? "moni__close--2" : ""}`}
          onClick={() => setOpen(!open)}
        />
        <Side className="side" open={open} listOrMap={listOrMap}>
          {(monitoringFilter ?? []).map((e, key) => {
            return (
              <React.Fragment key={key}>
                <div className={`side__name ${key === 0 ? "first" : ""}`}>
                  <img src={imgList.ico_location} alt="" />
                  <div className="color-black font-sukhumvitSet-text size-16 weight-600">
                    {`${e?.name ?? ""} (${(e?.devices ?? []).length})`}
                  </div>
                </div>
                <div className="side__box">
                  {(e?.devices ?? []).length > 0 ? (
                    (e?.devices ?? []).map((e2: any, key2: number) => {
                      return (
                        <BoxDevice
                          key={key2}
                          selected={
                            (e2?.deviceInfo?.macAddress ?? "") ===
                            selectedDevice.macAddress
                          }
                          data={e2}
                          locationID={e?.id ?? ""}
                          onClick={(e) =>
                            setSelectedDevice(
                              e?.macAddress === selectedDevice.macAddress
                                ? initSelectedDevice
                                : e
                            )
                          }
                          onMute={() => {
                            handleMute(e2?.deviceInfo?.macAddress ?? "");
                          }}
                        />
                      );
                    })
                  ) : (
                    <NoDevice>
                      <img src={imgList.icoFaq} alt="" />
                      <div className="font-sukhumvitSet-text size-12 weight-500 color-gray747">
                        คุณยังไม่ได้เพิ่มอุปกรณ์ในพื้นที่นี้
                      </div>
                    </NoDevice>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        </Side>
        <Map open={open} listOrMap={listOrMap}>
          {stuck && !isMobile && !isTablet && (
            <>
              <Date>
                <div className="moni__text font-sukhumvitSet-text size-16 color-gray474">
                  <img src={imgList.ico_clock} alt="" />
                  อัปเดตล่าสุด:
                  <span className="color-black">{dateConvert(updateDate)}</span>
                </div>
              </Date>
              <TotalDevices className="total">
                <div className="total__box">
                  <div className="total__box-total">
                    <div className="font-sukhumvitSet-bold size-10 total__text">
                      Total
                    </div>
                    <div className="font-sukhumvitSet-bold size-12">
                      Devices
                    </div>
                  </div>
                  <div className="font-sukhumvitSet-bold size-16 total__number">
                    {count?.totalDevices ?? 0}
                  </div>
                </div>
                <div className="total__box total__box--1">
                  <img
                    src={imgList.icoCatNormal}
                    alt=""
                    className="total__img"
                  />
                  <div className="font-sukhumvitSet-bold size-16 total__text-number">
                    {count?.normal ?? 0}
                  </div>
                </div>
                <div className="total__box total__box--2">
                  <img
                    src={imgList.icoCatPower}
                    alt=""
                    className="total__img"
                  />
                  <div className="font-sukhumvitSet-bold size-16 total__text-number">
                    {count?.powerOff ?? 0}
                  </div>
                </div>
                <div className="total__box total__box--3">
                  <img
                    src={imgList.icoCatOffline}
                    alt=""
                    className="total__img"
                  />
                  <div className="font-sukhumvitSet-bold size-16 total__text-number">
                    {count?.offline ?? 0}
                  </div>
                </div>
                <div className="total__box total__box--4 last">
                  <img
                    src={imgList.icoCatAlarm}
                    alt=""
                    className="total__img"
                  />
                  <div className="font-sukhumvitSet-bold size-16 total__text-number">
                    {count?.alarm ?? 0}
                  </div>
                </div>
              </TotalDevices>
            </>
          )}
          <MapName>
            <img src={imgList.ico_location_bold} alt="" />
            <div className="color-black font-sukhumvitSet-text size-14">
              <span className="weight-500 font-sukhumvitSet-bold">
                ตำแหน่งปัจจุบัน:
              </span>{" "}
              {nameAddress}
            </div>
          </MapName>
          <GoogleMap
            selectedDevice={selectedDevice}
            devices={devices.filter(
              (e) =>
                (e?.coordinates?.[0] ?? 0) !== 0 &&
                (e?.coordinates?.[1] ?? 0) !== 0
            )}
            setSelectedDevice={(e: any) => {
              setSelectedDevice(
                e?.macAddress === selectedDevice.macAddress
                  ? initSelectedDevice
                  : e
              );
              document
                ?.getElementById(`box-device-${e?.macAddress}`)
                ?.scrollIntoView();
            }}
            showFull={!open}
            onMute={(e: any) => handleMute(e)}
          />
        </Map>
      </div>
      <Bar className="bar">
        <div className="bar__text first" onClick={() => setModalFilter(true)}>
          <img src={imgList.ico_bar_filter} alt="" />
          <div className="font-sukhumvitSet-text size-14 color-white">
            Filter
          </div>
        </div>
        <div className="bar__text" onClick={() => setListOrMap(!listOrMap)}>
          <img
            src={imgList[listOrMap ? "ico_bar_map" : "ico_bar_list"]}
            alt=""
          />
          <div className="font-sukhumvitSet-text size-14 color-white">
            {listOrMap ? "Map" : "List"}
          </div>
        </div>
      </Bar>
      <ModalNoti
        open={modalNoti}
        onOk={() => {}}
        onClose={() => {
          setModalNoti(false);
          localStorage.setItem("showNoti", "0");
        }}
      />
      <ModalFilterMonitoring
        open={modalFilter}
        onOk={() => setModalFilter(false)}
        onClose={() => setModalFilter(false)}
      />
    </Container>
  );
}
interface IStyle {
  open: boolean;
}
interface IStyleWithNewState {
  open: boolean;
  listOrMap: boolean;
}
interface IStyleCon {
  open: boolean;
  stuck: boolean;
  collapsed: boolean;
}
const Bar = styled.div`
  position: fixed;
  bottom: 4%;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 23.5px;
  background: #000;
  box-shadow: 0px 10px 16px 0px rgba(58, 34, 40, 0.16);
  display: none;
  align-items: center;
  padding: 10px 16px;
  @media ${device.laptop} {
    display: flex;
  }
  .bar {
    &__text {
      display: flex;
      align-items: center;
      > img {
        width: 24px;
        margin: 0 8px 0 0;
      }
      &.first {
        margin: 0 18px 0 0;
      }
    }
  }
`;
const TotalDevices = styled.div`
  position: fixed;
  max-width: 23.889vw;
  background: #ffffff;
  box-shadow: 0px 0.278vw 0.278vw rgba(0, 0, 0, 0.25);
  border-radius: 0.417vw;
  z-index: 9;
  top: 7.292vw;
  right: 24.583vw;
  display: flex;
  align-items: center;
  padding: 0.514vw 0;
  > img {
    width: 1.389vw;
    margin: 0 0.556vw 0 0;
  }
  @media ${device.desktopL} {
    max-width: 344px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    top: 105px;
    right: 354px;
    padding: 7.4px 0;
    > img {
      width: 20px;
      margin: 0 8px 0 0;
    }
  }
  .total {
    &__box {
      display: flex;
      align-items: center;
      border-right: 0.035vw solid rgba(0, 0, 0, 0.2);
      padding: 0 0.833vw;
      height: 1.944vw;
      &.last {
        border-right: none;
      }
      &--1 {
        color: #12b76a;
      }
      &--2 {
        color: #656565;
      }
      &--3 {
        color: #959595;
      }
      &--4 {
        color: #d93a36;
      }
      @media ${device.desktopL} {
        border-right: 0.5px solid rgba(0, 0, 0, 0.2);
        padding: 0 12px;
        height: 28px;
      }
    }
    &__text {
      margin: 0 0 -0.486vw;
      @media ${device.desktopL} {
        margin: 0 0 -7px;
      }
    }
    &__number {
      margin: 0 0 0 0.347vw;
      @media ${device.desktopL} {
        margin: 0 0 0 5px;
      }
    }
    &__img {
      width: 0.972vw;
      margin: 0 0.417vw 0 0;
      @media ${device.desktopL} {
        width: 14px;
        margin: 0 6px 0 0;
      }
    }
    &__text-number {
      margin: 0.139vw 0 0;
      @media ${device.desktopL} {
        margin: 2px 0 0;
      }
    }
    &__box-total {
      margin: 0 0 -0.278vw;
      @media ${device.desktopL} {
        margin: 0 0 -4px;
      }
    }
  }
`;
const NoDevice = styled.div`
  width: 100%;
  background: #ecedee;
  border: 0.069vw solid #eceef6;
  box-shadow: 0px 1.389vw 4.167vw rgba(0, 0, 0, 0.05);
  border-radius: 0.556vw;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 1.319vw 1.111vw;
  margin: 0.694vw 0 0;
  > img {
    width: 1.111vw;
    margin: 0 0.417vw 0 0;
  }
  > div {
    max-width: calc(100% - 2.083vw);
    line-height: 1.3;
  }
  @media ${device.desktopL} {
    border: 1px solid #eceef6;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 19px 16px;
    margin: 10px 0 0;
    > img {
      width: 16px;
      margin: 0 6px 0 0;
    }
    > div {
      max-width: calc(100% - 30px);
    }
  }
`;
const MapName = styled.div`
  position: absolute;
  max-width: 23.889vw;
  background: #ffffff;
  box-shadow: 0px 0.278vw 0.278vw rgba(0, 0, 0, 0.25);
  border-radius: 0.417vw;
  z-index: 9;
  bottom: 1.111vw;
  left: 1.111vw;
  display: flex;
  align-items: center;
  padding: 0.694vw 0.417vw;
  > img {
    width: 1.389vw;
    margin: 0 0.556vw 0 0;
  }
  @media ${device.desktopL} {
    max-width: 344px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    bottom: 16px;
    left: 16px;
    padding: 10px 6px;
    > img {
      width: 20px;
      margin: 0 8px 0 0;
    }
  }
  @media ${device.laptop} {
    width: calc(100% - 16px);
    max-width: unset;
    top: 8px;
    bottom: unset;
    left: 8px;
  }
`;
const Date = styled.div`
  position: fixed;
  max-width: 23.889vw;
  background: #ffffff;
  box-shadow: 0px 0.278vw 0.278vw rgba(0, 0, 0, 0.25);
  border-radius: 0.417vw;
  z-index: 9;
  top: 7.292vw;
  right: 6.25vw;
  display: flex;
  align-items: center;
  padding: 0.694vw 0.417vw;
  > img {
    width: 1.389vw;
    margin: 0 0.556vw 0 0;
  }
  > div {
    line-height: 0 !important;
  }
  @media ${device.desktopL} {
    max-width: 344px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    top: 105px;
    right: 90px;
    padding: 10px 6px;
    > img {
      width: 20px;
      margin: 0 8px 0 0;
    }
  }
`;
const Map = styled.div<IStyleWithNewState>`
  position: relative;
  width: calc(100% - ${(props) => (props.open ? "23.056vw" : "0px")});
  height: 100vh;
  @media ${device.desktopL} {
    width: calc(100% - ${(props) => (props.open ? "332px" : "0px")});
  }
  @media ${device.laptop} {
    width: 100%;
    display: ${(props) => (props.listOrMap ? "none" : "block")};
  }
`;
const Side = styled.div<IStyleWithNewState>`
  position: relative;
  width: ${(props) => (props.open ? "23.056vw" : "0")};
  max-height: 100vh;
  overflow: auto;
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(0.347vw);
  padding: ${(props) => (props.open ? "1.250vw" : "0")};
  @media ${device.desktopL} {
    width: ${(props) => (props.open ? "332px" : "0")};
    backdrop-filter: blur(5px);
    padding: ${(props) => (props.open ? "18px" : "0")};
  }
  @media ${device.laptop} {
    width: 100%;
    display: ${(props) => (props.listOrMap ? "block" : "none")};
    padding: 0;
    background: transparent;
    max-height: ${(props) => (props.listOrMap ? "unset" : "100vh")};
  }
  .side {
    &__name {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 2.083vw 0 0;
      &.first {
        margin: 0;
      }
      > img {
        width: 1.389vw;
        margin: 0 0.417vw 0 0;
      }
      > div {
        line-height: 1.2;
      }
      @media ${device.desktopL} {
        margin: 30px 0 0;
        > img {
          width: 20px;
          margin: 0 6px 0 0;
        }
      }
    }
    &__box {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      @media ${device.laptop} {
        > div {
          width: 49%;
        }
        & .box__group {
          > div {
            width: 33px;
            padding-top: 33px;
          }
        }
      }
      @media ${device.mobileLL} {
        display: block;
        > div {
          width: 100%;
        }
        & .box__group {
          > div {
            width: calc(70% / 7);
            padding-top: calc(70% / 7);
          }
        }
      }
    }
  }
`;
const Filter = styled.div`
  position: relative;
  z-index: 999;
  width: 100%;
  background: #ffffff;
  border: 0.069vw solid #ededed;
  box-shadow: 0px 0.417vw 0.833vw rgba(218, 218, 218, 0.32);
  border-radius: 0.833vw;
  padding: 1.389vw 1.111vw;
  margin: 1.25vw 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media ${device.desktopL} {
    border: 1px solid #ededed;
    box-shadow: 0px 6px 12px rgba(218, 218, 218, 0.32);
    border-radius: 12px;
    padding: 20px 16px;
    margin: 18px 0 0;
  }
  @media ${device.laptop} {
    // flex-wrap: wrap;
    display: none;
  }
  .filter {
    &__box {
      &--1 {
        width: 28%;
      }
      &--2 {
        width: 21%;
      }
      @media ${device.laptop} {
        width: calc(50% - 8px);
        margin: 0 0 16px;
      }
      @media ${device.mobileLL} {
        width: 100%;
      }
    }
  }
`;
const Headline = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0.278vw 0.833vw rgba(150, 150, 150, 0.25);
  border-radius: 0.833vw;
  padding: 1.25vw 2.083vw;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 1.944vw 0 0;
  @media ${device.desktopL} {
    box-shadow: 0px 4px 12px rgba(150, 150, 150, 0.25);
    border-radius: 12px;
    padding: 18px 30px;
    margin: 28px 0 0;
  }
  @media ${device.laptop} {
    position: sticky;
    top: 20px;
    z-index: 999;
    margin: 28px auto 0;
    width: calc(100% - 16px);
  }
  @media ${device.mobileLL} {
    padding: 18px 18px;
    margin: 12px auto;
    align-items: center;
  }
  .head {
    &__box {
      width: calc(100% / 5);
      border-right: 0.069vw solid #efefef;
      &.last {
        border-right: none;
      }
      &--1 {
        color: #12b76a;
        text-align: center;
      }
      &--2 {
        color: #656565;
        text-align: center;
      }
      &--3 {
        color: #959595;
        text-align: center;
      }
      &--4 {
        color: #d93a36;
        text-align: center;
      }
      @media ${device.desktopL} {
        border-right: 1px solid #efefef;
      }
      @media ${device.mobileLL} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(70% / 4);
        min-height: 32px;
        &.first {
          width: 30%;
          padding: 0 14px 0 0;
        }
      }
    }
    &__name {
      display: flex;
      align-items: center;
      line-height: 1;
      &--center {
        justify-content: center;
      }
      > img {
        width: 1.111vw;
        margin: 0 0.417vw 0 0;
      }
      @media ${device.desktopL} {
        > img {
          width: 16px;
          margin: 0 6px 0 0;
        }
      }
      @media ${device.mobileLL} {
        > img {
          width: 18px;
          margin: 0 6px 0 0;
        }
        > div {
          display: none;
        }
      }
    }
    &__etc {
      line-height: 1;
      margin: 0.833vw 0 0;
      @media ${device.desktopL} {
        margin: 12px 0 0;
      }
      @media ${device.mobileLL} {
        font-size: 18px !important;
        margin: 0;
      }
    }
  }
`;

const Container = styled.div<IStyleCon>`
  width: 100%;
  @media ${device.mobileLL} {
    width: calc(100% + 26px);
    margin: -13px -13px 0;
  }
  .moni {
    &__top {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      & .moni__text {
        min-width: 240px;
      }
      @media ${device.mobileLL} {
        flex-wrap: wrap;
      }
    }
    &__text {
      display: flex;
      align-items: center;
      line-height: 3.75vw;
      > img {
        width: 1.528vw;
        margin: 0 0.208vw 0 0;
      }
      > span {
        margin: 0 0 0 0.139vw;
      }
      @media ${device.desktopL} {
        line-height: 54px;
        > span {
          margin: 0 0 0 2px;
        }
        > img {
          width: 22px;
          margin: 0 3px 0 0;
        }
      }
      @media ${device.mobileLL} {
        line-height: 42px;
      }
    }
    &__content {
      position: relative;
      width: 100%;
      display: flex;
      align-items: flex-start;
      @media ${device.laptop} {
        margin: 32px 0 0;
      }
      @media ${device.mobileLL} {
        margin: 0;
      }
    }
    &__close {
      position: ${(props) => (props.stuck ? "fixed" : "absolute")};
      width: 1.944vw;
      height: 1.944vw;
      top: ${(props) => (props.stuck ? "7.222vw" : "1.111vw")};
      left: ${(props) =>
        props.open
          ? props.stuck
            ? props.collapsed
              ? "29.861vw"
              : "43.611vw"
            : "22.014vw"
          : props.stuck
          ? props.collapsed
            ? "6.875vw"
            : "20.833vw"
          : "-0.764vw"};
      z-index: 2;
      cursor: pointer;
      &--2 {
        transform: rotate(180deg);
      }
      @media ${device.desktopL} {
        width: 28px;
        height: 28px;
        top: ${(props) => (props.stuck ? "104px" : "16px")};
        left: ${(props) =>
          props.open
            ? props.stuck
              ? props.collapsed
                ? "430px"
                : "628px"
              : "317px"
            : props.stuck
            ? props.collapsed
              ? "99px"
              : "300px"
            : "-11px"};
      }
      @media ${device.laptop} {
        display: none;
      }
    }
  }
`;
