import styled from "styled-components";
import { Input as InputAntd, InputProps } from "antd";

import { colors } from "@constants/colors";
import { device } from "@constants/styles";
import { imgList } from "@constants/imgList";

interface IInputSimple {
  classNameWarp?: string;
  labelText?: string;
}

export default function InputIcon({
  classNameWarp = "",
  labelText = "",
  ...rest
}: IInputSimple & InputProps) {
  return (
    <Input className={classNameWarp}>
      {labelText && (
        <Text>
          <div className="size-14 weight-500 font-jakarta-display color-black522">
            {labelText}
          </div>
        </Text>
      )}
      <Icon>
        <img src={imgList.icoSearch} alt="" />
      </Icon>
      {rest.type === "password" ? (
        <InputAntd.Password placeholder="โปรดระบุ" {...rest} />
      ) : (
        <InputAntd placeholder="โปรดระบุ" {...rest} />
      )}
    </Input>
  );
}

const Text = styled.div`
  margin: 0 0 0.694vw;
  @media ${device.desktopL} {
    margin: 0 0 10px;
  }
`;

const Icon = styled.div`
  position: absolute;
  left: 0.972vw;
  z-index: 1;
  transform: translate(0, -50%);
  top: 50%;
  > img {
    width: 1.667vw;
  }
  @media ${device.desktopL} {
    left: 14px;
    > img {
      width: 24px;
    }
  }
`;

const Input = styled.div`
  position: relative;
  width: 100%;
  & .ant-input-affix-wrapper {
    font-family: "SukhumvitSet-Text", sans-serif;
    border: 1px solid #d9d9d9;
    border-radius: 0.694vw;
    padding: 0.903vw 1.389vw;
    border: 0.069vw solid rgba(145, 158, 171, 0.32);
    @media ${device.desktopL} {
      border-radius: 10px;
      padding: 13px 20px;
      border: 1px solid rgba(145, 158, 171, 0.32);
    }
  }
  & .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: ${colors.primary2};
  }
  & .ant-input-password {
    & .ant-input {
      border: none !important;
      border-radius: 0 !important;
    }
  }
  & .ant-input {
    font-family: "SukhumvitSet-Text", sans-serif;
    font-size: 0.972vw;
    font-weight: 400;
    color: ${colors.blackd33};
    padding: 1.111vw 3.056vw;
    border-radius: 0.694vw;
    border: 0.069vw solid rgba(145, 158, 171, 0.32);
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(0, 0, 0, 0.25);
      opacity: 1; /* Firefox */
    }
    &::-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(0, 0, 0, 0.25);
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(0, 0, 0, 0.25);
    }
    @media ${device.desktopL} {
      font-size: 14px;
      padding: 16px 44px;
      border-radius: 10px;
      border: 1px solid rgba(145, 158, 171, 0.32);
    }
  }
  & .ant-input:hover {
    border-color: ${colors.primary2};
  }
  & .ant-input-focused,
  .ant-input:focus {
    border-color: ${colors.primary2};
    box-shadow: none;
  }
  & .ant-input-affix-wrapper-focused,
  .ant-input-affix-wrapper:focus {
    border-color: ${colors.primary2};
    box-shadow: none;
  }
  & .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
`;
