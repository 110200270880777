import styled from "styled-components";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import Routes from "./Routes";
import { colors } from "@constants/colors";
import { device } from "@constants/styles";
import configureStore from "./stores/configureStore";

function App() {
  const { store, persistor } = configureStore();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Globals>
          <Routes />
        </Globals>
      </PersistGate>
    </Provider>
  );
}

export default App;

const Globals = styled.div`
  & .text-center {
    text-align: center;
  }
  & .text-left {
    text-align: left;
  }
  & .text-right {
    text-align: right;
  }
  & .line-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  & .line-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  & .size-10 {
    font-size: 0.694vw;
    @media ${device.desktopL} {
      font-size: 10px;
    }
  }
  & .size-12 {
    font-size: 0.833vw;
    @media ${device.desktopL} {
      font-size: 12px;
    }
  }
  & .size-14 {
    font-size: 0.972vw;
    @media ${device.desktopL} {
      font-size: 14px;
    }
  }
  & .size-15 {
    font-size: 1.042vw;
    @media ${device.desktopL} {
      font-size: 15px;
    }
  }
  & .size-16 {
    font-size: 1.111vw;
    @media ${device.desktopL} {
      font-size: 16px;
    }
  }
  & .size-18 {
    font-size: 1.25vw;
    @media ${device.desktopL} {
      font-size: 18px;
    }
  }
  & .size-20 {
    font-size: 1.389vw;
    @media ${device.desktopL} {
      font-size: 20px;
    }
  }
  & .size-22 {
    font-size: 1.528vw;
    @media ${device.desktopL} {
      font-size: 22px;
    }
  }
  & .size-24 {
    font-size: 1.667vw;
    @media ${device.desktopL} {
      font-size: 24px;
    }
  }
  & .size-26 {
    font-size: 1.806vw;
    @media ${device.desktopL} {
      font-size: 26px;
    }
  }
  & .size-28 {
    font-size: 1.944vw;
    @media ${device.desktopL} {
      font-size: 28px;
    }
  }
  & .size-30 {
    font-size: 2.083vw;
    @media ${device.desktopL} {
      font-size: 30px;
    }
  }
  & .size-36 {
    font-size: 2.5vw;
    @media ${device.desktopL} {
      font-size: 36px;
    }
  }
  & .size-48 {
    font-size: 3.333vw;
    @media ${device.desktopL} {
      font-size: 48px;
    }
  }
  & .weight-400 {
    font-weight: 400;
  }
  & .weight-500 {
    font-weight: 500 !important;
  }
  & .weight-600 {
    font-weight: 600 !important;
  }
  & .weight-700 {
    font-weight: 700 !important;
  }
  & .color-black522 {
    color: ${colors.black522};
  }
  & .color-grayca4 {
    color: ${colors.grayca4};
  }
  & .color-primary {
    color: ${colors.primary};
  }
  & .color-whitecfc {
    color: ${colors.whitecfc};
  }
  & .color-gray67e {
    color: ${colors.gray67e};
  }
  & .color-blackd1f {
    color: ${colors.blackd1f};
  }
  & .color-white {
    color: ${colors.white};
  }
  & .color-blackf0f {
    color: ${colors.blackf0f};
  }
  & .color-white4f7 {
    color: ${colors.white4f7};
  }
  & .color-black {
    color: #000;
  }
  & .color-grayd9d {
    color: ${colors.grayd9d};
  }
  & .color-black747 {
    color: ${colors.black747};
  }
  & .color-black909 {
    color: ${colors.black909};
  }
  & .color-blue828 {
    color: ${colors.blue828};
  }
  & .color-gray085 {
    color: ${colors.gray085};
  }
  & .color-gray474 {
    color: ${colors.gray474};
  }
  & .color-grayf4f {
    color: ${colors.grayf4f};
  }
  & .color-status-normal {
    color: #12b76a;
  }
  & .color-status-poweroff {
    color: ${colors.gray565};
  }
  & .color-status-offline {
    color: ${colors.gray595};
  }
  & .color-status-alarm {
    color: ${colors.reda36};
  }
  & .color-status-updating {
    color: rgb(254, 188, 17);
  }
  & .color-grayaba {
    color: ${colors.grayaba};
  }
  & .color-red {
    color: red;
  }
  & .color-graye6e {
    color: ${colors.graye6e};
  }
  & .color-gray797 {
    color: ${colors.gray797};
  }
  & .color-gray565 {
    color: ${colors.gray565};
  }
  & .color-gray595 {
    color: ${colors.gray595};
  }
  & .color-reda36 {
    color: ${colors.reda36};
  }
  & .color-gray-rgba {
    color: rgba(255, 255, 255, 0.7);
  }
  & .color-gray333 {
    color: ${colors.gray333};
  }
  & .color-grayd7d {
    color: ${colors.grayd7d};
  }
  & .color-gray282 {
    color: ${colors.gray282};
  }
  & .color-greena48 {
    color: #027a48;
  }
  & .color-blue5ff {
    color: #2395ff;
  }
  & .color-orange708 {
    color: #b54708;
  }
  & .color-orange803 {
    color: #dc6803;
  }
  & .color-red318 {
    color: #b42318;
  }
  & .color-redd20 {
    color: #d92d20;
  }
  & .color-gray270 {
    color: #556270;
  }
  & .color-blueaa2 {
    color: #026aa2;
  }
  & .color-blueA4B {
    color: #2b3a4b;
  }
  & .color-gray381 {
    color: #637381;
  }
  & .color-grayb36 {
    color: #212b36;
  }
  & .bg-status-normal {
    background: #12b76a;
  }
  & .bg-status-poweroff {
    background: ${colors.gray565};
  }
  & .bg-status-offline {
    background: ${colors.gray595};
  }
  & .bg-status-alarm {
    background: ${colors.reda36};
  }
  & .bg-status-updating {
    background: rgb(254, 188, 17);
  }
  & .font-sukhumvitSet-text {
    font-family: "SukhumvitSet-Text", sans-serif;
    font-weight: 400;
  }
  & .font-sukhumvitSet-bold {
    font-family: "SukhumvitSet-Bold", sans-serif;
    font-weight: 500;
  }
  & .font-inter {
    font-family: "Inter", sans-serif;
  }
  & .font-jakarta-sans {
    font-family: "Plus Jakarta Sans", sans-serif;
  }
  & .font-jakarta-display {
    font-family: "Plus Jakarta Display", sans-serif;
  }
`;
