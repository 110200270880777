import styled from "styled-components";
import GoogleMapReact from "google-map-react";

import { imgList } from "@constants/imgList";
import { device } from "@constants/styles";

export default function GoogleMapSM({ lat = 0, lng = 0, googlePlaceId = "" }) {
  const Marker = () => {
    return (
      <MarkerContainer className="mark">
        <img src={imgList.marker_sm} alt="" className="mark__marker" />
      </MarkerContainer>
    );
  };
  return (
    <Container>
      <div
        className="title font-sukhumvitSet-bold size-14 color-black"
        onClick={() =>
          window.open(
            `https://www.google.com/maps/place/?q=place_id:${googlePlaceId}`,
            "_blank"
          )
        }
      >
        ดูบนแผนที่
      </div>
      <div className="map-div">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_KEY }}
          center={{
            lat: lat,
            lng: lng,
          }}
          zoom={15}
          draggable={false}
          zoomControl={false}
          clickableIcons={false}
          fullscreenControl={false}
          disableDefaultUI={true}
          yesIWantToUseGoogleMapApiInternals
        >
          <Marker lat={lat} lng={lng} />
        </GoogleMapReact>
      </div>
    </Container>
  );
}
const MarkerContainer = styled.div`
  position: absolute;
  transform: translate(-50%, 0);
  bottom: 0;
  width: 1.389vw;
  cursor: pointer;
  z-index: 9;
  > img {
    width: 100%;
  }
  @media ${device.desktopL} {
    width: 20px;
  }
  .mark {
    &__text {
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      > span {
        vertical-align: super;
      }
    }
    &__marker {
      position: relative;
      z-index: 1;
    }
    &__tool {
      position: absolute;
      bottom: -20%;
    }
  }
`;
const Container = styled.div`
  position: relative;
  width: 100%;
  height: 134px;
  border-radius: 12px;
  overflow: hidden;
  margin: 4px 0 0;
  & .gm-fullscreen-control {
    display: none;
  }
  & .title {
    position: absolute;
    bottom: 16%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100px;
    height: 35px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #bcbcbc;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    cursor: pointer;
  }
  & .map-div {
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`;
