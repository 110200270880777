import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { imgList } from "@constants/imgList";
import { device } from "@constants/styles";
import { displayImage } from "@utils/image";
interface IBoxDevice {
  data: any;
  selected: boolean;
  locationID?: string;
  onClick?: (e: any) => void;
  onMute?: () => void;
}

export default function BoxDevice({
  data = {},
  selected = false,
  locationID = "",
  onClick = () => {},
  onMute = () => {},
}: IBoxDevice) {
  const navigate = useNavigate();
  const renderStatus = (status = "", statusText = "") => {
    let color = "";
    switch (status) {
      case "normal":
        color = "color-status-normal";
        break;
      case "powerOff":
        color = "color-status-poweroff";
        break;
      case "offline":
        color = "color-status-offline";
        break;
      case "alarm":
        color = "color-status-alarm";
        break;
      case "updating":
        color = "color-status-updating";
        break;
      default:
        color = "color-status-normal";
        break;
    }
    return (
      <div className={`size-12 font-sukhumvitSet-text color-grayaba`}>
        Status: <span className={color}>{statusText}</span>
      </div>
    );
  };
  const renderWifi = (wifi = false, rssi = 0, wifiStrength = "") => {
    if (wifiStrength === "offline") {
      return (
        <Mode active={wifi}>
          <img src={imgList[`ico_wifi_offline`]} alt="" />
        </Mode>
      );
    } else {
      if (rssi > -50) {
        return (
          <Mode active={true}>
            <img src={imgList[`ico_wifi_active`]} alt="" />
          </Mode>
        );
      } else if (rssi <= -50 && rssi >= -59) {
        return (
          <Mode active={true}>
            <img src={imgList[`ico_wifi_1`]} alt="" />
          </Mode>
        );
      } else if (rssi <= -60 && rssi >= -69) {
        return (
          <Mode active={true} isError={true}>
            <img src={imgList[`ico_wifi_2`]} alt="" />
          </Mode>
        );
      } else {
        return (
          <Mode active={true} isError={true}>
            <img src={imgList[`ico_wifi_3`]} alt="" />
          </Mode>
        );
      }
    }
  };
  const renderLight = (light = false) => {
    return (
      <Mode active={light}>
        <img
          src={imgList[`ico_light_bulb_${light ? "active" : "disable"}`]}
          alt=""
        />
      </Mode>
    );
  };
  const renderComp = (status = "", comp = false, flash = false) => {
    const active = status !== "updating" && (comp || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_snow_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderFan = (status = "", fan = false, flash = false) => {
    const active = status !== "updating" && (fan || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_fan_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderDefrost = (status = "", defrost = false, flash = false) => {
    const active = status !== "updating" && (defrost || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_bulb_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderAlarm = (alarm = false, flash = false) => {
    return (
      <Mode active={alarm || flash} isError={false}>
        <img
          src={imgList[`ico_shield_${alarm || flash ? "active" : "disable"}`]}
          alt=""
          className={`${alarm || flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderDoor = (status = "", door = false, flash = false) => {
    const active = status !== "updating" && (door || flash) ? true : false;
    return (
      <Mode active={active}>
        <img
          src={imgList[`ico_door_${active ? "active" : "disable"}`]}
          alt=""
          className={`${flash ? "animate-flicker" : ""}`}
        />
      </Mode>
    );
  };
  const renderSound = (macAddress = "", soundAlarm = false, alarm = false) => {
    if (alarm) {
      if (soundAlarm) {
        return (
          <img
            src={imgList.ico_sound}
            alt=""
            className="box__sound"
            onClick={(e) => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              onMute();
            }}
          />
        );
      } else {
        return (
          <img src={imgList.ico_sound_disable} alt="" className="box__sound" />
        );
      }
    } else {
      return (
        <img
          src={imgList.ico_sound_disable}
          alt=""
          className="box__sound box__sound--hide"
        />
      );
    }
  };
  return selected ? (
    <ContainerDetail
      id={`box-device-${data?.deviceInfo?.macAddress}`}
      className="box"
      onClick={() =>
        onClick({
          macAddress: data?.deviceInfo?.macAddress ?? "",
          lat: data?.deviceInfo?.coordinates[1] ?? "",
          lng: data?.deviceInfo?.coordinates[0] ?? "",
        })
      }
    >
      <div className="box__top">
        <div className="box__img">
          <img
            src={
              data?.deviceInfo?.image ?? ""
                ? displayImage(data?.deviceInfo?.image ?? "")
                : imgList.ex
            }
            alt=""
          />
        </div>
        <div className="box__content">
          <div className="size-14 color-white font-sukhumvitSet-text weight-600">
            {data?.deviceInfo?.name ?? ""}
          </div>
          <div className="size-12 color-grayaba font-sukhumvitSet-text">
            Current Temp{" "}
            <span className="color-white">
              {(data?.deviceInfo?.currentTemp ?? 0).toFixed(1)}°C
            </span>
          </div>
        </div>
        {renderSound(
          data?.controlInfo?.macAddress ?? "",
          data?.controlInfo?.soundAlarm ?? false,
          data?.controlInfo?.control?.alarm ?? false
        )}
      </div>
      <div className="box__mid">
        {renderStatus(
          data?.deviceStatus?.status ?? "",
          data?.deviceStatus?.statusDisplayText ?? ""
        )}
        <div className="size-12 font-sukhumvitSet-text color-white">
          24H Avg
        </div>
      </div>
      <div className="box__mid box__mid--2">
        <div className="size-12 font-sukhumvitSet-text color-grayaba">
          Mode:{" "}
          <span className="color-white">
            {data?.deviceStatus?.modeDisplayText ?? ""}
          </span>
        </div>
        <div className="size-12 font-sukhumvitSet-text color-grayaba">
          Electricity unit:{" "}
          <span className="color-white">
            {(data?.deviceStatus?.electricityUnit ?? 0).toFixed(1)} kWh
          </span>
        </div>
      </div>
      <div className="box__mid box__mid--2">
        <div className="size-12 font-sukhumvitSet-text color-grayaba"></div>
        <div className="size-12 font-sukhumvitSet-text color-grayaba">
          Voltage:{" "}
          <span className="color-white">
            {data?.deviceStatus?.voltage ?? ""}V
          </span>
        </div>
      </div>
      <div className="box__group">
        {renderWifi(
          data?.controlInfo?.control?.wifi ?? false,
          data?.controlInfo?.rssi ?? 0,
          data?.controlInfo?.wifiStrength ?? "good"
        )}
        {renderLight(data?.controlInfo?.control?.light ?? false)}
        {renderDefrost(
          data?.deviceStatus?.status ?? "",
          data?.controlInfo?.control?.defrost ?? false,
          data?.controlInfo?.flashing?.defrost ?? false
        )}
        {renderFan(
          data?.deviceStatus?.status ?? "",
          data?.controlInfo?.control?.fan ?? false,
          data?.controlInfo?.flashing?.fan ?? false
        )}
        {renderComp(
          data?.deviceStatus?.status ?? "",
          data?.controlInfo?.control?.comp ?? false,
          data?.controlInfo?.flashing?.comp ?? false
        )}
        {renderAlarm(
          data?.controlInfo?.control?.alarm ?? false,
          data?.controlInfo?.flashing?.alarm ?? false
        )}
        {renderDoor(
          data?.deviceStatus?.status ?? "",
          data?.controlInfo?.control?.door ?? false,
          data?.controlInfo?.flashing?.door ?? false
        )}
      </div>
      {(data?.controlInfo?.errors ?? []).length > 0 && (
        <Danger className="danger">
          {(data?.controlInfo?.errors ?? []).map((e: any, key: number) => {
            return (
              <React.Fragment key={key}>
                <div
                  className={`danger__left ${
                    key !== 0 ? "danger__left--hide" : ""
                  }`}
                >
                  <img src={imgList.ico_danger} alt="" />
                </div>
                {/* <div className="danger__right">
                  <div className="size-12 weight-600 font-sukhumvitSet-text color-white">
                    {e?.code ?? ""} :{" "}
                    <span className="size-10 font-sukhumvitSet-text color-white">
                      {e?.description ?? ""}
                    </span>
                  </div>
                </div> */}
                <div className="danger__middle">
                  <div className="size-12 weight-600 font-sukhumvitSet-text color-white">
                    {e?.code ?? ""}:
                  </div>
                </div>
                <div className="danger__right">
                  <div className="size-10 font-sukhumvitSet-text color-white">
                    {e?.description ?? ""}
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </Danger>
      )}
      <div className="box__text-group">
        <div className="box__text box__text--sp">
          <img src={imgList.ico_location_gray} alt="" />
          <div className="color-white size-12 font-sukhumvitSet-text">
            {data?.addressText ?? "" ? data?.addressText ?? "" : "-"}
          </div>
        </div>
      </div>
      {/* <div className="box__text-group">
        <div className="box__text">
          <img src={imgList.ico_build_gray} alt="" />
          <div className="color-white size-12 font-sukhumvitSet-text">
            โซนออฟฟิศ
          </div>
        </div>
        <div className="box__text">
          <img src={imgList.ico_folder_fray} alt="" />
          <div className="color-white size-12 font-sukhumvitSet-text">
            ห้องเย็น
          </div>
        </div>
      </div> */}
      <div className="box__hr" />
      <div className="box__btn-group">
        <div className="box__btn-device color-white font-sukhumvitSet-text size-12 weight-400">
          <img src={imgList.ico_user} alt="" />
          <div>{data?.isOwner ?? false ? "My Device" : "Shared with me"}</div>
        </div>
        <div
          className="box__btn-detail color-black font-sukhumvitSet-text size-14 weight-600"
          // onClick={() =>
          //   navigate(
          //     `/device-detail/${locationID}/${
          //       data?.deviceInfo?.macAddress ?? ""
          //     }`
          //   )
          // }
          onClick={() =>
            navigate(`/device-detail/${data?.deviceInfo?.macAddress ?? ""}`)
          }
        >
          ดูรายละเอียด
        </div>
      </div>
    </ContainerDetail>
  ) : (
    <Container
      id={`box-device-${data?.deviceInfo?.macAddress}`}
      className="box"
      onClick={() =>
        onClick({
          macAddress: data?.deviceInfo?.macAddress ?? "",
          lat: data?.deviceInfo?.coordinates[1] ?? "",
          lng: data?.deviceInfo?.coordinates[0] ?? "",
        })
      }
    >
      <div className="box__top">
        <div className="box__img">
          <img
            src={
              data?.deviceInfo?.image ?? ""
                ? displayImage(data?.deviceInfo?.image ?? "")
                : imgList.ex
            }
            alt=""
          />
        </div>
        <div className="box__content">
          <div className="size-14 color-black font-sukhumvitSet-text weight-600">
            {data?.deviceInfo?.name ?? ""}
          </div>
          <div className="size-12 color-grayf4f font-sukhumvitSet-text">
            Current Temp{" "}
            <span className="color-black">
              {(data?.deviceInfo?.currentTemp ?? 0).toFixed(1)}°C
            </span>
          </div>
        </div>
        {renderSound(
          data?.controlInfo?.macAddress ?? "",
          data?.controlInfo?.soundAlarm ?? false,
          data?.controlInfo?.control?.alarm ?? false
        )}
      </div>
      <div className="box__mid">
        {renderStatus(
          data?.deviceStatus?.status ?? "",
          data?.deviceStatus?.statusDisplayText ?? ""
        )}
        <div className="size-12 font-sukhumvitSet-text color-grayf4f">
          Mode:{" "}
          <span className="color-black">
            {data?.deviceStatus?.modeDisplayText ?? ""}
          </span>
        </div>
      </div>
      <div className="box__group">
        {renderWifi(
          data?.controlInfo?.control?.wifi ?? false,
          data?.controlInfo?.rssi ?? 0,
          data?.controlInfo?.wifiStrength ?? "good"
        )}
        {renderLight(data?.controlInfo?.control?.light ?? false)}
        {renderDefrost(
          data?.deviceStatus?.status ?? "",
          data?.controlInfo?.control?.defrost ?? false,
          data?.controlInfo?.flashing?.defrost ?? false
        )}
        {renderFan(
          data?.deviceStatus?.status ?? "",
          data?.controlInfo?.control?.fan ?? false,
          data?.controlInfo?.flashing?.fan ?? false
        )}
        {renderComp(
          data?.deviceStatus?.status ?? "",
          data?.controlInfo?.control?.comp ?? false,
          data?.controlInfo?.flashing?.comp ?? false
        )}
        {renderAlarm(
          data?.controlInfo?.control?.alarm ?? false,
          data?.controlInfo?.flashing?.alarm ?? false
        )}
        {renderDoor(
          data?.deviceStatus?.status ?? "",
          data?.controlInfo?.control?.door ?? false,
          data?.controlInfo?.flashing?.door ?? false
        )}
      </div>
      {!(data?.hasLocated ?? false) && (
        <Warning className="warn">
          <div className="warn__left">
            <img src={imgList.ico_warning} alt="" />
          </div>
          <div className="warn__right">
            <div className="size-12 weight-600 font-sukhumvitSet-text color-black">
              โปรดระบุตำแหน่งอุปกรณ์ของคุณ
            </div>
            <div className="size-10 font-sukhumvitSet-text color-grayf4f">
              อุปกรณ์ที่ไม่ได้ระบุตำแหน่ง จะไม่แสดงบนแผนที่
            </div>
          </div>
        </Warning>
      )}
    </Container>
  );
}
interface IStyle {
  active: boolean;
  isError?: boolean;
}
const ContainerDetail = styled.div`
  width: 100%;
  background: #101828;
  border: 0.069vw solid #eceef7;
  box-shadow: 0px 1.389vw 4.167vw rgba(0, 0, 0, 0.05);
  border-radius: 0.833vw;
  padding: 0.833vw 1.111vw;
  margin: 0.972vw 0 0;
  cursor: pointer;
  @media ${device.desktopL} {
    border: 1px solid #eceef7;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 12px 16px;
    margin: 14px 0 0;
  }
  .box {
    &__top {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &__img {
      position: relative;
      width: 3.542vw;
      height: 3.542vw;
      overflow: hidden;
      background: #e9e9e9;
      border: 0.069vw solid #eef6ff;
      border-radius: 0.556vw;
      > img {
        position: absolute;
        width: 110%;
        height: 110%;
        max-width: unset;
        max-height: unset;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      @media ${device.desktopL} {
        width: 51px;
        height: 51px;
        border: 1px solid #eef6ff;
        border-radius: 8px;
      }
    }
    &__content {
      width: calc(100% - 3.542vw - 1.736vw - 1.389vw);
      > div {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &:first-child {
          margin: 0.347vw 0 0;
        }
      }
      @media ${device.desktopL} {
        width: calc(100% - 51px - 25px - 20px);
        > div {
          &:first-child {
            margin: 5px 0 0;
          }
        }
      }
    }
    &__sound {
      pointer-events: all;
      width: 1.736vw;
      height: 1.736vw;
      @media ${device.desktopL} {
        width: 25px;
        height: 25px;
      }
      &--hide {
        opacity: 0;
      }
    }
    &__mid {
      width: 100%;
      margin: 0.694vw 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &--2 {
        margin: 0.278vw 0 0;
      }
      @media ${device.desktopL} {
        margin: 10px 0 0;
        &--2 {
          margin: 4px 0 0;
        }
      }
    }
    &__group {
      width: 100%;
      margin: 1.042vw 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        width: calc(70% / 7);
        padding-top: calc(70% / 7);
      }
      @media ${device.desktopL} {
        margin: 15px 0 0;
      }
    }
    &__text-group {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 0.833vw 0 0;
      @media ${device.desktopL} {
        margin: 12px 0 0;
      }
    }
    &__text {
      width: 48%;
      display: flex;
      align-items: flex-start;
      > img {
        width: 1.111vw;
        margin: 0 0.417vw 0 0;
      }
      > div {
        width: calc(100% - 1.528vw);
      }
      &--sp {
        width: 100%;
      }
      @media ${device.desktopL} {
        > img {
          width: 16px;
          margin: 0 6px 0 0;
        }
        > div {
          width: calc(100% - 22px);
        }
      }
    }
    &__hr {
      border-top: 0.035vw solid rgba(255, 255, 255, 0.2);
      margin: 1.111vw 0;
      @media ${device.desktopL} {
        border-top: 0.5px solid rgba(255, 255, 255, 0.2);
        margin: 16px 0;
      }
    }
    &__btn-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    &__btn-device {
      display: inline-flex;
      align-items: center;
      background: rgba(255, 255, 255, 0.14);
      border-radius: 0.556vw;
      padding: 0.278vw 0.694vw;
      cursor: pointer;
      > img {
        width: 1.111vw;
        margin: 0 0.278vw 0 0;
      }
      > div {
        width: calc(100% - 1.389vw);
        margin: 0.139vw 0 0;
      }
      @media ${device.desktopL} {
        border-radius: 8px;
        padding: 4px 10px;
        > img {
          width: 16px;
          margin: 0 4px 0 0;
        }
        > div {
          width: calc(100% - 20px);
          margin: 2px 0 0;
        }
      }
    }
    &__btn-detail {
      display: inline-block;
      background: #febc11;
      border-radius: 0.556vw;
      padding: 0.694vw 1.25vw;
      cursor: pointer;
      @media ${device.desktopL} {
        border-radius: 8px;
        padding: 10px 18px;
      }
    }
  }
`;
const Danger = styled.div`
  width: 100%;
  background: #c41616;
  border-radius: 0.417vw;
  padding: 0.694vw;
  margin: 0.694vw 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media ${device.desktopL} {
    border-radius: 6px;
    padding: 10px;
    margin: 10px 0 0;
  }
  .danger {
    &__left {
      width: 1.111vw;
      margin: 0 0.486vw 0 0;
      &--hide {
        opacity: 0;
      }
      > img {
        max-width: 100% !important;
      }
      @media ${device.desktopL} {
        width: 16px;
        margin: 0 7px 0 0;
      }
    }
    // &__right {
    //   width: calc(100% - 1.25vw - 0.486vw);
    //   @media ${device.desktopL} {
    //     width: calc(100% - 18px - 7px);
    //   }
    // }
    &__middle {
      width: 1.528vw;
      margin: 0 0 0 0;
      @media ${device.desktopL} {
        width: 22px;
        margin: 0 0 0 0;
      }
    }
    &__right {
      width: calc(100% - 1.25vw - 1.528vw - 0.486vw);
      line-height: 1.3;
      margin: 0.208vw 0 0;
      @media ${device.desktopL} {
        width: calc(100% - 18px - 22px - 7px);
        margin: 3px 0 0;
      }
    }
  }
`;
const Warning = styled.div`
  width: 100%;
  background: #fff5cc;
  border-radius: 0.417vw;
  padding: 0.694vw;
  margin: 0.694vw 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media ${device.desktopL} {
    border-radius: 6px;
    padding: 10px;
    margin: 10px 0 0;
  }
  .warn {
    &__left {
      width: 1.25vw;
      margin: 0 0.486vw 0 0;
      @media ${device.desktopL} {
        width: 18px;
        margin: 0 7px 0 0;
      }
      > img {
        max-width: 100% !important;
      }
    }
    &__right {
      width: calc(100% - 1.25vw - 0.486vw);
      @media ${device.desktopL} {
        width: calc(100% - 18px - 7px);
      }
    }
  }
`;
const Mode = styled.div<IStyle>`
  & .animate-flicker {
    -webkit-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
  }
  position: relative;
  background: ${(props) =>
    props.active
      ? props.isError
        ? "rgba(255, 221, 221, 0.3)"
        : "#eef6ff"
      : "rgba(208, 209, 211, 0.4)"};
  border-radius: 0.417vw;
  > img {
    position: absolute;
    width: 58%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media ${device.desktopL} {
    border-radius: 6px;
  }
`;
const Container = styled.div`
  width: 100%;
  background: #ffffff;
  border: 0.069vw solid #eceef7;
  box-shadow: 0px 1.389vw 4.167vw rgba(0, 0, 0, 0.05);
  border-radius: 0.833vw;
  padding: 0.833vw 1.111vw;
  margin: 0.972vw 0 0;
  cursor: pointer;
  @media ${device.desktopL} {
    border: 1px solid #eceef7;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 12px 16px;
    margin: 14px 0 0;
  }
  .box {
    &__top {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
    &__img {
      position: relative;
      width: 3.542vw;
      height: 3.542vw;
      overflow: hidden;
      background: #e9e9e9;
      border: 0.069vw solid #eef6ff;
      border-radius: 0.556vw;
      > img {
        position: absolute;
        width: 110%;
        height: 110%;
        max-width: unset;
        max-height: unset;
        object-fit: cover;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      @media ${device.desktopL} {
        width: 51px;
        height: 51px;
        border: 1px solid #eef6ff;
        border-radius: 8px;
      }
    }
    &__content {
      width: calc(100% - 3.542vw - 1.736vw - 1.389vw);
      > div {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &:first-child {
          margin: 0.347vw 0 0;
        }
      }
      @media ${device.desktopL} {
        width: calc(100% - 51px - 25px - 20px);
        > div {
          &:first-child {
            margin: 5px 0 0;
          }
        }
      }
    }
    &__sound {
      pointer-events: all;
      width: 1.736vw;
      height: 1.736vw;
      @media ${device.desktopL} {
        width: 25px;
        height: 25px;
      }
      &--hide {
        opacity: 0;
      }
    }
    &__mid {
      width: 100%;
      margin: 0.694vw 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &--2 {
        margin: 0.278vw 0 0;
      }
      @media ${device.desktopL} {
        margin: 10px 0 0;
        &--2 {
          margin: 4px 0 0;
        }
      }
    }
    &__group {
      width: 100%;
      margin: 1.042vw 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        width: calc(70% / 6);
        padding-top: calc(70% / 6);
      }
      @media ${device.desktopL} {
        margin: 15px 0 0;
      }
    }
  }
`;
