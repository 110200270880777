export const regexEmail =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const regexEng = /^[a-zA-Z\s]*$/;

export const regexThai = /^[\u0E00-\u0E7F\s]*$/;

export const regexNumber = /^[0-9\s]*$/;

export const regexNumberWithDot = /^[0-9]*\.?[0-9]*$/;

export const regexLatLng = /^[0-9]*\.?[0-9]*$/;

export const regextNotNumber = /^([^0-9]*)$/;
